import { BaseUrl, POST_REVIEW, USER_LOGIN } from "../../constants/apiConstants";
import api from "../../helpers/apiUtils";
import { getSession, SESSION_KEYS } from "../../helpers/sessionStore";
import legacyApi from "../../helpers/legacyApiHelper";

const postReview = body => {
  const gatOrgUnit = getSession(SESSION_KEYS.gatOrganizationUnit);
  const gatLocale = getSession(SESSION_KEYS.locale);
  const appId = getSession(SESSION_KEYS.appId);
  return api
    .post(`${POST_REVIEW}`, BaseUrl.GatUrl, body, {
      gatOrgUnit,
      gatLocale,
      appId
    })
    .then(res => {
      return res;
    })
    .catch(err => {
      throw err;
    });
};

const fetchToken = body => {
  return legacyApi
    .post(`${USER_LOGIN}`, BaseUrl.RescueUrl, {
      mobile: body.mobile,
      regno: body.regno
    })
    .then(res => {
      return res.data.access_token;
    })
    .catch(err => {
      throw err;
    });
};

export default { postReview, fetchToken };
