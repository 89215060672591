const getCustomerCoordinates = ({ position }) =>
  position.get("customerPosition");

const getVehiclePosition = ({ position }) => position.get("vehiclePosition");

const getServeVehiclePosition = ({ position }) =>
  position.get("positionToServe");

const getDestinationPosition = ({ position }) =>
  position.get("destinationChangerPosition");

const getShopCoordinates = ({ position }) => position.get("shopPosition");

const getUnsavedShopPosition = ({ position }) =>
  position.get("unsavedShopPosition");

const getTowtruckCoordinates = ({ position }) =>
  position.get("towtruckPosition");

const getPreviousTowtruckCoordinates = ({ position }) =>
  position.get("previousTowtruckPosition");

const getNoTowTruckModalState = ({ position }) =>
  position.get("noTowTrucksModalState");

const getNoLocationServiceModalState = ({ position }) =>
  position.get("noLocationServiceModalState");

const getIsFetchingReverseGeoCode = ({ position }) =>
  position.get("isFetchingVehiclePosition");

export default {
  getCustomerCoordinates,
  getShopCoordinates,
  getTowtruckCoordinates,
  getPreviousTowtruckCoordinates,
  getNoTowTruckModalState,
  getNoLocationServiceModalState,
  getVehiclePosition,
  getIsFetchingReverseGeoCode,
  getUnsavedShopPosition,
  getDestinationPosition,
  getServeVehiclePosition
};
