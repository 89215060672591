import React from "react";
import {
  GOTEBORG_SUBDOMAIN,
  MALMO_SUBDOMAIN,
  STOCKHOLM_SUBDOMAIN
} from "../constants/routeConstants";

export const isSubdomainHelper = () => {
  const subdomain = window.location.hostname.split(".")[1];
  switch (subdomain) {
    case STOCKHOLM_SUBDOMAIN:
    case MALMO_SUBDOMAIN:
    case GOTEBORG_SUBDOMAIN:
      return true;
    default:
      return false;
  }
};

export const subdomainStringsRender = intl => {
  const subdomain = window.location.hostname.split(".")[1];

  switch (subdomain) {
    case STOCKHOLM_SUBDOMAIN:
      return (
        <>
          <p>
            {intl.formatMessage({
              id: "SubdomainInfo.Stockholm",
              defaultMessage:
                "Assistansbolaget are quick to help you with towing of cars, light trucks, trailers, motorhomes or MC’s in Stockholm. Upon arrival we try to repair your vehicle on spot, if that’s not possible we’ll tow the vehicle to the nearest workshop.",
              description: "SEO text for Stockholm subdomain"
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: "SubdomainInfo.GeneralInfo",
              defaultMessage:
                "Our towing is safe and price worthy. We are the leading option with 350 000 satisfied customers in the Nordics.",
              description: "General towing info for subdomain."
            })}
          </p>
        </>
      );
    case MALMO_SUBDOMAIN:
      return (
        <>
          <p>
            {intl.formatMessage({
              id: "SubdomainInfo.Malmo",
              defaultMessage:
                "Assistansbolaget are quick to help you with towing of cars, light trucks, trailers, motorhomes or MC’s in Malmo. Upon arrival we try to repair your vehicle on spot, if that’s not possible we’ll tow the vehicle to the nearest workshop.",
              description: "SEO text for Malmo subdomain"
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: "SubdomainInfo.GeneralInfo",
              defaultMessage:
                "Our towing is safe and price worthy. We are the leading option with 350 000 satisfied customers in the Nordics.",
              description: "General towing info for subdomain."
            })}
          </p>
        </>
      );
    case GOTEBORG_SUBDOMAIN:
      return (
        <>
          <p>
            {intl.formatMessage({
              id: "SubdomainInfo.Goteborg",
              defaultMessage:
                "Assistansbolaget are quick to help you with towing of cars, light trucks, trailers, motorhomes or MC’s in Goteborg. Upon arrival we try to repair your vehicle on spot, if that’s not possible we’ll tow the vehicle to the nearest workshop.",
              description: "SEO text for Goteborg subdomain"
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: "SubdomainInfo.GeneralInfo",
              defaultMessage:
                "Our towing is safe and price worthy. We are the leading option with 350 000 satisfied customers in the Nordics.",
              description: "General towing info for subdomain."
            })}
          </p>
        </>
      );
    default:
      return null;
  }
};
