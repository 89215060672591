import {
  START_CANCEL_ORDER,
  OPEN_CANCEL_ORDER_MODAL,
  CLOSE_CANCEL_ORDER_MODAL,
  TOGGLE_STARTED_ORDER_MODAL,
  GET_ESTIMATED_ARRIVAL_TIME,
  GET_ESTIMATED_ARRIVAL_TIME_SUCCESS,
  CANCEL_ESTIMATED_ARRIVAL_TIME_POLLER,
  CANCEL_FETCH_TOWTRUCK_POSITION,
  FETCH_TOWTRUCK_POSITION,
  SET_ORDER_STATUS,
  TOW_TRUCK_CANCELLED,
  SET_TOWTRUCK_LOCATION,
  GET_MISSION_BY_TOKEN,
  GET_MISSION_BY_TOKEN_SUCCESS,
  GET_MISSION_BY_TOKEN_FAILURE, FIND_CURRENT_VIEW
} from "./constants";

const findCurrentView = () => ({
  type: FIND_CURRENT_VIEW
});

const cancelOrder = () => ({
  type: START_CANCEL_ORDER
});

const openCancelModal = () => ({
  type: OPEN_CANCEL_ORDER_MODAL
});

const closeCancelModal = () => ({
  type: CLOSE_CANCEL_ORDER_MODAL
});

const toggleStartedModal = bool => ({
  type: TOGGLE_STARTED_ORDER_MODAL,
  payload: bool
});

const getEstimatedArrivalTime = () => ({
  type: GET_ESTIMATED_ARRIVAL_TIME
});

const getEstimatedArrivalTimeSuccess = time => ({
  type: GET_ESTIMATED_ARRIVAL_TIME_SUCCESS,
  payload: time
});

const cancelEstimatedArrivalTimePoller = () => ({
  type: CANCEL_ESTIMATED_ARRIVAL_TIME_POLLER
});

const cancelFetchTowtruckPosition = () => ({
  type: CANCEL_FETCH_TOWTRUCK_POSITION
});

const fetchTowtruckPosition = () => ({
  type: FETCH_TOWTRUCK_POSITION
});

const setOrderStatus = order => ({
  type: SET_ORDER_STATUS,
  payload: order
});

const towTruckCancelled = () => ({
  type: TOW_TRUCK_CANCELLED
});

const setTowTruckLocation = towtruckPosition => ({
  type: SET_TOWTRUCK_LOCATION,
  towtruckPosition
});

const getMission = token => ({
  type: GET_MISSION_BY_TOKEN,
  token
});

const getMissionSuccess = mission => {
  return {
    type: GET_MISSION_BY_TOKEN_SUCCESS,
    mission
  };
};

const getMissionFailure = error => ({
  type: GET_MISSION_BY_TOKEN_FAILURE,
  error
});

export default {
  cancelOrder,
  findCurrentView,
  openCancelModal,
  closeCancelModal,
  toggleStartedModal,
  getEstimatedArrivalTime,
  getEstimatedArrivalTimeSuccess,
  cancelEstimatedArrivalTimePoller,
  cancelFetchTowtruckPosition,
  fetchTowtruckPosition,
  setOrderStatus,
  towTruckCancelled,
  setTowTruckLocation,
  getMission,
  getMissionSuccess,
  getMissionFailure
};
