import {
  SET_RATING,
  SET_DISPLAY_VALUE,
  SUMMARY_SUBMITTED,
  TOGGLE_SUMMARY_SUCCESS_MODAL,
  TOGGLE_SUMMARY_FAILURE_MODAL,
  RECEIPT_SUBMITTED,
  TOGGLE_RECEIPT_FAILURE_MODAL,
} from "./constants";

const setRating = (starsSelected) => ({
  type: SET_RATING,
  starsSelected,
});

const setDisplayValue = (value) => ({
  type: SET_DISPLAY_VALUE,
  value,
});

const submitSummaryForm = () => ({
  type: SUMMARY_SUBMITTED,
});

const submitReceiptForm = () => ({
  type: RECEIPT_SUBMITTED,
});

const toggleSummarySuccessModal = (bool) => ({
  type: TOGGLE_SUMMARY_SUCCESS_MODAL,
  payload: bool,
});

const toggleSummaryFailureModal = (bool) => ({
  type: TOGGLE_SUMMARY_FAILURE_MODAL,
  payload: bool,
});

const toggleReceiptFailureModal = (bool) => ({
  type: TOGGLE_RECEIPT_FAILURE_MODAL,
  payload: bool,
});

export default {
  setRating,
  setDisplayValue,
  submitSummaryForm,
  toggleSummarySuccessModal,
  toggleSummaryFailureModal,
  submitReceiptForm,
  toggleReceiptFailureModal,
};
