import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import actions from "./actions";
import selectors from "./selectors";
import welcomeScreenSelectors from "../welcomeScreen/selectors";
import languageHelper from "../../helpers/languageHelper";
import formatPrice from "../../helpers/formatPrice";
import { DAMAGE_REASON_VIEW, SEEKING_RESPONDER_VIEW } from "./viewConstants";
import CardBoxContainer from "../../components/CardBoxContainer/CardBoxContainer";
import NextStepButton from "../../components/ButtonContainer/NextStepButton";
import EstimateDestination from "./renderDestinationInput";

const language = languageHelper();

const {
  estimatePrice,
  goBack,
  setCurrentView,
  setPreviousView,
  setNextView,
  goForward,
  toggleEstimateDestination
} = actions;

const {
  getEstimateAssistancePrice,
  getEstimateTowingPrice,
  getEstimateTime,
  getNextView,
  getPreviousView,
  getShowTowDestinationPicker,
  getShowPreviousEstimate,
  getPreviousEstimatePrice,
  getHasPreviousVehiclePositionChanged,
  getCustomerType
} = selectors;
const { getVehiclePosition, getShopCoordinates } = welcomeScreenSelectors;

class EstimatePrice extends React.Component {
  componentDidMount() {
    const {
      getEstimatePrice,
      previousView,
      nextView,
      showPreviousEstimate,
      vehiclePositionHasChanged
    } = this.props;
    previousView();
    nextView();
    if (!showPreviousEstimate || vehiclePositionHasChanged) getEstimatePrice();
  }

  render() {
    const {
      estimatedAssistancePrice,
      estimatedTowingPrice,
      estimatedTime,
      vehiclePosition,
      next,
      previous,
      showTowDestinationPicker,
      shopLocation,
      showPreviousEstimate,
      previousEstimatePrice,
      customerType
    } = this.props;

    let DisplayDestinationComponent;

    if (shopLocation && shopLocation.formattedAddress) {
      const DisplayNameAndCity = (
        <span
          style={{ fontSize: "12px" }}
        >{`${shopLocation.name}, ${shopLocation.city}`}</span>
      );

      const DisplayNameAndAddress = (
        <span style={{ fontSize: "12px" }}>
          {`${shopLocation.name}, ${shopLocation.formattedAddress.substring(
            0,
            14
          )}...`}
        </span>
      );

      const DisplayAddress = (
        <span style={{ fontSize: "12px" }}>
          {shopLocation.formattedAddress.length >= 36
            ? `${shopLocation.formattedAddress.substring(0, 36)}...`
            : shopLocation.formattedAddress}
        </span>
      );

      if (shopLocation.name && shopLocation.city) {
        DisplayDestinationComponent = DisplayNameAndCity;
      } else if (shopLocation.name && !shopLocation.city) {
        DisplayDestinationComponent = DisplayNameAndAddress;
      } else {
        DisplayDestinationComponent = DisplayAddress;
      }
    }

    const d = moment();
    d.add(estimatedTime, "s");
    if (
      estimatedTowingPrice === 0 &&
      estimatedAssistancePrice === 0 &&
      estimatedTime === 0
    ) {
      return null;
    }

    const engineInsuranceCoverage = customerType === "ENGINE_INSURANCE";
    return (
      <>
        <CardBoxContainer minimumHeight="200px">
          <h3>
            {showTowDestinationPicker && !showPreviousEstimate
              ? language.createOrder.estimatePrice.titleChange
              : language.createOrder.estimatePrice.title}
          </h3>
          <div className="info-text-container hideable center-text">
            {engineInsuranceCoverage ? (
              language.createOrder.estimatePrice.textEngineInsuranceCoverage
            ) : (
              <>
                {language.createOrder.estimatePrice.text1}
                <span className="font-bold">{` ${formatPrice(
                  estimatedAssistancePrice
                )} ${language.currency}. `}</span>
                {language.createOrder.estimatePrice.text2}
                <span className="font-bold">
                  {` ${formatPrice(estimatedTowingPrice)} ${
                    language.currency
                  }.`}
                </span>
              </>
            )}
          </div>
          <div className="flex-column justify-between">
            <div className="flex">
              <span className="font-bold flex-grow">
                {language.createOrder.estimatePrice.suggestedArrival}
              </span>
              <span className="font-bold">{`${
                language.createOrder.estimatePrice.time
              } ${d.format("HH:mm")}`}</span>
            </div>
            <div className="flex justify-between hideable">
              <span style={{ fontSize: "12px" }}>
                {vehiclePosition && vehiclePosition.address.length >= 36
                  ? `${vehiclePosition.address.substring(0, 36)}...`
                  : vehiclePosition.address}
              </span>
              <span className="font-italic" style={{ fontSize: "12px" }}>
                {language.createOrder.preliminary}
              </span>
            </div>
          </div>
          <hr className="hr-gold" />
          <div className="flex-column justify-between">
            <div className="flex">
              <span className="font-bold flex-grow">
                {language.createOrder.estimatePrice.suggestedAssistancePrice}
              </span>

              <span className="font-bold">
                {`${formatPrice(estimatedAssistancePrice)} ${
                  language.currency
                }`}
              </span>
            </div>

            <div className="flex justify-between">
              <span className="hideable" style={{ fontSize: "12px" }}>
                {vehiclePosition && vehiclePosition.address.length >= 36
                  ? `${vehiclePosition.address.substring(0, 36)}...`
                  : vehiclePosition.address}
              </span>
            </div>
          </div>
          <hr className="hr-gold" />
          <div
            className="flex-column justify-between"
            style={{ marginBottom: "12px" }}
          >
            <div className="flex align-center">
              <span className="font-bold flex-grow">
                {language.createOrder.estimatePrice.suggestedShopPrice}
              </span>
              <>
                {showPreviousEstimate && (
                  <span
                    style={{
                      fontSize: "12px",
                      textDecoration: "line-through",
                      margin: "2px 5px 0 0",
                      textAlign: "right"
                    }}
                  >
                    {`${formatPrice(previousEstimatePrice)} ${
                      language.currency
                    }`}
                  </span>
                )}
                <span className="font-bold">
                  {` ${formatPrice(estimatedTowingPrice)} ${language.currency}`}
                </span>
              </>
            </div>
            {((shopLocation &&
              shopLocation.address &&
              !showTowDestinationPicker) ||
              showPreviousEstimate) && (
              <div className="flex justify-between">
                {DisplayDestinationComponent}
              </div>
            )}
          </div>
          <EstimateDestination />
        </CardBoxContainer>
        <NextStepButton
          id="acceptPriceScreen3"
          previousStepText={language.createOrder.previousStep}
          previousStep={previous}
          nextStep={next}
          nextStepText={language.createOrder.nextStep}
          disabled={false}
        />
      </>
    );
  }
}

EstimatePrice.propTypes = {
  previousView: PropTypes.func.isRequired,
  getEstimatePrice: PropTypes.func.isRequired,
  nextView: PropTypes.func.isRequired,
  estimatedAssistancePrice: PropTypes.number.isRequired,
  estimatedTowingPrice: PropTypes.number.isRequired,
  estimatedTime: PropTypes.number.isRequired,
  showTowDestinationPicker: PropTypes.bool.isRequired,
  showPreviousEstimate: PropTypes.bool.isRequired,
  previousEstimatePrice: PropTypes.number.isRequired,
  customerType: PropTypes.string.isRequired,
  vehiclePosition: PropTypes.shape({
    address: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    town: PropTypes.string,
    zipcode: PropTypes.string
  }).isRequired,
  shopLocation: PropTypes.shape({
    formattedAddress: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    address: PropTypes.string,
    name: PropTypes.string,
    city: PropTypes.string
  }).isRequired,
  next: PropTypes.func.isRequired,
  previous: PropTypes.func.isRequired,
  vehiclePositionHasChanged: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  next: getNextView(state),
  previous: getPreviousView(state),
  estimatedAssistancePrice: getEstimateAssistancePrice(state),
  estimatedTowingPrice: getEstimateTowingPrice(state),
  estimatedTime: getEstimateTime(state),
  vehiclePosition: getVehiclePosition(state),
  showTowDestinationPicker: getShowTowDestinationPicker(state),
  shopLocation: getShopCoordinates(state),
  showPreviousEstimate: getShowPreviousEstimate(state),
  previousEstimatePrice: getPreviousEstimatePrice(state),
  vehiclePositionHasChanged: getHasPreviousVehiclePositionChanged(state),
  customerType: getCustomerType(state)
});

const mapDispatchToProps = dispatch => ({
  previousView: () => dispatch(setPreviousView(DAMAGE_REASON_VIEW)),
  nextView: () => dispatch(setNextView(SEEKING_RESPONDER_VIEW)),
  getEstimatePrice: () => dispatch(estimatePrice()),
  previous: () => dispatch(goBack()),
  next: () => dispatch(goForward()),
  handlePreviousButton: previous => dispatch(setCurrentView(previous)),
  toggleSetDestination: () => dispatch(toggleEstimateDestination())
});

export default connect(mapStateToProps, mapDispatchToProps)(EstimatePrice);
