import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import history from "../../history";
import CreateOrderMap from "./CreateOrderMap";
import ActiveOrderMapHandler from "./ActiveOrderMapHandler";
import {
  CREATE_ORDER_PATH,
  ACTIVE_ORDER_PATH,
  SUMMARY_PATH
} from "../../constants/routeConstants";
import selectors from "../createOrder/selectors";
import {
  CAR_POSITION_VIEW,
  SEEKING_RESPONDER_VIEW
} from "../createOrder/viewConstants";
import SeekingResponderMap from "./SeekingResponderMap";

import "./style.css";

const { getCurrentView } = selectors;

const currentRoute = history.location.pathname;
const isCurrentRouteDispatchPosition = currentRoute.includes(
  "dispatchPosition"
);

function backgroundRenderer(path, current) {
  if (path === CREATE_ORDER_PATH && current === CAR_POSITION_VIEW) return null;
  if (isCurrentRouteDispatchPosition) return null;

  if (path === CREATE_ORDER_PATH)
    if (current === SEEKING_RESPONDER_VIEW) {
      return (
        <>
          <SeekingResponderMap />
        </>
      );
    } else {
      return (
        <>
          <CreateOrderMap />
        </>
      );
    }

  if (path === ACTIVE_ORDER_PATH)
    return (
      <>
        <ActiveOrderMapHandler />
      </>
    );
  if (path === SUMMARY_PATH)
    return (
      <>
        <CreateOrderMap />
      </>
    );

  return <CreateOrderMap />;
}

const Background = ({ current, currentPath }) => {
  const [backgroundComponent, setBackgroundComponent] = useState(null);
  useEffect(() => {
    setBackgroundComponent(backgroundRenderer(currentPath, current));
  }, [currentPath, current]);
  return <div className="background">{backgroundComponent}</div>;
};

Background.propTypes = {
  current: PropTypes.string.isRequired,
  currentPath: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  current: getCurrentView(state),
  currentPath: history.location.pathname
});

export default connect(mapStateToProps, null)(Background);
