import {
  GET_MISSION,
  BaseUrl,
  GET_MISSIONS_BY_ID
} from "../../constants/apiConstants";
import api from "../../helpers/apiUtils";
import { getSession, SESSION_KEYS } from "../../helpers/sessionStore";

const getMissionNow = token => {
  const gatLocale = getSession(SESSION_KEYS.locale);
  const appId = getSession(SESSION_KEYS.appId);
  return api
    .get(`${GET_MISSION}?token=${token}`, BaseUrl.GatUrl, { gatLocale, appId })
    .then(res => {
      return res;
    })
    .catch(err => {
      throw err;
    });
};
const getOrderById = id => {
  const gatLocale = getSession(SESSION_KEYS.locale);
  const gatOrgUnit = getSession(SESSION_KEYS.gatOrganizationUnit);
  const accessToken = getSession(SESSION_KEYS.accessToken);
  return api
    .get(`${GET_MISSIONS_BY_ID}${id}`, BaseUrl.GatUrl, {
      gatOrgUnit,
      accessToken,
      gatLocale
    })
    .then(res => {
      return res;
    })
    .catch(err => {
      throw err;
    });
};

export default {
  getMissionNow,
  getOrderById
};
