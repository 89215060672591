export const ARRIVED = "ARRIVED";
export const COMPLETED = "COMPLETED";
export const DISPATCH = "DISPATCH";
export const DROPPED = "DROPPED";
export const INACTIVITY = "INACTIVITY";
export const PENDING_DISPATCH = "PENDING_DISPATCH";
export const PENDING = "PENDING";
export const PICKEDUP = "PICKEDUP";
export const PREPARE_TRIP_TO_WORKSHOP = "PREPARE_TRIP_TO_WORKSHOP";
export const PROVIDER = "PROVIDER";
export const REJECTED = "REJECTED";
export const SCHEDULED = "SCHEDULED";
export const SEARCHING = "SEARCHING";
export const STARTED = "STARTED";
export const USER = "USER";
export const WAITING_FOR_DISPATCH = "WAITING_FOR_DISPATCH";
export const WEB = "WEB";

// GAT 2.0 STATES
export const DRIVER_DRIVING_TO_SOURCE = "DrivingToSource";

export const DRIVER_AT_SOURCE = "DriverAtSource";
export const DRIVER_PREPARE_DRIVE_TO_DESTINATION = "PrepareDriveToDestination";

export const DRIVER_DRIVING_TO_DESTINATION = "DrivingToDestination";
export const DRIVER_AT_DESTINATION = "DriverAtDestination";

export const CANCELLED = "Cancelled";

export const FINISHED = "Finished";

export const DEBRIEFED = "Debriefed";

export const REVIEW_MISSION_SUMMARY = "ReviewMissionSummary";

export const MISSION_NOT_STARTED = "NotStarted";

export const MISSION_CREATED = "Created";

export const WAITING_FOR_DRIVER_ACCEPT = "WaitingForDriverAccept";

export const WAITING_FOR_CUSTOMER_ACCEPT = "WaitingForCustomerAccept";
