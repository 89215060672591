import mapStyle from "./mapStyle";

export const defaultMapOptions = {
  styles: mapStyle,
  streetViewControl: false,
  scaleControl: false,
  mapTypeControl: false,
  panControl: false,
  zoomControl: false,
  rotateControl: false,
  fullscreenControl: false,
  gestureHandling: "none",
  disableDefaultUI: true,
  draggableCursor: "default",
  minZoom: 4
};

export const directionsMapOptions = {
  styles: mapStyle,
  streetViewControl: false,
  scaleControl: false,
  mapTypeControl: false,
  panControl: false,
  zoomControl: false,
  rotateControl: false,
  fullscreenControl: false,
  gestureHandling: "greedy",
  disableDefaultUI: true,
  draggableCursor: "default",
  maxZoom: 17,
  minZoom: 4
};

export default { defaultMapOptions, directionsMapOptions };
