import { SubmissionError } from "redux-form";
import validators from "./validators";

const {
  validateRegno,
  validateMobile,
  validateSocialNumber,
  validatePosition
} = validators;

function submitCustomerDetailsValidation(values) {
  const regnoError = validateRegno(values.regno);
  const mobileError = validateMobile(values.mobile);
  const carPositionError = validatePosition(values.carPosition);
  const socialNumberError = validateSocialNumber(values.socialNumber);
  if (regnoError) {
    throw new SubmissionError({
      regno: regnoError,
      _error: regnoError
    });
  }

  if (mobileError) {
    throw new SubmissionError({
      mobile: mobileError,
      _error: mobileError
    });
  }

  if (carPositionError) {
    throw new SubmissionError({
      carPosition: carPositionError,
      _error: carPositionError
    });
  }

  if (socialNumberError) {
    throw new SubmissionError({
      socialNumber: socialNumberError,
      _error: socialNumberError
    });
  }
}

export default submitCustomerDetailsValidation;
