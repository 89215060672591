import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import createOrderSelectors from "../../features/createOrder/selectors";
import gatIcon from "../../assets/images/gat_symbol_animate.svg";

const { getLoading } = createOrderSelectors;

const LoadingSpinner = ({ loading, positionStyle, sizeStyle, infoText }) => {
  return (
    <>
      {loading && (
        <div className="loading-spinner" style={positionStyle}>
          <div className="loader" style={sizeStyle}>
            <img src={gatIcon} alt="loading" style={sizeStyle} />
            {infoText && <p>{infoText}</p>}
          </div>
        </div>
      )}
    </>
  );
};

LoadingSpinner.propTypes = {
  loading: PropTypes.bool.isRequired,
  positionStyle: PropTypes.shape({}),
  sizeStyle: PropTypes.shape({}),
  infoText: PropTypes.string
};

LoadingSpinner.defaultProps = {
  positionStyle: {},
  sizeStyle: {},
  infoText: ""
};

const mapStateToProps = state => ({
  isLoading: getLoading(state)
});

export default connect(mapStateToProps, null)(LoadingSpinner);
