/* global google */
import React from "react";
import PropTypes from "prop-types";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import locationPin from "../../assets/images/location-pin.svg";
import searchImage from "../../assets/images/search-dark.svg";
import actions from "../../features/createOrder/actions";
import serveActions from "../../features/welcomeScreen/actions";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import logErrorHelper from "../../helpers/logErrorHelper";
import addressComponentsHelper from "../../helpers/addressComponentsHelper";

import "./style.css";

const { setSearchPlacesResult } = actions;

const { setPositionToServe } = serveActions;

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: "", showError: false, addressComponents: {} };
  }

  setAddressComponents = data => {
    this.setState({ addressComponents: data });
  };

  handleChange = result => {
    this.handleSetError(false);
    this.setState({ value: result });
  };

  handleSetError = bool => {
    this.setState({ showError: bool });
  };

  // If user hits enter key then placeId will be null
  handleSelect = (address, placeId) => {
    if (!placeId) {
      this.handleChange("");
      this.handleSetError(true);
      return;
    }
    this.handleSetError(false);
    const {
      saveSearchPlacesResult,
      map,
      showSearchField,
      setPositionToServe
    } = this.props;
    geocodeByAddress(address)
      .then(results => {
        this.handleChange(results[0].formatted_address);
        this.setAddressComponents(addressComponentsHelper(results));
        return getLatLng(results[0]);
      })
      .then(latLng => {
        const { addressComponents, value } = this.state;
        console.log("addressComponents", addressComponents);
        saveSearchPlacesResult({
          addressComponents: { ...addressComponents },
          address: value,
          lat: latLng.lat,
          lng: latLng.lng
        });
        setPositionToServe({
          lat: latLng.lat,
          lng: latLng.lng,
          address:
            `${addressComponents.route} ${addressComponents.streetNumber}` ||
            addressComponents.streetAddress,
          zipCode: addressComponents.postalCode,
          city: addressComponents.postalTown
        });
        const currentLatLng = new google.maps.LatLng({
          lat: latLng.lat,
          lng: latLng.lng
        });
        showSearchField();
        map.panTo(currentLatLng);
      })
      .catch(error => {
        logErrorHelper(error);
      });
  };

  render() {
    const searchOptions = {
      types: ["address"]
      // componentRestrictions: { country: "swe" }
    };

    const { value, showError } = this.state;
    const { intl, placeholder: message } = this.props;

    return (
      <PlacesAutocomplete
        value={value}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={searchOptions}
        shouldFetchSuggestions={value.length > 1}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="flex justify-center ">
            <div className="location-search-input-container">
              {loading && (
                <LoadingSpinner
                  loading={loading}
                  positionStyle={{ background: "none", left: 0 }}
                  sizeStyle={{ width: "30px", height: "30px" }}
                />
              )}
              <img
                src={searchImage}
                alt="inputimage"
                className="search-image-left"
              />
              <input
                {...getInputProps({
                  autoFocus: true,
                  placeholder: message,
                  className: "location-search-input"
                })}
              />
              {showError && (
                <span className="location-search-input-error">
                  {intl.formatMessage({
                    id: "DispatchPosition.CarPosition.ErrorMessage",
                    defaultMessage: "Choose a valid address",
                    description: "CarPosition.ErrorMessage: choose option"
                  })}
                </span>
              )}
              <div className="autocomplete-dropdown-container">
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  const style = suggestion.active
                    ? {
                        cursor: "pointer",
                        padding: "10px 8px"
                      }
                    : {
                        cursor: "pointer",
                        padding: "10px 8px"
                      };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style
                      })}
                    >
                      <img
                        className="location-pin"
                        src={locationPin}
                        alt="location pin"
                      />
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

LocationSearchInput.propTypes = {
  saveSearchPlacesResult: PropTypes.func.isRequired,
  setPositionToServe: PropTypes.func.isRequired,
  map: PropTypes.shape({
    panTo: PropTypes.func
  }).isRequired,
  showSearchField: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  placeholder: PropTypes.string.isRequired
};

const mapDispatchToProps = dispatch => ({
  saveSearchPlacesResult: address => dispatch(setSearchPlacesResult(address)),
  setPositionToServe: position => dispatch(setPositionToServe(position))
});

export default injectIntl(
  connect(undefined, mapDispatchToProps)(LocationSearchInput)
);
