import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { injectIntl } from "react-intl";
import actions from "./actions";
import selectors from "./selectors";
import EstimatePrice from "./estimatePrice";
import CustomerDetails from "./customerDetails";
import DamageReason from "./damageReason";
import SeekingResponder from "./seekingResponder";
import UserTerms from "./userTerms";
import TmaCarTerms from "./tmaCarTerms";
import ConfirmOrder from "./confirmOrder";
import Modal from "../../components/Modal/Modal";
import languageHelper from "../../helpers/languageHelper";
import history from "../../history";
import { CREATE_ORDER_PATH } from "../../constants/routeConstants";
import {
  CUSTOMER_DETAILS_VIEW,
  CAR_POSITION_VIEW,
  DAMAGE_REASON_VIEW,
  ESTIMATE_PRICE_VIEW,
  CONFIRM_ORDER_VIEW,
  USER_TERMS_VIEW,
  TMA_CAR_TERMS_VIEW,
  SEEKING_RESPONDER_VIEW
} from "./viewConstants";
import { CarPositionPicker } from "../carPositionPicker";
import activeOrderSelectors from "../activeOrder/selectors";
import activeOrderActions from "../activeOrder/actions";

import "./style.css";
import logout from "../../helpers/clearSessionStore";
import {
  isSubdomainHelper,
  subdomainStringsRender
} from "../../helpers/SubdomainHelper";
import arrowButton from "../../assets/images/arrow-down.svg";
import { SESSION_KEYS, setSession } from "../../helpers/sessionStore";

const language = languageHelper();

const { getPreventResumeOrderState } = activeOrderSelectors;
const { findCurrentView: findCurrentViewAction } = activeOrderActions;

const {
  toggleNoConfirmationModal,
  toggleAlreadyStartedErrorModal,
  toggleUnexpectedErrorModal,
  setCurrentView,
  toggleCancelledByDispatchErrorModal,
  toggleDispatchWillCallModal,
  login
} = actions;

const {
  getCurrentView,
  getNoConfirmationModalState,
  getUnexpectedErrorModalState,
  getAlreadyStartedErrorModalState,
  getCancelledByDispatchModalState,
  getDispatchWillCallModalState
} = selectors;
class CreateOrder extends React.Component {
  state = {
    toggleDescription: false,
    descriptionHeight: 0
  };

  componentDidMount() {
    const { findCurrentView } = this.props;

    setSession(SESSION_KEYS.appId, process.env.REACT_APP_APPLICATION_ID);
    findCurrentView();
  }

  toggleDescriptionHandler = () => {
    const { toggleDescription } = this.state;
    if (!toggleDescription) {
      this.expandDescription();
    } else {
      this.collapseDescription();
    }
    this.setState(prevState => ({
      toggleDescription: !toggleDescription
    }));
  };

  expandDescription = () => {
    this.setState({ descriptionHeight: 10 });
  };

  collapseDescription = () => {
    this.setState({ descriptionHeight: 0 });
  };

  render() {
    const {
      closeNoConfirmationModal,
      noConfirmationModalState,
      alreadyStartedErrorModalState,
      unexpectedErrorModalState,
      closeAlreadyStartedErrorModal,
      closeUnexpectedErrorModal,
      currentView,
      cancelledByDispatchModalState,
      closeCancelledByDispatchErrorModal,
      dispatchWillCallModalState,
      closeDispatchWillCallModal,
      intl
    } = this.props;

    const { toggleDescription } = this.state;

    const MetaDataHandler = () => {
      const allowMetadata = isSubdomainHelper();

      return allowMetadata ? (
        <div
          style={{
            pointerEvents: "all",
            transition: "height 0.5s ease-in-out"
          }}
          ref={el => {
            this.DOMRef = el;
          }}
        >
          <div className="metadata-collapsible">
            <button
              type="button"
              className="arrow-down-button"
              onClick={this.toggleDescriptionHandler}
            >
              <img
                src={arrowButton}
                alt="arrow"
                className={`arrow-button-image ${
                  toggleDescription ? "rotated" : ""
                }`}
              />
              <span className="welcome-screen-toggle-text">
                Visa mer information
              </span>
            </button>
          </div>
          <div
            className={`welcome-screen-additional-description ${
              toggleDescription ? "expanded" : "collapsed"
            }`}
          >
            <div
              className="welcome-screen-region-text"
              ref={el => {
                this.regionTextRef = el;
              }}
            >
              {subdomainStringsRender(intl)}
            </div>
          </div>
        </div>
      ) : null;
    };

    return (
      <>
        {noConfirmationModalState && (
          <Modal
            title={intl.formatMessage({
              id: "DispatchPosition.FindMyPosition.Title",
              defaultMessage: "Find my position",
              description: "DispatchPosition: Find my position title"
            })}
            bodyText={intl.formatMessage({
              id: "CreateOrder.NoConfirmationModal.Text",
              defaultMessage:
                "The time ran out. You will be redirected to the startpage.",
              description: "NoConfirmationModal: The time ran out text"
            })}
            primaryModalButtonAction={closeNoConfirmationModal}
            primaryModalButtonText={intl.formatMessage({
              id: "CreateOrder.NoConfirmationModal.Button",
              defaultMessage:
                "The time ran out. You will be redirected to the startpage.",
              description: "NoConfirmationModal: The time ran out text"
            })}
          />
        )}
        {dispatchWillCallModalState && (
          <Modal
            title={intl.formatMessage({
              id: "DispatchPosition.CreateMission.ErrorModal.Title",
              defaultMessage: "Something went wrong",
              description: "Create mission error modal title"
            })}
            bodyText={intl.formatMessage({
              id: "DispatchPosition.CreateMission.ErrorModal.Text",
              defaultMessage:
                "Something went wrong. Try again or call us on the number below.",
              description: "Create mission error modal text"
            })}
            bodyText2={
              <a
                href={`tel: ${intl.formatMessage({
                  id: "CreateOrder.CustomerDetails.CallNumber",
                  defaultMessage: "+4620400500",
                  description: "phonenr to callcenter"
                })}`}
                style={{ color: "#FFF" }}
              >
                {intl.formatMessage({
                  id: "CreateOrder.CustomerDetails.DisplayedPhoneNumber",
                  defaultMessage: "Call +4620400500",
                  description: "Displayed phonenr to call center"
                })}
              </a>
            }
            primaryModalButtonAction={closeDispatchWillCallModal}
            primaryModalButtonText={
              language.createOrder.dispatchWillCallModal.button
            }
          />
        )}
        {alreadyStartedErrorModalState && (
          <Modal
            title={language.createOrder.alreadyStartedErrorModal.title}
            bodyText={language.createOrder.alreadyStartedErrorModal.text}
            primaryModalButtonAction={closeAlreadyStartedErrorModal}
            primaryModalButtonText={
              language.createOrder.alreadyStartedErrorModal.button
            }
          />
        )}
        {unexpectedErrorModalState && (
          <Modal
            title={intl.formatMessage({
              id: "ErrorModal.Title",
              defaultMessage: "Something went wrong",
              description: "error modal title"
            })}
            bodyText={intl.formatMessage({
              id: "ErrorModal.BreadText",
              defaultMessage:
                "Something went wrong. We will contact you as soon as possible",
              description: "error modal title"
            })}
            primaryModalButtonAction={closeUnexpectedErrorModal}
            primaryModalButtonText={
              language.createOrder.unexpectedErrorModal.button
            }
          />
        )}
        {cancelledByDispatchModalState && (
          <Modal
            title={language.createOrder.cancelledByDispatchErrorModal.title}
            bodyText={language.createOrder.cancelledByDispatchErrorModal.text}
            primaryModalButtonAction={closeCancelledByDispatchErrorModal}
            primaryModalButtonText={
              language.createOrder.cancelledByDispatchErrorModal.button
            }
          />
        )}
        {/* pointer-events-none class makes it able to click on map trough createOrder container */}
        <div
          className={classNames(
            "createOrder flex-column align-center justify-center",
            {
              "pointer-events-none": currentView !== CAR_POSITION_VIEW
            }
          )}
          id="create-order-container"
        >
          {currentView === CUSTOMER_DETAILS_VIEW && <CustomerDetails />}
          {currentView === CAR_POSITION_VIEW && <CarPositionPicker />}
          {currentView === DAMAGE_REASON_VIEW && <DamageReason />}
          {currentView === ESTIMATE_PRICE_VIEW && <EstimatePrice />}
          {currentView === SEEKING_RESPONDER_VIEW && <SeekingResponder />}
          {currentView === CONFIRM_ORDER_VIEW && <ConfirmOrder />}
          {currentView === USER_TERMS_VIEW && <UserTerms />}
          {currentView === TMA_CAR_TERMS_VIEW && <TmaCarTerms />}
          {currentView === CUSTOMER_DETAILS_VIEW && <MetaDataHandler />}
        </div>
      </>
    );
  }
}

CreateOrder.propTypes = {
  closeNoConfirmationModal: PropTypes.func.isRequired,
  noConfirmationModalState: PropTypes.bool.isRequired,
  alreadyStartedErrorModalState: PropTypes.bool.isRequired,
  unexpectedErrorModalState: PropTypes.bool.isRequired,
  closeAlreadyStartedErrorModal: PropTypes.func.isRequired,
  closeUnexpectedErrorModal: PropTypes.func.isRequired,
  currentView: PropTypes.string.isRequired,
  cancelledByDispatchModalState: PropTypes.bool.isRequired,
  closeCancelledByDispatchErrorModal: PropTypes.func.isRequired,
  dispatchWillCallModalState: PropTypes.bool.isRequired,
  closeDispatchWillCallModal: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  findCurrentView: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  preventResumeOrder: getPreventResumeOrderState(state),
  currentView: getCurrentView(state),
  noConfirmationModalState: getNoConfirmationModalState(state),
  unexpectedErrorModalState: getUnexpectedErrorModalState(state),
  cancelledByDispatchModalState: getCancelledByDispatchModalState(state),
  alreadyStartedErrorModalState: getAlreadyStartedErrorModalState(state),
  dispatchWillCallModalState: getDispatchWillCallModalState(state)
});

const mapDispatchToProps = dispatch => ({
  findCurrentView: () => dispatch(findCurrentViewAction()),
  closeNoConfirmationModal: () => {
    dispatch(toggleNoConfirmationModal(false));
    dispatch(setCurrentView(CUSTOMER_DETAILS_VIEW));
  },
  closeAlreadyStartedErrorModal: () => {
    dispatch(toggleAlreadyStartedErrorModal(false));
    dispatch(login());
  },
  closeUnexpectedErrorModal: () => {
    logout();
    dispatch(toggleUnexpectedErrorModal(false));
    history.push(CREATE_ORDER_PATH);
  },
  closeCancelledByDispatchErrorModal: () => {
    logout();
    dispatch(toggleCancelledByDispatchErrorModal(false));
    dispatch(setCurrentView(CUSTOMER_DETAILS_VIEW));
    history.push(CREATE_ORDER_PATH);
  },
  closeDispatchWillCallModal: () => {
    logout();
    dispatch(toggleDispatchWillCallModal(false));
    history.push(CREATE_ORDER_PATH);
  }
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(CreateOrder)
);
