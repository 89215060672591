/* eslint-disable react/forbid-prop-types */
import { InputStyles } from "@assistansbolaget/gat-components";
import PropTypes from "prop-types";
import React from "react";
import "react-phone-number-input/style.css";
import { useIntl } from "react-intl";
import {useSessionStorage} from "usehooks-ts";
import {
  PhoneNumber,
  PhoneNumberContainer
} from "../../features/createOrder/customerDetails.style";
import { SESSION_KEYS } from "../../helpers/sessionStore";
import "./style.css";

function getDefaultCountry(locale) {
  const country = locale?.split("-")[1];
  return country ? country : "US";
}

const PhoneNumberInput = ({
  input: { onFocus, onBlur, onChange, value },
  meta: { active, error, touched },
  ref,
  placeholder,
  ...rest
}) => {
  const [locale] = useSessionStorage(SESSION_KEYS.locale, undefined);
  const shouldShowError = touched && !active && error;

  const defaultCountry = getDefaultCountry(locale);
  const intl = useIntl();
  return (
    <PhoneNumberContainer
      isError={!!shouldShowError}
      flex={1}
      label={placeholder}
    >
      <InputStyles.Label>{placeholder}</InputStyles.Label>
      <InputStyles.InputContent>
        <InputStyles.InputWrapper
          isError={!!shouldShowError}
          isInFocus={false}
          hasHelpText={false}
          hasLabel={!!placeholder}
          hasCornerHint={false}
          size="Large"
        >
          <PhoneNumber
            value={value || undefined}
            onChange={onChange}
            onBlur={() => onBlur()}
            onFocus={() => onFocus()}
            defaultCountry={defaultCountry}
            type="tel"
            {...rest}
          />
        </InputStyles.InputWrapper>
        <InputStyles.BottomLabel isError={shouldShowError}>
          {shouldShowError
            ? intl.formatMessage({
                id: "CreateOrder.PhoneInput.ErrorMessage",
                defaultMessage: "Wrong format",
                description: "phonenumber input: errormessage"
              })
            : undefined}
        </InputStyles.BottomLabel>
      </InputStyles.InputContent>
    </PhoneNumberContainer>
  );
};

PhoneNumberInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  ref: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired
};

export default PhoneNumberInput;
