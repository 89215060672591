import { fromJS } from "immutable";
import * as constants from "./constants";

const initialState = fromJS({
  receiptLoading: false,
  rating: 0,
  showOpinion: false,
  summarySuccessModalState: false,
  summaryFailureModalState: false,
  receiptFailureModalState: false,
  receiptSuccessState: false
});

const reducer = (state = initialState, action) => {
  let newState = state;
  switch (action.type) {
    case constants.SET_RATING:
      newState = newState.set("rating", action.starsSelected);
      return newState;
    case constants.SET_DISPLAY_VALUE:
      newState = newState.set("showOpinion", action.value);
      return newState;
    case constants.TOGGLE_SUMMARY_SUCCESS_MODAL:
      newState = newState.set("summarySuccessModalState", action.payload);
      return newState;
    case constants.TOGGLE_SUMMARY_FAILURE_MODAL:
      newState = newState.set("summaryFailureModalState", action.payload);
      return newState;
    case constants.POST_RECEIPT:
      newState = newState.set("receiptLoading", true);
      return newState;
    case constants.POST_RECEIPT_SUCCESS:
      newState = newState.set("receiptLoading", false);
      newState = newState.set("receiptSuccessState", action.payload);
      return newState;
    case constants.TOGGLE_RECEIPT_FAILURE_MODAL:
      newState = newState.set("receiptLoading", false);
      newState = newState.set("receiptSuccessState", false);
      newState = newState.set("receiptFailureModalState", action.payload);
      return newState;
    default:
      return newState;
  }
};

export default { reducer };
