import React from "react";
import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import { withScriptjs, withGoogleMap } from "react-google-maps";
import { useIntl } from "react-intl";
import { GOOGLE_MAP_URL } from "../../constants/apiConstants";
import actions from "./actions";
import selectors from "./selectors";
import createOrderActions from "../createOrder/actions";
import welcomeScreenSelectors from "../welcomeScreen/selectors";
import "./style.css";
import CarPositionPresentation from "./carPositionPresentation";
import dispatchPositionSelectors from "../dispatchPosition/selectors";
import dispatchPositionActions from "../dispatchPosition/actions";
import Modal from "../../components/Modal/Modal";

const {
  getCustomerCoordinates,
  getVehiclePosition,
  getIsFetchingReverseGeoCode
} = welcomeScreenSelectors;

const {
  getLocationBlockedModalState,
  getShowDispatchMap
} = dispatchPositionSelectors;

const { setCurrentView, getPosition } = createOrderActions;
const { saveMapToState, saveCenterCoords } = actions;
const { getMap, getCenterCoords, getPreviousView } = selectors;

const { saveCenterDestinationCoords } = dispatchPositionActions;

const CarPositionPicker = compose(
  withProps({
    googleMapURL: GOOGLE_MAP_URL,
    loadingElement: <div className="full-height-width" />,
    containerElement: <div className="map-container-element" />,
    mapElement: <div className="full-height-width" />
  }),
  withScriptjs,
  withGoogleMap
)(props => {
  const intl = useIntl();
  const saveText = intl.formatMessage({
    id: "DispatchPosition.ButtonTitle",
    defaultMessage: "Save",
    description: "DispatchPosition: Save button title"
  });
  const {
    fetchPreviousView,
    locationBlockedModalState,
    showDispatchMap,
    requestPosition
  } = props;

  const windowReloadAction = () => {
    requestPosition();
  };

  return (
    <>
      {locationBlockedModalState && (
        <Modal
          title={intl.formatMessage({
            id: "DispatchPosition.AccessDeniedModal.Title",
            defaultMessage: "Access denied",
            description: "dispatchPosition: access denied modal title"
          })}
          bodyText={intl.formatMessage({
            id: "DispatchPosition.AccessDeniedModal.Text",
            defaultMessage:
              "Check the browser settings for allowed locations to grant access to location information.",
            description: "dispatchPosition: access denied modal text"
          })}
          windowReload={intl.formatMessage({
            id: "DispatchPosition.AccessDeniedModal.ButtonText",
            defaultMessage: "Reload the page",
            description: "dispatchPosition: access denied modal button text"
          })}
          windowReloadAction={windowReloadAction}
        />
      )}
      {showDispatchMap && (
        <CarPositionPresentation
          {...props}
          saveText={saveText}
          previousView={fetchPreviousView}
        />
      )}
    </>
  );
});

const mapStateToProps = state => ({
  map: getMap(state),
  geoCoordinates: getCustomerCoordinates(state),
  centerCoords: getCenterCoords(state),
  vehiclePosition: getVehiclePosition(state),
  isFetchingReverseGeoCode: getIsFetchingReverseGeoCode(state),
  fetchPreviousView: getPreviousView(state),
  locationBlockedModalState: getLocationBlockedModalState(state),
  showDispatchMap: getShowDispatchMap(state)
});

const mapDispatchToProps = dispatch => ({
  saveMap: map => dispatch(saveMapToState(map)),
  saveCenter: centerCoords => dispatch(saveCenterCoords(centerCoords)),
  saveDestinationChange: centerCoords =>
    dispatch(saveCenterDestinationCoords(centerCoords)),
  handleNavigationButton: value => dispatch(setCurrentView(value)),
  requestPosition: () => dispatch(getPosition())
});

export default connect(mapStateToProps, mapDispatchToProps)(CarPositionPicker);
