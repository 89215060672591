import { SubmissionError } from "redux-form";
import validators from "./validators";
import languageHelper from "../../helpers/languageHelper";

const language = languageHelper();

const { validateRadio } = validators;

function submitDamageReasonValidation(values) {
  const reserveTireError = validateRadio(values.reserveTire);
  const garageError = validateRadio(values.garage);
  const engineInsuranceTypeError = validateRadio(values.engineInsuranceType);

  if (!values.damageReason) {
    throw new SubmissionError({
      damageReason: language.errors.required,
      _error: language.errors.required
    });
  }
  if (values.damageReason === "901113" && !values.damageDetails) {
    throw new SubmissionError({
      damageDetails: language.errors.required,
      _error: language.errors.required
    });
  }
  if (values.damageReason === "901105" && !values.damageDetails) {
    throw new SubmissionError({
      damageDetails: language.errors.required,
      _error: language.errors.required
    });
  }
  if (values.damageReason === "901115" && !values.damageDetails) {
    throw new SubmissionError({
      damageDetails: language.errors.required,
      _error: language.errors.required
    });
  }
  if (values.damageReason === "901114" && !values.damageDetails) {
    throw new SubmissionError({
      damageDetails: language.errors.required,
      _error: language.errors.required
    });
  }
  if (values.damageReason === "901114" && reserveTireError) {
    throw new SubmissionError({
      reserveTire: reserveTireError,
      _error: language.errors.required
    });
  }
  if (garageError) {
    throw new SubmissionError({
      garage: garageError,
      _error: garageError
    });
  }
  if (engineInsuranceTypeError) {
    throw new SubmissionError({
      garage: engineInsuranceTypeError,
      _error: engineInsuranceTypeError
    });
  }
  if (!values.terms) {
    throw new SubmissionError({
      terms: language.errors.terms,
      _error: language.errors.terms
    });
  }
}

export default submitDamageReasonValidation;
