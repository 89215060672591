import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./style.css";

const LegacyTextArea = ({ input, meta: { error, touched }, ...rest }) => {
  return (
    <div className="text-area-container">
      {rest.label && <span className="text-area-label">{rest.label}</span>}
      <textarea
        className={classNames("text-area", {
          "text-area-error-bg ": touched && error
        })}
        {...rest}
        {...input}
        style={{ fontSize: "13px" }}
      />
      {touched && error ? (
        <span className="text-area-error">{error}</span>
      ) : null}
    </div>
  );
};

/* eslint-disable */
LegacyTextArea.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};

export default LegacyTextArea;
