/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable react/no-array-index-key */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import actions from "./legacyActions";
import starSolid from "../../../assets/images/star-solid.svg";
import star from "../../../assets/images/star.svg";

const { setRating, legacySubmitSummaryForm } = actions;

const Star = ({ selected, onClick }) => (
  <img
    src={selected ? starSolid : star}
    alt="rating"
    onClick={onClick}
    role="button"
    onKeyDown={onClick}
    tabIndex={0}
  />
);

Star.propTypes = {
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

Star.defaultProps = {
  selected: false
};

class LegacyRate extends Component {
  constructor(props) {
    super(props);
    this.state = { starsSelected: 0 };
  }

  change = starsSelected => {
    const { storeRating, submitRating } = this.props;
    this.setState({ starsSelected });
    storeRating(starsSelected);
    submitRating();
  };

  render() {
    const { totalStars } = this.props;
    const { starsSelected } = this.state;

    return (
      <>
        {[...Array(totalStars)].map((n, i) => (
          <Star
            key={i}
            selected={i < starsSelected}
            onClick={() => this.change(i + 1)}
          />
        ))}
      </>
    );
  }
}

LegacyRate.propTypes = {
  totalStars: PropTypes.number,
  storeRating: PropTypes.func.isRequired,
  submitRating: PropTypes.func.isRequired
};

LegacyRate.defaultProps = {
  totalStars: 5
};

const mapDispatchToProps = dispatch => ({
  storeRating: starsSelected => dispatch(setRating(starsSelected)),
  submitRating: () => dispatch(legacySubmitSummaryForm())
});

export default connect(null, mapDispatchToProps)(LegacyRate);
