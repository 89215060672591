import React from "react";
import PropTypes from "prop-types";
import RadioGroupsStyle from "./RadioGroups.style";

const ButtonGroup = ({
  value,
  name,
  submitFailed,
  label,
  values,
  onChange
}) => {
  const convertValueToName = () => {
    if (value === true) {
      return "Ja";
    }
    return "Nej";
  };
  return (
    <div className="marginBottom20 flex flex-column">
      <label
        id={`${name}-label`}
        htmlFor={name}
        style={{ fontSize: "13px", padding: "10px 0" }}
      >
        {label}
      </label>
      {values.map(v => (
        <RadioGroupsStyle
          id={`${name}-${v.value}`}
          value={value}
          name={convertValueToName(value)}
          label={v.label}
          submitFailed={submitFailed}
          checked={value === v.value}
          onChange={() => onChange(v.value)}
        />
      ))}
    </div>
  );
};

ButtonGroup.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  submitFailed: PropTypes.bool,
  label: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  )
};

ButtonGroup.defaultProps = {
  value: undefined,
  name: "",
  submitFailed: undefined,
  label: "",
  values: []
};

export default ButtonGroup;
