const getMap = ({ carPositionPicker }) => carPositionPicker.get("map");

const getCenterCoords = ({ carPositionPicker }) =>
  carPositionPicker.get("centerCoords");

const getSpinnerBool = ({ carPositionPicker }) =>
  carPositionPicker.get("isLoading");

const getPreviousView = ({ createOrder }) =>
  createOrder.getIn(["view", "previous"]);

export default {
  getMap,
  getCenterCoords,
  getSpinnerBool,
  getPreviousView
};
