export default {
  summary: {
    receipt: {
      header: "TACK FÖR ATT DU VALDE ASSISTANSBOLAGET!",
      title: "SAMMANFATTNING AV DITT UPPDRAG",
      text1: "Vi hoppas att du är nöjd med vår hjälp.",
      text2: "Betygsätt oss gärna - ditt omdöme är viktigt för oss!",
      text3: "Maila mig kvitto & uppdragsbeskrivning:",
      assistance: "ASSISTANS:",
      towing: "BÄRGNING:",
      shopChange: "BYTE AV VERKSTAD:",
      gradeExperience: "Betygsätt din upplevelse",
      summary: "SKRIV OMDÖME",
      placeholder: "Ange din epostadress",
      sendEmailButton: "SKICKA",
      emailSent: "SKICKAD",
      repairAtSpotLabel: "Reparation på plats",
      downloadReceipt: "HÄMTA FULLSTÄNDIGT KVITTO",
      customerLabel: "BESTÄLLARE:",
      nameLabel: "Namn",
      addressLabel: "Adress",
      vehicleLabel: "FORDON:",
      brandLabel: "Bilmärke:",
      yearLabel: "Årsmodell:",
      missionLabel: "UPPDRAG:",
      missionTypeLabel: "Uppdrag:",
      missionNumber: "Uppdragsnummer:",
      missionType: "Uppdragstyp:",
      specification: "SPECIFIKATION",
      paymentMethod: "BETALSÄTT",
      totaltAmount: "TOTALKOSTNAD",
      startedAt: "Påbörjades:",
      regnoLabel: "Reg.nr:",
      damageInformationLabel: "Skadeinformation",
      damageReasonHeader: "Skadeorsak:",
      locationsForDamage: "PLATSER:",
      costs: "KOSTNAD",
      damageScene: "Skadeplats",
      descriptiveDamageReason: "Skadebeskrivning:",
      towingLabel: "Bärgning",
      towingTo: "Bärgad till",
      dateLabel: "Datum",
      towedFromLabel: "Bärgad från:",
      towedToLabel: "Bärgad till:",
      chargedDistanceLabel: "Extra debiterad sträcka:",
      costsLabel: "Kostnader",
      costTypeLabel: "Kostnadstyp",
      costLabel: "Kostnader",
      paymentMethodLabel: "Betalningssätt",
      totalLabel: "Totalt",
      assistansbolagetCompanyName: "Rescue Europe AB",
      assistansbolagetAddressStreet: "Redaregatan 50",
      assistansbolagetAddressZip: "252 36 Helsingborg",
      receiptHeader: "KVITTO",
      assistanstbolaget: "Assistansbolaget",
      emailLabel: "Epost",
      email: "kontakt@assistansbolaget.nu",
      orgNrLabel: "Org. nr",
      orgNr: "559248-8265",
      phoneNrLabel: "Telefon",
      phoneNr: "042 324 324",
      vatLabel: "Varav moms",
      missionId: "Uppdragsnummer",
      recieptGenerated: "Kvitto genererat"
    },
    opinion: {
      title: "HUR VAR DIN UPPLEVELSE?",
      text1: "Tack för att du valde Assistansbolaget!",
      text2:
        "Vi skulle uppskatta om du lämnade ett omdöme om din upplevelse så att vi kan bli ännu bättre.",
      placeholder: "Beskriv din upplevelse...",
      send: "SKICKA OMDÖME"
    },
    summarySuccessModal: {
      title: "TACK FÖR DIN RECENSION",
      text: "Din åsikt är viktig för oss!",
      primaryButton: "STÄNG"
    },
    summaryFailureModal: {
      title: "NÅGONTING GICK FEL",
      text: "Vänligen försök igen.",
      primaryButton: "STÄNG"
    },
    receiptFailureModal: {
      title: "NÅGONTING GICK FEL",
      text:
        "Vi kunde tyvärr inte skicka uppdragsbeskrivningen till dig. Vänligen försök igen eller kontakta oss på Tel: +46 42 324 324.",
      primaryButton: "STÄNG"
    }
  },
  damageDetails: {
    frontLeft: "Vänster fram",
    frontRight: "Höger fram",
    rearLeft: "Vänster bak",
    rearRight: "Höger bak",
    missing: "Saknas",
    defect: "Defekt",
    lockedInsideVehicle: "Inlåst",
    petrol: "Bensin",
    diesel: "Diesel",
    ethanol: "Etanol",
    electric: "El",
    red: "Röd",
    yellow: "Gul",
    errorMessage: "Felmeddelande",
    camDrive: "Kamdrivning/drivsystem",
    turbo: "Turbo/kompressor",
    oilLeakage: "Oljeläckage",
    fanBelt: "Fläktrem",
    coolingSystem: "Kylsystem",
    waterPump: "Vattenpump",
    powerTransmission: "Kraftöverföring",
    clutch: "Koppling",
    flywheel: "Svänghjul",
    distributionBox: "Fördelningslåda",
    generator: "Generator",
    controlDevice: "Styrdon/styrenhet",
    ignitionLock: "Tändlås",
    batteryBank: "Batteribank(elbil)",
    fuelInjector: "Spridare",
    fuelPump: "Bränslepump",
    gasRegulator: "Gasregulator",
    steeringGear: "Styrväxel",
    servopump: "Servopump",
    steeringWheelLock: "Rattlås",
    abs: "ABS/ESP",
    parkingBrake: "Parkeringsbroms",
    brakeServo: "Bromsservo",
    brakePads: "Bromsok/skivor/klossar",
    rearCarriage: "Bakvagn",
    frontCarriage: "Framvagn",
    exhaustSystem: "Avgassystem",
    hydraulics: "Hydraulik",
    glassDamage: "Glasskada",
    fire: "Brand",
    fuelError: "Feltankning"
  },
  damageReasons: {
    flatTire: "Punktering",
    startHelp: "Strömlöst batteri",
    keyIssues: "Nyckelproblem",
    propulsion: "Drivmedelsbrist",
    warningLight: "Varningslampa lyser",
    engineProblem: "Motor",
    gearBox: "Växellåda & drivning",
    electronics: "Elektronik",
    fuelSystem: "Bränslesystem",
    steering: "Styrning",
    brakingSystem: "Bromssystem",
    otherDamage: "Övrig skada",
    collision: "Kollision",
    ditch: "Dikeskörning",
    transport: "Transport",
    drivingBan: "Körförbud"
  },
  accidentOptionFunction: {
    chooseWarningColor: "Välj färg på varningslampan",
    chooseReason: "Välj orsak",
    chooseTire: "Välj däck",
    fuelType: "Ange typ av drivmedel",
    damageDetail: "Skadeorsak detalj",
    chooseEngineProblem: "Välj motorproblem",
    chooseGearBox: "Välj växellådeproblem",
    chooseElectronics: "Välj elfel",
    chooseFuelSystem: "Välj bränslesystemsfel",
    chooseSteering: "Välj styrningsfel",
    chooseBrakingSystem: "Välj bromsfel",
    chooseOtherDamage: "Välj övrig skada"
  }
};
