import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { useSessionStorage } from "usehooks-ts";
import actions from "./actions";
import selectors from "./selectors";
import {
  CANCELLED,
  DRIVER_AT_SOURCE,
  DRIVER_PREPARE_DRIVE_TO_DESTINATION,
  DRIVER_DRIVING_TO_DESTINATION,
  FINISHED,
  DRIVER_DRIVING_TO_SOURCE,
  DRIVER_AT_DESTINATION,
  DEBRIEFED,
  REVIEW_MISSION_SUMMARY
} from "../../constants/orderStatusConstants";
import OrderOnWay from "./orderOnWay";
import OrderPickedUp from "./orderPickedUp";
import OrderArrived from "./orderArrived";
import OrderComplete from "./orderComplete";
import Modal from "../../components/Modal/Modal";
import welcomeScreenSelectors from "../welcomeScreen/selectors";
import history from "../../history";
import createOrderActions from "../createOrder/actions";
import "./style.css";
import { CREATE_ORDER_PATH } from "../../constants/routeConstants";
import { SESSION_KEYS } from "../../helpers/sessionStore";
import OrderCanceled from "./orderCanceled";
import logout from "../../helpers/clearSessionStore";

const { toggleUnexpectedErrorModal } = createOrderActions;

const { getCustomerCoordinates } = welcomeScreenSelectors;

const {
  getCancelModalState,
  getStartedOrderModalState,
  getTowCancelModalState,
  getUnexpectedErrorModalState,
  getOrder
} = selectors;
const {
  openCancelModal,
  closeCancelModal,
  cancelOrder,
  toggleStartedModal,
  cancelEstimatedArrivalTimePoller,
  getEstimatedArrivalTime,
  fetchTowtruckPosition,
  cancelFetchTowtruckPosition,
  getMission
} = actions;

function ActiveOrder({
  order,
  openModal,
  closeModal,
  closeStartedOrderModal,
  startedOrderModalState,
  towCancelModalState,
  closeUnexpectedErrorModal,
  unexpectedErrorModalState,
  getETA,
  getTowTruckPosition,
  cancelETAPoller,
  cancelTowTruckPosition,
  intl
}) {
  const [companyAttributes] = useSessionStorage(
    SESSION_KEYS.companyAttributes,
    undefined
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    getETA();
    getTowTruckPosition();
    return () => {
      cancelETAPoller();
      cancelTowTruckPosition();
    };
  }, []);

  const destinationAddress = `${order?.sourceToDestination?.address}, ${order?.sourceToDestination?.city} ${order?.sourceToDestination?.zipCode}`;

  const providerAtScene =
    order.state === DRIVER_AT_SOURCE ||
    order.state === DRIVER_PREPARE_DRIVE_TO_DESTINATION;
  const providerOnWay =
    order.state === DRIVER_DRIVING_TO_DESTINATION ||
    order.state === DRIVER_AT_DESTINATION;

  const isMissionDebriefed = order.state === DEBRIEFED;
  const missionOutcomeIsCancelled = order.missionOutcome === "Cancelled";

  const orderIsFinishedOrDebriefed =
    order.state === FINISHED ||
    order.state === REVIEW_MISSION_SUMMARY ||
    (isMissionDebriefed && !missionOutcomeIsCancelled);

  const missionIsCancelled =
    order.state === CANCELLED ||
    (isMissionDebriefed && missionOutcomeIsCancelled);

  const companyName = companyAttributes?.CompanyName?.value;
  return (
    <>
      <>
        {startedOrderModalState && (
          <Modal
            title={intl.formatMessage({
              id: "ActiveOrder.Started.Title",
              defaultMessage: "Thanks, we are on our way",
              description: "ActiveOrder: started title"
            })}
            bodyText={intl.formatMessage({
              id: "ActiveOrder.Started.BreadText",
              defaultMessage:
                "Here you can follow your case in real-time. A link has also been sent to you via SMS where you can also track your case.",
              description: "ActiveOrder: started bread text"
            })}
            primaryModalButtonAction={closeStartedOrderModal}
            primaryModalButtonText={intl.formatMessage({
              id: "general.ok",
              defaultMessage: "OK",
              description: "general: ok"
            })}
          />
        )}
        {towCancelModalState && (
          <Modal
            title={intl.formatMessage({
              id: "ActiveOrder.MissionAborted.Title",
              defaultMessage: "Mission is canceled",
              description: "ActiveOrder: mission is canceled title"
            })}
            bodyText={intl.formatMessage({
              id: "ActiveOrder.MissionAborted.BreadText",
              defaultMessage:
                "The mission was canceled. We will contact you shortly.",
              description: "ActiveOrder: mission is canceled breadtext"
            })}
            primaryModalButtonAction={closeModal}
            primaryModalButtonText={intl.formatMessage({
              id: "general.ok",
              defaultMessage: "OK",
              description: "general: ok"
            })}
          />
        )}
        {unexpectedErrorModalState && (
          <Modal
            title={intl.formatMessage({
              id: "ErrorModal.Title",
              defaultMessage: "Something went wrong",
              description: "error modal title"
            })}
            bodyText={intl.formatMessage({
              id: "ErrorModal.BreadText",
              defaultMessage:
                "Something went wrong. We will contact you as soon as possible",
              description: "error modal title"
            })}
            primaryModalButtonAction={closeUnexpectedErrorModal}
            primaryModalButtonText={intl.formatMessage({
              id: "general.ok",
              defaultMessage: "OK",
              description: "general: ok"
            })}
          />
        )}
        {order && (
          <div className="activeOrder flex-column align-center justify-end pointer-events-none marginBottom20 full-width">
            {order.state === DRIVER_DRIVING_TO_SOURCE && (
              <OrderOnWay
                company={companyName}
                driver={`${order.driverFirstName}`}
                towTruckRegNo={order.driverRegistrationNumber}
                openModal={openModal}
              />
            )}
            {providerAtScene && (
              <OrderArrived
                company={companyName}
                driver={`${order.driverFirstName}`}
                towTruckRegNo={order.driverRegistrationNumber}
              />
            )}
            {providerOnWay && (
              <OrderPickedUp
                company={companyName}
                driver={`${order.driverFirstName}`}
                destination={destinationAddress}
                towTruckRegNo={order.driverRegistrationNumber}
              />
            )}
            {missionIsCancelled && <OrderCanceled />}
            {orderIsFinishedOrDebriefed && <OrderComplete />}
          </div>
        )}
      </>
    </>
  );
}

ActiveOrder.propTypes = {
  order: PropTypes.shape({
    state: PropTypes.string,
    token: PropTypes.string,
    accessToken: PropTypes.string,
    driverDirection: PropTypes.number,
    driverFirstName: PropTypes.string,
    driverLastName: PropTypes.string,
    driverLatitude: PropTypes.number,
    driverLongitude: PropTypes.number,
    missionOutcome: PropTypes.string,
    driverRegistrationNumber: PropTypes.string,
    driverToSource: PropTypes.shape({
      address: PropTypes.string,
      city: PropTypes.string,
      drivingArrivalAt: PropTypes.string,
      drivingDistance: PropTypes.number,
      drivingTime: PropTypes.number,
      estimatedArrivalAt: PropTypes.string,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      name: PropTypes.string,
      zipCode: PropTypes.string
    }),
    id: PropTypes.string,
    organizationUnitId: PropTypes.string,
    organizationUnitName: PropTypes.string,
    sourceToDestination: PropTypes.shape({
      address: PropTypes.string,
      city: PropTypes.string,
      drivingArrivalAt: PropTypes.string,
      drivingDistance: PropTypes.number,
      drivingTime: PropTypes.number,
      estimatedArrivalAt: PropTypes.string,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      name: PropTypes.string,
      zipCode: PropTypes.string
    })
  }).isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  closeStartedOrderModal: PropTypes.func.isRequired,
  startedOrderModalState: PropTypes.bool.isRequired,
  towCancelModalState: PropTypes.bool.isRequired,
  closeUnexpectedErrorModal: PropTypes.func.isRequired,
  unexpectedErrorModalState: PropTypes.bool.isRequired,
  getETA: PropTypes.func.isRequired,
  getTowTruckPosition: PropTypes.func.isRequired,
  fetchGatOrder: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  cancelETAPoller: PropTypes.func.isRequired,
  cancelTowTruckPosition: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  coordinates: getCustomerCoordinates(state),
  cancelModalState: getCancelModalState(state),
  towCancelModalState: getTowCancelModalState(state),
  startedOrderModalState: getStartedOrderModalState(state),
  unexpectedErrorModalState: getUnexpectedErrorModalState(state),
  order: getOrder(state)
});

const mapDispatchToProps = dispatch => ({
  openModal: () => dispatch(openCancelModal()),
  closeModal: () => dispatch(closeCancelModal()),
  cancelOrderAction: () => {
    dispatch(cancelOrder());
    dispatch(closeCancelModal());
  },
  closeStartedOrderModal: () => dispatch(toggleStartedModal(false)),
  closeUnexpectedErrorModal: () => {
    logout();
    dispatch(toggleUnexpectedErrorModal(false));
    history.push(CREATE_ORDER_PATH);
  },
  cancelETAPoller: () => dispatch(cancelEstimatedArrivalTimePoller()),
  getETA: () => dispatch(getEstimatedArrivalTime()),
  getTowTruckPosition: () => dispatch(fetchTowtruckPosition()),
  cancelTowTruckPosition: () => dispatch(cancelFetchTowtruckPosition()),
  fetchGatOrder: token => dispatch(getMission(token))
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(ActiveOrder)
);
