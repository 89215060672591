import {
  POST,
  SET_SOURCE_LOCATION,
  BaseUrl,
  GET_DAMAGE_REASONS,
  IS_CONFINED_SPACE,
  GET_PAYMENT_OPTIONS,
  GET_VEHICLE_INSURANCE_LEVELS
} from "../../constants/apiConstants";
import { getSession, SESSION_KEYS } from "../../helpers/sessionStore";
import api from "../../helpers/apiUtils";

const getLocation = () => {
  const { geolocation } = navigator;

  return new Promise((resolve, reject) => {
    if (!geolocation) {
      reject(new Error("Not Supported"));
    }
    geolocation.getCurrentPosition(
      position => {
        resolve(position);
      },
      () => {
        reject(new Error("Could not get geolocation"));
      }
    );
  });
};

const reverseGeoCode = async vehiclePos => {
  const { lat, lng } = vehiclePos;
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCM3Er5wltGE45H78BW1z__41onnf_xl-0`;
  const response = await fetch(url);
  const data = await response.json();

  const addressComponents = data.results[0].address_components;
  const values = {};
  let fallbackPostalCode;
  let fallbackPostalTown;

  for (let i = 0; i < addressComponents.length; i++) {
    const component = addressComponents[i];
    const type = component.types[0];
    const value = component.long_name;

    switch (type) {
      case "route" || "street_address":
        values.street = value;
        break;
      case "street_number" || "premise":
        values.streetNumber = value;
        break;
      case "postal_code":
        values.postalCode = value;
        fallbackPostalCode = value;
        break;
      case "locality" || "city":
        values.town = value;
        break;
      case "postal_town":
        values.town = value;
        fallbackPostalTown = value;
        break;
      case "country":
        values.country = value;
        break;
      default:
        break;
    }
  }

  let positionStreet = "";
  let formattedAddress = data.results[0].formatted_address;
  if (values.streetNumber) {
    positionStreet = `${values.street} ${values.streetNumber}`;
  } else {
    positionStreet = values.street;
  }
  const zipcode = values.postalCode || fallbackPostalCode || "";
  const town = values.town || fallbackPostalTown || "";

  if (!values.street) {
    formattedAddress = `${lat} ${lng}`;
  }

  return {
    address: positionStreet,
    zipcode,
    town,
    formatted_address: formattedAddress
  };
};

const updateCustomerPosition = data => {
  const locale = getSession(SESSION_KEYS.locale);
  const appId = getSession(SESSION_KEYS.appId);
  const { positionToServe, token } = data;
  const { address, city, zipCode, lat, lng } = positionToServe;
  return fetch(
    `${api.getBaseUrl(BaseUrl.GatUrl)}${SET_SOURCE_LOCATION}?token=${token}`,
    {
      method: POST,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "gat-locale": locale,
        "gat-application-id": appId
      },
      body: JSON.stringify({
        token,
        address,
        city,
        zipCode,
        latitude: lat,
        longitude: lng
      })
    }
  )
    .then(res => {
      if (res.status > 299) {
        return res.status;
      }
      return res;
    })
    .catch(err => {
      throw err;
    });
};

const getDamageReasons = () => {
  const gatOrgUnit = getSession(SESSION_KEYS.gatOrganizationUnit);
  const gatLocale = getSession(SESSION_KEYS.locale);
  const accessToken = getSession(SESSION_KEYS.accessToken);
  const appId = getSession(SESSION_KEYS.appId);
  return api
    .get(`${GET_DAMAGE_REASONS}`, BaseUrl.GatUrl, {
      gatOrgUnit,
      gatLocale,
      accessToken,
      appId
    })
    .then(res => {
      if (res.exception) {
        throw res.message;
      }
      return res;
    })
    .catch(err => {
      throw err;
    });
};

const getAttributeConfinedSpace = () => {
  const gatOrgUnit = getSession(SESSION_KEYS.gatOrganizationUnit);
  const gatLocale = getSession(SESSION_KEYS.locale);
  const accessToken = getSession(SESSION_KEYS.accessToken);
  const appId = getSession(SESSION_KEYS.appId);
  return api
    .get(`${IS_CONFINED_SPACE}`, BaseUrl.GatUrl, {
      gatOrgUnit,
      gatLocale,
      accessToken,
      appId
    })
    .then(res => {
      if (res.exception) {
        throw res.message;
      }
      return res;
    })
    .catch(err => {
      throw err;
    });
};

const getVehicleInsuranceLevels = () => {
  const gatOrgUnit = getSession(SESSION_KEYS.gatOrganizationUnit);
  const gatLocale = getSession(SESSION_KEYS.locale);
  const accessToken = getSession(SESSION_KEYS.accessToken);
  const appId = getSession(SESSION_KEYS.appId);
  return api
    .get(`${GET_VEHICLE_INSURANCE_LEVELS}`, BaseUrl.GatUrl, {
      gatOrgUnit,
      gatLocale,
      accessToken,
      appId
    })
    .then(res => {
      if (res.exception) {
        throw res.message;
      }
      return res;
    })
    .catch(err => {
      throw err;
    });
};

const getPaymentOptions = () => {
  const gatOrgUnit = getSession(SESSION_KEYS.gatOrganizationUnit);
  const gatLocale = getSession(SESSION_KEYS.locale);
  const accessToken = getSession(SESSION_KEYS.accessToken);
  const missionId = getSession(SESSION_KEYS.missionId);
  const appId = getSession(SESSION_KEYS.appId);
  return api
    .get(`${GET_PAYMENT_OPTIONS}=${missionId}`, BaseUrl.GatUrl, {
      gatOrgUnit,
      gatLocale,
      accessToken,
      appId
    })
    .then(res => {
      if (res.exception) {
        throw res.message;
      }
      return res;
    })
    .catch(err => {
      throw err;
    });
};

export default {
  getLocation,
  updateCustomerPosition,
  getInsuranceLevelsForOrgUnit: getDamageReasons,
  getAttributeConfinedSpace,
  getPaymentOptions,
  reverseGeoCode,
  getVehicleInsuranceLevels
};
