import { fromJS } from "immutable";
import {
  RESET_POSITION,
  GET_LOCATION_SUCCESS,
  TOGGLE_NO_TOW_TRUCKS_MODAL,
  TOGGLE_NO_LOCATION_SERVICE_MODAL,
  SET_VEHICLE_LOCATION,
  FETCHING_REVERSE_GEOCODE,
  SET_UNSAVED_SHOP_LOCATION,
  CLEAR_VEHICLE_POSITION,
  SAVE_DESTINATION_CHANGER_WITH_ALL_INFO,
  SET_POSITION_TO_SERVE
} from "./constants";
import { SET_TOWTRUCK_LOCATION } from "../activeOrder/constants";
import {
  SET_SHOP_LOCATION,
  GET_POSITION_SUCCESS
} from "../createOrder/constants";

const initialState = {
  isFetchingVehiclePosition: false,
  destinationChangerPosition: {
    latitude: "",
    longitude: "",
    city: "",
    zipcode: "",
    address: ""
  },
  customerPosition: {},
  vehiclePosition: {
    latitude: "",
    longitude: "",
    city: "",
    zipcode: "",
    address: ""
  },
  towtruckPosition: {
    latitude: "",
    longitude: ""
  },
  previousTowtruckPosition: {
    latitude: "",
    longitude: ""
  },
  positionToServe: {
    latitude: "",
    longitude: "",
    city: "",
    zipcode: "",
    address: ""
  },
  shopPosition: {},
  unsavedShopPosition: {},
  noTowTrucksModalState: false,
  noLocationServiceModalState: false
};
const reducer = (state = fromJS(initialState), action) => {
  let newState = state;
  switch (action.type) {
    case RESET_POSITION:
      initialState.noTowTrucksModalState = state.get("noTowTrucksModalState");
      return fromJS(initialState);
    case GET_POSITION_SUCCESS:
      newState = newState.set("customerPosition", action.customerPosition);
      newState = newState.set("locationFetched", true);
      return newState;
    case GET_LOCATION_SUCCESS:
      newState = newState.set("customerPosition", action.customerPosition);
      newState = newState.set("locationFetched", true);
      return newState;
    case SET_SHOP_LOCATION:
      newState = newState.set("shopPosition", action.shopPosition);
      newState = newState.set("unsavedShopPosition", {});
      return newState;
    case SET_UNSAVED_SHOP_LOCATION: {
      const {
        data,
        formattedAddress,
        latitude,
        longitude,
        name
      } = action.payload;

      let street = null;
      let streetNumber = null;
      let route = null;
      let postalTown = null;
      let postalCode = null;
      let address = null;

      data[0].address_components.forEach(adressComponent => {
        if (adressComponent.types.includes("street")) {
          street = adressComponent.long_name;
        } else if (adressComponent.types.includes("street_number")) {
          streetNumber = adressComponent.long_name;
        } else if (adressComponent.types.includes("route")) {
          route = adressComponent.long_name;
        } else if (adressComponent.types.includes("postal_town")) {
          postalTown = adressComponent.long_name;
        } else if (adressComponent.types.includes("postal_code")) {
          postalCode = adressComponent.long_name;
        }
      });

      if (street) {
        address = street;
        if (streetNumber) {
          address = `${street} ${streetNumber}`;
        }
      } else if (route) {
        address = route;
        if (streetNumber) {
          address = `${route} ${streetNumber}`;
        }
      } else {
        address = formattedAddress;
      }

      newState = newState.set("unsavedShopPosition", {
        formattedAddress,
        address,
        name,
        street,
        streetNumber,
        route,
        city: postalTown,
        zipcode: postalCode,
        latitude,
        longitude
      });
      return newState;
    }

    case SET_TOWTRUCK_LOCATION: {
      if (
        action.towtruckPosition.latitude &&
        action.towtruckPosition.longitude
      ) {
        newState = newState.set(
          "previousTowtruckPosition",
          state.get("towtruckPosition")
        );
        newState = newState.set("towtruckPosition", action.towtruckPosition);
      }
      return newState;
    }
    case FETCHING_REVERSE_GEOCODE:
      newState = newState.set("isFetchingVehiclePosition", action.payload);
      return newState;
    case SET_VEHICLE_LOCATION:
      newState = newState.set("vehiclePosition", action.payload);
      return newState;
    case TOGGLE_NO_TOW_TRUCKS_MODAL:
      newState = newState.set("noTowTrucksModalState", action.payload);
      return newState;
    case TOGGLE_NO_LOCATION_SERVICE_MODAL:
      newState = newState.set("noLocationServiceModalState", action.payload);
      return newState;
    case SAVE_DESTINATION_CHANGER_WITH_ALL_INFO:
      newState = newState.set("destinationChangerPosition", action.payload);
      return newState;
    case SET_POSITION_TO_SERVE:
      newState = newState.set("positionToServe", action.payload);
      return newState;
    case CLEAR_VEHICLE_POSITION:
      newState = newState.set("vehiclePosition", {
        latitude: "",
        longitude: "",
        city: "",
        zipcode: "",
        address: ""
      });
      return newState;
    default:
      return newState;
  }
};

export default { reducer };
