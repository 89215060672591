import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCarWrench } from "@fortawesome/pro-light-svg-icons";
import { useIntl } from "react-intl";
import { useSessionStorage } from "usehooks-ts";
import { Button } from "@assistansbolaget/gat-components";
import CardBoxContainer from "../../components/CardBoxContainer/CardBoxContainer";
import { SESSION_KEYS } from "../../helpers/sessionStore";

const OrderPickedUp = ({ company, driver, towTruckRegNo, destination }) => {
  const [companyAttributes] = useSessionStorage(
    SESSION_KEYS.companyAttributes,
    undefined
  );
  const phoneNumber = companyAttributes?.CompanyPhone?.value;
  const intl = useIntl();
  return (
    <>
      <CardBoxContainer disableToggle minimumHeight="88px">
        <div className="driver-onRoute">
          <div className="driver-onRoute-title">
            <FontAwesomeIcon
              icon={faCarWrench}
              color="#FFD4A3"
              style={{ width: "2rem", height: "2rem" }}
            />
            <h3>
              {intl.formatMessage({
                id: "ActiveOrder.OrderPickedUp.Title",
                defaultMessage: "Your vehicle is being towed",
                description: "activeOrder: pickedup title"
              })}
            </h3>
          </div>
          <div className="driver-onRoute-firstInfoText">
            <p>
              {intl.formatMessage({
                id: "ActiveOrder.OrderPickedUp.InfoTextDriver",
                defaultMessage: "Our driver",
                description: "activeOrder: pickedup info text driver"
              })}
              <strong>{` ${driver} `}</strong>
              {intl.formatMessage({
                id: "ActiveOrder.OrderPickedUp.InfoTextFrom",
                defaultMessage: "from",
                description: "activeOrder: pickedup info text from"
              })}
              <strong>{` ${company} `}</strong>
              {intl.formatMessage({
                id: "ActiveOrder.OrderPickedUp.ToDestinationText",
                defaultMessage: "towing your vehicle to address",
                description: "activeOrder: destination text"
              })}
              <strong>{` ${destination}`}</strong>
            </p>
          </div>
          <div className="driver-onRoute-secondInfoText">
            <p>
              {intl.formatMessage({
                id: "ActiveOrder.OrderPickedUp.InfoTextRegistrationNumber",
                defaultMessage: "The registration number of the tow truck is",
                description:
                  "activeOrder: pickedup info text registration number"
              })}
              <strong>{` ${towTruckRegNo}`}</strong>
            </p>
          </div>
        </div>
      </CardBoxContainer>
      <a
        href={`tel: ${phoneNumber}`}
        className="activeOrderCallSupport card-button flex justify-center"
      >
        <Button
          fullWidth
          kind="Solid"
          type="submit"
          className="button button-primary"
          style={{ width: "376px" }}
        >
          {intl
            .formatMessage({
              id: "ActiveOrder.OrderPickedUp.CallSupport",
              defaultMessage: "Call support",
              description: "call support button"
            })
            .toUpperCase()}
        </Button>
      </a>
    </>
  );
};

OrderPickedUp.propTypes = {
  company: PropTypes.string.isRequired,
  driver: PropTypes.string.isRequired,
  towTruckRegNo: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired
};

export default OrderPickedUp;
