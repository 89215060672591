export const GET = "GET";
export const POST = "POST";

export const BaseUrl = {
  GatUrl: "GatUrl",
  RescueUrl: "RescueUrl"
};

export const USER_LOGIN = "user/login";

export const GAT_DEV = "https://gat.dev.assi.nu/";
export const GAT_STG = "https://gat.stg.assi.nu/";
export const GAT_PROD = "https://api.smashtechsoftware.com/";

export const DEV_RESQUE = "https://testssl.assistansbolaget.nu/api/";
export const STAGING_RESQUE =
  "https://resque-backend-test.assistansbolaget.nu/api/";
export const PROD_RESQUE =
  "https://prod-rescue-backend.assistansbolaget.nu/api/";

export const AB_DEV = "https://api.test-cluster.assistansbolaget.nu/";
export const AB_STAGING = "https://testssl.assistansbolaget.nu/api/";
export const AB_PROD = "https://api-cluster.assistansbolaget.nu/";

export const SET_SOURCE_LOCATION = "v1/missions,set-source-location-by-token";

export const GET_JWT_TOKEN = "v1/missions,create-by-customer";

export const GET_DAMAGE_REASONS = "v1/mission-reason-groups,with-reasons";

export const IS_CONFINED_SPACE =
  "v1/attributes,paging?filters=key=IsConfinedSpace";

export const SELF_SERVE = "v1/missions,self-serve";

export const GET_PAYMENT_OPTIONS = "v1/payment-options?missionId";

export const GET_MISSION = "v1/missions,by-token";

export const GET_MISSIONS_BY_ID = "v1/missions/";

export const UPDATE_MISSION_DESTINATION =
  "v1/missions,set-destination-location";

export const FIND_DRIVER = "v1/missions,find-driver";

export const CUSTOMER_ACCEPT_MISSION = "v1/missions,customer-accept";

export const EDIT_MISSION_REQUEST = "v1/missions,edit";

export const CLEAR_DESTINATION_CHANGE =
  "v1/missions,clear-destination-location";

export const POST_REVIEW = "v1/missions,create-mission-review-by-token";

// To fetch old missions
export const USER_ASSISTANCE_ORDER = "user/assistance/order";

export const GET_VEHICLE_INSURANCE_LEVELS =
  "v1/vehicle-insurance-levels,in-hierarchy";

/* WIP TODO - Save to future imlementation */
export const CANCEL_MISSION = "v1/missions,cancel";

/* TODO - Fix google maps key to env variable */
export const GOOGLE_MAP_URL =
  "https://maps.googleapis.com/maps/api/js?libraries=places,geometry,drawing&key=AIzaSyCM3Er5wltGE45H78BW1z__41onnf_xl-0&language=sv";
