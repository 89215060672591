import styled from "styled-components";
import { Card } from "@assistansbolaget/gat-components";

const PositionInputContainer = styled(Card)`
  width: 310px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding: 24px;
`;

export default PositionInputContainer;
