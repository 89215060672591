import { fromJS } from "immutable";
import TOGGLE_LOADING from "./constants";

const initialState = fromJS({
  isLoading: false,
});

const reducer = (state = initialState, action) => {
  let newState = state;
  switch (action.type) {
    case TOGGLE_LOADING:
      newState = newState.set("isLoading", action.payload);
      return newState;
    default:
      return newState;
  }
};

export default { reducer };
