import React from "react";
import { connect } from "react-redux";
import {
  Field,
  getFormError,
  getFormSubmitErrors,
  getFormValues,
  reduxForm,
  submit
} from "redux-form";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import actions from "./actions";
import selectors from "./selectors";
import activeOrderSelectors from "../activeOrder/selectors";
import SelectField from "../../components/SelectField/SelectField";
import formValidate from "../../helpers/formValidate";
import submitDamageReasonValidation from "./submitDamageReasonValidation";
import {
  CUSTOMER_DETAILS_VIEW,
  ESTIMATE_PRICE_VIEW,
  SEEKING_RESPONDER_VIEW
} from "./viewConstants";
import CardBoxContainer from "../../components/CardBoxContainer/CardBoxContainer";
import TwoButtonContainer from "../../components/ButtonContainer/TwoButtonContainer";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";
import { SESSION_KEYS, getSession } from "../../helpers/sessionStore";

const { required } = formValidate;

const {
  decrementStep,
  clearValues,
  setCurrentView,
  setPreviousView,
  setNextView,
  submitCreateOrderForm,
  checkPreviousVehiclePosition,
  fetchDamageReasons,
  fetchConfinedSpaceAttributes,
  fetchPaymentOptionsList,
  fetchVehicleInsuranceLevels
} = actions;

const {
  getToggleFormValue,
  getNextView,
  getPreviousView,
  getDamageReasons,
  selectConfinedSpaces
} = selectors;

const { getOrder } = activeOrderSelectors;

class DamageReason extends React.Component {
  constructor(props) {
    const currentMission = getSession(SESSION_KEYS.missionId);
    super(props);
    this.state = {
      hasSetInitialValues: false,
      currentMission
    };
  }

  componentDidMount() {
    const {
      checkIfVehiclePositionHasChanged,
      previousView,
      nextView,
      customerDetailsFormValues,
      dispatchFetchDamageReasons,
      dispatchFetchConfinedSpaceAttributes,
      dispatchFetchPaymentOptionsList,
      dispatchFetchVehicleInsuranceLevels
    } = this.props;
    previousView();
    dispatchFetchDamageReasons();
    dispatchFetchConfinedSpaceAttributes();
    dispatchFetchPaymentOptionsList();
    dispatchFetchVehicleInsuranceLevels();
    if (customerDetailsFormValues?.isInsured) {
      nextView(SEEKING_RESPONDER_VIEW);
    } else {
      checkIfVehiclePositionHasChanged();
      nextView(ESTIMATE_PRICE_VIEW);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const gatLocaleMission = getSession(SESSION_KEYS.missionId);
    const {
      currentMission,
      dispatchFetchDamageReasons,
      order,
      damageReasons,
      initialize,
      getSelectConfinedSpaces
    } = this.props;
    if (gatLocaleMission !== currentMission) {
      dispatchFetchDamageReasons();
    }
    const currentDamageDetails = order?.reason;
    if (!currentDamageDetails) {
      return;
    }
    if (
      !this.state.hasSetInitialValues &&
      order.size !== 0 &&
      damageReasons.size !== 0 &&
      getSelectConfinedSpaces !== ""
    ) {
      const currentDamageReasonGroup = damageReasons.find(item =>
        item?.missionReasons?.find(reason => reason?.id === order?.reason?.id)
      );
      const garage =
        order?.attributes?.find(
          item => item?.attributeId === getSelectConfinedSpaces
        )?.value === "true";
      initialize({
        damageReason: {
          label: currentDamageReasonGroup?.name,
          value: currentDamageReasonGroup?.id
        },
        damageDetails: {
          label: currentDamageDetails?.name,
          value: currentDamageDetails?.id
        },
        garage
      });
      this.state.hasSetInitialValues = true;
    }
  }

  render() {
    const {
      handleSubmit,
      nextStep,
      formValues,
      changeValue,
      submitFailed,
      handlePreviousButton,
      previous,
      damageReasons,
      intl
    } = this.props;

    const handleGarageChange = value => {
      const { change } = this.props;
      change("garage", value);
    };

    const damageGroupReasonsOptions = damageReasons?.map(item => ({
      value: item.id,
      name: item.name
    }));

    const damageReasonsOptions = damageReasons?.find(
      item => item?.id === formValues?.damageReason?.value
    );

    const damageReasonsOptionsValues = damageReasonsOptions?.missionReasons?.map(
      item => ({
        value: item.id,
        name: item.name
      })
    );

    const { damageDetails, damageReason } = formValues || {};

    const shouldDisableNextButton = !damageDetails || !damageReason;

    return (
      <>
        <div className="damage-reason-form">
          <CardBoxContainer disableToggle>
            <h3 style={{ fontSize: "1.5rem", fontWeight: "700" }}>
              {intl.formatMessage({
                id: "CreateOrder.DamageReason.Title",
                defaultMessage: "Select damage reason",
                description: "Damagereason: damagereason title"
              })}
            </h3>
            <form
              className="damage-reason-form-container"
              onSubmit={handleSubmit}
            >
              <Field
                name="damageReason"
                onChange={changeValue}
                component={SelectField}
                notSelected={!formValues || !formValues.damageReason}
                options={damageGroupReasonsOptions}
                value={formValues && damageReason}
                placeholder={intl.formatMessage({
                  id: "CreateOrder.DamageReason.ChooseReason",
                  defaultMessage: "Choose reason",
                  description: "Damagereason: Choose reason"
                })}
              ></Field>
              {damageReasonsOptionsValues && (
                <Field
                  name="damageDetails"
                  component={SelectField}
                  notSelected={!formValues || !formValues.damageDetails}
                  validate={[required]}
                  options={damageReasonsOptionsValues}
                  value={formValues && damageReason}
                  placeholder={intl.formatMessage({
                    id: "CreateOrder.DamageReason.ChooseDetails",
                    defaultMessage: "Chose...",
                    description: "Damagereason: Choose reason"
                  })}
                  onChange={changeValue}
                ></Field>
              )}
              <ButtonGroup
                value={formValues && formValues.garage}
                name="garage"
                submitFailed={submitFailed}
                label={intl.formatMessage({
                  id: "CreateOrder.DamageReason.ConfinedSpaceQuestion",
                  defaultMessage: "Confined space question",
                  description: "Damagereason: Confined space question"
                })}
                onChange={handleGarageChange}
                values={[
                  {
                    value: false,
                    label: intl.formatMessage({
                      id: "general.no",
                      defaultMessage: "No",
                      description: "Switchbutton: no"
                    })
                  },
                  {
                    value: true,
                    label: intl.formatMessage({
                      id: "general.yes",
                      defaultMessage: "Yes",
                      description: "Switchbutton: yes"
                    })
                  }
                ]}
              />
            </form>
            <TwoButtonContainer
              id="confirm-or-go-back-damage-reason-buttons"
              previousStepText={intl
                .formatMessage({
                  id: "general.goBack",
                  defaultMessage: "Go back",
                  description: "Go back"
                })
                .toUpperCase()}
              previousStep={() => handlePreviousButton(previous)}
              nextStep={nextStep}
              nextStepText={intl
                .formatMessage({
                  id: "general.continue",
                  defaultMessage: "Continue",
                  description: "Continue"
                })
                .toUpperCase()}
              disabled={shouldDisableNextButton}
            />
          </CardBoxContainer>
        </div>
      </>
    );
  }
}

DamageReason.propTypes = {
  // eslint-disable-next-line
  formValues: PropTypes.shape({
    damageReason: PropTypes.object,
    damageDetails: PropTypes.string,
    reserveTire: PropTypes.bool,
    garage: PropTypes.bool,
    engineInsuranceType: PropTypes.string
  }),
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  customerDetailsFormValues: PropTypes.shape({
    isInsured: PropTypes.bool
  }).isRequired,
  nextStep: PropTypes.func.isRequired,
  previousView: PropTypes.func.isRequired,
  nextView: PropTypes.func.isRequired,
  previous: PropTypes.string.isRequired,
  changeValue: PropTypes.func.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  handlePreviousButton: PropTypes.func.isRequired,
  checkIfVehiclePositionHasChanged: PropTypes.func.isRequired,
  damageReasons: PropTypes.arrayOf(PropTypes.object).isRequired,
  dispatchFetchDamageReasons: PropTypes.func.isRequired,
  dispatchFetchConfinedSpaceAttributes: PropTypes.func.isRequired,
  dispatchFetchVehicleInsuranceLevels: PropTypes.func.isRequired,
  dispatchFetchPaymentOptionsList: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired
};

// eslint-disable-next-line
DamageReason = reduxForm({
  form: "createOrderForm",
  initialValues: { garage: false, reserveTire: undefined },
  onSubmit: submitDamageReasonValidation,
  destroyOnUnmount: false
})(DamageReason);

const mapStateToProps = state => ({
  order: getOrder(state),
  next: getNextView(state),
  previous: getPreviousView(state),
  formValues: getFormValues("createOrderForm")(state),
  customerDetailsFormValues: getFormValues("customerDetails")(state),
  submitErrors: getFormSubmitErrors("createOrderForm")(state),
  formError: getFormError("createOrderForm")(state),
  toggleFormValue: getToggleFormValue(state),
  damageReasons: getDamageReasons(state),
  getSelectConfinedSpaces: selectConfinedSpaces(state)
});

const mapDispatchToProps = dispatch => ({
  previousView: () => dispatch(setPreviousView(CUSTOMER_DETAILS_VIEW)),
  nextView: view => dispatch(setNextView(view)),
  submitForm: () => dispatch(submit("createOrderForm")),
  previousStep: () => dispatch(decrementStep()),
  nextStep: () => dispatch(submitCreateOrderForm()),
  changeValue: () => dispatch(clearValues()),
  handlePreviousButton: previous => dispatch(setCurrentView(previous)),
  checkIfVehiclePositionHasChanged: () =>
    dispatch(checkPreviousVehiclePosition()),
  dispatchFetchDamageReasons: () => dispatch(fetchDamageReasons()),
  dispatchFetchConfinedSpaceAttributes: () =>
    dispatch(fetchConfinedSpaceAttributes()),
  dispatchFetchPaymentOptionsList: () => dispatch(fetchPaymentOptionsList()),
  dispatchFetchVehicleInsuranceLevels: () =>
    dispatch(fetchVehicleInsuranceLevels())
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(DamageReason)
);
