import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import "./style.css";
import CardBoxContainerTest from "./CardBoxContainer.style";

const CardBoxContainer = ({ children, setDarkStyle, id }) => {
  // const [isOpen, setIsOpen] = useState(true);
  const isOpen = true;

  return (
    <>
      <CardBoxContainerTest
        className={classNames(
          "card-box",
          { "card-box-height": isOpen },
          { hide: !isOpen },
          { "overflow-hidden": !isOpen },
          { "card-box-dark": setDarkStyle }
        )}
        id={id}
      >
        {children}
      </CardBoxContainerTest>
    </>
  );
};

CardBoxContainer.defaultProps = {
  setDarkStyle: false,
  id: ""
};

CardBoxContainer.propTypes = {
  setDarkStyle: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  id: PropTypes.string
};

export default CardBoxContainer;
