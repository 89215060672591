import legacyStringsSummary from "./legacyStringsSummary";

const language = legacyStringsSummary;

const { damageDetails, damageReasons, accidentOptionFunction } = language;

const FLAT_TIRE = {
  CODE: 901114,
  HEADER: damageReasons.flatTire,
  DETAILS_TITLE: accidentOptionFunction.chooseTire,
  details: {
    FRONT_LEFT: damageDetails.frontLeft,
    FRONT_RIGHT: damageDetails.frontRight,
    REAR_LEFT: damageDetails.rearLeft,
    REAR_RIGHT: damageDetails.rearRight
  }
};

const START_HELP = {
  CODE: 901110,
  HEADER: damageReasons.startHelp
};

const KEY_ISSUES = {
  CODE: 901113,
  HEADER: damageReasons.keyIssues,
  DETAILS_TITLE: accidentOptionFunction.chooseReason,
  details: {
    MISSING: damageDetails.missing,
    DEFECT: damageDetails.defect,
    LOCKED_INSIDE_VEHICLE: damageDetails.lockedInsideVehicle
  }
};

const PROPULSION = {
  CODE: 901115,
  HEADER: damageReasons.propulsion,
  DETAILS_TITLE: accidentOptionFunction.fuelType,
  details: {
    PETROL: damageDetails.petrol,
    DIESEL: damageDetails.diesel,
    ETHANOL: damageDetails.ethanol,
    ELECTRIC: damageDetails.electric
  }
};

const WARNING_LIGHT = {
  CODE: 901105,
  HEADER: damageReasons.warningLight,
  DETAILS_TITLE: accidentOptionFunction.chooseWarningColor,
  details: {
    RED: damageDetails.red,
    YELLOW: damageDetails.yellow,
    ERROR_MESSAGE: damageDetails.errorMessage
  }
};

const ENGINE_PROBLEM = {
  CODE: 901123,
  HEADER: damageReasons.engineProblem,
  DETAILS_TITLE: accidentOptionFunction.chooseEngineProblem,
  details: {
    CAM_DRIVE: damageDetails.camDrive,
    TURBO: damageDetails.turbo,
    OIL_LEAKAGE: damageDetails.oilLeakage,
    FAN_BELT: damageDetails.fanBelt,
    COOLING_SYSTEM: damageDetails.coolingSystem,
    WATER_PUMP: damageDetails.waterPump,
    POWER_TRANSMISSION: damageDetails.powerTransmission
  }
};

const GEAR_BOX = {
  CODE: 901116,
  HEADER: damageReasons.gearBox,
  DETAILS_TITLE: accidentOptionFunction.chooseGearBox,
  details: {
    CLUTCH: damageDetails.clutch,
    FLYWHEEL: damageDetails.flywheel,
    DISTRIBUTION_BOX: damageDetails.distributionBox
  }
};

const ELECTRONICS = {
  CODE: 901170,
  HEADER: damageReasons.electronics,
  DETAILS_TITLE: accidentOptionFunction.chooseElectronics,
  details: {
    GENERATOR: damageDetails.generator,
    CONTROL_DEVICE: damageDetails.controlDevice,
    IGNITION_LOCK: damageDetails.ignitionLock,
    BATTERY_BANK: damageDetails.batteryBank
  }
};

const FUEL_SYSTEM = {
  CODE: 901171,
  HEADER: damageReasons.fuelSystem,
  DETAILS_TITLE: accidentOptionFunction.chooseFuelSystem,
  details: {
    FUEL_INJECTOR: damageDetails.fuelInjector,
    FUEL_PUMP: damageDetails.fuelPump,
    GAS_REGULATOR: damageDetails.gasRegulator
  }
};

const STEERING = {
  CODE: 901112,
  HEADER: damageReasons.steering,
  DETAILS_TITLE: accidentOptionFunction.chooseSteering,
  details: {
    STEERING_GEAR: damageDetails.steeringGear,
    SERVO_PUMP: damageDetails.servopump,
    STEERING_WHEEL_LOCK: damageDetails.steeringWheelLock
  }
};

const BRAKING_SYSTEM = {
  CODE: 901172,
  HEADER: damageReasons.brakingSystem,
  DETAILS_TITLE: accidentOptionFunction.chooseBrakingSystem,
  details: {
    ABS_ESP: damageDetails.abs,
    PARKING_BRAKE: damageDetails.parkingBrake,
    BRAKE_SERVO: damageDetails.brakeServo,
    BRAKE_PADS: damageDetails.brakePads
  }
};

const OTHER_DAMAGE = {
  CODE: 901122,
  HEADER: damageReasons.otherDamage,
  DETAILS_TITLE: accidentOptionFunction.chooseOtherDamage,
  details: {
    REAR_CARRIAGE: damageDetails.rearCarriage,
    FRONT_CARRIAGE: damageDetails.frontCarriage,
    EXHAUST_SYSTEM: damageDetails.exhaustSystem,
    HYDRAULICS: damageDetails.hydraulics,
    GLASS_DAMAGE: damageDetails.glassDamage,
    FIRE: damageDetails.fire,
    FUEL_ERROR: damageDetails.fuelError
  }
};

const COLLISION = { CODE: 901106, HEADER: damageReasons.collision };

const DITCH = { CODE: 901139, HEADER: damageReasons.ditch };

const TRANSPORT = { CODE: 920010, HEADER: damageReasons.transport };

const DRIVING_BAN = { CODE: 901173, HEADER: damageReasons.drivingBan };

export const DAMAGE_REASONS = {
  FLAT_TIRE,
  START_HELP,
  KEY_ISSUES,
  PROPULSION,
  WARNING_LIGHT,
  ENGINE_PROBLEM,
  GEAR_BOX,
  ELECTRONICS,
  FUEL_SYSTEM,
  STEERING,
  BRAKING_SYSTEM,
  OTHER_DAMAGE,
  COLLISION,
  DITCH,
  TRANSPORT,
  DRIVING_BAN
};
