import {
  AB_DEV,
  AB_PROD,
  AB_STAGING,
  BaseUrl,
  DEV_RESQUE,
  PROD_RESQUE,
  STAGING_RESQUE
} from "../constants/apiConstants";

async function tryParseResponse(response) {
  try {
    const json = await response.json();
    return {
      statusCode: response.status,
      data: json
    };
  } catch (error) {
    throw new Error(error);
  }
}

export function legacyGetBaseUrl(base) {
  if (process.env.REACT_APP_ENV === "PRODUCTION") {
    if (base === BaseUrl.RescueUrl) return PROD_RESQUE;
    return AB_PROD;
  }
  if (process.env.REACT_APP_ENV === "STAGING") {
    if (base === BaseUrl.RescueUrl) return STAGING_RESQUE;
    return AB_STAGING;
  }
  if (base === BaseUrl.RescueUrl) return DEV_RESQUE;
  return AB_DEV;
}

async function post(url, base, body, token) {
  const response = await fetch(`${legacyGetBaseUrl(base)}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(body)
  });
  return tryParseResponse(response);
}

async function get(url, base, token) {
  const response = await fetch(`${legacyGetBaseUrl(base)}${url}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  });
  return tryParseResponse(response);
}

const legacyApi = {
  post,
  get
};

export default legacyApi;
