import {
  SAVE_MAP_TO_STATE,
  SAVE_CENTER_COORDS,
  SHOW_SPINNER,
} from "./constants";

const saveMapToState = (map) => ({
  type: SAVE_MAP_TO_STATE,
  payload: map,
});

const saveCenterCoords = (centerCoords) => ({
  type: SAVE_CENTER_COORDS,
  payload: centerCoords,
});

const showSpinner = () => ({
  type: SHOW_SPINNER,
});

export default {
  saveMapToState,
  saveCenterCoords,
  showSpinner,
};
