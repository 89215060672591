import { BaseUrl, GAT_DEV, GAT_PROD, GAT_STG } from "../constants/apiConstants";

function headers(gatHeaders) {
  const { gatLocale, gatOrgUnit, appId, accessToken } = gatHeaders || {};

  const headersPrompt = {
    "Content-Type": "application/json"
  };

  if (accessToken) {
    headersPrompt.Authorization = `Bearer ${accessToken}`;
  }
  if (gatLocale) {
    headersPrompt["gat-locale"] = gatLocale;
  }
  if (gatOrgUnit) {
    headersPrompt["gat-organization-unit"] = gatOrgUnit;
  }
  if (appId) {
    headersPrompt["gat-application-id"] = appId;
  }
  return headersPrompt;
}

const getErrorObj = response => {
  return {
    statusCode: response?.statusCode,
    data: response.data || response.statusText
  };
};

async function tryParseResponse(response) {
  try {
    const json = await response.json();
    return {
      statusCode: response.status,
      data: json
    };
  } catch (error) {
    throw getErrorObj(response);
  }
}

function getBaseUrl(base) {
  if (process.env.REACT_APP_ENV === "PRODUCTION") {
    if (base === BaseUrl.GatUrl) return GAT_PROD;
  }
  if (process.env.REACT_APP_ENV === "STAGING") {
    if (base === BaseUrl.GatUrl) return GAT_STG;
  }
  return GAT_DEV;
}

async function get(url, base, gatHeaders) {
  const response = await fetch(`${getBaseUrl(base)}${url}`, {
    method: "GET",
    headers: headers(gatHeaders)
  });

  return tryParseResponse(response);
}

async function post(url, base = BaseUrl.GatUrl, body, gatHeaders) {
  const response = await fetch(`${getBaseUrl(base)}${url}`, {
    method: "POST",
    headers: headers(gatHeaders),
    body: JSON.stringify(body)
  });
  return tryParseResponse(response);
}

async function put(url, base = BaseUrl.GatUrl, body) {
  const response = await fetch(`${getBaseUrl(base)}${url}`, {
    method: "PUT",
    headers: headers(),
    body: JSON.stringify(body)
  });
  return tryParseResponse(response);
}

const api = { get, post, put, getBaseUrl };

export default api;
