import towTruckTemplate from "./towtruckTemplate";

const getTemplateFromType = type => {
  switch (type) {
    case "transporttruck":
      //   return transportTemplate;
      return towTruckTemplate;
    case "towtruck":
    default:
      return towTruckTemplate;
  }
};

export default function truckIcon(color, heading, type) {
  const template = getTemplateFromType(type);
  return template(color, heading);
}
