import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import { faUserHeadset } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { injectIntl } from "react-intl";
import actions from "./actions";
import selectors from "./selectors";
import StyledInfoCard from "./SeekingResponder.styles";

const { fetchFinalPrice, fetchDispatch } = actions;
const { getNoEstimateReason } = selectors;

class SeekingResponder extends React.Component {
  componentDidMount() {
    const {
      createAssistanceOrder,
      createDispatchOrder,
      customerDetailsFormValues,
      noEstimateReason
    } = this.props;
    if (
      customerDetailsFormValues?.isInsured ||
      customerDetailsFormValues?.noEstimate ||
      noEstimateReason !== undefined
    ) {
      createDispatchOrder();
    } else {
      createAssistanceOrder();
    }
  }

  render() {
    const { intl } = this.props;
    return (
      <div className="flex justify-center align-center full-height-width absolute top-left-0">
        <div
          className="overlay-content align-center"
          style={{ background: "none" }}
        >
          <StyledInfoCard>
            <h4
              style={{
                fontWeight: "700",
                fontSize: "18px",
                marginBottom: "15px"
              }}
            >
              {intl.formatMessage({
                id: "SeekingResponder.Title",
                defaultMessage: "We will assist you!",
                description: "Title for Seeking responder view"
              })}
            </h4>
            <>
              <h4 style={{ marginBottom: "15px" }}>
                {intl.formatMessage({
                  id: "SeekingResponder.BreadText",
                  defaultMessage:
                    "We will get in touch with you shortly. You mission will be handled by our dispatch",
                  description: "Bread text for Seeking Responder"
                })}
              </h4>
              <FontAwesomeIcon
                icon={faUserHeadset}
                size="2x"
                style={{ color: "#FFD4A3", marginBottom: "15px" }}
              />
              <h4
                style={{
                  color: "#FFD4A3",
                  fontWeight: "700",
                  marginBottom: "15px"
                }}
              >
                {intl.formatMessage({
                  id: "SeekingResponder.HighLightText",
                  defaultMessage:
                    "Please wait, we will call you within minutes on the provided phone number",
                  description: "High lighted text in Seeking Responder modal"
                })}
              </h4>
            </>
          </StyledInfoCard>
        </div>
      </div>
    );
  }
}

SeekingResponder.propTypes = {
  createAssistanceOrder: PropTypes.func.isRequired,
  createDispatchOrder: PropTypes.func.isRequired,
  customerDetailsFormValues: PropTypes.shape({
    isInsured: PropTypes.bool,
    noEstimate: PropTypes.bool
  }).isRequired,
  noEstimateReason: PropTypes.bool.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired
};

const mapStateToProps = state => ({
  customerDetailsFormValues: getFormValues("customerDetails")(state),
  noEstimateReason: getNoEstimateReason(state)
});

const mapDispatchToProps = dispatch => ({
  createDispatchOrder: () => dispatch(fetchDispatch()),
  createAssistanceOrder: () => dispatch(fetchFinalPrice())
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(SeekingResponder)
);
