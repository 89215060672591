import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import classNames from "classnames";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Button } from "@assistansbolaget/gat-components";
import selectors from "../selectors";
import validators from "../validators";
import CardBoxContainer from "../../../components/CardBoxContainer/CardBoxContainer";
import styles from "./receipt.module.scss";
import location from "../../../assets/images/location-pin-secondary.svg";
import arrowDown from "../../../assets/images/arrow-down-outline.svg";
import repair from "../../../assets/images/repair.svg";
import calendar from "../../../assets/images/calendar.svg";
import towTruck from "../../../assets/images/towTruckReciept.svg";
import StaticMap from "../../map/static/staticMap";
import { formatHoursAndMinutes, formatYYYYMMDD } from "../../../helpers/date";
import PDF from "./legacyPDF/pdf";
import legacyStringsSummary from "./legacyStringsSummary";
import legacyGetSummary from "./legacyApi";
import LegacyRate from "./legacyRate";
import actions from "./legacyActions";
import LegacyTextArea from "../../../components/TextArea/LegacyTextArea";

const language = legacyStringsSummary;

const { validateEmail } = validators;

const { getRating } = selectors;

const { legacySubmitSummaryForm } = actions;

const validate = values => {
  const errors = {};
  errors.email = validateEmail(values.email);
  return errors;
};

let LegacyReciept = ({ legacyOrder, rating, handleSummarySubmit }) => {
  const order = legacyOrder;
  const [damageDate, setDamageDate] = useState("");
  const [summary, setSummary] = useState();
  const [showOpinion, setShowOpinion] = useState(false);

  const checkIfRepairedAtSpot = () => {
    return !!order?.timestamps?.repairedAtDamagePlace;
  };

  const createMapMarkers = () => {
    if (checkIfRepairedAtSpot()) {
      return [
        {
          icon:
            "https://assets-media-smashtechsoftware.s3.eu-central-1.amazonaws.com/sourceLocationPng.png",
          lat: order?.s_latitude,
          long: order?.s_longitude
        }
      ];
    }
    return [
      {
        icon:
          "https://assets-media-smashtechsoftware.s3.eu-central-1.amazonaws.com/sourceLocationPng.png",
        lat: order?.s_latitude,
        long: order?.s_longitude
      },
      {
        icon:
          "https://assets-media-smashtechsoftware.s3.eu-central-1.amazonaws.com/ico_repair_destination.png",
        lat: order?.d_latitude,
        long: order?.d_longitude
      }
    ];
  };

  useEffect(() => {
    const currentDamageDate = order?.assigned_at;
    setDamageDate(currentDamageDate);
  }, [order]);

  useEffect(() => {
    if (order?.damageOrderNumber) {
      legacyGetSummary
        .getSummary(order?.damageOrderNumber)
        .then(res => setSummary(res));
    }
  }, [order?.damageOrderNumber]);

  const renderCostSummary = () => {
    const { orderLines, total } = summary?.order;
    return (
      <div className={styles.costSummary}>
        {orderLines?.map(orderLine => (
          <div key={orderLine.id} className={styles.cost}>
            <span>{orderLine.name}</span>
            <span>{orderLine.price} kr</span>
          </div>
        ))}
        <div className={styles.cost}>
          <span className={styles.totalCostHeader}>Totalkostnad</span>
          <span className={styles.totalCostHeader}>{total || 0} kr</span>
        </div>
      </div>
    );
  };

  const repairedAtSpot = checkIfRepairedAtSpot();

  const handleClick = () => {
    setShowOpinion(true);
  };

  const handleSubmit = () => {
    setShowOpinion(false);
    handleSummarySubmit();
  };

  return (
    <div className={styles.container}>
      <CardBoxContainer setDarkStyle disableToggle>
        <div className={styles.textBox}>
          <p className={styles.text}>{language.summary.receipt.text1}</p>
          <p className={classNames(styles.text, styles.golden)}>
            {language.summary.receipt.text2}
          </p>
        </div>

        <span className={styles.rating}>
          <LegacyRate />
        </span>
        {showOpinion && (
          <span>
            <div>
              <form onSubmit={handleSubmit}>
                <CardBoxContainer>
                  <p className="color-white center-text">
                    {language.summary.opinion.text1}
                  </p>
                  <p className="color-white center-text">
                    {language.summary.opinion.text2}
                  </p>
                  <span style={{ width: "100%" }}>
                    <Field
                      name="message"
                      component={LegacyTextArea}
                      type="text"
                      placeholder={language.summary.opinion.placeholder}
                    />
                  </span>
                </CardBoxContainer>

                <button
                  className="button button-primary card-button"
                  type="submit"
                >
                  {language.summary.opinion.send}
                </button>
              </form>
            </div>
          </span>
        )}

        {!showOpinion && (
          <button
            type="button"
            className={styles.button}
            onClick={handleClick}
            style={{ width: "100%" }}
            disabled={rating === 0}
          >
            {language.summary.receipt.summary}
          </button>
        )}

        <div className={styles.receipt}>
          <h4 className={styles.caption}>{language.summary.receipt.title}</h4>
          {damageDate && (
            <>
              <div className={styles.textWithIcon}>
                <img src={calendar} alt="created date" />
                <span className={styles.text}>
                  {formatYYYYMMDD(damageDate)}{" "}
                  {formatHoursAndMinutes(damageDate)}
                </span>
              </div>
            </>
          )}
          <div className={styles.timeStamps}>
            {repairedAtSpot ? (
              <div className={styles.textWithIcon}>
                <img src={repair} alt="repair" />
                <span className={styles.text}>
                  {language.summary.receipt.repairAtSpotLabel}
                </span>
              </div>
            ) : (
              <div className={styles.textWithIcon}>
                <img src={towTruck} alt="tow truck" />
                <span className={styles.text}>
                  {language.summary.receipt.towingLabel}
                </span>
              </div>
            )}
            <div className={styles.damageLocations}>
              <img src={location} alt="source location" />
              <div className={styles.damageLocationsText}>
                <span className={styles.text}>
                  {summary?.order?.damagePositionStreet}
                </span>
                <span className={styles.text}>
                  {summary?.order?.damagePositionArea}
                </span>
              </div>
            </div>
            {!repairedAtSpot && (
              <>
                <div className={styles.textWithIcon}>
                  <img
                    src={arrowDown}
                    alt="path"
                    className={styles.arrowIcon}
                  />
                </div>

                <div className={styles.damageLocations}>
                  <img src={location} alt="workshop location" />
                  <div className={styles.damageLocationsText}>
                    <span className={styles.text}>
                      {summary?.order?.transportedToStreet}
                    </span>
                    <span className={styles.text}>
                      {summary?.order?.transportedToArea}
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <StaticMap markers={createMapMarkers()} />

        {summary && summary.order && renderCostSummary()}

        {summary && (
          <PDFDownloadLink
            style={{ width: "100%" }}
            fileName="receipt.pdf"
            document={
              <PDF
                summary={summary}
                markers={createMapMarkers()}
                damageDate={`${formatYYYYMMDD(
                  damageDate
                )} ${formatHoursAndMinutes(damageDate)}`}
                regno={order?.user.regno}
                fixedOnPlace={!repairedAtSpot}
              />
            }
          >
            {() => (
              <Button fullWidth type="button">
                {language.summary.receipt.downloadReceipt}
              </Button>
            )}
          </PDFDownloadLink>
        )}
      </CardBoxContainer>
    </div>
  );
};

LegacyReciept.propTypes = {
  rating: PropTypes.number.isRequired,
  legacyOrder: PropTypes.instanceOf(Object).isRequired,
  handleSummarySubmit: PropTypes.func.isRequired
};

LegacyReciept = reduxForm({
  form: "receiptForm",
  initialValues: {
    email: "",
    message: ""
  },
  validate,
  destroyOnUnmount: true
})(LegacyReciept);

const mapStateToProps = state => ({
  rating: getRating(state)
});

const mapDispatchToProps = dispatch => ({
  handleSummarySubmit: () => {
    dispatch(legacySubmitSummaryForm());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(LegacyReciept);
