import React from "react";
import { compose, withProps } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";
import { directionsMapOptions } from "./defaultMapOptions";
import { GOOGLE_MAP_URL } from "../../constants/apiConstants";

const OrderCompleteMap = compose(
  withProps({
    googleMapURL: GOOGLE_MAP_URL,
    loadingElement: <div className="full-height-width" />,
    containerElement: <div className="map-container-element" />,
    mapElement: <div className="full-height-width" />
  }),
  withScriptjs,
  withGoogleMap
)(props => {
  const { vehicleCoordinates } = props;
  return (
    <>
      {vehicleCoordinates && vehicleCoordinates.latitude && (
        <GoogleMap
          defaultCenter={{
            lat: vehicleCoordinates.latitude,
            lng: vehicleCoordinates.longitude
          }}
          defaultZoom={14}
          defaultOptions={directionsMapOptions}
        />
      )}
    </>
  );
});

export default OrderCompleteMap;
