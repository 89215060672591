function parseJSON(value) {
  try {
    return value === "undefined" ? undefined : JSON.parse(value ?? "");
  } catch {
    return undefined;
  }
}

export const getSession = key => {
  return parseJSON(sessionStorage.getItem(key));
};

export const deleteSession = key => {
  sessionStorage.removeItem(key);
};

export const setSession = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const SESSION_KEYS = {
  organizationHeaderName: "gat-organization-header-name",
  gatOrganizationUnit: "gat-organization-unit",
  locale: "gat-locale",
  companyAttributes: "gat-company-attributes",
  accessToken: "gat-access-token",
  missionId: "gat-mission-id",
  token: "gat-token",
  appId: "gat-application-id"
};
