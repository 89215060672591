import React, { useEffect, useState } from "react";
import {
  Document,
  Image,
  Page,
  Text,
  View,
  Svg,
  Path
} from "@react-pdf/renderer";
import PropTypes from "prop-types";
import { DAMAGE_REASONS } from "../legacyDamageReasons";
import styles from "./pdf.style";
import legacyStringsSummary from "../legacyStringsSummary";

const PDF = ({ summary, damageDate, fixedOnPlace, regno, markers }) => {
  const {
    total,
    orderLines,
    chargedKilometers,
    vat,
    taxRate,
    damagePositionStreet,
    damagePositionArea,
    transportedToStreet,
    transportedToArea
  } = summary?.order;
  const { brand, year } = summary?.vehicle;
  const {
    firstName,
    lastName,
    city,
    zipCode,
    streetAddress
  } = summary?.customer;
  const { damageReason, damageDetails } = summary;

  const [specifiedDamageDetail, setSpecifiedDamageDetail] = useState("");
  const queryParams = new URLSearchParams(window.location.search);
  const missionId = queryParams.get("id");
  const language = legacyStringsSummary;
  const damageReasonLabel = Object.values(DAMAGE_REASONS).find(
    d => d.CODE === damageReason
  ).HEADER;

  const newValue = Object.values(DAMAGE_REASONS).find(
    d => d.CODE === damageReason
  ).details;

  useEffect(() => {
    Object.entries(newValue || {}).forEach(([key, value]) => {
      if (key === damageDetails) {
        setSpecifiedDamageDetail(value);
      }
    });
  }, []);

  const todaysDate = new Date().toLocaleDateString("SV");

  const baseUrl = "https://maps.googleapis.com/maps/api/staticmap";
  const apiKey = "AIzaSyCM3Er5wltGE45H78BW1z__41onnf_xl-0";
  const markerParameters = () => {
    if (markers.length === 1) {
      return markers
        .map(m => `markers=icon:${m.icon}%7C${m.lat},${m.long}&zoom=12`)
        .join("&");
    }
    return markers
      .map(m => `markers=icon:${m.icon}%7C${m.lat},${m.long}`)
      .join("&");
  };

  const mapUrl = `${baseUrl}?${markerParameters()}&size=800x300&key=${apiKey}&format=png&maptype=roadmap&style=feature:administrative%7Celement:geometry%7Cvisibility:off&style=feature:administrative.land_parcel%7Celement:labels%7Cvisibility:off&style=feature:poi%7Cvisibility:off&style=feature:poi%7Celement:labels.text%7Cvisibility:off&style=feature:road%7Celement:labels.icon%7Cvisibility:off&style=feature:road.local%7Celement:labels%7Cvisibility:off&style=feature:transit%7Cvisibility:off`;

  return (
    <Document>
      <Page size="A4" style={styles.body} wrap>
        <View fixed>
          <Text style={styles.recieptText}>
            {language.summary.receipt.recieptGenerated} {todaysDate}
          </Text>
        </View>
        <View style={styles.iconNav} fixed>
          <View style={styles.iconSize}>
            <Svg width={210} height={120} style={styles.iconPadding}>
              <Path
                transform="scale(.40)"
                fill="black"
                stroke="black"
                strokeWidth={1}
                d="M0 58.138C1.35545 57.4234 2.65097 56.55 4.07097 56.0035C18.7642 50.4452 34.0753 47.7921 49.64 46.5964C65.5596 45.3633 81.4977 44.1675 97.445 43.3782C115.951 42.4627 133.198 37.1473 149.902 29.3049C163.433 22.9525 177.14 16.9085 191.04 11.4716C206.727 5.25791 223.258 1.50455 240.062 0.340972C240.537 0.266238 241.008 0.154138 241.464 0H258.292C261.948 0.509122 265.622 0.948182 269.274 1.53671C286.056 4.23646 302.422 8.67844 318.6 13.8677C329.684 17.4176 340.55 21.6868 351.694 25.0264C358.987 27.2077 366.553 28.6043 374.077 29.8C388.503 32.0747 403.159 32.4017 417.668 30.7809C420.102 30.51 422.509 30.0289 424.934 29.6832C425.856 29.5478 426.787 29.5291 428 29.4357C427.115 31.7711 425.395 32.5091 423.689 32.9295C419.208 34.0505 414.699 35.0921 410.149 35.9188C400.66 37.633 391.066 37.9506 381.38 37.9366C395.688 44.441 410.298 50.2397 425.16 55.3122C425.045 55.6158 425.026 55.9147 424.893 55.9848C419.231 59.0956 413.754 62.2344 406.728 60.3614C378.881 52.9441 350.527 51.0197 321.869 52.9301C317.817 53.1963 313.769 53.598 309.721 53.9203C309.061 53.9717 308.402 53.9203 307.738 53.9203L307.526 53.3411C309.011 52.5237 310.449 51.5989 311.989 50.9122C317.614 48.4227 323.653 48.007 329.656 47.4932C343.275 46.3505 356.953 46.0853 370.605 46.6991C370.988 46.6991 371.38 46.6524 372.376 46.587C371.32 46.0592 370.808 45.7603 370.273 45.5314C350.384 37.2313 330.647 28.5342 310.569 20.7386C292.598 13.779 273.907 9.35571 254.77 6.83812C230.261 3.61991 206.895 7.59947 184.341 17.4409C171.34 23.13 158.44 29.0714 145.305 34.4008C125.166 42.567 103.906 47.5452 82.2722 49.1607C65.2968 50.4825 48.2937 51.4307 31.3091 52.6405C23.6697 53.187 16.164 54.4714 8.9257 57.2179C6.7081 58.0727 4.35681 58.5444 2.03779 59.0956C1.40156 59.2497 0.682336 59.0395 0 58.9975V58.138Z"
              />
              <Path
                transform="scale(.40)"
                stroke="black"
                strokeWidth={1}
                fill="black"
                d="M285.702 42.8366C284.611 43.4865 283.561 44.2193 282.419 44.7724C277.435 47.206 272.071 47.9849 266.614 48.3537C247.641 49.6442 228.668 51.1191 209.673 52.1516C189.278 53.2762 168.87 53.396 148.444 52.5203C146.564 52.4373 144.679 52.3083 142.813 52.1838C142.712 52.1838 142.621 52.0271 142.222 51.6722C150.319 46.6114 157.747 40.4721 167.898 40.6381L168.32 41.2787L160.64 46.192C202.35 47.8282 243.891 45.6343 285.422 42.1268L285.697 42.832L285.702 42.8366Z"
              />
            </Svg>
          </View>
          <View style={styles.logoContainer}>
            <View style={styles.logoTextContainer}>
              <View>
                <Text style={styles.recieptHeader}>
                  {language.summary.receipt.receiptHeader}
                </Text>
              </View>

              <View style={styles.recieptAdressContainer}>
                <Text style={styles.recieptAdress}>
                  {language.summary.receipt.assistanstbolaget}
                </Text>
                <Text style={styles.recieptAdress}>
                  {language.summary.receipt.assistansbolagetAddressStreet}
                </Text>
                <Text style={styles.recieptAdress}>
                  {language.summary.receipt.assistansbolagetAddressZip}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.testEtt}>
          <View style={styles.heroContainer}>
            <View style={styles.borderLine}>
              <View style={styles.heroOne}>
                <View style={styles.oneInfo}>
                  <Text style={styles.header}>
                    {language.summary.receipt.customerLabel}
                  </Text>
                  <Text style={styles.smallText}>
                    {firstName} {lastName}
                  </Text>
                  <Text style={styles.smallText}>{streetAddress}</Text>
                  <Text style={styles.smallText}>
                    {zipCode} {city}
                  </Text>
                </View>

                <View style={styles.oneInfo}>
                  <Text style={styles.header}>
                    {language.summary.receipt.vehicleLabel}
                  </Text>
                  <Text style={styles.smallText}>
                    {language.summary.receipt.regnoLabel} {regno}
                  </Text>
                  <Text style={styles.smallText}>
                    {language.summary.receipt.brandLabel} {brand}
                  </Text>
                  <Text style={styles.smallText}>
                    {language.summary.receipt.yearLabel} {year}
                  </Text>
                </View>
              </View>
            </View>

            <View style={styles.borderLine}>
              <View style={styles.heroOne}>
                <View style={styles.oneInfo}>
                  <Text style={styles.header}>
                    {language.summary.receipt.missionLabel}
                  </Text>
                  <Text style={styles.smallText}>
                    {language.summary.receipt.missionNumber} {missionId}
                  </Text>
                  <Text style={styles.smallText}>
                    {language.summary.receipt.missionType}{" "}
                    {fixedOnPlace ? (
                      <Text style={styles.smallText}>
                        {language.summary.receipt.towingLabel}
                      </Text>
                    ) : (
                      <Text style={styles.smallText}>
                        {language.summary.receipt.repairAtSpotLabel}
                      </Text>
                    )}
                  </Text>
                  <Text style={styles.smallText}>
                    {language.summary.receipt.damageReasonHeader}{" "}
                    {damageReasonLabel}
                  </Text>
                  {specifiedDamageDetail && (
                    <Text style={styles.smallText}>
                      {language.summary.receipt.descriptiveDamageReason}{" "}
                      {specifiedDamageDetail}
                    </Text>
                  )}
                  <Text style={styles.smallText}>
                    {language.summary.receipt.startedAt} {damageDate}
                  </Text>
                </View>

                <View style={styles.oneInfo}>
                  <Text style={styles.header}>
                    {language.summary.receipt.locationsForDamage}
                  </Text>
                  {fixedOnPlace ? (
                    <>
                      <Text style={styles.mediumText}>
                        {language.summary.receipt.towedFromLabel}{" "}
                        <Text style={styles.smallText}>
                          {damagePositionStreet}
                        </Text>
                      </Text>
                      <Text style={styles.smallText}>{damagePositionArea}</Text>
                    </>
                  ) : (
                    <>
                      <Text style={styles.mediumText}>
                        {language.summary.receipt.damageScene}:{" "}
                        <Text style={styles.smallText}>
                          {damagePositionStreet}
                        </Text>
                      </Text>
                      <Text style={styles.smallText}>{damagePositionArea}</Text>
                    </>
                  )}
                  {transportedToStreet && (
                    <>
                      <Text style={styles.mediumText}>
                        {language.summary.receipt.towedToLabel}{" "}
                        <Text style={styles.smallText}>
                          {transportedToStreet}
                        </Text>
                      </Text>
                      <Text style={styles.smallText}>{transportedToArea}</Text>
                    </>
                  )}
                  {chargedKilometers && (
                    <Text style={styles.smallText}>
                      {language.summary.receipt.chargedDistanceLabel}{" "}
                      {chargedKilometers} km
                    </Text>
                  )}
                </View>
              </View>
            </View>

            <View style={styles.priceInfo}>
              <View style={styles.oneInfo}>
                <Text style={styles.header}>
                  {language.summary.receipt.specification}
                </Text>
                {orderLines?.map(({ name, id }) => {
                  return (
                    <Text key={id} style={styles.smallText}>
                      {name}
                    </Text>
                  );
                })}
              </View>

              <View style={styles.oneInfo}>
                <Text style={styles.header}>
                  {language.summary.receipt.paymentMethod}
                </Text>
                {orderLines?.map(({ paymentType, id }) => {
                  return (
                    <Text key={id} style={styles.smallText}>
                      {paymentType}
                    </Text>
                  );
                })}
              </View>

              <View style={styles.onePricesInfo}>
                <Text style={styles.header}>
                  {language.summary.receipt.costs}
                </Text>
                {orderLines?.map(({ price, id }) => {
                  return (
                    <Text key={id} style={styles.smallText}>
                      {price} sek
                    </Text>
                  );
                })}
              </View>
            </View>

            <View style={styles.priceInfo}>
              <View style={styles.oneInfo}></View>

              <View style={styles.oneInfo}>
                <Text style={styles.header}>
                  {language.summary.receipt.totaltAmount}
                </Text>
                <Text style={styles.smallText}>
                  {language.summary.receipt.vatLabel} ({taxRate}%)
                </Text>
              </View>

              <View style={styles.onePricesInfo}>
                <Text style={styles.header}>{total} sek</Text>
                <Text style={styles.smallText}>{vat} sek</Text>
              </View>
            </View>
          </View>

          <View style={styles.imageContainer}>
            <Image style={styles.image} src={mapUrl} />
          </View>
        </View>

        <View style={styles.footerContainer} fixed>
          <View style={styles.divider}></View>
          <View style={styles.footer}>
            <View>
              <Text style={styles.smallTextBold}>
                {language.summary.receipt.assistanstbolaget}
              </Text>
              <Text style={styles.smallTextThin}>
                {language.summary.receipt.assistansbolagetCompanyName}
              </Text>
            </View>
            <View>
              <Text style={styles.smallTextBold}>
                {language.summary.receipt.assistansbolagetAddressStreet}
              </Text>
              <Text style={styles.smallTextThin}>
                {language.summary.receipt.assistansbolagetAddressZip}
              </Text>
            </View>

            <View>
              <Text style={styles.smallTextBold}>
                {language.summary.receipt.orgNrLabel}
              </Text>
              <Text style={styles.smallTextThin}>
                {language.summary.receipt.orgNr}
              </Text>
            </View>

            <View>
              <Text style={styles.smallTextBold}>
                {language.summary.receipt.phoneNrLabel}
              </Text>
              <Text style={styles.smallTextThin}>
                {language.summary.receipt.phoneNr}
              </Text>
            </View>

            <View>
              <Text style={styles.smallTextBold}>
                {language.summary.receipt.emailLabel}
              </Text>
              <Text style={styles.smallTextThin}>
                {language.summary.receipt.email}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

PDF.propTypes = {
  summary: PropTypes.instanceOf(Object).isRequired,
  damageDate: PropTypes.string.isRequired,
  fixedOnPlace: PropTypes.bool.isRequired,
  regno: PropTypes.string.isRequired,
  markers: PropTypes.instanceOf(Array).isRequired
};

export default PDF;
