import React from "react";
import { Button } from "@assistansbolaget/gat-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserHeadset } from "@fortawesome/pro-light-svg-icons";
import { useIntl } from "react-intl";
import history from "../../history";
import CardBoxContainer from "../../components/CardBoxContainer/CardBoxContainer";
import { CREATE_ORDER_PATH } from "../../constants/routeConstants";
import logout from "../../helpers/clearSessionStore";

const OrderCanceled = () => {
  const intl = useIntl();
  const handleOnClick = () => {
    logout();
    history.push(CREATE_ORDER_PATH);
  };
  return (
    <>
      <CardBoxContainer disableToggle minimumHeight="88px">
        <h4
          style={{ fontWeight: "700", fontSize: "18px", marginBottom: "15px" }}
        >
          {intl.formatMessage({
            id: "SeekingResponder.Title",
            defaultMessage: "We will assist you!",
            description: "Title for Seeking responder view"
          })}
        </h4>
        <>
          <h4 style={{ marginBottom: "15px" }}>
            {intl.formatMessage({
              id: "SeekingResponder.BreadText",
              defaultMessage:
                "We will get in touch with you shortly. You mission will be handled by our dispatch",
              description: "Bread text for Seeking Responder"
            })}
          </h4>
          <FontAwesomeIcon
            icon={faUserHeadset}
            size="2x"
            style={{ color: "#FFD4A3", marginBottom: "15px" }}
          />
          <h4
            style={{
              color: "#FFD4A3",
              fontWeight: "700",
              marginBottom: "15px"
            }}
          >
            {intl.formatMessage({
              id: "SeekingResponder.HighLightText",
              defaultMessage:
                "Please wait, we will call you within minutes on the provided phone number",
              description: "High lighted text in Seeking Responder modal"
            })}
          </h4>
        </>
      </CardBoxContainer>
      <Button
        fullWidth
        kind="Solid"
        className="button button-secondary card-button"
        type="submit"
        onClick={() => handleOnClick()}
      >
        {intl
          .formatMessage({
            id: "ErrorModal.ButtonText",
            defaultMessage: "to homepage",
            description: "homepage button"
          })
          .toUpperCase()}
      </Button>
    </>
  );
};

export default OrderCanceled;
