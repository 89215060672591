import { Font, StyleSheet } from "@react-pdf/renderer";
import DinProBold from "../../../../assets/fonts/DinProFont/DINPro-Bold_13934.ttf";
import DINPro from "../../../../assets/fonts/DinProFont/DINPro-Regular_13937.ttf";
import DINProLight from "../../../../assets/fonts/DinProFont/DINPro-Light_13935.ttf";
import DINProMedium from "../../../../assets/fonts/DinProFont/DINPro-Medium_13936.ttf";

Font.register({
  family: "DINPro",
  fonts: [
    {
      src: DINPro
    },
    {
      src: DinProBold,
      fontWeight: "bold"
    },
    {
      src: DINProLight,
      fontWeight: 100
    },
    {
      src: DINProMedium,
      fontWeight: 600
    }
  ]
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 5,
    paddingHorizontal: 5,
    paddingBottom: 70,
    display: "flex",
    flexDirection: "column"
  },
  recieptText: {
    fontSize: "10px",
    fontFamily: "DINPro",
    fontWeight: 100,
    letterSpacing: 0
  },
  logoContainer: {
    display: "flex",
    width: 200,
    flexDirection: "column",
    paddingLeft: 15,
    bottom: 7
  },
  iconSize: {
    position: "relative",
    top: 30
  },
  iconPadding: {
    top: 14,
    paddingLeft: 25
  },
  recieptHeader: {
    fontSize: "38px",
    fontFamily: "DINPro",
    fontWeight: 100,
    bottom: 4,
    right: 3
  },
  recieptAdress: {
    fontSize: "7px",
    fontFamily: "DINPro",
    fontWeight: 100
  },
  recieptAdressContainer: {
    display: "flex",
    alignItems: "flex-start",
    height: "36px",
    paddingTop: 8
  },
  logoTextContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    width: 200
  },
  iconNav: {
    display: "flex",
    height: 100,
    justifyContent: "center",
    alignItems: "flex-end",
    marginBottom: 20,
    paddingHorizontal: 65
  },
  borderLine: {
    borderBottom: "1px solid gray"
  },
  heroContainer: {
    paddingHorizontal: 65
  },
  heroOne: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 10,
    marginBottom: 5
  },
  oneInfo: {
    width: 200,
    paddingLeft: 15
  },
  onePricesInfo: {
    width: 200,
    alignItems: "flex-end",
    paddingLeft: 15
  },
  smallText: {
    fontSize: "12px",
    fontFamily: "DINPro",
    fontWeight: 100
  },
  mediumText: {
    fontSize: "12px",
    fontFamily: "DINPro",
    fontWeight: 600
  },
  smallTextBold: {
    fontSize: "9px",
    fontFamily: "DINPro",
    fontWeight: "bold"
  },
  smallTextThin: {
    fontSize: "9px",
    fontFamily: "DINPro",
    fontWeight: 100
  },
  priceInfo: {
    display: "flex",
    flexDirection: "row"
  },
  pricesInfo: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 10,
    paddingHorizontal: 10,
    justifyContent: "space-between"
  },
  onePriceInfo: {
    width: 100
  },
  towPriceInfo: {
    width: 365
  },
  logoWidth: {
    width: "162px",
    marginBottom: -7,
    marginLeft: 14
  },
  imageContainer: {
    paddingHorizontal: 65,
    marginTop: 45
  },
  image: {
    objectFit: "contain"
  },
  footerContainer: {
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center"
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    width: "100%"
  },
  divider: {
    marginTop: 20,
    marginBottom: 10,
    width: "77%",
    borderBottom: "1px solid gray"
  },
  header: {
    fontSize: "12px",
    paddingTop: 10,
    fontWeight: "bold",
    fontFamily: "DINPro"
  },
  testEtt: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column"
  }
});

export default styles;
