import React from "react";
import { compose } from "redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Loading } from "./features/loading";
import { CreateOrder } from "./features/createOrder";
import LoginTriggerComponent from "./features/login/LoginTriggerComponent";
import { ActiveOrder } from "./features/activeOrder";
import { Summary } from "./features/summary";
import {
  CREATE_ORDER_PATH,
  ACTIVE_ORDER_PATH,
  SUMMARY_PATH,
  DISPATCH_POSITION_PATH,
  LOGIN_PATH,
  OLD_CREATE_ORDER_PATH
} from "./constants/routeConstants";
import { DispatchPosition } from "./features/dispatchPosition";
import TopBar from "./components/TopBar/TopBar";
import Background from "./features/background";
import HeaderTag from "./helpers/HeaderTag";

const App = () => {
  return (
    <div className="site">
      <HeaderTag />
      <TopBar />
      <div className="content flex">
        <Switch>
          <Route
            exact
            path={OLD_CREATE_ORDER_PATH}
            render={props => (
              <Redirect
                to={`${CREATE_ORDER_PATH}${props.location.search}`}
                {...props}
              />
            )}
          />
          <Route
            exact
            path={CREATE_ORDER_PATH}
            render={props => <CreateOrder {...props} />}
          />
          <Route
            exact
            path={ACTIVE_ORDER_PATH}
            render={props => <ActiveOrder {...props} />}
          />
          <Route
            exact
            path={SUMMARY_PATH}
            render={props => <Summary {...props} />}
          />
          <Route
            exact
            path={DISPATCH_POSITION_PATH}
            render={props => <DispatchPosition {...props} />}
          />
          <Route
            exact
            path={LOGIN_PATH}
            render={props => <LoginTriggerComponent {...props} />}
          />
          {/* IMPORTANT: This route has to be the last one */}
          <Route render={() => <Redirect to="/" />} />
        </Switch>
        <Loading />
      </div>
      <Background />
    </div>
  );
};

App.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired
};

export default compose(injectIntl, withRouter, connect(null, null))(App);
