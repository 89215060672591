import strings from "../assets/language/sweStrings";

const checkMissionType = languageFile => {
  if (window.location.search.includes("transport")) {
    return languageFile.transport;
  }
  return languageFile.towing;
};

const languageHelper = () => {
  return checkMissionType(strings);
};

export default languageHelper;
