import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { compose, withProps } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";
import { defaultMapOptions } from "./defaultMapOptions";
import { GOOGLE_MAP_URL } from "../../constants/apiConstants";
import welcomeScreenSelectors from "../welcomeScreen/selectors";

const { getVehiclePosition } = welcomeScreenSelectors;

const CreateOrderMap = compose(
  withProps({
    googleMapURL: GOOGLE_MAP_URL,
    loadingElement: <div className="full-height-width" />,
    containerElement: <div className="map-container-element" />,
    mapElement: <div className="full-height-width" />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  const { carPositionCoordinates } = props;

  return (
    <>
      <GoogleMap
        zoom={16}
        defaultCenter={{
          lat: carPositionCoordinates.lat,
          lng: carPositionCoordinates.lng,
        }}
        defaultOptions={defaultMapOptions}
      />
    </>
  );
});

CreateOrderMap.propTypes = {
  carPositionCoordinates: PropTypes.shape({
    accuracy: PropTypes.number,
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
};

const mapStateToProps = (state) => ({
  carPositionCoordinates: getVehiclePosition(state),
});

export default connect(mapStateToProps, null)(CreateOrderMap);
