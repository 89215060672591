import {
  SET_RATING,
  SET_DISPLAY_VALUE,
  RECEIPT_SUBMITTED,
  TOGGLE_SUMMARY_FAILURE_MODAL,
  TOGGLE_SUMMARY_SUCCESS_MODAL,
  TOGGLE_RECEIPT_FAILURE_MODAL,
  LEGACY_SUMMARY_SUBMITTED
} from "../constants";

const setRating = starsSelected => ({
  type: SET_RATING,
  starsSelected
});

const setDisplayValue = value => ({
  type: SET_DISPLAY_VALUE,
  value
});

const legacySubmitSummaryForm = () => ({
  type: LEGACY_SUMMARY_SUBMITTED
});

const submitReceiptForm = () => ({
  type: RECEIPT_SUBMITTED
});

const toggleSummarySuccessModal = bool => ({
  type: TOGGLE_SUMMARY_SUCCESS_MODAL,
  payload: bool
});

const toggleSummaryFailureModal = bool => ({
  type: TOGGLE_SUMMARY_FAILURE_MODAL,
  payload: bool
});

const toggleReceiptFailureModal = bool => ({
  type: TOGGLE_RECEIPT_FAILURE_MODAL,
  payload: bool
});

export default {
  setRating,
  setDisplayValue,
  legacySubmitSummaryForm,
  toggleSummarySuccessModal,
  toggleSummaryFailureModal,
  submitReceiptForm,
  toggleReceiptFailureModal
};
