import React from "react";
import { connect } from "react-redux";
import { Field, getFormValues, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";
import { injectIntl } from "react-intl";
import activeOrderSelectors from "../activeOrder/selectors";
import actions from "./actions";
import selectors from "./selectors";
import validators from "./validators";
import Input from "../../components/Input/Input";
import submitCustomerDetailsValidation from "./submitCustomerDetailsValidation";
import { DAMAGE_REASON_VIEW, WELCOME_SCREEN_VIEW } from "./viewConstants";
import CardBoxContainer from "../../components/CardBoxContainer/CardBoxContainer";
import welcomeScreenSelectors from "../welcomeScreen/selectors";
import locationPinLogo from "../../assets/images/pin.svg";
import PhoneNumberInput from "../../components/PhoneNumberInput/PhonenumberInput";
import PositionInput from "../../components/Input/PositionInput";
import NextStepButton from "../../components/ButtonContainer/NextStepButton";

const {
  submitCustomerDetailsForm,
  setNextView,
  setPreviousView,
  getPosition,
  fetchVehicle
} = actions;
const {
  getSocialNumberFieldError,
  getRegnoFieldError,
  getNextView,
  getIsFetchingVehicle,
  getIsFetchingPerson,
  isCookiesAccepted
} = selectors;
const {
  validateRegno,
  validateMobile,
  validateSocialNumber,
  validatePosition
} = validators;

const { getOrder } = activeOrderSelectors;

const { getVehiclePosition } = welcomeScreenSelectors;

const validate = values => {
  const errors = {};
  errors.regno = validateRegno(values.regno);
  errors.mobile = validateMobile(values.mobile);
  errors.carPosition = validatePosition(values.carPosition);
  errors.socialNumber = validateSocialNumber(values.socialNumber);
  return errors;
};

class CustomerDetails extends React.Component {
  // eslint-disable-next-line
  debounceVehicleSearch = debounce(this.props.getVehicle, 1000, {
    leading: false
  });

  constructor(props) {
    super(props);
    this.state = {
      hasSetInitialValues: false
    };
  }

  componentDidMount() {
    const { previousView, nextView } = this.props;
    previousView();
    nextView();
  }

  componentDidUpdate() {
    const { order, initialize, getCurrentVehiclePosition } = this.props;
    const { hasSetInitialValues } = this.state;
    if (
      hasSetInitialValues ||
      order.size !== undefined ||
      getCurrentVehiclePosition.size !== undefined
    )
      return;
    if (
      !order?.customerVehicle?.registrationNumber ||
      !order?.customer?.mobilePhone ||
      !getCurrentVehiclePosition?.address
    )
      return;
    initialize({
      regno: order?.customerVehicle?.registrationNumber,
      mobile: order?.customer?.mobilePhone,
      carPosition: getCurrentVehiclePosition.address
    });
    this.state.hasSetInitialValues = true;
  }

  render() {
    const {
      handleSubmit,
      handleNextButton,
      regnoFieldError,
      requestPosition,
      getCurrentVehiclePosition,
      formValues,
      intl
    } = this.props;

    const { regno, mobile, carPosition } = formValues || {};
    const shouldDisableNextButton = !regno || !mobile || !carPosition;

    return (
      <>
        <CardBoxContainer id="continueLandingScreen1">
          <h3 style={{ fontSize: "1.5rem", fontWeight: "700" }}>
            {intl.formatMessage({
              id: "CreateOrder.CustomerDetails.Title",
              defaultMessage: "Roadassistance",
              description: "Startpage: Ask for customer details"
            })}
          </h3>
          <p className="center-text" style={{ padding: "10px 0" }}>
            {intl.formatMessage({
              id: "CreateOrder.CustomerDetails.ProvideText",
              defaultMessage: "Provide details",
              description: "Startpage: Provide details text"
            })}
          </p>
          <form style={{ width: "100%" }} onSubmit={handleSubmit}>
            <Field
              name="regno"
              component={Input}
              onChange={this.debounceVehicleSearch}
              customFormerror={
                regnoFieldError.type === "regno" && regnoFieldError
              }
              type="text"
              placeholder={intl.formatMessage({
                id: "CreateOrder.CustomerDetails.RegistrationNumber",
                defaultMessage: "Registration number",
                description: "Startpage: registration number placeholder"
              })}
            />
            <Field
              name="mobile"
              type="tel"
              component={PhoneNumberInput}
              placeholder={intl.formatMessage({
                id: "CreateOrder.CustomerDetails.PhoneNumber",
                defaultMessage: "Phone number",
                description: "Startpage: phone number placeholder"
              })}
            />
            <p>
              {intl.formatMessage({
                id: "CreateOrder.CustomerDetails.VehiclPositionLabel",
                defaultMessage: "Vehicle position",
                description: "Vehicle position title"
              })}
            </p>
            {getCurrentVehiclePosition.address && (
              <div className="source-location-pin">
                <img src={locationPinLogo} alt="pin-location" />
                <p>{getCurrentVehiclePosition.address}</p>
              </div>
            )}
            <div style={{ marginBottom: "15px" }}>
              <Field
                name="carPosition"
                component={PositionInput}
                onClick={() => requestPosition()}
                label={intl
                  .formatMessage({
                    id: "CreateOrder.CustomerDetails.VehiclePosition",
                    defaultMessage: "Vehicle position",
                    description: "Startpage: vehicle position title"
                  })
                  .toUpperCase()}
              />
              <NextStepButton
                id="continueDamageReason2"
                nextStep={handleNextButton}
                disabled={shouldDisableNextButton}
                nextStepText={intl
                  .formatMessage({
                    id: "general.continue",
                    defaultMessage: "Continue",
                    description: "Continue"
                  })
                  .toUpperCase()}
              />
              <div className="flex-column align-center justify-center marginTop15">
                <p>
                  {intl.formatMessage({
                    id: "CreateOrder.CustomerDetails.CallInformation",
                    defaultMessage: "Do you need help with your order?",
                    description: "Call information"
                  })}
                </p>
                <a
                  href={`tel: ${intl.formatMessage({
                    id: "CreateOrder.CustomerDetails.CallNumber",
                    defaultMessage: "+4620400500",
                    description: "phonenr to callcenter"
                  })}`}
                >
                  {intl.formatMessage({
                    id: "CreateOrder.CustomerDetails.DisplayedPhoneNumber",
                    defaultMessage: "Call +4620400500",
                    description: "Displayed phonenr to call center"
                  })}
                </a>
              </div>
            </div>
            {/* <div className="flex-row align-center justify-center marginTop15">
              <Field
                name="isInsured"
                type="checkbox"
                component={renderToggleInput}
              />
              <span className="toggle-text color-white">
                {language.createOrder.toggleText1}
                {language.createOrder.toggleText2}
              </span>
            </div>
            {formValues && formValues.isInsured && (
              <>
                <p
                  className="font-italic center-text letter-spacing-05"
                  style={{ fontSize: "12px" }}
                >
                  {language.createOrder.customerDetails.pnrInfo}
                </p>
                <Field
                  name="socialNumber"
                  onChange={e => (!isFetchingPerson ? getPerson(e) : null)}
                  component={Input}
                  placeholder="Personnummer"
                  type="tel"
                  normalize={normalizeSocialNumber}
                  customFormerror={
                    socialNumberFieldError.type === "socialNumber" &&
                    socialNumberFieldError
                  }
                  loading={isFetchingPerson}
                />
              </>
            )} */}
          </form>
        </CardBoxContainer>
      </>
    );
  }
}

CustomerDetails.propTypes = {
  // eslint-disable-next-line
  formValues: PropTypes.shape({
    regno: PropTypes.string,
    mobile: PropTypes.string,
    carPosition: PropTypes.string,
    isInsured: PropTypes.bool,
    socialNumber: PropTypes.string
  }),
  handleSubmit: PropTypes.func.isRequired,
  requestPosition: PropTypes.func.isRequired,
  getCurrentVehiclePosition: PropTypes.string.isRequired,
  previousView: PropTypes.func.isRequired,
  nextView: PropTypes.func.isRequired,
  socialNumberFieldError: PropTypes.shape({
    show: PropTypes.bool,
    message: PropTypes.string,
    type: PropTypes.string
  }).isRequired,
  regnoFieldError: PropTypes.shape({
    show: PropTypes.bool,
    message: PropTypes.string,
    type: PropTypes.string
  }).isRequired,
  handleNextButton: PropTypes.func.isRequired,
  getVehicle: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  initialize: PropTypes.func.isRequired,
  order: PropTypes.shape({
    id: PropTypes.string,
    customerVehicle: PropTypes.string,
    customer: PropTypes.string,
    size: PropTypes.string
  }).isRequired
};

// eslint-disable-next-line
CustomerDetails = reduxForm({
  form: "customerDetails",
  initialValues: {
    isInsured: false,
    mobile: undefined,
    regno: undefined,
    carPosition: undefined
  },
  onSubmit: submitCustomerDetailsValidation,
  validate,
  destroyOnUnmount: false
})(CustomerDetails);

const mapStateToProps = state => ({
  socialNumberFieldError: getSocialNumberFieldError(state),
  regnoFieldError: getRegnoFieldError(state),
  next: getNextView(state),
  formValues: getFormValues("customerDetails")(state),
  isFetchingVehicle: getIsFetchingVehicle(state),
  isFetchingPerson: getIsFetchingPerson(state),
  getCurrentVehiclePosition: getVehiclePosition(state),
  order: getOrder(state),
  cookieValue: isCookiesAccepted(state)
});

const mapDispatchToProps = dispatch => ({
  previousView: () => dispatch(setPreviousView(WELCOME_SCREEN_VIEW)),
  nextView: () => dispatch(setNextView(DAMAGE_REASON_VIEW)),
  handleNextButton: () => dispatch(submitCustomerDetailsForm()),
  requestPosition: () => dispatch(getPosition()),
  getVehicle: value => {
    dispatch(fetchVehicle(value));
  }
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(CustomerDetails)
);
