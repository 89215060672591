import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import languageHelper from "../../helpers/languageHelper";
import actions from "./actions";
import selectors from "./selectors";
import welcomeScreenSelectors from "../welcomeScreen/selectors";
import welcomeScreenActions from "../welcomeScreen/actions";
import searchImage from "../../assets/images/search-dark.svg";
import locationPin from "../../assets/images/location-pin.svg";
import logErrorHelper from "../../helpers/logErrorHelper";

import "../../components/LocationSearchInput/style.css";

const language = languageHelper();

const {
  toggleEstimateDestination,
  estimateDestinationPrice,
  estimatePrice,
  setShowPreviousPrice
} = actions;

const { getShowTowDestinationPicker, getShowPreviousEstimate } = selectors;
const { getShopCoordinates, getVehiclePosition } = welcomeScreenSelectors;
const { handleUnsavedShopPosition } = welcomeScreenActions;

class EstimateDestination extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      name: null,
      data: null,
      showUndoButton: false,
      canSave: false,
      formattedAddress: ""
    };
  }

  componentDidMount() {
    const {
      showPreviousEstimate,
      showTowDestinationPicker,
      getShopLocation
    } = this.props;

    if (showTowDestinationPicker && !showPreviousEstimate) {
      this.setShowUndo(false);
      this.setAllowSave(false);
      this.handleChange("");
    } else if (showTowDestinationPicker && showPreviousEstimate) {
      this.setShowUndo(true);
      this.setAllowSave(false);
      const shopLocationAddress =
        getShopLocation.address.length > 32
          ? `${getShopLocation.address.substring(0, 32)}...`
          : getShopLocation.address;
      this.handleChange(shopLocationAddress);
    } else if (!showTowDestinationPicker && !showPreviousEstimate) {
      this.setShowUndo(false);
      this.setAllowSave(false);
    }
  }

  setGoogleResponse = result => this.setState({ data: result });

  setWorkShopName = workshopName => this.setState({ name: workshopName });

  setFormattedAddress = fAddress =>
    this.setState({ formattedAddress: fAddress });

  handleChange = address => {
    this.setState({ address });
  };

  setShowUndo = value => {
    this.setState({ showUndoButton: value });
  };

  setAllowSave = value => {
    this.setState({ canSave: value });
  };

  handleSetError = bool => {
    this.setState({ showError: bool });
  };

  // If user hits enter key then placeId will be null
  handleSelect = (address, placeId) => {
    const { setUnsavedShopPosition } = this.props;
    const splitAdress = address.split(",");
    const workshopName = splitAdress[0];

    if (!placeId) {
      this.handleChange("");
      this.handleSetError(true);
      return;
    }
    this.handleSetError(false);

    geocodeByAddress(address)
      .then(results => {
        let inputFieldValue;
        const formattedAddress = results[0].formatted_address;
        this.setFormattedAddress(formattedAddress);
        const splitFormattedAddress = formattedAddress.split(",");
        const index0 = splitFormattedAddress[0];

        if (
          index0 !== workshopName &&
          results[0].types.includes("car_repair")
        ) {
          inputFieldValue = `${workshopName}, ${formattedAddress}`;
          this.setWorkShopName(workshopName);
        } else {
          inputFieldValue = formattedAddress;
          this.setWorkShopName(null);
        }
        const formattedInputFieldValue =
          inputFieldValue.length > 32
            ? `${inputFieldValue.substring(0, 32)}...`
            : inputFieldValue;
        this.handleChange(formattedInputFieldValue);
        this.setGoogleResponse(results);
        return getLatLng(results[0]);
      })
      .then(latLng => {
        const { formattedAddress, data, name } = this.state;
        const shopPosition = {
          latitude: latLng.lat,
          longitude: latLng.lng,
          name,
          formattedAddress,
          data
        };
        setUnsavedShopPosition(shopPosition);
        this.setAllowSave(true);
      })
      .catch(error => {
        logErrorHelper(error);
      });
  };

  render() {
    const {
      toggleSetDestination,
      showTowDestinationPicker,
      getEstimatePrice,
      getEstimateDestinationPrice,
      showPreviousPrice,
      showPreviousEstimate,
      vehiclePosition
    } = this.props;

    const searchOptions = {
      location: new window.google.maps.LatLng(
        vehiclePosition.lat,
        vehiclePosition.lng
      ),
      radius: 20000
      // componentRestrictions: { country: "swe" },
    };

    const { address, showError, showUndoButton, canSave } = this.state;
    const opacity = !canSave ? 0.5 : 1;
    return (
      <div className="showTowDestinationPicker hideable">
        {showTowDestinationPicker ? (
          <>
            {!showPreviousEstimate && (
              <PlacesAutocomplete
                value={address}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
                searchOptions={searchOptions}
                shouldFetchSuggestions={address.length > 1}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                  <div className="flex justify-center location-container-small">
                    <div className="location-search-input-container-small">
                      <input
                        {...getInputProps({
                          autoFocus: true,
                          placeholder:
                            language.estimatePriceView
                              .searchLocationPlaceholder,
                          className: "location-search-input-small"
                        })}
                      />
                      <img
                        src={searchImage}
                        alt="inputimage"
                        className="search-image-small"
                      />
                      {showError && (
                        <span className="location-search-input-error-small">
                          {language.carPositionPicker.searchError}
                        </span>
                      )}
                      <div className="autocomplete-dropdown-container-small">
                        {suggestions.map(suggestion => {
                          const className = suggestion.active
                            ? "flex align-center suggestion-item--active"
                            : "flex align-center suggestion-item";
                          const style = suggestion.active
                            ? {
                                backgroundColor: "#fafafa",
                                cursor: "pointer",
                                padding: "4px 4px"
                              }
                            : {
                                backgroundColor: "#ffffff",
                                cursor: "pointer",
                                padding: "4px 4px"
                              };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style
                              })}
                            >
                              <img
                                className="location-pin"
                                src={locationPin}
                                alt="location pin"
                              />
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            )}

            {showUndoButton ? (
              <button
                className="button button-secondary"
                style={{
                  width: "100%"
                }}
                type="submit"
                onClick={() => {
                  showPreviousPrice(false);
                  this.setShowUndo(false);
                  toggleSetDestination();
                  getEstimatePrice();
                }}
              >
                {language.createOrder.estimatePrice.destinationButtonUndo}
              </button>
            ) : (
              <button
                className="button button-secondary"
                disabled={!canSave}
                type="submit"
                onClick={() => {
                  showPreviousPrice(true);
                  this.setShowUndo(true);
                  this.setAllowSave(false);
                  getEstimateDestinationPrice();
                }}
                style={{ opacity, width: "100%" }}
              >
                {language.createOrder.estimatePrice.destinationButtonSave}
              </button>
            )}
          </>
        ) : (
          <button
            className="button button-secondary"
            style={{
              width: "100%"
            }}
            type="submit"
            onClick={() => {
              toggleSetDestination();
              this.handleChange("");
            }}
          >
            {language.createOrder.estimatePrice.destinationButtonOpen}
          </button>
        )}
      </div>
    );
  }
}
EstimateDestination.propTypes = {
  toggleSetDestination: PropTypes.func.isRequired,
  showTowDestinationPicker: PropTypes.bool.isRequired,
  showPreviousEstimate: PropTypes.bool.isRequired,
  getShopLocation: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    address: PropTypes.string
  }).isRequired,
  setUnsavedShopPosition: PropTypes.func.isRequired,
  getEstimatePrice: PropTypes.func.isRequired,
  getEstimateDestinationPrice: PropTypes.func.isRequired,
  showPreviousPrice: PropTypes.func.isRequired,
  vehiclePosition: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }).isRequired
};

const mapStateToProps = state => ({
  showTowDestinationPicker: getShowTowDestinationPicker(state),
  getShopLocation: getShopCoordinates(state),
  showPreviousEstimate: getShowPreviousEstimate(state),
  vehiclePosition: getVehiclePosition(state)
});

const mapDispatchToProps = dispatch => ({
  toggleSetDestination: () => dispatch(toggleEstimateDestination()),
  setUnsavedShopPosition: pos => dispatch(handleUnsavedShopPosition(pos)),
  getEstimatePrice: () => dispatch(estimatePrice()),
  getEstimateDestinationPrice: () => dispatch(estimateDestinationPrice()),
  showPreviousPrice: show => dispatch(setShowPreviousPrice(show))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EstimateDestination);
