import {
  all,
  call,
  delay,
  put,
  race,
  select,
  take,
  takeLatest
} from "redux-saga/effects";
import {
  CANCELLED,
  DEBRIEFED,
  DRIVER_AT_DESTINATION,
  DRIVER_AT_SOURCE,
  DRIVER_DRIVING_TO_DESTINATION,
  DRIVER_DRIVING_TO_SOURCE,
  DRIVER_PREPARE_DRIVE_TO_DESTINATION,
  FINISHED,
  MISSION_CREATED,
  MISSION_NOT_STARTED,
  REVIEW_MISSION_SUMMARY,
  WAITING_FOR_CUSTOMER_ACCEPT,
  WAITING_FOR_DRIVER_ACCEPT
} from "../../constants/orderStatusConstants";
import {
  ACTIVE_ORDER_PATH,
  CREATE_ORDER_PATH,
  SUMMARY_PATH
} from "../../constants/routeConstants";
import {
  getSession,
  SESSION_KEYS,
  setSession
} from "../../helpers/sessionStore";
import history from "../../history";
import createOrderActions from "../createOrder/actions";
import {
  CONFIRM_ORDER_VIEW,
  CUSTOMER_DETAILS_VIEW
} from "../createOrder/viewConstants";
import toggleLoading from "../loading/actions";
import welcomeScreenActions from "../welcomeScreen/actions";
import actions from "./actions";
import {
  CANCEL_ESTIMATED_ARRIVAL_TIME_POLLER,
  CANCEL_FETCH_TOWTRUCK_POSITION,
  FETCH_TOWTRUCK_POSITION,
  FIND_CURRENT_VIEW,
  GET_ESTIMATED_ARRIVAL_TIME,
  GET_MISSION_BY_TOKEN,
  START_CANCEL_ORDER
} from "./constants";
import selectors from "./selectors";
import service from "./service";

const {
  setOrderStatus,
  getEstimatedArrivalTimeSuccess,
  cancelFetchTowtruckPosition,
  towTruckCancelled,
  setTowTruckLocation,
  getMissionSuccess,
  getMissionFailure
} = actions;

const { getOrder } = selectors;
const { cancelOrder, getOrderById, getMissionNow } = service;

const { setVehicleLocation } = welcomeScreenActions;
const {
  setCurrentView,
  toggleUnexpectedErrorModal,
  setShopLocation
} = createOrderActions;

const ONGOING_STATUSES = [
  DRIVER_DRIVING_TO_SOURCE,
  DRIVER_DRIVING_TO_DESTINATION,
  DRIVER_PREPARE_DRIVE_TO_DESTINATION,
  DRIVER_AT_DESTINATION,
  DRIVER_AT_SOURCE
];

function* fetchGatMission() {
  const urlParams = new URLSearchParams(window.location.search);
  let token = urlParams.get("token");
  if (!token) {
    token = getSession(SESSION_KEYS.token);
  }
  try {
    const data = yield call(getMissionNow, token);
    if (data.statusCode < 299) {
      yield put(getMissionSuccess(data.data));
      return;
    }
    if (data.statusCode > 299) {
      yield put(getMissionFailure(data));
    }
  } catch (error) {
    yield call(getMissionFailure);
  }
}

function* getEstimatedArrivalTimeWorker() {
  while (1) {
    const order = yield select(getOrder);
    if (
      order.state === CANCELLED ||
      order.state === FINISHED ||
      order.state === DEBRIEFED
    ) {
      return;
    }
    let response = "";
    const { state, driverToSource, sourceToDestination } = order;
    if (state === DRIVER_DRIVING_TO_SOURCE) {
      const { estimatedArrivalAt, drivingArrivalAt } = driverToSource;
      response = estimatedArrivalAt || drivingArrivalAt;
    }
    if (state === DRIVER_DRIVING_TO_DESTINATION) {
      const { estimatedArrivalAt, drivingArrivalAt } = sourceToDestination;
      response = estimatedArrivalAt || drivingArrivalAt;
    }
    yield put(getEstimatedArrivalTimeSuccess(response));
    yield delay(2000);

    // check if order is cancelled after each iteration
    const newOrder = yield select(getOrder);
    if (
      newOrder.state === CANCELLED ||
      newOrder.state === FINISHED ||
      order.state === DEBRIEFED
    ) {
      break;
    }
  }
}

/* - TODO - Fixa cancel mot 2.0 */
function* cancelOrderWorker() {
  try {
    const order = yield select(getOrder);
    yield put(toggleLoading(true));
    yield call(cancelOrder, order.id);
    const { data } = yield call(getOrderById, order.id);
    yield all([
      put(cancelFetchTowtruckPosition()),
      put(setOrderStatus(data)),
      put(setCurrentView(CUSTOMER_DETAILS_VIEW)),
      put(toggleLoading(false))
    ]);
  } catch (error) {
    yield put(toggleLoading(false));
    yield put(toggleUnexpectedErrorModal(true));
  }
}

function* findCurrentViewWorker() {
  const urlParams = new URLSearchParams(window.location.search);
  try {
    let token = urlParams.get("token");
    if (!token) {
      token = getSession(SESSION_KEYS.token);
    }
    if (!token) {
      return;
    }
    const data = yield call(getMissionNow, token);
    const { id, accessToken } = data.data;
    setSession(SESSION_KEYS.missionId, id);
    setSession(SESSION_KEYS.token, token);
    setSession(SESSION_KEYS.accessToken, accessToken);
    if (data.statusCode < 299) {
      yield put(getMissionSuccess(data.data));
      const { state, missionOutcome, driverToSource } = data.data;
      const { latitude, longitude, address } = driverToSource;
      // eslint-disable-next-line default-case
      switch (state) {
        case MISSION_CREATED:
          yield put(
            setVehicleLocation({ address, lat: latitude, lng: longitude })
          );
          yield put(setCurrentView(CUSTOMER_DETAILS_VIEW));
          history.push(`${CREATE_ORDER_PATH}`);
          break;
        case MISSION_NOT_STARTED:
          yield put(
            setVehicleLocation({ address, lat: latitude, lng: longitude })
          );
          yield put(setCurrentView(CONFIRM_ORDER_VIEW));
          history.push(`${CREATE_ORDER_PATH}`);
          break;
        case WAITING_FOR_DRIVER_ACCEPT:
        case WAITING_FOR_CUSTOMER_ACCEPT:
          yield put(setCurrentView(CONFIRM_ORDER_VIEW));
          history.push(`${CREATE_ORDER_PATH}?id=${id}`);
          break;
        case DRIVER_DRIVING_TO_SOURCE:
        case DRIVER_DRIVING_TO_DESTINATION:
        case DRIVER_PREPARE_DRIVE_TO_DESTINATION:
        case DRIVER_AT_DESTINATION:
        case DRIVER_AT_SOURCE:
        case CANCELLED:
          history.push(`${ACTIVE_ORDER_PATH}`);
          break;
        case FINISHED:
        case REVIEW_MISSION_SUMMARY:
          history.push(`${SUMMARY_PATH}?token=${token}`);
          break;
        case DEBRIEFED:
          if (missionOutcome === "Cancelled") {
            history.push(`${ACTIVE_ORDER_PATH}`);
          } else {
            history.push(`${SUMMARY_PATH}?token=${token}`);
          }
      }
      return;
    }
    if (data.statusCode > 299) {
      yield put(getMissionFailure(data));
    }
  } catch (error) {
    yield call(getMissionFailure);
  }
}

function* towtruckPositionWorker() {
  while (1) {
    try {
      yield call(fetchGatMission);
      const data = yield select(getOrder);
      const towtruckPosition = {
        latitude: data.driverLatitude,
        longitude: data.driverLongitude
      };
      const shopPosition = {
        latitude: data.sourceToDestination?.latitude,
        longitude: data.sourceToDestination?.longitude
      };
      const vehiclePosition = {
        lat: data.driverToSource?.latitude,
        lng: data.driverToSource?.longitude
      };
      yield all([
        put(setShopLocation(shopPosition)),
        put(setTowTruckLocation(towtruckPosition)),
        put(setVehicleLocation(vehiclePosition)),
        put(setOrderStatus(data))
      ]);

      if (ONGOING_STATUSES.includes(data.state)) {
        yield delay(2000);
      } else if (data.state === CANCELLED) {
        yield put(cancelFetchTowtruckPosition());
        yield put(towTruckCancelled());
      } else {
        yield put(cancelFetchTowtruckPosition());
      }
    } catch (error) {
      if (process.env.NODE_ENV === "development")
        yield put(toggleUnexpectedErrorModal(true));
    }
  }
}

function* findCurrentViewWatcher() {
  yield takeLatest(FIND_CURRENT_VIEW, findCurrentViewWorker);
}

function* cancelOrderWatcher() {
  yield takeLatest(START_CANCEL_ORDER, cancelOrderWorker);
}

function* towTruckPositionWatcher() {
  while (1) {
    yield take(FETCH_TOWTRUCK_POSITION);
    yield race([
      call(towtruckPositionWorker),
      take(CANCEL_FETCH_TOWTRUCK_POSITION)
    ]);
  }
}

function* getEstimatedArrivalTimeWatcher() {
  while (1) {
    yield take(GET_ESTIMATED_ARRIVAL_TIME);
    yield race([
      call(getEstimatedArrivalTimeWorker),
      take(CANCEL_ESTIMATED_ARRIVAL_TIME_POLLER)
    ]);
  }
}

// - TODO - Bygg om så vi kan köra denna i towTruckPositionWatcher

function* fetchGatMissionWatcher() {
  yield takeLatest(GET_MISSION_BY_TOKEN, fetchGatMission);
}

export default {
  findCurrentViewWatcher,
  cancelOrderWatcher,
  towTruckPositionWatcher,
  getEstimatedArrivalTimeWatcher,
  fetchGatMissionWatcher
};
