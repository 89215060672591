import {
  all,
  call,
  put,
  select,
  takeLatest,
  delay,
  take,
  race
} from "redux-saga/effects";
import { getFormValues, submit, clearFields, change } from "redux-form";
import history from "../../history";
import {
  CREATE_ORDER,
  ESTIMATE_PRICE,
  ESTIMATE_PRICE_DESTINATION,
  FETCH_FINAL_PRICE,
  LISTEN_FOR_DRIVER,
  LOGIN,
  DENY_HELP,
  VALUES_CHANGED,
  GET_POSITION,
  CUSTOMER_DETAILS_FORM_SUBMITTED,
  FETCH_PERSON,
  FETCH_VEHICLE,
  CREATE_ORDER_FORM_SUBMITTED,
  FETCH_DISPATCH,
  CHECK_PREVIOUS_VEHICLE_POSITION,
  FETCH_TOKEN,
  CANCEL_LISTEN_FOR_DRIVER,
  FETCH_DAMAGE_REASONS,
  FETCH_CONFINED_SPACE_ATTRIBUTES,
  FETCH_PAYMENT_OPTIONS,
  FETCH_VEHICLE_INSURANCE_LEVELS
} from "./constants";
import service from "./service";
import actions from "./actions";
import activeOrderService from "../activeOrder/service";
import activeOrderActions from "../activeOrder/actions";
import {
  SEARCHING,
  CANCELLED,
  PENDING,
  COMPLETED,
  REJECTED,
  WAITING_FOR_DISPATCH,
  WEB,
  DISPATCH,
  STARTED,
  ARRIVED,
  PENDING_DISPATCH,
  USER,
  INACTIVITY,
  PREPARE_TRIP_TO_WORKSHOP,
  PICKEDUP,
  DROPPED
} from "../../constants/orderStatusConstants";
import {
  ACTIVE_ORDER_PATH,
  CREATE_ORDER_PATH,
  SUMMARY_PATH
} from "../../constants/routeConstants";
import activeOrderSelectors from "../activeOrder/selectors";
import welcomeScreenSelectors from "../welcomeScreen/selectors";
import welcomeScreenActions from "../welcomeScreen/actions";
import {
  DAMAGE_REASON_VIEW,
  CAR_POSITION_VIEW,
  CONFIRM_ORDER_VIEW,
  SEEKING_RESPONDER_VIEW,
  CUSTOMER_DETAILS_VIEW
} from "./viewConstants";
import selectors from "./selectors";
import dispatchPositionActions from "../dispatchPosition/actions";
import {
  selectors as carPositionPickerSelectors,
  actions as carPositionPickerActions
} from "../carPositionPicker";
import { SAVE_CENTER_COORDS } from "../carPositionPicker/constants";
import validators from "./validators";
import { setCookie } from "../../helpers/cookieHelper";
import toggleLoading from "../loading/actions";
import logErrorHelper from "../../helpers/logErrorHelper";
import {
  BaseUrl,
  GET_JWT_TOKEN,
  POST,
  SELF_SERVE
} from "../../constants/apiConstants";
import api from "../../helpers/apiUtils";
import {
  getSession,
  SESSION_KEYS,
  setSession
} from "../../helpers/sessionStore";

const {
  setCurrentView,
  cancelListenForDriver,
  toggleUnexpectedErrorModal,
  toggleDispatchWillCallModal,
  fetchEstimateSuccess,
  fetchEstimateSuccessCoords,
  toggleNoConfirmationModal,
  toggleCancelledByDispatchErrorModal,
  listenForDriver,
  setShopLocation,
  fetchingVehicle,
  setPreviousPrice,
  getPersonDetailsFailure,
  getPositionSuccess,
  toggleAlreadyStartedErrorModal,
  setHasPreviousVehiclePositionChanged,
  setShowPreviousPrice,
  setToogleEstimateDestinationValue,
  getVehicleDetailsSuccess,
  getVehicleDetailsFailure,
  vehicleNotFoundError,
  setNoEstimateReason,
  fetchingPerson,
  fetchingPersonSuccess,
  personNotFound,
  fetchCurrentDamageReasonsSuccess,
  fetchCurrentDamageReasonsFailure,
  fetchConfinedSpaceAttributesSuccess,
  fetchConfinedSpaceAttributesFailure,
  fetchPaymentOptionsListFailure,
  fetchPaymentOptionsListSuccess,
  saveSelfServeMissionSuccess,
  fetchVehicleInsuranceLevelsSuccess,
  fetchVehicleInsuranceLevelsFailure
} = actions;
const {
  setOrderStatus,
  getEstimatedArrivalTimeSuccess,
  toggleStartedModal
} = activeOrderActions;
const { saveCenterCoords } = carPositionPickerActions;
const {
  setVehicleLocation,
  fetchingReverseGeocode,
  setPositionToServe
} = welcomeScreenActions;
const {
  getSocialNumberFieldError,
  getEstimateTowingPrice,
  getSearchPlacesResult,
  getIsCustomer,
  getIsFetchingPerson,
  getIsFetchingVehicle,
  getEstimatePriceCollectedCoords,
  selectConfinedSpaces,
  selectVehicleInsuranceLevels
} = selectors;

const {
  acceptOrder,
  estimatePrice,
  estimatePriceDestination,
  fetchToken,
  requestDriver,
  getLocation,
  getUserInfo,
  reverseGeoCode,
  requestDispatch,
  saveCustomerToPyramid,
  getLatestOrder,
  getInsuranceLevelsForOrgUnit,
  getAttributeConfinedSpace,
  getPaymentOptions,
  getVehicleInsuranceLevels
} = service;
const { cancelOrder, getEta, getOrderById } = activeOrderService;
const { toggleLocationBlockedModal, showDispatchMap } = dispatchPositionActions;
const {
  getVehiclePosition,
  getShopCoordinates,
  getUnsavedShopPosition,
  getServeVehiclePosition
} = welcomeScreenSelectors;
const { getCenterCoords } = carPositionPickerSelectors;
const { getOrder } = activeOrderSelectors;

const {
  validateMobile,
  validateRegno,
  validateSocialNumber,
  validatePosition
} = validators;

const ONGOING_STATUSES = [
  STARTED,
  ARRIVED,
  PREPARE_TRIP_TO_WORKSHOP,
  PICKEDUP,
  DROPPED
];

function urlSearchParams() {
  const urlParams = new URLSearchParams(window.location.search);
  return {
    mobile: urlParams.get("phone"),
    regno: urlParams.get("regno"),
    id: urlParams.get("id"),
    redirect: urlParams.get("redirect"),
    transport: urlParams.get("transport")
  };
}

function* createOrderWorker() {
  try {
    const order = yield select(getOrder);
    yield put(toggleLoading(true));
    yield call(acceptOrder, { request_id: order.id });
    const { data } = yield call(getOrderById, order.id);
    yield all([
      put(toggleLoading(false)),
      put(setOrderStatus(data)),
      put(cancelListenForDriver())
    ]);
    history.push(`${ACTIVE_ORDER_PATH}?id=${order.id}`);
    yield put(toggleStartedModal(true));
  } catch (error) {
    yield all([
      put(toggleLoading(false)),
      put(toggleUnexpectedErrorModal(true))
    ]);
  }
}

function* estimatePriceWorker() {
  try {
    const vehiclePos = yield select(getCenterCoords);
    yield put(toggleLoading(true));
    const { regno } = yield select(getFormValues("customerDetails"));
    const { garage, damageReason, engineInsuranceType } = yield select(
      getFormValues("createOrderForm")
    );
    const estimate = yield call(estimatePrice, {
      vehiclePos,
      regno,
      garage,
      damageReason,
      engineInsuranceType
    });
    if (estimate.exception === "ErrorException") {
      throw Error(estimate.message);
    }
    if (
      estimate.message !== "No Drivers Found" &&
      estimate.is_driver_available
    ) {
      const shopPosition = {
        formattedAddress: `${estimate.workshop_address}, ${estimate.workshop_zipcode}, ${estimate.workshop_city}`,
        latitude: estimate.workshop_latitude,
        longitude: estimate.workshop_longitude,
        address: estimate.workshop_address,
        zipcode: estimate.workshop_zipcode,
        city: estimate.workshop_city,
        name: estimate.workshop_name
      };
      yield all([
        put(setNoEstimateReason(undefined)),
        put(fetchEstimateSuccess(estimate)),
        put(fetchEstimateSuccessCoords(vehiclePos)),
        put(setShopLocation(shopPosition)),
        put(toggleLoading(false))
      ]);
    } else if (!estimate.is_driver_available) {
      yield all([
        put(setNoEstimateReason(" ")),
        put(fetchEstimateSuccessCoords({})),
        put(setCurrentView(SEEKING_RESPONDER_VIEW)),
        put(toggleLoading(false))
      ]);
    } else {
      const reason =
        estimate.reason !== "" ? estimate.reason : estimate.message;
      yield all([
        put(setNoEstimateReason(reason)),
        put(fetchEstimateSuccessCoords({})),
        put(setCurrentView(SEEKING_RESPONDER_VIEW)),
        put(toggleLoading(false))
      ]);
    }
  } catch (error) {
    yield all([
      put(toggleLoading(false)),
      put(toggleUnexpectedErrorModal(true))
    ]);
  }
}

function* setPreviousEstimate() {
  const towingPrice = yield select(getEstimateTowingPrice);
  yield put(setPreviousPrice(towingPrice));
}

function* estimatePriceDestinationWorker() {
  try {
    yield call(setPreviousEstimate);
    const vehiclePos = yield select(getCenterCoords);
    const destinationPos = yield select(getUnsavedShopPosition);
    const { regno } = yield select(getFormValues("customerDetails"));
    const { garage, damageReason, engineInsuranceType } = yield select(
      getFormValues("createOrderForm")
    );
    yield put(toggleLoading(true));
    const estimate = yield call(estimatePriceDestination, {
      vehiclePos,
      destinationPos,
      regno,
      garage,
      damageReason,
      engineInsuranceType
    });
    yield put(toggleLoading(false));
    if (
      estimate.message !== "No Drivers Found" &&
      estimate.is_driver_available
    ) {
      yield all([
        put(setNoEstimateReason(undefined)),
        put(fetchEstimateSuccess(estimate)),
        put(setShopLocation(destinationPos)),
        put(fetchEstimateSuccessCoords(vehiclePos))
      ]);
    } else if (!estimate.is_driver_available) {
      yield all([
        put(setNoEstimateReason(" ")),
        put(fetchEstimateSuccessCoords({})),
        put(setCurrentView(SEEKING_RESPONDER_VIEW)),
        put(toggleLoading(false))
      ]);
    } else {
      const reason =
        estimate.reason !== "" ? estimate.reason : estimate.message;
      yield all([put(setNoEstimateReason(reason))]);
      yield all([
        put(fetchEstimateSuccessCoords({})),
        put(setCurrentView(SEEKING_RESPONDER_VIEW))
      ]);
    }
  } catch (error) {
    yield all([
      put(toggleLoading(false)),
      put(toggleUnexpectedErrorModal(true))
    ]);
  }
}

// todo: Exclude regno for transport  mission
function* fetchTokenWorker() {
  try {
    const { regno, mobile } = urlSearchParams();
    const customerDetailsFormValues = yield select(
      getFormValues("customerDetails")
    );
    const { regno: r, mobile: m } = customerDetailsFormValues ?? {};

    let loginObject;

    if (regno && mobile) {
      loginObject = {
        regno,
        mobile
      };
    } else {
      loginObject = {
        regno: r,
        mobile: m
      };
    }
    const res = yield call(fetchToken, loginObject);
    if (!res.status) {
      throw Error("Error fetching token");
    }
    yield call(setCookie, { name: "token", val: res.access_token });
  } catch (error) {
    yield all([
      put(toggleLoading(false)),
      put(toggleUnexpectedErrorModal(true))
    ]);
    throw Error(error);
  }
}

function* loginWorker() {
  try {
    const { id, redirect, transport } = urlSearchParams();
    yield call(fetchTokenWorker);

    let order;
    if (id) {
      const { data } = yield call(getOrderById, id);
      order = data;
    } else {
      const { data } = yield call(getLatestOrder);
      order = data;
    }
    yield put(setOrderStatus(order));

    if (!redirect) {
      return;
    }

    let params = `?id=${order.id}`;

    if (transport) {
      params += "&transport=true";
    }

    if (order) {
      if (redirect.includes("createOrder")) {
        history.push(`${CREATE_ORDER_PATH}${params}`);
        yield put(listenForDriver());
      }
      if (redirect.includes("activeOrder")) {
        history.push(`${ACTIVE_ORDER_PATH}${params}`);
      }
      if (redirect.includes("summary")) {
        history.push(`${SUMMARY_PATH}${params}`);
      }
    }
  } catch (error) {
    yield all([
      put(toggleLoading(false)),
      put(toggleUnexpectedErrorModal(true))
    ]);
    throw Error(error);
  }
}

function* denyWorker() {
  try {
    const { id } = urlSearchParams();
    yield put(toggleLoading(true));
    const { data } = yield call(getOrderById, id);
    if (data.status === PENDING) {
      yield call(cancelOrder, data.id);
      const { data: d } = yield call(getOrderById, id);
      yield all([
        put(setCurrentView(CUSTOMER_DETAILS_VIEW)),
        put(cancelListenForDriver()),
        put(setOrderStatus(d)),
        put(toggleLoading(false))
      ]);
    } else {
      yield all([
        put(setCurrentView(CUSTOMER_DETAILS_VIEW)),
        put(cancelListenForDriver()),
        put(setOrderStatus(data)),
        put(toggleLoading(false))
      ]);
    }
    history.push(CREATE_ORDER_PATH);
  } catch (error) {
    yield all([
      put(toggleLoading(false)),
      put(toggleUnexpectedErrorModal(true))
    ]);
  }
}

function* clearValues() {
  yield put(clearFields("createOrderForm", false, false, "damageDetails"));
}

function* listenForDriverWorker() {
  try {
    while (1) {
      const { id } = urlSearchParams();
      const { data } = yield call(getOrderById, id);
      yield put(setOrderStatus(data));
      if (data.status === PENDING && data.booking_by === WEB) {
        yield put(toggleLoading(true));
        const etaResponse = yield call(getEta, data.id);
        yield all([
          put(getEstimatedArrivalTimeSuccess(etaResponse)),
          put(
            saveCenterCoords({ lat: data.s_latitude, lng: data.s_longitude })
          ),
          put(setCurrentView(CONFIRM_ORDER_VIEW))
        ]);
        history.push(`${CREATE_ORDER_PATH}?id=${data.id}`);
        yield put(toggleLoading(false));
        yield delay(90000);
      } else if (ONGOING_STATUSES.includes(data.status)) {
        history.push(`${ACTIVE_ORDER_PATH}?id=${data.id}`);
        yield put(cancelListenForDriver());
      } else if (data.status === COMPLETED) {
        if (window.location.pathname !== ACTIVE_ORDER_PATH) {
          // history.push(WELCOME_SCREEN_PATH);
          yield put(cancelListenForDriver());
        }
      } else if (data.status === CANCELLED) {
        yield put(change("customerDetails", "noEstimate", undefined));
        if (
          data.cancelled_by === USER &&
          data.booking_by === WEB &&
          data.cancel_reason === INACTIVITY
        ) {
          yield put(toggleNoConfirmationModal(true));
          history.push(CREATE_ORDER_PATH);
          yield put(cancelListenForDriver());
        }
        if (data.cancelled_by === REJECTED && data.booking_by === WEB) {
          yield all([
            put(setNoEstimateReason(REJECTED)),
            put(cancelListenForDriver())
          ]);
          history.push(CREATE_ORDER_PATH);
        } else if (data.cancelled_by === DISPATCH) {
          yield all([
            put(toggleCancelledByDispatchErrorModal(true)),
            put(cancelListenForDriver())
          ]);
        } else {
          yield put(setCurrentView(CUSTOMER_DETAILS_VIEW));
          history.push(CREATE_ORDER_PATH);
          yield put(cancelListenForDriver());
        }
      } else if (
        data.status === SEARCHING ||
        data.status === WAITING_FOR_DISPATCH ||
        data.status === PENDING_DISPATCH
      ) {
        history.push(`${CREATE_ORDER_PATH}?id=${data.id}`);
        yield all([put(setCurrentView(SEEKING_RESPONDER_VIEW))]);
        yield delay(2000);
        yield put(listenForDriver());
      } else {
        yield put(cancelListenForDriver());
      }
    }
  } catch (error) {
    yield all([
      put(toggleLoading(false)),
      put(toggleUnexpectedErrorModal(true)),
      put(cancelListenForDriver())
    ]);
  }
}

function* getPositionWorker() {
  try {
    yield put(toggleLoading(true));
    const position = yield call(getLocation);
    yield put(toggleLoading(false));
    yield put(toggleLocationBlockedModal(true));
    if (position) {
      yield put(toggleLocationBlockedModal(false));
      yield all([
        put(
          getPositionSuccess({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            accuracy: position.coords.accuracy
          })
        ),
        put(showDispatchMap(true)),
        put(setCurrentView(CAR_POSITION_VIEW))
      ]);
    } else {
      yield all([
        put(showDispatchMap(true)),
        put(setCurrentView(CAR_POSITION_VIEW))
      ]);
    }
  } catch (error) {
    yield put(toggleLocationBlockedModal(true));
    yield all([
      put(setCurrentView(CAR_POSITION_VIEW)),
      put(toggleLoading(false))
    ]);
  }
}

function* fetchPersonWorker(action) {
  try {
    if (!action.payload) {
      throw Error("No payload");
    }
    if (validateSocialNumber(action.payload)) {
      throw Error("Not a valid socialnumber");
    }
    yield put(fetchingPerson());
    const res = yield call(getUserInfo, action.payload);
    if (res.isValid) {
      yield put(fetchingPersonSuccess());
    } else {
      yield put(personNotFound());
    }
  } catch (error) {
    if (error.message === "Error Fetching Person") {
      yield put(getPersonDetailsFailure());
    }
  }
}

function* getVehicleWorker(action) {
  try {
    if (!action.payload) {
      throw Error("No payload");
    }
    if (validateRegno(action.payload)) {
      throw Error("Not a valid regno");
    }
    yield put(fetchingVehicle());
    yield put(getVehicleDetailsSuccess());
  } catch (error) {
    if (
      error.message === "No payload" ||
      error.message === "Not a valid regno"
    ) {
      logErrorHelper(error);
    } else if (error.message === "No vehicle found") {
      yield put(vehicleNotFoundError());
    } else {
      yield put(getVehicleDetailsFailure());
    }
  }
}

function* saveVehicleAddressWorker(action) {
  try {
    if (action.payload) {
      const searchPlacesResult = yield select(getSearchPlacesResult);
      if (
        searchPlacesResult &&
        Number.parseFloat(searchPlacesResult.lat).toFixed(4) ===
          Number.parseFloat(action.payload.lat).toFixed(4) &&
        Number.parseFloat(searchPlacesResult.lng).toFixed(4) ===
          Number.parseFloat(action.payload.lng).toFixed(4)
      ) {
        yield put(setVehicleLocation(searchPlacesResult));
        if (searchPlacesResult && searchPlacesResult.address) {
          yield put(
            change(
              "customerDetails",
              "carPosition",
              searchPlacesResult.address.length >= 24
                ? `${searchPlacesResult.address.substring(0, 24)}...`
                : searchPlacesResult.address
            )
          );
        }
      } else {
        yield put(fetchingReverseGeocode(true));
        const res = yield call(reverseGeoCode, action.payload);
        yield put(fetchingReverseGeocode(false));

        yield put(
          setPositionToServe({
            lat: action.payload.lat,
            lng: action.payload.lng,
            address: res.address,
            zipCode: res.zipcode,
            city: res.town
          })
        );

        yield put(
          setVehicleLocation({
            lat: action.payload.lat,
            lng: action.payload.lng,
            address: res.formatted_address
          })
        );
        yield put(
          change(
            "customerDetails",
            "carPosition",
            res.formatted_address?.length >= 24
              ? `${res.formatted_address.substring(0, 24)}...`
              : res.formatted_address
          )
        );
      }
    }
  } catch (error) {
    yield all([
      put(fetchingReverseGeocode(false)),
      put(toggleUnexpectedErrorModal(true))
    ]);
  }
}

// eslint-disable-next-line consistent-return
function* updateMissionSelfServe(body) {
  const gatOrgUnit = getSession(SESSION_KEYS.gatOrganizationUnit);
  const gatLocale = getSession(SESSION_KEYS.locale);
  const accessToken = getSession(SESSION_KEYS.accessToken);
  const appId = getSession(SESSION_KEYS.appId);
  try {
    const response = yield api.post(`${SELF_SERVE}`, BaseUrl.GatUrl, body, {
      gatOrgUnit,
      accessToken,
      gatLocale,
      appId
    });
    yield put(saveSelfServeMissionSuccess(response.data));
    return response.data;
  } catch (error) {
    logErrorHelper(error);
  }
}

function* submitCreateOrderForm() {
  try {
    const order = yield select(getOrder);
    yield put(submit("createOrderForm"));
    const formValueCreate = yield select(getFormValues("createOrderForm"));
    const formValueCustomer = yield select(getFormValues("customerDetails"));
    const orderRegno = order?.customerVehicle?.registrationNumber;
    const orderMobile = order?.customer?.mobilePhone;
    const vehiclePosition = yield select(getServeVehiclePosition);
    const confinedSpacesId = yield select(selectConfinedSpaces);
    const vehicleInsuranceLevel = yield select(selectVehicleInsuranceLevels);
    const { lat, lng, address, city, zipCode } = vehiclePosition;
    const requestBody = {
      missionId: getSession(SESSION_KEYS.missionId),
      vehicleInsuranceLevel: vehicleInsuranceLevel.id,
      reason: formValueCreate.damageDetails.value,
      address,
      zipCode,
      city,
      latitude: lat,
      longitude: lng,
      registrationNumber: formValueCustomer?.regno || orderRegno,
      paymentOptionReference: null,
      mobilePhone: formValueCustomer?.mobile || orderMobile,
      attributes: [
        {
          attributeId: confinedSpacesId,
          value: formValueCreate.garage
        }
      ]
    };
    yield put(toggleLoading(true));
    const response = yield call(updateMissionSelfServe, requestBody);
    if (response.id) {
      yield put(setCurrentView(CONFIRM_ORDER_VIEW));
    }
    yield put(toggleLoading(false));
  } catch (error) {
    logErrorHelper(error);
  }
}

function* createGatBody() {
  const formValueCustomer = yield select(getFormValues("customerDetails"));
  const formValueCreate = yield select(getFormValues("createOrderForm"));
  // Default reserveTire to false if undefined
  if (formValueCreate.reserveTire === undefined) {
    formValueCreate.reserveTire = false;
  }
  // HACK Default terms to true until validation is removed from backend
  formValueCreate.terms = true;
  const vehiclePosition = yield select(getVehiclePosition);

  const damageReason = formValueCreate.damageReason.value;
  const engineInsuranceType = formValueCreate.engineInsuranceType.value;
  const { damageDetails } = formValueCreate.damageDetails || undefined;
  const { garage, reserveTire, terms } = formValueCreate;

  if (formValueCustomer.noEstimate) {
    return {
      s_address: vehiclePosition.address,
      s_latitude: vehiclePosition.lat,
      s_longitude: vehiclePosition.lng,
      damageReason,
      damageDetails,
      engineInsuranceType,
      regno: formValueCustomer.regno,
      mobile: formValueCustomer.mobile,
      garage,
      reserveTire,
      terms
    };
  }

  if (formValueCustomer.isInsured) {
    return {
      customerCode: formValueCustomer.socialNumber,
      s_address: vehiclePosition.address,
      s_latitude: vehiclePosition.lat,
      s_longitude: vehiclePosition.lng,
      damageReason,
      damageDetails,
      engineInsuranceType,
      regno: formValueCustomer.regno,
      mobile: formValueCustomer.mobile,
      garage,
      reserveTire,
      terms
    };
  }
  const destinationPos = yield select(getShopCoordinates);
  const body = {
    d_latitude: destinationPos.latitude,
    d_longitude: destinationPos.longitude,
    d_address: destinationPos.address,
    workshop_address: destinationPos.address,
    workshop_zipcode: destinationPos.zipcode,
    workshop_city: destinationPos.city,
    s_address: vehiclePosition.address,
    s_latitude: vehiclePosition.lat,
    s_longitude: vehiclePosition.lng,
    regno: formValueCustomer.regno,
    mobile: formValueCustomer.mobile,
    engineInsuranceType,
    damageReason,
    damageDetails,
    garage,
    reserveTire,
    terms
  };

  if (destinationPos.name) {
    body.workshop_name = destinationPos.name;
  }
  return body;
}

function* handleGatRequestError(error) {
  if (error.message === "Request already in progress") {
    yield put(toggleAlreadyStartedErrorModal(true));
    history.push(`${CREATE_ORDER_PATH}?redirect=${CREATE_ORDER_PATH}`);
  } else {
    put(toggleUnexpectedErrorModal(true));
  }
}

function* fetchFinalPriceWorker() {
  try {
    const body = yield call(createGatBody);
    const { mobile } = body;
    const result = yield call(requestDriver, body);
    const { customerCode } = result;
    const pyramidBody = {
      mobile,
      customerCode,
      updateCustomerInfo: {
        mobile
      }
    };
    yield call(saveCustomerToPyramid, pyramidBody);
    history.push(`${CREATE_ORDER_PATH}?id=${result.request_id}`);
    yield all([put(setOrderStatus(result)), put(listenForDriver())]);
  } catch (error) {
    yield call(handleGatRequestError, error);
  }
}

function* fetchDispatchWorker() {
  try {
    const body = yield call(createGatBody);
    const result = yield call(requestDispatch, body);
    history.push(`${CREATE_ORDER_PATH}?id=${result.request_id}`);
    yield all([put(setOrderStatus(result)), put(listenForDriver())]);
  } catch (error) {
    yield call(handleGatRequestError, error);
  }
}

function* hasPreviousVehiclePositionChangedWorker() {
  try {
    const mapCenterCoords = yield select(getCenterCoords);
    const estimatePriceCollectedCoords = yield select(
      getEstimatePriceCollectedCoords
    );

    if (estimatePriceCollectedCoords) {
      if (
        mapCenterCoords &&
        estimatePriceCollectedCoords.lat === mapCenterCoords.lat &&
        estimatePriceCollectedCoords.lng === mapCenterCoords.lng
      ) {
        yield put(setHasPreviousVehiclePositionChanged(false));
      } else {
        yield all([
          put(setShowPreviousPrice(false)),
          put(setToogleEstimateDestinationValue(false)),
          put(setHasPreviousVehiclePositionChanged(true))
        ]);
      }
    }
  } catch (error) {
    logErrorHelper(error);
  }
}

function* fetchCurrentDamageReasons() {
  try {
    const damageReasons = yield call(getInsuranceLevelsForOrgUnit);
    if (damageReasons) {
      yield put(fetchCurrentDamageReasonsSuccess(damageReasons.data));
    }
  } catch (error) {
    yield call(fetchCurrentDamageReasonsFailure);
    logErrorHelper(error);
  }
}

function* fetchPaymentOptions() {
  try {
    const paymentOptions = yield call(getPaymentOptions);
    if (paymentOptions) {
      yield put(fetchPaymentOptionsListSuccess(paymentOptions.data));
    }
  } catch (error) {
    yield call(fetchPaymentOptionsListFailure);
    logErrorHelper(error);
  }
}

function* fetchConfinedSpaceAttributes() {
  try {
    const confinedSpaceAttributes = yield call(getAttributeConfinedSpace);
    if (confinedSpaceAttributes) {
      yield put(
        fetchConfinedSpaceAttributesSuccess(confinedSpaceAttributes.data)
      );
    }
  } catch (error) {
    yield call(fetchConfinedSpaceAttributesFailure);
    logErrorHelper(error);
  }
}

function* fetchVehicleInsuranceLevels() {
  try {
    const vehicleInsuranceLevels = yield call(getVehicleInsuranceLevels);
    if (vehicleInsuranceLevels) {
      const trafficInsurance = vehicleInsuranceLevels.data.find(
        insurance => insurance.externalReference === "TRAFFIC"
      );
      yield put(fetchVehicleInsuranceLevelsSuccess(trafficInsurance));
    }
  } catch (error) {
    yield call(fetchVehicleInsuranceLevelsFailure);
    logErrorHelper(error);
  }
}

async function tryParseResponse(response) {
  try {
    const json = await response.json();
    return {
      statusCode: response.status,
      data: json
    };
  } catch (error) {
    throw error;
  }
}

function* createGatMission() {
  const appId = getSession(SESSION_KEYS.appId);
  const response = yield fetch(
    `${api.getBaseUrl(BaseUrl.GatUrl)}${GET_JWT_TOKEN}`,
    {
      method: POST,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "gat-application-id": appId
      }
    }
  );
  const data = yield tryParseResponse(response);
  if (data?.data?.status) {
    throw Error;
  }
  const { token, id, accessToken } = data.data;
  setSession(SESSION_KEYS.accessToken, accessToken);
  setSession(SESSION_KEYS.missionId, id);
  setSession(SESSION_KEYS.token, token);
}

function* submitCustomerDetails() {
  const accessToken = getSession(SESSION_KEYS.accessToken);
  try {
    yield put(submit("customerDetails"));
    const formValue = yield select(getFormValues("customerDetails"));
    const socialNumberFieldError = yield select(getSocialNumberFieldError);
    const isCustomer = yield select(getIsCustomer);
    const isFetchingPerson = yield select(getIsFetchingPerson);
    const isFetchingVehicle = yield select(getIsFetchingVehicle);
    if (
      formValue &&
      !validateRegno(formValue.regno) &&
      !isFetchingVehicle &&
      !validateMobile(formValue.mobile) &&
      !validatePosition(formValue.carPosition) &&
      (!formValue.isInsured || !socialNumberFieldError.show) &&
      (!formValue.isInsured || isCustomer) &&
      (!formValue.isInsured || !isFetchingPerson) &&
      (!formValue.isInsured || !validateSocialNumber(formValue.socialNumber))
    ) {
      if (!accessToken) {
        try {
          yield call(createGatMission);
          yield put(setCurrentView(DAMAGE_REASON_VIEW));
        } catch (error) {
          yield put(toggleDispatchWillCallModal(true));
          logErrorHelper(error);
        }
      } else if (accessToken) {
        yield put(setCurrentView(DAMAGE_REASON_VIEW));
      }
    }
  } catch (error) {
    logErrorHelper(error);
  }
}

function* getPositionWatcher() {
  yield takeLatest(GET_POSITION, getPositionWorker);
}

function* createOrderWatcher() {
  yield takeLatest(CREATE_ORDER, createOrderWorker);
}

function* estimatePriceWatcher() {
  yield takeLatest(ESTIMATE_PRICE, estimatePriceWorker);
}

function* estimatePriceDestinationWatcher() {
  yield takeLatest(ESTIMATE_PRICE_DESTINATION, estimatePriceDestinationWorker);
}

function* fetchFinalPriceWatcher() {
  yield takeLatest(FETCH_FINAL_PRICE, fetchFinalPriceWorker);
}

function* listenForDriverWatcher() {
  while (1) {
    yield take(LISTEN_FOR_DRIVER);
    yield race([call(listenForDriverWorker), take(CANCEL_LISTEN_FOR_DRIVER)]);
  }
}

function* loginWatcher() {
  yield takeLatest(LOGIN, loginWorker);
}

function* denyWatcher() {
  yield takeLatest(DENY_HELP, denyWorker);
}

function* valuesChangedWatcher() {
  yield takeLatest(VALUES_CHANGED, clearValues);
}

function* submitCustomerDetailsWatcher() {
  yield takeLatest(CUSTOMER_DETAILS_FORM_SUBMITTED, submitCustomerDetails);
}

function* submitCreateOrderFormWatcher() {
  yield takeLatest(CREATE_ORDER_FORM_SUBMITTED, submitCreateOrderForm);
}

function* getVehicleWatcher() {
  yield takeLatest(FETCH_VEHICLE, getVehicleWorker);
}

function* fetchPersonWatcher() {
  yield takeLatest(FETCH_PERSON, fetchPersonWorker);
}

function* saveCenterCoordsWatcher() {
  yield takeLatest(SAVE_CENTER_COORDS, saveVehicleAddressWorker);
}

function* fetchDispatchWatcher() {
  yield takeLatest(FETCH_DISPATCH, fetchDispatchWorker);
}

function* hasPreviousVehiclePositionChangedWatcher() {
  yield takeLatest(
    CHECK_PREVIOUS_VEHICLE_POSITION,
    hasPreviousVehiclePositionChangedWorker
  );
}

function* fetchTokenWatcher() {
  yield takeLatest(FETCH_TOKEN, fetchTokenWorker);
}

function* fetchCurrentDamageReasonsWatcher() {
  yield takeLatest(FETCH_DAMAGE_REASONS, fetchCurrentDamageReasons);
}

function* fetchConfinedSpaceAttributesWatcher() {
  yield takeLatest(
    FETCH_CONFINED_SPACE_ATTRIBUTES,
    fetchConfinedSpaceAttributes
  );
}

function* fetchPaymentOptionsWatcher() {
  yield takeLatest(FETCH_PAYMENT_OPTIONS, fetchPaymentOptions);
}

function* fetchVehicleInsuranceLevelsWatcher() {
  yield takeLatest(FETCH_VEHICLE_INSURANCE_LEVELS, fetchVehicleInsuranceLevels);
}

export default {
  createOrderWatcher,
  estimatePriceWatcher,
  estimatePriceDestinationWatcher,
  fetchFinalPriceWatcher,
  listenForDriverWatcher,
  loginWatcher,
  denyWatcher,
  valuesChangedWatcher,
  getPositionWatcher,
  submitCustomerDetailsWatcher,
  submitCreateOrderFormWatcher,
  fetchPersonWatcher,
  getVehicleWatcher,
  saveCenterCoordsWatcher,
  fetchDispatchWatcher,
  hasPreviousVehiclePositionChangedWatcher,
  fetchTokenWatcher,
  fetchCurrentDamageReasonsWatcher,
  fetchConfinedSpaceAttributesWatcher,
  fetchPaymentOptionsWatcher,
  fetchVehicleInsuranceLevelsWatcher
};
