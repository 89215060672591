import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { useSessionStorage } from "usehooks-ts";
import { Button } from "@assistansbolaget/gat-components";
import actions from "./actions";
import selectors from "./selectors";
import Receipt from "./receipt/receipt";
import history from "../../history";
import Modal from "../../components/Modal/Modal";
import { CREATE_ORDER_PATH } from "../../constants/routeConstants";
import activeOrderSelectors from "../activeOrder/selectors";
import "./style.css";
import api from "../../helpers/apiUtils";
import {
  BaseUrl,
  GET_MISSION,
  USER_ASSISTANCE_ORDER
} from "../../constants/apiConstants";
import { getSession, SESSION_KEYS } from "../../helpers/sessionStore";
import ActiveOrdersActions from "../activeOrder/actions";
import logout from "../../helpers/clearSessionStore";
import services from "./service";
import legacyApi from "../../helpers/legacyApiHelper";
import LegacyReciept from "./LegacyReciept/LegacyReciept";

const { getOrder } = activeOrderSelectors;

const { setOrderStatus } = ActiveOrdersActions;

const {
  submitSummaryForm,
  toggleSummarySuccessModal,
  toggleSummaryFailureModal,
  toggleReceiptFailureModal
} = actions;

const {
  getShowOpinion,
  getSummarySuccessModalState,
  getSummaryFailureModalState,
  getReceiptFailureModalState
} = selectors;

const Summary = ({
  onOpinionClick,
  closeSummaryFailureModal,
  receiptFailureModalState,
  closeReceiptFailureModal,
  saveOrderToState,
  intl
}) => {
  const [gatOrgUnit] = useSessionStorage(
    SESSION_KEYS.gatOrganizationUnit,
    undefined
  );
  const [gatLocale] = useSessionStorage(SESSION_KEYS.locale, undefined);
  const [companyAttributes] = useSessionStorage(
    SESSION_KEYS.companyAttributes,
    undefined
  );

  const [order, setOrder] = useState({});

  const [errorModal, setErrorModal] = useState(false);

  const accessToken = getSession(SESSION_KEYS.accessToken);

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  const params = {
    id: urlParams.get("id"),
    regno: urlParams.get("regno"),
    phone: urlParams.get("phone")
  };

  const showLegacyReciept = params.id && params.regno && params.phone;

  useEffect(async () => {
    if (params.id && params.regno && params.phone) {
      const dispatchToken = await services.fetchToken({
        mobile: params.phone,
        regno: params.regno
      });

      legacyApi
        .get(
          `${USER_ASSISTANCE_ORDER}?id=${params.id}`,
          BaseUrl.RescueUrl,
          dispatchToken
        )
        .then(res => {
          if (res.statusCode < 299) {
            saveOrderToState(res.data.data);
            return setOrder(res.data.data);
          }
          return setErrorModal(true);
        })
        .catch(() => {
          setErrorModal(true);
        });
    }
  }, []);

  useEffect(() => {
    if (!token || token === "null") return;

    const appId = getSession(SESSION_KEYS.appId);

    try {
      api
        .get(`${GET_MISSION}?token=${token}`, BaseUrl.GatUrl, {
          gatOrgUnit,
          accessToken,
          gatLocale,
          appId
        })
        .then(res => {
          if (res.statusCode < 299) {
            saveOrderToState(res.data);
            return setOrder(res.data);
          }
          return setErrorModal(true);
        })
        .catch(() => {
          setErrorModal(true);
        });
    } catch (error) {
      setErrorModal(true);
    }
  }, [gatLocale]);

  const companyPhoneNumber = companyAttributes?.CompanyPhone?.value;

  return (
    <>
      {errorModal && (
        <Modal
          title={intl.formatMessage({
            id: "Summary.FailureRatingModal.Title",
            defaultMessage: "Something went wrong",
            description: "FailureRatingModal: Something went wrong text"
          })}
          bodyText={intl.formatMessage({
            id: "Summary.FailureRatingModal.BreadText",
            defaultMessage: "Please try again",
            description: "RatingModal: Please try again text"
          })}
          primaryModalButtonAction={closeSummaryFailureModal}
          primaryModalButtonText={intl.formatMessage({
            id: "general.close",
            defaultMessage: "Close",
            description: "RatingModal: Close text"
          })}
        />
      )}
      {receiptFailureModalState && (
        <Modal
          title={intl.formatMessage({
            id: "Summary.FailureRatingModal.Title",
            defaultMessage: "Something went wrong",
            description: "FailureRatingModal: Something went wrong text"
          })}
          bodyText={`${intl.formatMessage({
            id: "Summary.FailureRatingModal.Title",
            defaultMessage: "Something went wrong",
            description: "FailureRatingModal: Something went wrong text"
          })} ${companyPhoneNumber}`}
          primaryModalButtonAction={closeReceiptFailureModal}
          primaryModalButtonText={intl.formatMessage({
            id: "general.close",
            defaultMessage: "Close",
            description: "RatingModal: Close text"
          })}
        />
      )}
      <div className="summary-wrapper">
        <div className="summary flex-column align-center tinted-background z-index11">
          <div className="summary-content flex-column align-center">
            {showLegacyReciept ? (
              <LegacyReciept legacyOrder={order} />
            ) : (
              <Receipt onOpinionClick={onOpinionClick} order={order} />
            )}
            <Button
              variant="Secondary"
              onClick={closeReceiptFailureModal}
              fullWidth
            >
              {intl.formatMessage({
                id: "Summary.CreateNewMission",
                defaultMessage: "Create new mission",
                description: "Summary: button for create new mission"
              })}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

Summary.propTypes = {
  onOpinionClick: PropTypes.func.isRequired,
  closeSummaryFailureModal: PropTypes.func.isRequired,
  receiptFailureModalState: PropTypes.func.isRequired,
  closeReceiptFailureModal: PropTypes.func.isRequired,
  saveOrderToState: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired
};

const mapStateToProps = state => ({
  showOpinion: getShowOpinion(state),
  summarySuccessModalState: getSummarySuccessModalState(state),
  summaryFailureModalState: getSummaryFailureModalState(state),
  receiptFailureModalState: getReceiptFailureModalState(state),
  order: getOrder(state)
});

const mapDispatchToProps = dispatch => ({
  onOpinionClick: () => {
    dispatch(submitSummaryForm());
  },
  saveOrderToState: order => {
    dispatch(setOrderStatus(order));
  },
  closeSummarySuccessModal: () => {
    logout();
    dispatch(toggleSummarySuccessModal(false));
    history.push(CREATE_ORDER_PATH);
  },
  closeSummaryFailureModal: () => {
    logout();
    dispatch(toggleSummaryFailureModal(false));
    history.push(CREATE_ORDER_PATH);
  },
  closeReceiptFailureModal: () => {
    logout();
    dispatch(toggleReceiptFailureModal(false));
    history.push(CREATE_ORDER_PATH);
  }
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(Summary)
);
