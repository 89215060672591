import React from "react";
import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import { withScriptjs, withGoogleMap } from "react-google-maps";
import { GOOGLE_MAP_URL } from "../../constants/apiConstants";
import actions from "./actions";
import selectors from "./selectors";
import welcomeScreenSelectors from "../welcomeScreen/selectors";
import languageHelper from "../../helpers/languageHelper";

import "../carPositionPicker/style.css";
import CarPositionPresentation from "../carPositionPicker/carPositionPresentation";

const language = languageHelper();

const {
  getCustomerCoordinates,
  getVehiclePosition,
  getIsFetchingReverseGeoCode
} = welcomeScreenSelectors;

const { saveMapToState, saveCenterCoords, updateOrderPosition } = actions;
const { getMap, getCenterCoords } = selectors;

const DispatchCarPositionPicker = compose(
  withProps({
    googleMapURL: GOOGLE_MAP_URL,
    loadingElement: <div className="full-height-width" />,
    containerElement: <div className="map-container-element" />,
    mapElement: <div className="full-height-width" />
  }),
  withScriptjs,
  withGoogleMap
)(props => {
  const saveText = language.dispatchPosition.okButtonText;
  return <CarPositionPresentation {...props} saveText={saveText} />;
});

const mapStateToProps = state => ({
  map: getMap(state),
  geoCoordinates: getCustomerCoordinates(state),
  centerCoords: getCenterCoords(state),
  vehiclePosition: getVehiclePosition(state),
  isFetchingReverseGeoCode: getIsFetchingReverseGeoCode(state)
});

const mapDispatchToProps = dispatch => ({
  saveMap: map => dispatch(saveMapToState(map)),
  saveCenter: centerCoords => dispatch(saveCenterCoords(centerCoords)),
  handleNavigationButton: () => dispatch(updateOrderPosition())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DispatchCarPositionPicker);
