const getLoadingState = ({ summary }) => summary.get("loading");

const getShowOpinion = ({ summary }) => summary.get("showOpinion");

const getRating = ({ summary }) => summary.get("rating");

const getSummarySuccessModalState = ({ summary }) => {
  return summary.get("summarySuccessModalState");
};

const getSummaryFailureModalState = ({ summary }) => {
  return summary.get("summaryFailureModalState");
};

const getReceiptFailureModalState = ({ summary }) => {
  return summary.get("receiptFailureModalState");
};

const getReceiptSuccessState = ({ summary }) => {
  return summary.get("receiptSuccessState");
};

const getReceiptLoadingState = ({ summary }) => {
  return summary.get("receiptLoading");
};

export default {
  getLoadingState,
  getShowOpinion,
  getRating,
  getSummarySuccessModalState,
  getSummaryFailureModalState,
  getReceiptFailureModalState,
  getReceiptSuccessState,
  getReceiptLoadingState,
};
