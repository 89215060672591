import {
  CREATE_ORDER,
  ESTIMATE_PRICE,
  ESTIMATE_PRICE_DESTINATION,
  CUSTOMER_DETAILS_FORM_SUBMITTED,
  CREATE_ORDER_FORM_SUBMITTED,
  FETCH_FINAL_PRICE,
  DENY_HELP,
  VALUES_CHANGED,
  TOGGLE_NO_CONFIRMATION_MODAL,
  LOGIN,
  SET_NEXT_VIEW,
  SET_PREVIOUS_VIEW,
  SET_CURRENT_VIEW,
  GO_FORWARD,
  GO_BACK,
  GET_POSITION,
  FETCH_PERSON,
  FETCH_VEHICLE,
  TOGGLE_ESTIMATE_DESTINATION,
  SET_SHOP_LOCATION,
  SET_PREVIOUS_PRICE,
  SET_SHOW_PREVIOUS_PRICE,
  TOGGLE_ALREADY_STARTED_ERROR_MODAL,
  TOGGLE_UNEXPECTED_ERROR_MODAL,
  SET_SEARCH_PLACES_RESULT,
  FETCH_DISPATCH,
  TOGGLE_ORDER_CANCELLED_BY_DISPATCH_MODAL,
  TOGGLE_DISPATCH_WILL_CALL_MODAL,
  CHECK_PREVIOUS_VEHICLE_POSITION,
  CANCEL_LISTEN_FOR_DRIVER,
  FETCH_ESTIMATE_SUCCESS,
  FETCH_ESTIMATE_SUCCESS_COORDS,
  LISTEN_FOR_DRIVER,
  FETCHING_VEHICLE,
  GET_PERSON_DETAILS_FAILURE,
  GET_POSITION_SUCCESS,
  SET_HAS_PREVIOUS_VEHICLE_POSITION_CHANGED,
  SET_TOGGLE_ESTIMATE_DESTINATION_VALUE,
  GET_VEHICLE_DETAILS_SUCCESS,
  GET_VEHICLE_DETAILS_FAILURE,
  ERROR_VEHICLE_NOT_FOUND,
  SET_NO_ESTIMATE_REASON,
  FETCHING_PERSON,
  FETCHING_PERSON_SUCCESS,
  PERSON_NOT_FOUND,
  SET_MODAL_VIEW,
  MODAL_GO_BACK,
  FETCH_DAMAGE_REASONS,
  FETCH_DAMAGE_REASONS_SUCCESS,
  FETCH_DAMAGE_REASONS_FAILURE,
  FETCH_CONFINED_SPACE_ATTRIBUTES_SUCCESS,
  FETCH_CONFINED_SPACE_ATTRIBUTES_FAILURE,
  FETCH_CONFINED_SPACE_ATTRIBUTES,
  FETCH_PAYMENT_OPTIONS,
  FETCH_PAYMENT_OPTIONS_SUCCESS,
  FETCH_PAYMENT_OPTIONS_FAILURE,
  SAVE_SELF_SERVE_MISSION_SUCCESS,
  FETCH_VEHICLE_INSURANCE_LEVELS,
  FETCH_VEHICLE_INSURANCE_LEVELS_SUCCESS,
  FETCH_VEHICLE_INSURANCE_LEVELS_FAILURE,
  ACCEPT_COOKIE_VALUE
} from "./constants";

const getPosition = () => ({
  type: GET_POSITION
});

const goForward = () => ({
  type: GO_FORWARD
});

const goBack = () => ({
  type: GO_BACK
});

const setPreviousView = view => ({
  type: SET_PREVIOUS_VIEW,
  payload: view
});

const setNextView = view => ({
  type: SET_NEXT_VIEW,
  payload: view
});

const setCurrentView = view => ({
  type: SET_CURRENT_VIEW,
  payload: view
});

const setModalView = view => ({
  type: SET_MODAL_VIEW,
  payload: view
});

const modalGoBack = () => ({
  type: MODAL_GO_BACK
});

const clearValues = () => ({
  type: VALUES_CHANGED
});

const estimatePrice = () => ({
  type: ESTIMATE_PRICE
});

const estimateDestinationPrice = () => ({
  type: ESTIMATE_PRICE_DESTINATION
});

const submitCustomerDetailsForm = () => ({
  type: CUSTOMER_DETAILS_FORM_SUBMITTED
});

const submitCreateOrderForm = () => ({
  type: CREATE_ORDER_FORM_SUBMITTED
});

const createOrder = () => ({
  type: CREATE_ORDER
});

const fetchFinalPrice = () => ({
  type: FETCH_FINAL_PRICE
});

const fetchDispatch = () => ({
  type: FETCH_DISPATCH
});

const deny = () => ({
  type: DENY_HELP
});

const toggleNoConfirmationModal = bool => ({
  type: TOGGLE_NO_CONFIRMATION_MODAL,
  payload: bool
});

const login = () => ({
  type: LOGIN
});

const fetchPerson = socialNumber => ({
  type: FETCH_PERSON,
  payload: socialNumber
});

const fetchVehicle = regno => ({
  type: FETCH_VEHICLE,
  payload: regno
});

const toggleEstimateDestination = () => ({
  type: TOGGLE_ESTIMATE_DESTINATION
});

const setShopLocation = shopPosition => ({
  type: SET_SHOP_LOCATION,
  shopPosition
});

const setShowPreviousPrice = show => ({
  type: SET_SHOW_PREVIOUS_PRICE,
  show
});
const setPreviousPrice = price => ({
  type: SET_PREVIOUS_PRICE,
  payload: price
});

const toggleUnexpectedErrorModal = bool => ({
  type: TOGGLE_UNEXPECTED_ERROR_MODAL,
  payload: bool
});

const toggleAlreadyStartedErrorModal = bool => ({
  type: TOGGLE_ALREADY_STARTED_ERROR_MODAL,
  payload: bool
});

const setSearchPlacesResult = result => ({
  type: SET_SEARCH_PLACES_RESULT,
  payload: result
});

const toggleCancelledByDispatchErrorModal = bool => ({
  type: TOGGLE_ORDER_CANCELLED_BY_DISPATCH_MODAL,
  payload: bool
});

const toggleDispatchWillCallModal = bool => ({
  type: TOGGLE_DISPATCH_WILL_CALL_MODAL,
  payload: bool
});

const checkPreviousVehiclePosition = () => ({
  type: CHECK_PREVIOUS_VEHICLE_POSITION
});

const cancelListenForDriver = () => ({
  type: CANCEL_LISTEN_FOR_DRIVER
});

const fetchEstimateSuccess = estimate => ({
  type: FETCH_ESTIMATE_SUCCESS,
  estimate
});

const fetchEstimateSuccessCoords = coords => ({
  type: FETCH_ESTIMATE_SUCCESS_COORDS,
  payload: coords
});

const listenForDriver = () => ({
  type: LISTEN_FOR_DRIVER
});

const fetchingVehicle = () => ({
  type: FETCHING_VEHICLE
});

const getPersonDetailsFailure = () => ({
  type: GET_PERSON_DETAILS_FAILURE
});

const getPositionSuccess = customerPosition => ({
  type: GET_POSITION_SUCCESS,
  customerPosition
});

const setHasPreviousVehiclePositionChanged = boolean => ({
  type: SET_HAS_PREVIOUS_VEHICLE_POSITION_CHANGED,
  payload: boolean
});

const setToogleEstimateDestinationValue = boolean => ({
  type: SET_TOGGLE_ESTIMATE_DESTINATION_VALUE,
  payload: boolean
});

const getVehicleDetailsSuccess = () => ({
  type: GET_VEHICLE_DETAILS_SUCCESS
});

const getVehicleDetailsFailure = () => ({
  type: GET_VEHICLE_DETAILS_FAILURE
});

const vehicleNotFoundError = () => ({
  type: ERROR_VEHICLE_NOT_FOUND
});

const setNoEstimateReason = string => ({
  type: SET_NO_ESTIMATE_REASON,
  payload: string
});

const fetchingPerson = () => ({
  type: FETCHING_PERSON
});

const fetchingPersonSuccess = () => ({
  type: FETCHING_PERSON_SUCCESS
});

const personNotFound = () => ({
  type: PERSON_NOT_FOUND
});

const fetchDamageReasons = () => ({
  type: FETCH_DAMAGE_REASONS
});

const fetchCurrentDamageReasonsSuccess = damageReasons => ({
  type: FETCH_DAMAGE_REASONS_SUCCESS,
  payload: damageReasons
});

const fetchCurrentDamageReasonsFailure = () => ({
  type: FETCH_DAMAGE_REASONS_FAILURE
});

const fetchConfinedSpaceAttributes = () => ({
  type: FETCH_CONFINED_SPACE_ATTRIBUTES
});

const fetchConfinedSpaceAttributesSuccess = confinedSpaceAttributes => ({
  type: FETCH_CONFINED_SPACE_ATTRIBUTES_SUCCESS,
  payload: confinedSpaceAttributes
});

const fetchConfinedSpaceAttributesFailure = () => ({
  type: FETCH_CONFINED_SPACE_ATTRIBUTES_FAILURE
});

const fetchPaymentOptionsList = () => ({
  type: FETCH_PAYMENT_OPTIONS
});

const fetchPaymentOptionsListSuccess = paymentOptionsList => ({
  type: FETCH_PAYMENT_OPTIONS_SUCCESS,
  payload: paymentOptionsList
});

const fetchPaymentOptionsListFailure = () => ({
  type: FETCH_PAYMENT_OPTIONS_FAILURE
});

const saveSelfServeMissionSuccess = data => ({
  type: SAVE_SELF_SERVE_MISSION_SUCCESS,
  data
});

const fetchVehicleInsuranceLevels = () => ({
  type: FETCH_VEHICLE_INSURANCE_LEVELS
});

const fetchVehicleInsuranceLevelsSuccess = vehicleInsuranceLevels => ({
  type: FETCH_VEHICLE_INSURANCE_LEVELS_SUCCESS,
  payload: vehicleInsuranceLevels
});

const fetchVehicleInsuranceLevelsFailure = () => ({
  type: FETCH_VEHICLE_INSURANCE_LEVELS_FAILURE
});

const acceptCookieValue = () => ({
  type: ACCEPT_COOKIE_VALUE
});

export default {
  clearValues,
  fetchFinalPrice,
  estimatePrice,
  estimateDestinationPrice,
  createOrder,
  submitCustomerDetailsForm,
  submitCreateOrderForm,
  deny,
  toggleNoConfirmationModal,
  login,
  setPreviousView,
  setNextView,
  setCurrentView,
  setModalView,
  modalGoBack,
  goForward,
  goBack,
  getPosition,
  fetchPerson,
  fetchVehicle,
  toggleEstimateDestination,
  setShopLocation,
  setShowPreviousPrice,
  setPreviousPrice,
  toggleAlreadyStartedErrorModal,
  toggleUnexpectedErrorModal,
  setSearchPlacesResult,
  fetchDispatch,
  toggleCancelledByDispatchErrorModal,
  toggleDispatchWillCallModal,
  checkPreviousVehiclePosition,
  cancelListenForDriver,
  fetchEstimateSuccess,
  fetchEstimateSuccessCoords,
  listenForDriver,
  fetchingVehicle,
  getPersonDetailsFailure,
  getPositionSuccess,
  setHasPreviousVehiclePositionChanged,
  setToogleEstimateDestinationValue,
  getVehicleDetailsSuccess,
  getVehicleDetailsFailure,
  vehicleNotFoundError,
  setNoEstimateReason,
  fetchingPerson,
  fetchingPersonSuccess,
  personNotFound,
  fetchDamageReasons,
  fetchCurrentDamageReasonsSuccess,
  fetchCurrentDamageReasonsFailure,
  fetchConfinedSpaceAttributes,
  fetchConfinedSpaceAttributesSuccess,
  fetchConfinedSpaceAttributesFailure,
  fetchPaymentOptionsList,
  fetchPaymentOptionsListSuccess,
  fetchPaymentOptionsListFailure,
  saveSelfServeMissionSuccess,
  fetchVehicleInsuranceLevels,
  fetchVehicleInsuranceLevelsSuccess,
  fetchVehicleInsuranceLevelsFailure,
  acceptCookieValue
};
