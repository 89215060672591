import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import { reducer as loadingReducer } from "./features/loading";
import { reducer as welcomeReducer } from "./features/welcomeScreen";
import { reducer as createOrderReducer } from "./features/createOrder";
import { reducer as activeOrderReducer } from "./features/activeOrder";
import { reducer as summaryReducer } from "./features/summary";
import { reducer as carPositionPickerReducer } from "./features/carPositionPicker";
import { reducer as dispatchPositionReducer } from "./features/dispatchPosition";

const rootReducer = combineReducers({
  form,
  loading: loadingReducer.reducer,
  position: welcomeReducer.reducer,
  createOrder: createOrderReducer.reducer,
  activeOrder: activeOrderReducer.reducer,
  summary: summaryReducer.reducer,
  carPositionPicker: carPositionPickerReducer.reducer,
  dispatchPosition: dispatchPositionReducer.reducer
});

export default rootReducer;
