import React from "react";
import "./style.css";
import { useIntl } from "react-intl";
import { useSessionStorage } from "usehooks-ts";
import { Select } from "@assistansbolaget/gat-components";
import { SESSION_KEYS } from "../../helpers/sessionStore";
import useSetCompanyInformation from "../../helpers/hooks/useSetCompanyInformation";

const TopBar = () => {
  const { logo, langOptions } = useSetCompanyInformation();
  const [locale, setLocale] = useSessionStorage(SESSION_KEYS.locale);

  const intl = useIntl();
  const getFlagIcon = lang => {
    const code = lang?.split("-")?.[1];
    return (
      <img
        src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${code}.svg`}
        alt={code}
        style={{ width: "20px", marginRight: "5px" }}
      />
    );
  };

  const createOptions = langOptions.map(option => ({
    value: option.value,
    label: (
      <>
        {getFlagIcon(option.value)}
        {option.name}
      </>
    )
  }));

  const defaultOption = createOptions.find(option => option.value === locale);

  const handleSelectLanguage = option => {
    setLocale(option.value);
  };

  return (
    <div className="top-bar flex justify-center background-none">
      <div className="top-bar-logo-name">
        <div className="top-bar-logo-container">
          {logo && <img className="top-bar-logo" src={logo} alt="logo" />}
        </div>
        <div className="topBar-dropDown">
          <Select
            onChange={handleSelectLanguage}
            options={createOptions}
            value={defaultOption}
            flex="1"
            placeholder={intl.formatMessage({
              id: "TopBar.LanguageSelector.Placeholder",
              defaultMessage: "Language",
              description: "Topbar: select language"
            })}
          />
        </div>
      </div>
    </div>
  );
};

export default TopBar;
