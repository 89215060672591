import React from "react";
import PropTypes from "prop-types";
import { Button } from "@assistansbolaget/gat-components";

import "./style.css";

const Modal = ({
  title,
  bodyText,
  primaryModalButtonAction,
  primaryModalButtonText,
  bodyText2,
  secondaryModalButtonText,
  secondaryModalButtonAction,
  children,
  windowReload,
  windowReloadAction
}) => (
  <div className="z-index-16">
    <div className="overlay-opacity" />
    <div className="overlay-container">
      <div className="modal-content">
        {title && <h3 className="modal-title">{title.toUpperCase()}</h3>}
        <div className="flex-grow modal-text">
          {bodyText && <p>{bodyText}</p>}
          {bodyText2 && <p>{bodyText2}</p>}
        </div>

        {children}

        <div className="modal-button-container">
          {secondaryModalButtonText && (
            <Button
              type="submit"
              onClick={secondaryModalButtonAction}
              fullWidth
            >
              {secondaryModalButtonText}
            </Button>
          )}
          {primaryModalButtonText && (
            <Button type="submit" onClick={primaryModalButtonAction} fullWidth>
              {primaryModalButtonText}
            </Button>
          )}
          {windowReload && (
            <Button type="submit" onClick={windowReloadAction} fullWidth>
              {windowReload}
            </Button>
          )}
        </div>
      </div>
    </div>
  </div>
);

Modal.defaultProps = {
  title: null,
  bodyText: null,
  bodyText2: null,
  primaryModalButtonAction: null,
  primaryModalButtonText: null,
  secondaryModalButtonText: null,
  secondaryModalButtonAction: null,
  windowReload: null,
  windowReloadAction: null
};

Modal.propTypes = {
  title: PropTypes.string,
  bodyText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  bodyText2: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  primaryModalButtonAction: PropTypes.func,
  primaryModalButtonText: PropTypes.string,
  windowReload: PropTypes.string,
  windowReloadAction: PropTypes.func,
  secondaryModalButtonAction: PropTypes.func,
  secondaryModalButtonText: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default Modal;
