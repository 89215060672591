import { call, select, takeLatest } from "redux-saga/effects";
import { getFormValues } from "redux-form";
import activeOrderSelector from "../../activeOrder/selectors";
import selector from "../selectors";
import service from "./legacyApi";
import logErrorHelper from "../../../helpers/logErrorHelper";
import { LEGACY_SUMMARY_SUBMITTED } from "../constants";

const { getOrder } = activeOrderSelector;
const { getRating } = selector;
const { postOpinion } = service;

function* legacySubmitSummaryWorker() {
  try {
    const order = yield select(getOrder);
    const opinionFormValue = yield select(getFormValues("receiptForm"));
    const rating = yield select(getRating);
    let summaryBody;
    if (opinionFormValue.message) {
      summaryBody = {
        request_id: order.id,
        rating,
        comment: opinionFormValue.message
      };
    } else {
      summaryBody = {
        request_id: order.id,
        rating
      };
    }
    yield call(postOpinion, summaryBody);
  } catch (error) {
    logErrorHelper(error);
  }
}

function* legacySummarySagaWatcher() {
  yield takeLatest(LEGACY_SUMMARY_SUBMITTED, legacySubmitSummaryWorker);
}

export default { legacySummarySagaWatcher };
