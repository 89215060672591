import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  GET_POSITION_DISPATCH,
  SAVE_DESTINATION_CHANGER,
  SAVE_DISPATCH_CENTER_COORDS,
  UPDATE_ORDER_POSITION
} from "./constants";
import { service as createOrderService } from "../createOrder";
import welcomeScreenActions from "../welcomeScreen/actions";
import selectors from "./selectors";
import toggleLoading from "../loading/actions";
import actions from "./actions";
import creatOrderActions from "../createOrder/actions";
import welcomeScreenSelectors from "../welcomeScreen/selectors";
import service from "./service";
import updateServePositionAddressHelper from "../../helpers/updatePostionToServeComponentsHelper";

const {
  toggleCarLocalizationErrorModal,
  toggleDispatchPositionSuccessModal,
  showDispatchMap,
  toggleLocationBlockedModal
} = actions;
const {
  setVehicleLocation,
  fetchingReverseGeocode,
  setPositionToServe
} = welcomeScreenActions;
const { getVehiclePosition, getServeVehiclePosition } = welcomeScreenSelectors;
const { getPositionSuccess } = creatOrderActions;

const { getSearchPlacesResult } = selectors;

const { setDestination } = service;

const {
  getLocation,
  reverseGeoCode,
  updateCustomerPosition
} = createOrderService;

function* getPositionWorker() {
  try {
    yield put(toggleLoading(true));
    const position = yield call(getLocation);
    yield put(toggleLocationBlockedModal(false));
    if (position) {
      yield all([
        put(
          getPositionSuccess({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            accuracy: position.coords.accuracy
          })
        ),
        put(showDispatchMap(true)),
        put(toggleLoading(false))
      ]);
    }
  } catch (error) {
    yield all([
      put(toggleLocationBlockedModal(true)),
      put(toggleLoading(false))
    ]);
  }
}

function* saveVehicleAddressWorker(action) {
  try {
    if (action.payload) {
      const searchPlacesResult = yield select(getSearchPlacesResult);
      if (
        searchPlacesResult &&
        Number.parseFloat(searchPlacesResult.lat).toFixed(4) ===
          Number.parseFloat(action.payload.lat).toFixed(4) &&
        Number.parseFloat(searchPlacesResult.lng).toFixed(4) ===
          Number.parseFloat(action.payload.lng).toFixed(4)
      ) {
        const serveAddress = updateServePositionAddressHelper(
          searchPlacesResult
        );
        yield put(setVehicleLocation(searchPlacesResult));
        yield put(
          setPositionToServe({
            lat: searchPlacesResult.lat,
            lng: searchPlacesResult.lng,
            address: serveAddress.streetAddress,
            city: serveAddress.postalTown,
            zipCode: serveAddress.postalCode
          })
        );
      } else {
        yield put(fetchingReverseGeocode(true));
        const res = yield call(reverseGeoCode, action.payload);
        yield put(fetchingReverseGeocode(false));
        if (res) {
          yield put(
            setVehicleLocation({
              lat: action.payload.lat,
              lng: action.payload.lng,
              address: res.address || res.formatted_address,
              city: res.town,
              zipCode: res.zipcode
            })
          );
          yield put(
            setPositionToServe({
              lat: action.payload.lat,
              lng: action.payload.lng,
              address: res.address || res.formatted_address,
              city: res.town,
              zipCode: res.zipcode
            })
          );
        }
      }
    }
  } catch (error) {
    yield put(toggleCarLocalizationErrorModal(true));
  }
}

function* saveDestinationChangerWorker(action) {
  try {
    if (action.payload) {
      const searchPlacesResult = yield select(getSearchPlacesResult);
      if (
        searchPlacesResult &&
        Number.parseFloat(searchPlacesResult.lat).toFixed(4) ===
          Number.parseFloat(action.payload.lat).toFixed(4) &&
        Number.parseFloat(searchPlacesResult.lng).toFixed(4) ===
          Number.parseFloat(action.payload.lng).toFixed(4)
      ) {
        const body = {
          latitude: searchPlacesResult.lat,
          longitude: searchPlacesResult.lng,
          address: searchPlacesResult.address,
          zipCode: searchPlacesResult.zipCode,
          city: searchPlacesResult.city,
          name: searchPlacesResult.address
        };
        yield call(setDestination, body);
      } else {
        yield put(fetchingReverseGeocode(true));
        const res = yield call(reverseGeoCode, action.payload);
        yield put(fetchingReverseGeocode(false));
        if (res) {
          const body = {
            latitude: action.payload.lat,
            longitude: action.payload.lng,
            address: res?.address || res?.formatted_address || "",
            zipCode: res.zipcode || "",
            city: res.city || res.town || ""
          };
          yield call(setDestination, body);
        }
      }
    }
  } catch (error) {
    yield put(toggleCarLocalizationErrorModal(true));
  }
}

// eslint-disable-next-line consistent-return
function* updateOrderWorker() {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    if (!token) {
      throw Error("UpdateOrderWorker error: Missing orderNo");
    }
    const positionToServe = yield select(getServeVehiclePosition);
    const vehiclePosition = yield select(getVehiclePosition);
    const vehicleFullAddress = vehiclePosition.address;
    if (!vehicleFullAddress) {
      throw Error("UpdateOrderWorker error: Missing vehicleAddress");
    }
    if (!vehiclePosition || !vehiclePosition.lat) {
      throw Error("UpdateOrderWorker error: Missing vehiclePosition values");
    }
    yield put(toggleLoading(true));
    const response = yield call(updateCustomerPosition, {
      token,
      positionToServe
    });
    if (response > 299) {
      return yield all([
        put(toggleLoading(false)),
        put(toggleCarLocalizationErrorModal(true))
      ]);
    }
    return yield all([
      put(toggleLoading(false)),
      put(toggleDispatchPositionSuccessModal(true))
    ]);
  } catch (error) {
    yield all([
      put(toggleLoading(false)),
      put(toggleCarLocalizationErrorModal(true))
    ]);
  }
}

function* getPositionWatcher() {
  yield takeLatest(GET_POSITION_DISPATCH, getPositionWorker);
}
function* saveCenterCoordsWatcher() {
  yield takeLatest(SAVE_DISPATCH_CENTER_COORDS, saveVehicleAddressWorker);
}
function* updateOrderPositionWatcher() {
  yield takeLatest(UPDATE_ORDER_POSITION, updateOrderWorker);
}

function* saveDestinationChangerWatcher() {
  yield takeLatest(SAVE_DESTINATION_CHANGER, saveDestinationChangerWorker);
}

export default {
  getPositionWatcher,
  saveCenterCoordsWatcher,
  updateOrderPositionWatcher,
  saveDestinationChangerWatcher
};
