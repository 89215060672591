import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { faTruckTow } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { injectIntl } from "react-intl";
import selectors from "./selectors";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import CardBoxContainer from "../../components/CardBoxContainer/CardBoxContainer";

const {
  getEstimatedArrivalTimeText,
  getIsEstimatedArrivalTimeOutdated
} = selectors;

const OrderOnWay = ({
  company,
  driver,
  timeText,
  towTruckRegNo,
  etaOutdated,
  intl
}) => {
  return (
    <>
      <CardBoxContainer minimumHeight={etaOutdated ? "175px" : "120px"}>
        <div className="driver-onRoute">
          <div className="driver-onRoute-title">
            <FontAwesomeIcon
              icon={faTruckTow}
              color="#FFD4A3"
              style={{ width: "2rem", height: "2rem" }}
            />
            <h3>
              {intl.formatMessage({
                id: "ActiveOrder.OrderOnWay.Title",
                defaultMessage: "We are on our way",
                description: "ActiveOrder: orderOnWay title"
              })}
            </h3>
          </div>
          <div className="driver-onRoute-firstInfoText">
            <p>
              {intl.formatMessage({
                id: "ActiveOrder.OrderOnWay.InfoTextDriver",
                defaultMessage: "Our driver",
                description: "ActiveOrder: orderOnWay driver text"
              })}
              <strong>{` ${driver} `}</strong>
              {intl.formatMessage({
                id: "ActiveOrder.OrderOnWay.InfoTextFrom",
                defaultMessage: "from",
                description: "ActiveOrder: orderOnWay from text"
              })}
              <strong>{` ${company} `}</strong>
              {intl.formatMessage({
                id: "ActiveOrder.OrderOnWay.InfoBreadText",
                defaultMessage: "is on their way to help you.",
                description: "ActiveOrder: orderOnWay bread text"
              })}
            </p>
          </div>
          <div className="driver-onRoute-secondInfoText">
            <p>
              {intl.formatMessage({
                id: "ActiveOrder.OrderOnWay.InfoTextRegistrationNumber",
                defaultMessage: "The registration number of the tow truck is",
                description: "ActiveOrder: orderOnWay registration number text"
              })}
              <strong>{` ${towTruckRegNo}`}</strong>
            </p>
          </div>
        </div>
        <div className="driver-onRoute-timeTextContainer">
          <span>
            {intl.formatMessage({
              id: "ActiveOrder.OrderOnWay.InfoTextETA",
              defaultMessage: "Time to arrival",
              description: "ActiveOrder: orderOnWay ETA text"
            })}
          </span>
          {!timeText ? (
            <div className="order-onRoute-timeText">
              <LoadingSpinner
                loading
                positionStyle={{
                  position: "initial",
                  background: "none",
                  width: "0",
                  height: "0",
                  marginBottom: "1.6rem"
                }}
                sizeStyle={{ width: "20px", height: "25px" }}
              />
            </div>
          ) : (
            <div className="order-onRoute-timeText">
              <span
                className={classNames({
                  "outdated-eta": etaOutdated
                })}
              >
                {timeText}
              </span>
            </div>
          )}
        </div>
        {etaOutdated && (
          <div className="center-text outdated-eta-text">
            {intl.formatMessage({
              id: "ActiveOrder.OrderOnWay.EtaOutdatedText",
              defaultMessage: "ETA is outdated, and may not be accurate.",
              description: "ActiveOrder: orderOnWay ETA outdated text"
            })}
          </div>
        )}
      </CardBoxContainer>
    </>
  );
};

OrderOnWay.propTypes = {
  company: PropTypes.string.isRequired,
  driver: PropTypes.string.isRequired,
  towTruckRegNo: PropTypes.string.isRequired,
  timeText: PropTypes.string.isRequired,
  etaOutdated: PropTypes.bool.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired
};

const mapStateToProps = state => ({
  timeText: getEstimatedArrivalTimeText(state),
  etaOutdated: getIsEstimatedArrivalTimeOutdated(state)
});

export default injectIntl(connect(mapStateToProps, null)(OrderOnWay));
