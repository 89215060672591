const getPreviousEstimatePrice = ({ createOrder }) =>
  createOrder.getIn(["previousEstimate", "price"]);

const getShowDispatchMap = ({ dispatchPosition }) =>
  dispatchPosition.get("showDispatchMap");

const getMap = ({ dispatchPosition }) => dispatchPosition.get("map");

const getCenterCoords = ({ dispatchPosition }) =>
  dispatchPosition.get("centerCoords");

const getOrderNo = ({ dispatchPosition }) => dispatchPosition.get("orderNo");

const getCarLocalizationErrorModalState = ({ dispatchPosition }) =>
  dispatchPosition.get("carLocalizationErrorModalState");

const getCarLocalizationSuccessModalState = ({ dispatchPosition }) =>
  dispatchPosition.get("carLocalizationSuccessModalState");

const getSearchPlacesResult = ({ dispatchPosition }) =>
  dispatchPosition.get("searchPlacesResult");

const getLocationBlockedModalState = ({ dispatchPosition }) =>
  dispatchPosition.get("locationBlockedModalState");

export default {
  getPreviousEstimatePrice,
  getShowDispatchMap,
  getMap,
  getCenterCoords,
  getOrderNo,
  getCarLocalizationErrorModalState,
  getCarLocalizationSuccessModalState,
  getSearchPlacesResult,
  getLocationBlockedModalState
};
