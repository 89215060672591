import { all } from "redux-saga/effects";
import { sagas as welcomeSaga } from "./features/welcomeScreen";
import { sagas as createOrderSaga } from "./features/createOrder";
import { sagas as activeOrderSaga } from "./features/activeOrder";
import { sagas as summarySaga } from "./features/summary";
import { sagas as dispatchPositionSaga } from "./features/dispatchPosition";
import { sagas as legacySummarySaga } from "./features/summary/LegacyReciept";

function* rootSaga() {
  yield all([
    welcomeSaga.resumeOrderWatcher(),
    createOrderSaga.fetchTokenWatcher(),
    createOrderSaga.getPositionWatcher(),
    createOrderSaga.createOrderWatcher(),
    createOrderSaga.estimatePriceWatcher(),
    createOrderSaga.estimatePriceDestinationWatcher(),
    createOrderSaga.fetchFinalPriceWatcher(),
    createOrderSaga.listenForDriverWatcher(),
    createOrderSaga.loginWatcher(),
    createOrderSaga.denyWatcher(),
    createOrderSaga.valuesChangedWatcher(),
    createOrderSaga.submitCustomerDetailsWatcher(),
    createOrderSaga.submitCreateOrderFormWatcher(),
    createOrderSaga.getVehicleWatcher(),
    createOrderSaga.fetchPersonWatcher(),
    createOrderSaga.saveCenterCoordsWatcher(),
    createOrderSaga.fetchDispatchWatcher(),
    createOrderSaga.hasPreviousVehiclePositionChangedWatcher(),
    createOrderSaga.fetchCurrentDamageReasonsWatcher(),
    createOrderSaga.fetchConfinedSpaceAttributesWatcher(),
    createOrderSaga.fetchPaymentOptionsWatcher(),
    createOrderSaga.fetchVehicleInsuranceLevelsWatcher(),
    activeOrderSaga.findCurrentViewWatcher(),
    activeOrderSaga.cancelOrderWatcher(),
    activeOrderSaga.towTruckPositionWatcher(),
    activeOrderSaga.getEstimatedArrivalTimeWatcher(),
    activeOrderSaga.fetchGatMissionWatcher(),
    summarySaga.summarySagaWatcher(),
    legacySummarySaga.legacySummarySagaWatcher(),
    summarySaga.registerEmailSagaWatcher(),
    dispatchPositionSaga.getPositionWatcher(),
    dispatchPositionSaga.saveCenterCoordsWatcher(),
    dispatchPositionSaga.updateOrderPositionWatcher(),
    dispatchPositionSaga.saveDestinationChangerWatcher()
  ]);
}

export default rootSaga;
