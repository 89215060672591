export const WELCOME_SCREEN_PATH = "/";
export const OLD_CREATE_ORDER_PATH = "/createOrder";
export const CREATE_ORDER_PATH = "/";
export const ACTIVE_ORDER_PATH = "/activeOrder";
export const SUMMARY_PATH = "/summary";
export const LOGIN_PATH = "/login";
export const DISPATCH_POSITION_PATH = "/dispatchPosition";

export const STARTED = "STARTED";
export const ARRIVED = "ARRIVED";
export const PREPARE_TRIP_TO_WORKSHOP = "PREPARE_TRIP_TO_WORKSHOP";
export const PICKEDUP = "PICKEDUP";
export const DROPPED = "DROPPED";
export const PENDING = "PENDING";
export const COMPLETED = "COMPLETED";

export const STOCKHOLM_SUBDOMAIN = "stockholm";
export const GOTEBORG_SUBDOMAIN = "goteborg";
export const MALMO_SUBDOMAIN = "malmo";
