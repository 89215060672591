export const CREATE_ORDER_FORM_SUBMITTED = "CREATE_ORDER_FORM_SUBMITTED";
export const CUSTOMER_DETAILS_FORM_SUBMITTED =
  "CUSTOMER_DETAILS_FORM_SUBMITTED";
export const CREATE_ORDER = "CREATE_ORDER";
export const DECREMENT_STEP = "DECREMENT_STEP";
export const DENY_HELP = "DENY_HELP";
export const ERROR_VEHICLE_NOT_FOUND = "ERROR_VEHICLE_NOT_FOUND";
export const SET_NO_ESTIMATE_REASON = "SET_NO_ESTIMATE_REASON";
export const ESTIMATE_PRICE_DESTINATION = "ESTIMATE_PRICE_DESTINATION";
export const ESTIMATE_PRICE = "ESTIMATE_PRICE";
export const FETCH_DISPATCH = "FETCH_DISPATCH";
export const FETCH_ESTIMATE_SUCCESS = "FETCH_ESTIMATE_SUCCESS";
export const FETCH_ESTIMATE = "FETCH_ESTIMATE";
export const FETCH_FINAL_PRICE_FAILURE = "FETCH_FINAL_PRICE_FAILURE";
export const FETCH_FINAL_PRICE_SUCCESS = "FETCH_FINAL_PRICE_SUCCESS";
export const FETCH_FINAL_PRICE = "FETCH_FINAL_PRICE";
export const FETCH_PERSON = "FETCH_PERSON";
export const FETCH_VEHICLE_FAILURE = "FETCH_VEHICLE_FAILURE";
export const FETCH_VEHICLE_SUCCESS = "FETCH_VEHICLE_SUCCESS";
export const FETCH_VEHICLE = "FETCH_VEHICLE";
export const FETCHING_PERSON = "FETCHING_PERSON";
export const FETCHING_VEHICLE = "FETCHING_VEHICLE";
export const GET_PERSON_DETAILS_FAILURE = "GET_PERSON_DETAILS_FAILURE";
export const FETCHING_PERSON_SUCCESS = "FETCHING_PERSON_SUCCESS";
export const GET_POSITION_FAILURE = "GET_POSITION_FAILURE";
export const GET_POSITION_SUCCESS = "GET_POSITION_SUCCESS";
export const GET_POSITION = "GET_POSITION";
export const GET_VEHICLE_DETAILS_FAILURE = "GET_VEHICLE_DETAILS_FAILURE";
export const GET_VEHICLE_DETAILS_SUCCESS = "GET_VEHICLE_DETAILS_SUCCESS";
export const GET_VEHICLE_DETAILS = "GET_VEHICLE_DETAILS";
export const GO_BACK = "GO_BACK";
export const GO_FORWARD = "GO_FORWARD";
export const INCREASE_STEP = "INCREASE_STEP";
export const LISTEN_FOR_DRIVER_FAILURE = "LISTEN_FOR_DRIVER_FAILURE";
export const LISTEN_FOR_DRIVER = "LISTEN_FOR_DRIVER";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN = "LOGIN";
export const PERSON_NOT_FOUND = "PERSON_NOT_FOUND";
export const SET_CURRENT_VIEW = "SET_CURRENT_VIEW";
export const SET_NEXT_VIEW = "SET_NEXT_VIEW";
export const SET_PREVIOUS_PRICE = "SET_PREVIOUS_PRICE";
export const SET_MODAL_VIEW = "SET_MODAL_VIEW";
export const MODAL_GO_BACK = "MODAL_GO_BACK";
export const SET_PREVIOUS_VIEW = "SET_PREVIOUS_VIEW";
export const SET_SEARCH_PLACES_RESULT = "SET_SEARCH_PLACES_RESULT";
export const SET_SHOP_LOCATION = "SET_SHOP_LOCATION";
export const SET_SHOW_PREVIOUS_PRICE = "SET_SHOW_PREVIOUS_PRICE";
export const SET_STEP = "SET_STEP";
export const START_FETCH_FINAL_PRICE = "START_FETCH_FINAL_PRICE";
export const TOGGLE_ALREADY_STARTED_ERROR_MODAL =
  "TOGGLE_ALREADY_STARTED_ERROR_MODAL";
export const TOGGLE_DISPATCH_WILL_CALL_MODAL =
  "TOGGLE_DISPATCH_WILL_CALL_MODAL";
export const TOGGLE_ESTIMATE_DESTINATION = "TOGGLE_ESTIMATE_DESTINATION";
export const TOGGLE_NO_CONFIRMATION_MODAL = "TOGGLE_NO_CONFIRMATION_MODAL";
export const TOGGLE_ORDER_CANCELLED_BY_DISPATCH_MODAL =
  "TOGGLE_ORDER_CANCELLED_BY_DISPATCH_MODAL";
export const TOGGLE_UNEXPECTED_ERROR_MODAL = "TOGGLE_UNEXPECTED_ERROR_MODAL";
export const VALUES_CHANGED = "VALUES_CHANGED";
export const CHECK_PREVIOUS_VEHICLE_POSITION =
  "CHECK_PREVIOUS_VEHICLE_POSITION";
export const SET_HAS_PREVIOUS_VEHICLE_POSITION_CHANGED =
  "SET_HAS_PREVIOUS_VEHICLE_POSITION_CHANGED";
export const FETCH_ESTIMATE_SUCCESS_COORDS = "FETCH_ESTIMATE_SUCCESS_COORDS";
export const SET_TOGGLE_ESTIMATE_DESTINATION_VALUE =
  "SET_TOGGLE_ESTIMATE_DESTINATION_VALUE";
export const FETCH_TOKEN = "FETCH_TOKEN";
export const CANCEL_LISTEN_FOR_DRIVER = "CANCEL_LISTEN_FOR_DRIVER";

export const TOO_HEAVY = "Vehicle too heavy for assistance";

export const FETCH_DAMAGE_REASONS = "FETCH_DAMAGE_REASONS";
export const FETCH_DAMAGE_REASONS_SUCCESS = "FETCH_DAMAGE_REASONS_SUCCESS";
export const FETCH_DAMAGE_REASONS_FAILURE = "FETCH_DAMAGE_REASONS_FAILURE";

export const FETCH_CONFINED_SPACE_ATTRIBUTES =
  "FETCH_CONFINED_SPACE_ATTRIBUTES";
export const FETCH_CONFINED_SPACE_ATTRIBUTES_SUCCESS =
  "FETCH_CONFINED_SPACE_ATTRIBUTES_SUCCESS";
export const FETCH_CONFINED_SPACE_ATTRIBUTES_FAILURE =
  "FETCH_CONFINED_SPACE_ATTRIBUTES_FAILURE";

export const FETCH_PAYMENT_OPTIONS = "FETCH_PAYMENT_OPTIONS";
export const FETCH_PAYMENT_OPTIONS_SUCCESS = "FETCH_PAYMENT_OPTIONS_SUCCESS";
export const FETCH_PAYMENT_OPTIONS_FAILURE = "FETCH_PAYMENT_OPTIONS_FAILURE";

export const SAVE_SELF_SERVE_MISSION_SUCCESS =
  "SAVE_SELF_SERVE_MISSION_SUCCESS";

export const FETCH_VEHICLE_INSURANCE_LEVELS = "FETCH_VEHICLE_INSURANCE_LEVELS";
export const FETCH_VEHICLE_INSURANCE_LEVELS_SUCCESS =
  "FETCH_VEHICLE_INSURANCE_LEVELS_SUCCESS";
export const FETCH_VEHICLE_INSURANCE_LEVELS_FAILURE =
  "FETCH_VEHICLE_INSURANCE_LEVELS_FAILURE";

export const ACCEPT_COOKIE_VALUE = "ACCEPT_COOKIE_VALUE";
