import React from "react";
import { Button } from "@assistansbolaget/gat-components";
import { useIntl } from "react-intl";
import { useSessionStorage } from "usehooks-ts";
import CardBoxContainer from "../../components/CardBoxContainer/CardBoxContainer";
import { SESSION_KEYS } from "../../helpers/sessionStore";

const OrderComplete = () => {
  const [companyAttributes] = useSessionStorage(
    SESSION_KEYS.companyAttributes,
    undefined
  );

  const phoneNumber = companyAttributes?.CompanyPhone?.value;
  const intl = useIntl();
  return (
    <>
      <CardBoxContainer disableToggle minimumHeight="80px">
        <h3 className="marginBottom20">
          {intl.formatMessage({
            id: "ActiveOrder.OrderComplete.Title",
            defaultMessage: "Mission is completed",
            description: "orderComplete: OrderComplete Title"
          })}
        </h3>
        <div className="center-text marginBottom20">
          <p>
            {intl.formatMessage({
              id: "ActiveOrder.OrderComplete.BreadTextOne",
              defaultMessage:
                "Thank you for choosing us. We hope you enjoyed our assistance.",
              description: "orderComplete: OrderComplete BreadTextOne"
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: "ActiveOrder.OrderComplete.BreadTextTwo",
              defaultMessage:
                "If you have further questions, please contact our support.",
              description: "orderComplete: OrderComplete BreadTextTwo"
            })}
          </p>
        </div>
      </CardBoxContainer>
      <a
        href={`tel: ${phoneNumber}`}
        className="activeOrderCallSupport card-button flex justify-center"
      >
        <Button
          fullWidth
          kind="Solid"
          type="submit"
          className="button button-primary"
          style={{ width: "376px" }}
        >
          {intl
            .formatMessage({
              id: "ActiveOrder.OrderPickedUp.CallSupport",
              defaultMessage: "Call support",
              description: "call support button"
            })
            .toUpperCase()}
        </Button>
      </a>
    </>
  );
};

export default OrderComplete;
