import {
  BaseUrl,
  UPDATE_MISSION_DESTINATION
} from "../../constants/apiConstants";
import api from "../../helpers/apiUtils";
import { getSession, SESSION_KEYS } from "../../helpers/sessionStore";

const setDestination = body => {
  const gatOrgUnit = getSession(SESSION_KEYS.gatOrganizationUnit);
  const gatLocale = getSession(SESSION_KEYS.locale);
  const accessToken = getSession(SESSION_KEYS.accessToken);
  const missionId = getSession(SESSION_KEYS.missionId);
  const appId = getSession(SESSION_KEYS.appId);
  return api
    .post(
      `${UPDATE_MISSION_DESTINATION}`,
      BaseUrl.GatUrl,
      { missionId, ...body },
      {
        gatOrgUnit,
        gatLocale,
        accessToken,
        appId
      }
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      throw err;
    });
};

export default { setDestination };
