const getOrder = ({ activeOrder }) => activeOrder.get("order");

const getStartedOrderModalState = ({ activeOrder }) =>
  activeOrder.get("startedOrderModal");

const getCancelModalState = ({ activeOrder }) =>
  activeOrder.get("cancelModalOpen");
const getTowCancelModalState = ({ activeOrder }) =>
  activeOrder.get("towCancelModalOpen");

const getCosts = ({ activeOrder }) => activeOrder.getIn(["order", "costs"]);

const getOriginTowingPrice = ({ activeOrder }) =>
  activeOrder.getIn(["order", "origin_towing_price"]);

const getOriginAssistancePrice = ({ activeOrder }) =>
  activeOrder.getIn(["order", "origin_assistance_price"]);

const getEstimatedArrivalTimeText = ({ activeOrder }) =>
  activeOrder.getIn(["estimatedArrivalTime", "timeText"]);

const getIsEstimatedArrivalTimeOutdated = ({ activeOrder }) =>
  activeOrder.getIn(["estimatedArrivalTime", "outdatedEta"]);

const getEstimatedArrivalTimeSeconds = ({ activeOrder }) =>
  activeOrder.getIn(["estimatedArrivalTime", "timeSeconds"]);

const getCountDownTime = ({ activeOrder }) => activeOrder.get("countDownTime");

const getTimeSinceLastCall = ({ activeOrder }) =>
  activeOrder.get("timeSinceLastCall");

const getUnexpectedErrorModalState = ({ activeOrder }) =>
  activeOrder.get("unexpectedErrorModalState");

const getPreventResumeOrderState = ({ activeOrder }) =>
  activeOrder.get("preventResumeOrder");

export default {
  getOrder,
  getCancelModalState,
  getStartedOrderModalState,
  getTowCancelModalState,
  getCosts,
  getOriginTowingPrice,
  getOriginAssistancePrice,
  getEstimatedArrivalTimeSeconds,
  getCountDownTime,
  getTimeSinceLastCall,
  getUnexpectedErrorModalState,
  getPreventResumeOrderState,
  getEstimatedArrivalTimeText,
  getIsEstimatedArrivalTimeOutdated,
};
