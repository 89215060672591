import legacyApi, { legacyGetBaseUrl } from "../../../helpers/legacyApiHelper";
import { BaseUrl, GET } from "../../../constants/apiConstants";
import services from "../service";

const MISSIONS_SUMMARY = "v1/missions/summary";
const USER_ASSISTANCE_RATE = "user/assistance/rate";

const getSummary = damageOrderNumber => {
  const url = `${legacyGetBaseUrl(
    BaseUrl.AbUrl
  )}${MISSIONS_SUMMARY}?damageOrderNumber=${damageOrderNumber}`;
  return fetch(url, { method: GET })
    .then(res => {
      return res.json();
    })
    .catch(err => {
      throw err;
    });
};

const postOpinion = async body => {
  const urlParams = new URLSearchParams(window.location.search);

  const params = {
    id: urlParams.get("id"),
    regno: urlParams.get("regno"),
    phone: urlParams.get("phone")
  };
  const dispatchToken = await services.fetchToken({
    mobile: params.phone,
    regno: params.regno
  });

  return legacyApi
    .post(
      `${USER_ASSISTANCE_RATE}`,
      BaseUrl.RescueUrl,
      {
        rating: body.rating,
        request_id: body.request_id,
        comment: body.comment
      },
      dispatchToken
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      throw err;
    });
};

export default { getSummary, postOpinion };
