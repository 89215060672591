import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Button } from "@assistansbolaget/gat-components";
import {
  faClock,
  faCalendar,
  faMessageCheck
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIntl } from "react-intl";
import { useSessionStorage } from "usehooks-ts";
import Rate from "../rate";
import selectors from "../selectors";
import validators from "../validators";
import CardBoxContainer from "../../../components/CardBoxContainer/CardBoxContainer";
import styles from "./receipt.module.scss";
import arrowDown from "../../../assets/images/arrow-down-outline.svg";
import StaticMap from "../../map/static/staticMap";
import { formatHoursAndMinutes, formatYYYYMMDD } from "../../../helpers/date";
import PDF from "./pdf/pdf";
import { SESSION_KEYS } from "../../../helpers/sessionStore";
import LegacyTextArea from "../../../components/TextArea/LegacyTextArea";

const { validateEmail } = validators;

const { getRating, getSummarySuccessModalState } = selectors;

const validate = values => {
  const errors = {};
  errors.email = validateEmail(values.email);
  return errors;
};

let Receipt = ({ order, onOpinionClick, rating, summarySuccessModalState }) => {
  const [companyAttributes] = useSessionStorage(
    SESSION_KEYS.companyAttributes,
    undefined
  );

  const intl = useIntl();

  const combinedSourceAddress = `${order?.driverToSource?.address ||
    ""}, ${order?.driverToSource?.zipCode || ""} ${order?.driverToSource
    ?.city || ""}`;

  const combinedDestinationAddress = `${order?.sourceToDestination?.coordinate
    ?.name || ""} ${order?.sourceToDestination?.address || ""}, ${order
    ?.sourceToDestination?.zipCode || ""} ${order?.sourceToDestination?.city ||
    ""}`;

  const missionDate = order?.missionDate;

  const createMapMarkers = () => {
    const markers = [];

    if (order?.driverToSource?.latitude) {
      markers.push({
        icon:
          "https://assets-media-smashtechsoftware.s3.eu-central-1.amazonaws.com/sourceLocationPng.png",
        lat: order.driverToSource.latitude,
        long: order.driverToSource.longitude
      });
    }

    if (order?.sourceToDestination?.latitude) {
      markers.push({
        icon:
          "https://assets-media-smashtechsoftware.s3.eu-central-1.amazonaws.com/ico_repair_destination.png",
        lat: order.sourceToDestination.latitude,
        long: order.sourceToDestination.longitude
      });
    }

    return markers;
  };

  const renderOrderLines = orderLines => {
    return orderLines.map(orderLineItem => (
      <div key={orderLineItem.id} className={styles.cost}>
        <span>{orderLineItem?.articleName}</span>
        <span>
          {orderLineItem?.total + orderLineItem?.calculatedVAT}{" "}
          {order?.currency}
        </span>
      </div>
    ));
  };

  const renderCostSummary = () => {
    if (!order) {
      return null;
    }

    const { orders, currency } = order;

    const finishedOrders = orders?.filter(item => item?.status === "Finished");

    const totalCost = finishedOrders?.reduce((accumulator, orderLine) => {
      const orderLineCost = orderLine.orderLines.reduce(
        (subtotal, orderLineItem) => {
          return subtotal + orderLineItem?.total + orderLineItem?.calculatedVAT;
        },
        0
      );
      return accumulator + orderLineCost;
    }, 0);

    return (
      <div className={styles.costSummary}>
        {finishedOrders?.map(orderLine => (
          <div key={orderLine.id} className={styles.orderLinesCost}>
            {renderOrderLines(orderLine?.orderLines)}
          </div>
        ))}
        <div className={styles.cost}>
          <span className={styles?.totalCostHeader}>
            {intl.formatMessage({
              id: "general.total",
              defaultMessage: "Total",
              description: "Receipt: total cost header"
            })}
          </span>
          <span className={styles.totalCostHeader}>
            {totalCost || 0} {currency}
          </span>
        </div>
      </div>
    );
  };

  const checkDestination = !!order?.sourceToDestination?.latitude;

  const organizationUnitName = companyAttributes?.CompanyName?.value;

  const triggers = order?.triggers;

  const [isMissionReviewEnabled, setIsMissionReviewEnabled] = React.useState(
    false
  );

  React.useEffect(() => {
    if (triggers) {
      const findTriggerForReview = triggers?.find(
        trigger => trigger?.trigger === "CreateMissionReview"
      );

      setIsMissionReviewEnabled(findTriggerForReview?.isComplete);
    }
  }, [triggers]);

  return (
    <div className={styles.container}>
      <CardBoxContainer setDarkStyle disableToggle>
        <div className={styles.textBox}>
          <p className={styles.header}>
            {intl.formatMessage({
              id: "Summary.Receipt.Header",
              defaultMessage: "Thank you for using",
              description: "Receipt: thank you for using text"
            })}{" "}
            {organizationUnitName}
          </p>
          <p className={styles.text}>
            {intl.formatMessage({
              id: "Summary.Receipt.BreadText",
              defaultMessage: "We hope you are satisfied with the assistance.",
              description: "Receipt: bread text"
            })}
          </p>
        </div>
        <span className={styles.rating}>
          {isMissionReviewEnabled || summarySuccessModalState ? (
            <div className={styles.orderReviewed}>
              <p>
                {intl.formatMessage({
                  id: "Summary.ReviewedMission.Title",
                  defaultMessage: "Thanks for your feedback",
                  description: "Summary: title for reviewed mission"
                })}
              </p>
              <FontAwesomeIcon
                icon={faMessageCheck}
                color="#ffd4a3"
                size="2x"
              />
            </div>
          ) : (
            <>
              <Rate />
              {rating > 0 && (
                <div className={styles.textAreaContainer}>
                  <Field
                    name="message"
                    component={LegacyTextArea}
                    type="text"
                    label={intl.formatMessage({
                      id: "Summary.Rating.Label",
                      defaultMessage: "Rate our assistance",
                      description: "Receipt: title for review box"
                    })}
                  />
                </div>
              )}
              <Button
                type="submit"
                onClick={onOpinionClick}
                fullWidth
                disabled={rating === 0}
              >
                {intl.formatMessage({
                  id: "Summary.Receipt.RatingButton",
                  defaultMessage: "Rate us",
                  description: "Receipt: rate us button"
                })}
              </Button>
            </>
          )}
        </span>

        <div className={styles.receipt}>
          <h4 className={styles.caption}>
            {intl.formatMessage({
              id: "Summary.Receipt.Title",
              defaultMessage: "Summary for the assistance",
              description: "Receipt: title for the assistance"
            })}
          </h4>
          {missionDate && (
            <>
              <div className={styles.textWithIcon}>
                <span className={styles.icon}>
                  <FontAwesomeIcon icon={faCalendar} color="#ffd4a3" />
                  <p>{formatYYYYMMDD(missionDate)}</p>
                </span>
                <span className={styles.icon}>
                  <FontAwesomeIcon icon={faClock} color="#ffd4a3" size="sm" />
                  <p>{formatHoursAndMinutes(missionDate)}</p>
                </span>
              </div>
            </>
          )}
          <div className={styles.timeStamps}>
            <div className={styles.damageLocations}>
              <div className={styles.damageLocationsText}>
                <span className={styles.text}>{combinedSourceAddress}</span>
              </div>
            </div>
            {checkDestination && (
              <>
                <div className={styles.textWithIcon}>
                  <img
                    src={arrowDown}
                    alt="path"
                    className={styles.arrowIcon}
                  />
                </div>

                <div className={styles.damageLocations}>
                  <div className={styles.damageLocationsText}>
                    <span className={styles.text}>
                      {combinedDestinationAddress}
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <StaticMap markers={createMapMarkers()} />

        {order && order.orders && renderCostSummary()}

        {order && (
          <PDFDownloadLink
            style={{ width: "100%" }}
            fileName="receipt.pdf"
            document={
              <PDF
                intl={intl}
                summary={order}
                markers={createMapMarkers()}
                damageDate={`${formatYYYYMMDD(
                  missionDate
                )} ${formatHoursAndMinutes(missionDate)}`}
                sourceAddress={combinedSourceAddress}
                destinationAddress={combinedDestinationAddress}
                fixedOnPlace={checkDestination}
              />
            }
          >
            {() => (
              <Button type="button" fullWidth>
                {intl.formatMessage({
                  id: "Summary.Receipt.ReceiptButton",
                  defaultMessage: "Get full receipt",
                  description: "Receipt: get full receipt button"
                })}
              </Button>
            )}
          </PDFDownloadLink>
        )}
      </CardBoxContainer>
    </div>
  );
};

Receipt.propTypes = {
  onOpinionClick: PropTypes.func.isRequired,
  rating: PropTypes.number.isRequired,
  order: PropTypes.instanceOf(Object).isRequired,
  summarySuccessModalState: PropTypes.bool.isRequired
};

Receipt = reduxForm({
  form: "receiptForm",
  initialValues: {
    message: ""
  },
  validate,
  destroyOnUnmount: true
})(Receipt);

const mapStateToProps = state => ({
  rating: getRating(state),
  summarySuccessModalState: getSummarySuccessModalState(state)
});

export default connect(mapStateToProps, null)(Receipt);
