import { useEffect } from "react";
import { useSessionStorage } from "usehooks-ts";
import { BaseUrl } from "../../constants/apiConstants";
import api from "../apiUtils";
import { getSession, SESSION_KEYS } from "../sessionStore";

const useSetCompanyInformation = () => {
  const [companyAttributes, setCompanyAttributes] = useSessionStorage(
    SESSION_KEYS.companyAttributes,
    undefined
  );
  // eslint-disable-next-line no-unused-vars
  const [_, setOrganizationUnitId] = useSessionStorage(
    SESSION_KEYS.gatOrganizationUnit,
    undefined
  );
  // eslint-disable-next-line no-unused-vars
  const [__, setOrganizationUnitHeadName] = useSessionStorage(
    SESSION_KEYS.organizationHeaderName,
    undefined
  );
  useEffect(() => {
    const appId = getSession(SESSION_KEYS.appId);
    api
      .get("v1/applications,me?version=1.0.0", BaseUrl.GatUrl, {
        gatOrgUnit: "",
        appId
      })
      .then(res => {
        const recordCompanyAttributes = res?.data?.companyAttributes?.reduce(
          (acc, { key, ...rest }) => {
            acc[key] = rest;
            return acc;
          },
          {}
        );
        setCompanyAttributes(recordCompanyAttributes);
        setOrganizationUnitId(res?.data?.organizationUnitId);
        setOrganizationUnitHeadName(res?.data?.organizationUnitName);
      });
  }, []);
  return {
    logo: companyAttributes?.CompanyLogo?.value,
    langOptions: companyAttributes?.CompanyLocale?.choices || []
  };
};

export default useSetCompanyInformation;
