import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import selectors from "./selectors";
import gatLoader from "../../assets/images/gat_symbol_animate.svg";

const { getLoading } = selectors;

const Loading = ({ loading }) => {
  return (
    <>
      {loading && (
        <div className="loading-spinner">
          <div className="loader">
            <img src={gatLoader} alt="loading" />
          </div>
        </div>
      )}
    </>
  );
};

Loading.propTypes = {
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  loading: getLoading(state)
});

export default connect(mapStateToProps, null)(Loading);
