/* global google */
import React, { useState } from "react";
import { compose, withProps } from "recompose";
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs
} from "react-google-maps";
import { Button } from "@assistansbolaget/gat-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCarBurst } from "@fortawesome/pro-light-svg-icons";
import { useIntl } from "react-intl";
import LocationSearchInput from "../../components/LocationSearchInput/LocationSearchInput";
import { GOOGLE_MAP_URL } from "../../constants/apiConstants";
import { SWEDEN_LAT_LNG, DENMARK_LAT_LNG } from "../../helpers/locations";
import { directionsMapOptions } from "../background/defaultMapOptions";
import location from "../../assets/images/car-position-icon.svg";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import locationPinLogo from "../../assets/images/pin.svg";
import "./style.css";
import PositionInputContainer from "./CarPositionPresentation.style";
import {
  CONFIRM_ORDER_VIEW,
  CUSTOMER_DETAILS_VIEW
} from "../createOrder/viewConstants";
import { getSession, SESSION_KEYS } from "../../helpers/sessionStore";

const CarPositionPresentation = compose(
  withProps({
    googleMapURL: GOOGLE_MAP_URL,
    loadingElement: <div className="full-height-width" />,
    containerElement: <div className="map-container-element" />,
    mapElement: <div className="full-height-width" />
  }),
  withScriptjs,
  withGoogleMap
)(props => {
  const [showSearchField, setShowSearchField] = useState(false);
  const appId = getSession(SESSION_KEYS.appId);

  const intl = useIntl();

  const handleShowSearchField = bool => {
    setShowSearchField(bool);
  };

  const handleSaveButton = () => {
    if (props.previousView === CONFIRM_ORDER_VIEW) {
      props.saveDestinationChange({
        lat: props.map.getCenter().lat(),
        lng: props.map.getCenter().lng()
      });
      return props.handleNavigationButton(CONFIRM_ORDER_VIEW);
    }
    if (props.handleSaveButton) {
      return props.handleSaveButton();
    }

    return props.handleNavigationButton(CUSTOMER_DETAILS_VIEW);
  };

  const handleBackButton = () => {
    const { previousView } = props;
    if (previousView === CONFIRM_ORDER_VIEW) {
      props.saveCenter(null);
      return props.handleNavigationButton(CONFIRM_ORDER_VIEW);
    }
    return null;
  };

  let accuracy;
  if (props.geoCoordinates && props.geoCoordinates.accuracy >= 10) {
    accuracy = 10;
  } else {
    // eslint-disable-next-line
    accuracy = props.geoCoordinates.accuracy;
  }

  let zoomLevel;

  if (props.map) {
    zoomLevel = props.map.getZoom();
  }

  // eslint-disable-next-line
  let markerCoords = { lat: 0, lng: 0 };
  if (props.centerCoords && props.centerCoords.lat) {
    markerCoords = {
      lat: props.centerCoords.lat,
      lng: props.centerCoords.lng
    };
  }
  let geoCoords = { lat: 0, lng: 0 };

  let defaultCenter = {};

  if (props.centerCoords && props.centerCoords.lat) {
    defaultCenter = {
      lat: props.centerCoords.lat,
      lng: props.centerCoords.lng
    };
  } else if (props.geoCoordinates && props.geoCoordinates.latitude) {
    defaultCenter = {
      lat: props.geoCoordinates.latitude,
      lng: props.geoCoordinates.longitude
    };
  } else {
    defaultCenter =
      appId === "gat-rescue-frontend" ? SWEDEN_LAT_LNG : DENMARK_LAT_LNG;
  }

  if (props.geoCoordinates && props.geoCoordinates.latitude) {
    geoCoords = {
      lat: props.geoCoordinates.latitude,
      lng: props.geoCoordinates.longitude
    };
  }
  let address;
  if (props.vehiclePosition && props.vehiclePosition.address) {
    if (props.vehiclePosition.address.length >= 27) {
      address = `${props.vehiclePosition.address.substring(0, 27)}...`;
    }
    if (
      props.vehiclePosition.city !== "" &&
      props.vehiclePosition.zipCode !== ""
    ) {
      const fullAddress = `${props.vehiclePosition.address}, ${props.vehiclePosition.zipCode} ${props.vehiclePosition.city}`;
      if (fullAddress.length > 27) {
        address = `${fullAddress.substring(0, 27)}...`;
      } else {
        // eslint-disable-next-line
        address = props.vehiclePosition.address;
      }
    }
  }
  const hasAddress =
    props.vehiclePosition &&
    props.vehiclePosition.address &&
    !props.isFetchingReverseGeoCode;

  const messageToDisplay =
    props.previousView !== CONFIRM_ORDER_VIEW
      ? intl.formatMessage({
          id: "DispatchPosition.CarPosition.Placeholder",
          defaultMessage: "Your position?",
          description: "CarPosition.Placeholder: your position"
        })
      : intl.formatMessage({
          id: "DispatchPosition.DestinationInput.Placeholder",
          defaultMessage: "Your destination?",
          description: "CarPosition.Placeholder: your destination"
        });

  return (
    <>
      <div className="flex justify-center handle-map-buttons-wrapper">
        <PositionInputContainer>
          <div className="confirmOrder-header-container">
            <FontAwesomeIcon icon={faCarBurst} size="2x" color="#FFD4A3" />
            <div className="confirmOrder-header">
              <p>
                {props.previousView === CONFIRM_ORDER_VIEW
                  ? intl.formatMessage({
                      id: "DispatchPosition.Destination",
                      defaultMessage: "Destination",
                      description: "dispatchPosition: destination"
                    })
                  : intl.formatMessage({
                      id: "DispatchPosition.Title",
                      defaultMessage: "Vehicle position",
                      description: "dispatchPosition: title"
                    })}
              </p>
            </div>
          </div>
          {!showSearchField ? (
            <div
              role="button"
              tabIndex={0}
              className="flex justify-center"
              onClick={() => handleShowSearchField(true)}
              onKeyDown={() => handleShowSearchField(true)}
            >
              <div className="flex justify-left align-center location-search-input">
                <img
                  src={locationPinLogo}
                  alt="location pin"
                  className="pin-image-left"
                />
                {hasAddress ? (
                  address
                ) : (
                  <LoadingSpinner
                    loading
                    positionStyle={{ background: "none", left: 0 }}
                    sizeStyle={{ width: "30px", height: "30px" }}
                  />
                )}
              </div>
            </div>
          ) : (
            <LocationSearchInput
              saveCenter={() => props.saveCenter()}
              map={props.map}
              showSearchField={() => handleShowSearchField(false)}
              placeholder={messageToDisplay}
            />
          )}
          <div className="handle-map-buttons-container">
            <Button
              type="button"
              fullWidth
              disabled={!hasAddress}
              onClick={handleSaveButton}
            >
              {intl
                .formatMessage({
                  id: "general.confirm",
                  defaultMessage: "Confirm",
                  description: "Confirm"
                })
                .toUpperCase()}
            </Button>
            {props.previousView === CONFIRM_ORDER_VIEW && (
              <Button
                type="button"
                fullWidth
                kind="Ghost"
                onClick={handleBackButton}
              >
                {intl
                  .formatMessage({
                    id: "general.goBack",
                    defaultMessage: "Go back",
                    description: "Go back"
                  })
                  .toUpperCase()}
              </Button>
            )}
          </div>
        </PositionInputContainer>
      </div>
      <GoogleMap
        ref={map => {
          props.saveMap(map);
        }}
        onZoomChanged={() => {
          handleShowSearchField(false);
          if (props.centerCoords) {
            props.saveCenter(null);
          }
        }}
        onDragStart={() => {
          handleShowSearchField(false);
          if (props.centerCoords) {
            props.saveCenter(null);
          }
        }}
        defaultCenter={{ lat: defaultCenter.lat, lng: defaultCenter.lng }}
        defaultZoom={14}
        defaultOptions={directionsMapOptions}
        onIdle={() => {
          props.saveCenter({
            lat: props.map.getCenter().lat(),
            lng: props.map.getCenter().lng()
          });
        }}
      >
        <img alt="location marker" className="centerMarker" src={location} />
        <Marker
          zIndex={2}
          name="geolocation"
          position={geoCoords}
          icon={{
            path: google.maps.SymbolPath.CIRCLE,
            scale: 7,
            fillOpacity: 1,
            fillColor: "#FFD4A3",
            strokeWeight: 2,
            strokeColor: "#232323",
            strokeOpacity: 1
          }}
        />
        <Marker
          zIndex={1}
          name="circle"
          position={geoCoords}
          icon={{
            path: google.maps.SymbolPath.CIRCLE,
            scale: (Math.min(100, accuracy) * zoomLevel) / (23 - zoomLevel),
            fillOpacity: 0.2,
            fillColor: "#FFD4A3",
            strokeWeight: 0.2,
            strokeColor: "#232323",
            strokeOpacity: 0.2
          }}
        />
      </GoogleMap>
    </>
  );
});

export default CarPositionPresentation;
