import { all, call, put, takeLatest } from "redux-saga/effects";
import history from "../../history";
import { RESUME_ORDER } from "./constants";
import {
  ACTIVE_ORDER_PATH,
  CREATE_ORDER_PATH,
  SUMMARY_PATH,
  STARTED,
  ARRIVED,
  PREPARE_TRIP_TO_WORKSHOP,
  PICKEDUP,
  DROPPED,
  PENDING,
  COMPLETED
} from "../../constants/routeConstants";
import createOrderService from "../createOrder/service";
import { CONFIRM_ORDER_VIEW } from "../createOrder/viewConstants";
import createOrderActions from "../createOrder/actions";
import activeOrderService from "../activeOrder/service";
import { getCookie } from "../../helpers/cookieHelper";
import activeOrderActions from "../activeOrder/actions";
import logErrorHelper from "../../helpers/logErrorHelper";

const { setOrderStatus, getEstimatedArrivalTimeSuccess } = activeOrderActions;
const { getEta } = activeOrderService;
const { getLatestOrder } = createOrderService;
const { setCurrentView } = createOrderActions;

const ONGOING_STATUSES = [
  STARTED,
  ARRIVED,
  PREPARE_TRIP_TO_WORKSHOP,
  PICKEDUP,
  DROPPED
];

function* resumeOrderWorker() {
  try {
    const token = yield call(getCookie, "token");
    if (token) {
      const { data } = yield call(getLatestOrder);
      if (data) {
        if (data.status === PENDING) {
          const eta = yield call(getEta, data.id);
          yield all([
            put(setOrderStatus(data)),
            put(getEstimatedArrivalTimeSuccess(eta)),
            put(setCurrentView(CONFIRM_ORDER_VIEW))
          ]);
          history.push(`${CREATE_ORDER_PATH}?id=${data.id}`);
        } else if (ONGOING_STATUSES.includes(data.status)) {
          yield put(setOrderStatus(data));
          history.push(`${ACTIVE_ORDER_PATH}?id=${data.id}`);
        } else if (data.status === COMPLETED) {
          yield put(setOrderStatus(data));
          history.push(`${SUMMARY_PATH}?id=${data.id}`);
        }
      }
    }
  } catch (error) {
    logErrorHelper(error);
  }
}
function* resumeOrderWatcher() {
  yield takeLatest(RESUME_ORDER, resumeOrderWorker);
}

export default { resumeOrderWatcher };

/* WIP - Use this with new function to resumeOrder depending on mission */
