import React, { useEffect, useState } from "react";
import { Divider } from "@assistansbolaget/gat-components";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHourglassClock } from "@fortawesome/pro-light-svg-icons";

const CustomerAcceptCountDown = ({ countdownTime }) => {
  const [time, setTime] = useState(countdownTime);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(prevTime => prevTime - 1);
    }, 1000);

    if (time === 0) {
      clearInterval(timer);
      return false;
    }
    return () => clearInterval(timer);
  }, [time]);

  const minutes = Math.floor(time / 60);
  const seconds = time % 60 || 0;

  return (
    <>
      <Divider fullWidth variant="line" />
      <div className="countdownTimer">
        <FontAwesomeIcon icon={faHourglassClock} color="#ffd4a3" />
        <h3>{`${minutes || 0}:${seconds < 10 ? "0" : ""}${seconds.toFixed(
          0
        )}`}</h3>
      </div>
    </>
  );
};

CustomerAcceptCountDown.propTypes = {
  countdownTime: PropTypes.number.isRequired
};

export default CustomerAcceptCountDown;
