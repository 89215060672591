const TERMS = `
Användarvillkor för bärgningstjänster via GAT
Senast uppdaterad 2021-04-20
Dessa användarvillkor, eventuella andra villkor som från tid till annan görs tillgängliga på vår hemsida och vår Personuppgiftspolicy (gemensamt ”Användarvillkoren”), samt i förekommande fall gällande försäkringsvillkor, utgör gemensamt villkoren för användning av de tjänster som Assistansbolaget Rescue Europe AB, org.nr 559248-8265 (”Assistansbolaget Rescue”) eller Assistansbolaget Försäkring Sverige AB, org.nr 556985-2162 (”Assistansbolaget Försäkring” och tillsammans med Assistansbolaget Rescue, ”Assistansbolaget” eller ”vi”) erbjuder dig genom denna applikation (tjänsterna och applikationen benämns nedan gemensamt ”Tjänsterna” och enbart applikationen benämns nedan ”Appen”). För att kunna beställa en Tjänst måste du godkänna de vid var tid gällande Användarvillkoren. När Användarvillkoren godkänts anses ett avtal ha kommit till stånd mellan dig och Assistansbolaget Rescue eller Assistansbolaget Försäkring avseende den beställda Tjänsten. Läs därför Användarvillkoren ordentligt innan du beställer en Tjänst.

Allmänt

Tjänsterna kan beställas såväl av Assistansbolagets försäkringskunder (dvs. kunder som innan Tjänsten beställs tecknat försäkring genom Assistansbolaget Försäkring) (”Försäkringskunder”) som av kunder som inte har tecknat försäkring genom Assistansbolaget Försäkring. När en Tjänst inte omfattas av Assistansbolagets Försäkrings försäkringar blir du eller den registrerade ägaren till fordonet betalningsskyldig för den beställda Tjänsten.

När Tjänster tillhandahålls till Försäkringskunder är det Assistansbolaget Försäkring, eller dess underleverantör, som utför Tjänsten. När Tjänster tillhandahålls till kund som inte är Försäkringskund är det Assistansbolaget Rescue, eller dess underleverantör, som utför Tjänsten. Om det inte är möjligt att beställa en Tjänst genom Appen går det bra att dygnet runt, året om, ringa till vår Räddningscentral på 020-400 500.

Assistansbolagets ambition är att löpande utveckla Tjänsterna varför den exakta utformningen och Tjänsternas funktioner kan komma att ändras över tid. Den utformning och funktion som gäller för de Tjänster som tillhandahålls från tid till annan framgår av dessa Användarvillkor. Assistansbolaget förbehåller sig följaktligen rätten att förändra innehållet i dessa Användarvillkor genom att publicera uppdaterade villkor på https://assistansbolaget.nu/villkor/.

Användning av Tjänsten

För att använda Tjänsterna ska du ha fyllt 18 år. När du beställer en Tjänst kommer du bli ombedd att lämna vissa personliga uppgifter, uppgift om skadeposition och vilket fordon Tjänsten avser, samt vem som framför fordonet. Vi kommer också att inhämta och behandla andra personuppgifter samt uppgifter om det fordon som Tjänsten avser samt föraren av fordonet.

Information om vår personuppgiftsbehandling finns i vår Personuppgiftspolicy som finns tillgänglig på https://assistansbolaget.nu/villkor/.
  
Tillhandahållande av Tjänsterna

Assistansbolaget strävar efter att kontinuerligt utveckla och uppdatera Tjänsterna och Appen, men vi tillhandahåller Appen utan några utfästelser eller garantier av något slag. Appen kan helt eller delvis vara otillgänglig på grund av planerat underhåll, oplanerade driftstörningar eller andra  omständigheter utanför vår kontroll. Detta kan innebära att data går förlorad, eller att Tjänsterna inte går att använda.

Assistansbolaget förbehåller sig vidare rätten att helt upphöra med Tjänsterna tillfälligt eller permanent. Detta gäller dock inte för enskilda redan beställda Tjänster.

Kostnader för Tjänsterna

Om du är Försäkringskund och skadan omfattas av försäkringsvillkoren tillhandahålls Tjänsterna utan kostnad. Kontrollera ditt försäkringsskydd om du är osäker på om skadan omfattas av försäkringen. Under normal kontorstid kan du också ringa vår kundtjänst på telefonnummer 042-324 324 för att få råd.

Om du inte är Försäkringskund, eller om skadan inte omfattas av försäkringsvillkoren, debiteras du kostnaden för utförd Tjänst i enlighet med vår vid var tid gällande prislista. Avgiften framgår när du beställer Tjänsten.

Ångerrätt

Om du beställer en Tjänst, till exempel bärgning eller transport, som ska utföras inom 14 dagar från dagen för beställningen kommer vi endast att påbörja Tjänsten om du vid beställningen uttryckligen begär att Tjänsten utförs under ångerfristen. Att du begär detta innebär att du kan bli ersättningsskyldig för kostnader förknippade med arbete som utförts innan du avbryter Tjänsten.

Om du har beställt en Tjänst och avbryter Tjänsten innan vi har påbörjat uppdraget tar vi inte ut någon avgift. Om du däremot avbryter Tjänsten efter att vi påbörjat utförandet av uppdraget, till exempel påbörjat körning till den position som lämnas vid beställning av bärgning, debiteras du en framkörningsavgift om 995 kr. Samma sak gäller om du har avvikit från platsen innan bärgaren kommit fram. Fakturan skickas till den registrerade ägaren av det fordon som angetts i beställningen av Tjänsten för det fall du inte redan har valt betalningsmetod och godkänt kostnaden via Klarna.

Ångerrätten gäller inte när Tjänsten har fullgjorts. Du godkänner att din ångerrätt har förbrukats vid den tidpunkt då Tjänsten har fullgjorts.

Betalning

Betalning av Tjänsterna kan ske antingen genom faktura med Assistansbolaget som betalningsmottagare eller genom av Assistansbolaget anlitad betallösningsleverantör, för närvarande Klarna. För frågor gällande betalning via Klarna hänvisas till Klarna och Klarnas vid var tid gällande villkor.

Assistansbolaget förbehåller sig rätten att vid var tid ändra betallösningsleverantör.

För det fall du har valt faktura med Assistansbolaget som betalningsmottagare som betalningsmetod och fakturan inte betalas till oss i tid, tar vi ut en påminnelseavgift om 65 kr och sedan sedvanlig inkassohantering.

Ditt ansvar som användare

Du ansvarar för att användningen av Tjänsterna inte ger upphov till skada eller annan olägenhet för Assistansbolaget eller någon annan, kränker Assistansbolagets eller annans upphovsrätt eller annan immaterialrätt, strider mot någon annans villkor som du åtagit dig att följa, eller strider mot lag eller myndighets föreskrift eller beslut.

Du ansvarar gentemot Assistansbolaget och mot annan för skada eller förlust som uppkommit genom otillåten användning (dvs. i strid med dessa villkor ), vid fel eller försummelse från din sida vid användningen av Tjänsterna eller av dina uppgifter, på grund av fel eller brist i de uppgifter du har tillhandahållit inom ramen för Tjänsterna eller som uppkommit på grund av ditt brottsliga förfarande.

Assistansbolagets ansvar

Assistansbolaget tillhandahåller Appen i befintligt skick och ansvarar inte för skada som beror på fel eller avbrott i Appen. Vi är således under inga omständigheter skyldiga att utge ersättning eller skadestånd till dig med anledning av din användning av Appen, att du inte har kunnat beställa Tjänster genom Appen eller vid fel, brister, dataförluster och annan skada som Appen har gett upphov till.

Assistansbolagets ansvar för bärgning och transport av fordon som beställs genom Appen är begränsat till direkt skada och till ett maxbelopp av 100 000 kr. Vi ansvarar inte för följdskador eller andra indirekta skador.

Ovanstående begränsningar av vårt ansvar gäller inte om annat följer av tvingande lagstiftning. Assistansbolaget tar inget ansvar för skador eller kostnader som föranleds av felaktiga uppgifter som lämnats av användare av Tjänst.

Behandling av personuppgifter

I samband med att du beställer en Tjänst samlar Assistansbolaget in och behandlar dina personuppgifter. Assistansbolaget hanterar all kundinformation som konfidentiell och i enlighet med gällande dataskyddslagstiftning. Fullständig information om hur och varför Assistansbolaget behandlar personuppgifter samt dina rättigheter avseende vår behandling av dina personuppgifter finns i vår Personuppgiftspolicy som du kan ta del av i Appen eller på https://assistansbolaget.nu/villkor/. Vill du ha vår Personuppgiftspolicy hemskickad till dig ber vi dig att kontakta vår kundtjänst på telefonnummer 042-324 324.

Vi inhämtar uppgifter om dig från olika datakällor såsom folkbokföringen, Transportstyrelsen etc. Assistansbolaget kan komma att använda dina personuppgifter för marknadsföring och analys i enlighet med vad som anges i Personuppgiftspolicyn.

Immateriella rättigheter

Alla immateriella rättigheter och tekniska rättigheter hänförliga till Tjänsterna och den programvara, de tjänster och funktioner som ingår i Tjänsterna, tillhör Assistansbolaget eller den som vi fått tillåtelse från att använda dessa. Inget innehåll får kopieras, ändras eller på annat sätt användas utan skriftligt medgivande från Assistansbolaget. Du bekräftar och accepterar att otillåten användning av innehållet, utöver att strida mot Användarvillkoren, kan vara brottsligt.

Äganderätt och upphovsrätt till eventuella bilder och videomaterial som tillhandahålls av dig tillhör dig. Assistansbolaget har dock rätt att använda sådant innehåll inom ramen för tillhandahållandet av Tjänsterna till dig. Assistansbolaget använder inte sådant innehåll i annat syfte än att tillhandahålla Tjänsterna.

Tillägg och ändringar

Assistansbolaget har rätt att ensidigt göra tillägg till och ändringar i Användarvillkoren.
En ny version av Användarvillkoren gäller från och med den tidpunkt som anges i den nya versionen av Användarvillkoren och blir gällande gentemot dig när du, efter sådan tidpunkt, använder Tjänsten eller på annat sätt godkänner de uppdaterade Användarvillkoren. De vid var tid gällande Användarvillkoren finns tillgängliga i Appen och på https://assistansbolaget.nu/villkor/. Om du inte accepterar de uppdaterade Användarvillkoren ska du upphöra att använda Tjänsten.

Om vi inte kommer överens

Om du inte är nöjd med Tjänsterna vill vi att du kontaktar oss för att få råd om hur du kan få ärendet omprövat. Du kontaktar då vår kundtjänst på telefon 042-324 324. Om du fortfarande inte är nöjd kan du kontakta klagomålsansvarig på Assistansbolaget och begära omprövning. Kontakta klagomålsansvarig via e-post på klagomalsansvarig@assistansbolaget.nu eller skicka ditt klagomål till:

Klagomålsansvarig
Assistansbolaget Försäkring Sverige AB/Assistansbolaget Rescue Europe AB Redaregatan 50, 252 36 Helsingborg

Om du har fått ditt ärende omprövat och ändå inte är nöjd har du möjlighet att vända dig till nedanstående:
ALLMÄNNA REKLAMATIONSNÄMNDEN (ARN)
Allmänna reklamationsnämnden (ARN) Adress: Box 174, 101 23 Stockholm Telefon: 08-508 860 00
https://arn.se/
ARN prövar klagomål från privatpersoner, dock inte vållandefrågor i trafikolyckor. Prövningen är kostnadsfri.

ALLMÄN DOMSTOL

Du kan även vända dig till allmän domstol för att få ditt ärende prövat. https://www.domstol.se/

Kontaktuppgifter
Om du har några frågor avseende Tjänsterna är du välkommen att kontakta oss enligt följande kontaktuppgifter:

Assistansbolaget Försäkring Sverige AB/Assistansbolaget Rescue Europe AB Adress: Redaregatan 50, 252 36 Helsingborg
Telefon: 042-324 324
E-post: kontakt@assistansbolaget.nu

Tillämplig lag och tvistelösning
Vid tolkning och tillämpning av dessa Användarvillkor gäller svensk rätt. Tvist med anledning av din användning av Tjänsten och/eller Användarvillkoren ska i första hand lösas genom förhandling mellan parterna. Om ingen överenskommelse kan nås ska tvisten avgöras av svensk domstol.

Dessa Användarvillkor begränsar inte till någon del de rättigheter du må ha enligt tillämplig lag,
som inte går att avtala bort.
`;

export default {
  towing: {
    topBar: {
      phoneAlt: "Klicka för att ringa oss på telefonnummer +4642324324"
    },
    orientation: {
      title: "VÄGHJÄLP DIREKT STÖDJER ENDAST PORTRÄTTLÄGE",
      message: "Var god rotera tillbaka telefonen till porträttläge"
    },
    toggleInsured: {
      yes: "Ja",
      no: "Nej"
    },
    logoAlt: "Assistansbolaget",
    currency: "kr",
    minutes: "min",
    errors: {
      vehicleNotFound: "Ogiltigt reg.nr (ABC123, ABC12A)",
      fetchVehicleFailure: "Ett fel inträffade ring oss på: +4642324324",
      fetchUserFailure: "Ett fel inträffade ring oss på: +4642324324",
      personNotFound: "Ogiltigt personnummer (ÅÅMMDDXXXX)",
      vehicleRequired: "Du måste ange ett registreringsnummer",
      socialNumberRequired: "Ogiltigt personnummer (ÅÅMMDDXXXX)",
      required: "Obligatoriskt fält",
      minimumLength: "Ogiltigt reg.nr (ABC123, ABC12A)",
      maximumLength: "Ogiltigt reg.nr (ABC123, ABC12A)",
      invalidPhone: "Ogiltigt telefonnummer (0701234567)",
      invalidEmail: "Ogiltig epostadress (namn@namn.com)",
      terms: "Du måste godkänna användarvillkoren.",
      messageMaximumLength: "Max 4000 tecken",
      minimumLengthSocialNumber: "Ogiltigt personnummer (ÅÅMMDDXXXX)",
      maximumLengthSocialNumber: "Ogiltigt personnummer (ÅÅMMDDXXXX)"
    },
    phoneNumber: "+4620400500",
    background: {
      alt: "Bakgrunds bild",
      gotQuestions: "Har du frågor?",
      contactUs: "Kontakta oss på"
    },
    createOrder: {
      preliminary: "Preliminärt",
      toggleText1: "Har du en gällande vägassistans-",
      toggleText2: "försäkring hos Assistansbolaget?",
      time: "kl",
      readMore: "Läs mer",
      tmaCar: "Inkluderar ej eventuell kostnad för TMA-bil",
      engineInsuranceType: "Ange bilförsäkringstyp",
      fullOrHalfInsurance: "Hel/Halv",
      trafficInsurance: "Trafik",
      insuranceMissing: "Vet ej",
      noConfirmationModal: {
        title: "BOKNING AVBRÖTS",
        text:
          "Tiden för att bekräfta bokningen har gått ut. Du blir automatiskt omdirigerad till föregående steg för att genomföra bokningen på nytt.",
        button: "OK"
      },
      dispatchWillCallModal: {
        text:
          "Tyvärr finns det inte någon ledig bärgningsbil i ditt område just nu.",
        text2:
          "Oroa dig inte, vi kommer att ringa upp dig för vidare hjälp inom några minuter.",
        button: "OK"
      },
      alreadyStartedErrorModal: {
        title: "DU HAR REDAN ETT PÅGÅENDE ÄRENDE",
        text:
          "Det finns redan ett pågående uppdrag med mobilnumret du angav. Klicka på OK för att följa ditt uppdrag.",
        button: "OK"
      },
      cancelledByDispatchErrorModal: {
        title: "BOKNINGEN AVBRÖTS",
        text: "Räddningscentralen avbröt bokningen.",
        button: "OK"
      },
      unexpectedErrorModal: {
        title: "NÅGONTING GICK FEL",
        text:
          "Någonting gick fel och vi kan inte presentera information för dig. Vänligen kontakta oss på Tel: +46 42 324 324 så hjälper vi dig vidare.",
        button: "OK"
      },
      welcomeScreen: {
        title: "BEGÄR VÄGHJÄLP",
        subtitle: "I SVERIGE",
        roundTheClock: "Dygnet runt i hela Sverige",
        quickResponse: "Snabbt på plats",
        payWithKlarna: "Delbetala med Klarna",
        happyCustomers: "4,6/5 hos Reco",
        call: "RING 020-400 500",
        book: "BOKA BÄRGNINGSBIL",
        description:
          "Välkommen till en av Sveriges största vägassistansoperatörer. Här begär du tryggt och enkelt väghjälp direkt med våra professionella och erfarna bärgare.",
        errorTitle: "INGEN BÄRGARE LEDIG",
        errorDescription:
          "Tyvärr finns det inte någon ledig bärgningsbil i ditt område just nu. Var god försök igen senare.",
        noLocationServiceModal: {
          title: "POSITION NEKAD",
          text1:
            "Eftersom att du nekade oss åtkomst till din position kan vi tyvärr inte erbjuda dig assistans.",
          text2:
            "Vänligen klicka på knappen ring räddningscentralen så hjälper vi dig.",
          button: "OK"
        },
        metaData: {
          buttonText: "visa mer information",
          seoText1:
            "Assistansbolaget är snabba på att hjälpa dig med bärgning av bil, lätt lastbil, släpvagn, husbil eller MC i Stockholm. Vid ankomst försöker vi reparera ditt fordon på plats, om inte det är möjligt bärgas fordonet till närmaste verkstad.",
          seoText2:
            "Vår bärgning är trygg och prisvärd. Vi är det marknadsledande alternativet med 350 000 nöjda kunder i Norden."
        }
      },
      estimatePrice: {
        title: "PRELIMINÄRT PRIS",
        titleChange: "ÄNDRA DESTINATION",
        text1: "Om vi kan reparera ditt fordon på plats betalar du endast",
        text2: "Om ditt fordon behöver bärgas betalar du totalt",
        textEngineInsuranceCoverage:
          "Eftersom du har valt att du har hel- eller halvförsäkring så kommer vi endast att debitera dig din självrisk, överskjutande del debiteras ditt försäkringsbolag.",
        suggestedAssistancePrice: "REPARATION PÅ PLATS:",
        suggestedShopPrice: "BÄRGNING TILL:",
        suggestedPrice: "BERÄKNAT PRIS:",
        suggestedArrival: "BÄRGARENS ANKOMSTTID:",
        destinationButtonOpen: "ÄNDRA DESTINATION",
        destinationButtonSave: "SPARA & BERÄKNA NYTT PRIS",
        destinationButtonUndo: "ÅNGRA ÄNDRING",
        time: "kl"
      },
      customerDetails: {
        title: "MINA UPPGIFTER",
        text:
          "Vänligen fyll i dina uppgifter för att beräkna priset för din väghjälp.",
        carNumber: "Registreringsnummer",
        mobileNumber: "Telefonnummer",
        carPosition: "Fordonets position",
        socialNumber: "ÅÅÅÅMMDDXXXX",
        pnrInfo: "Som försäkringstagare behöver du ange ditt personnummer.",
        engineInsuranceTypeSelect: "Ange bilförsäkringstyp"
      },
      damageReason: {
        title: "ANGE VAD SOM ÄR FEL",
        selectDamage: "Ange vad som är fel",
        garage: "Är fordonet i ett P-hus eller annat trångt utrymme?",
        tierPlacement: "Välj däck",
        chooseFuelType: "Ange drivmedelstyp",
        warningLamp: "Välj färg på varningslampan",
        red: "Röd",
        yellow: "Gul",
        chooseReason: "Välj orsak",
        missing: "Saknas",
        defect: "Defekt",
        lockedInside: "Inlåst",
        petrol: "Bensin",
        diesel: "Diesel",
        etanol: "Etanol",
        electric: "El",
        frontTierLeft: "Vänster fram",
        frontTierRight: "Höger fram",
        rearTierLeft: "Vänster bak",
        rearTierRight: "Höger bak",
        spareTier: "Har du reservdäck?",
        acceptText: "Genom att boka bärgning godkänner du",
        terms: "användarvillkoren"
      },
      userTerms: {
        title: "ANVÄNDARVILLKOR",
        text: TERMS
      },
      tmaCarTerms: {
        title: "TMA-VILLKOR",
        text: TERMS
      },
      seekingResponder: {
        towtruck: {
          title: "VI SÖKER EFTER NÄRMSTA BÄRGNINGSBIL",
          text:
            "Vänligen vänta, stäng inte ner denna sida under tiden som vi söker efter en bärgningsbil åt dig."
        },
        dispatch: {
          title: "VI KOMMER ATT HJÄLPA DIG!",
          text1: "Som försäkringstagare hos Assistansbolaget får du",
          text2: "prioriterad",
          text3:
            "väghjälp. Ditt ärende har nu mottagits av vår räddningscentral.",
          text4:
            "Vänligen vänta, vi ringer upp dig inom några minuter på det telefonnummer du uppgett."
        }
      },
      confirmOrder: {
        title: "PRIS",
        text1: "Om vi kan reparera ditt fordon på plats betalar du endast",
        text2: "Om ditt fordon behöver bärgas betalar du totalt",
        estimateArrival: "BÄRGARENS ANKOMSTTID:",
        assistanceTitle: "REPARATION PÅ PLATS:",
        towingTitle: "BÄRGNING TILL:",
        confirm: "BEKRÄFTA",
        time: "kl"
      },
      abort: "AVBRYT",
      previousStep: "TILLBAKA",
      nextStep: "VIDARE",
      close: "STÄNG",
      yes: "Ja",
      no: "Nej",
      home: "TILL STARTSIDAN"
    },
    activeOrder: {
      unexpectedErrorModal: {
        title: "NÅGONTING GICK FEL",
        text:
          "Någonting gick fel och vi kan inte presentera information för dig. Vänligen kontakta oss på Tel: +46 42 324 324 så hjälper vi dig vidare.",
        button: "OK"
      },
      button: "OK",
      orderStartedTitle: "TACK, VI ÄR NU PÅ VÄG",
      orderStartedDescription:
        "Här kan du följa ditt ärende i realtid. En länk har även skickats till dig per SMS där också kan följa ditt ärende.",
      cancelOrder: "AVBRYT",
      callSuport: "RING RÄDDNINGSCENTRALEN",
      onWay: {
        title: "VI ÄR PÅ VÄG",
        text1: "Föraren",
        text2: "från",
        text4: "är på väg för att hjälpa dig.",
        text3: "Räddningsfordonets registreringsnummer är:",
        time: "Ankomsttid:",
        etaOutdated:
          "Ankomsttiden har inte kunnat uppdateras på över fem minuter och kan därför vara missvisande."
      },
      arrived: {
        title: "VI ÄR PLATS",
        text1: "Föraren",
        text2: "från",
        text4: "är nu på plats för att hjälpa dig.",
        text3: "Räddningsfordonets registreringsnummer är:"
      },
      pickedup: {
        title: "DITT FORDON BÄRGAS",
        text1: "Föraren",
        text2: "från",
        text3: "bärgar ditt fordon till",
        text4: "Bärgningsbilens registreringsnummer är:"
      },
      dropped: {
        title: "DITT FORDON HAR BÄRGATS",
        text: "",
        text1: "Föraren",
        text2: "från",
        text3: "har nu bärgat ditt fordon till"
      },
      canceled: {
        title: "DIN VÄGHJÄLP ÄR AVBRUTEN",
        text1:
          "Du har valt att avbryta din väghjälp och kommer att debiteras för framkörningsavgiften om",
        arrivalFee: "FRAMKÖRNINGSAVGIFT:",
        towTruckTitle: "UPPDRAGET ÄR AVBRUTET",
        ok: "OK",
        towTruckText:
          "Bärgaren har avbrutit uppdraget. Vi kommer att kontakta dig inom kort."
      },
      complete: {
        title: "UPPDRAGET ÄR SLUTFÖRT",
        text1:
          "Tack för att du valde Assistansbolaget. Vi hoppas att du är nöjd med vår hjälp.",
        text2:
          "Vid frågor är du alltid varmt välkommen att kontakta vår räddningscentral."
      },
      cancelOrderModal: {
        abort: "NEJ, AVBRYT INTE",
        cancel: "JA, AVBRYT",
        title: "AVBRYT VÄGHJÄLP",
        text1:
          "Vi är redan på väg till dig. Om du väljer att avbryta din väghjälp nu kommer du att debiteras för framkörningsavgiften om",
        text2: "Är du säker på att du vill avbryta?"
      }
    },
    summary: {
      receipt: {
        header: "Tack för att du valde",
        title: "Sammanfattning av ditt uppdrag",
        text1: "Vi hoppas att du är nöjd med vår hjälp.",
        text2: "Betygsätt oss gärna - ditt omdöme är viktigt för oss!",
        text3: "Maila mig kvitto & uppdragsbeskrivning:",
        assistance: "ASSISTANS:",
        towing: "BÄRGNING:",
        shopChange: "BYTE AV VERKSTAD:",
        gradeExperience: "Betygsätt din upplevelse",
        summary: "SKRIV OMDÖME",
        placeholder: "Ange din epostadress",
        sendEmailButton: "SKICKA",
        emailSent: "SKICKAD",
        repairAtSpotLabel: "Reparation på plats",
        downloadReceipt: "HÄMTA FULLSTÄNDIGT KVITTO",
        customerLabel: "BESTÄLLARE:",
        nameLabel: "Namn",
        addressLabel: "Adress",
        vehicleLabel: "FORDON:",
        brandLabel: "Bilmärke:",
        yearLabel: "Årsmodell:",
        missionLabel: "UPPDRAG:",
        missionTypeLabel: "Uppdrag:",
        missionNumber: "Uppdragsnummer:",
        missionType: "Uppdragstyp:",
        specification: "SPECIFIKATION",
        paymentMethod: "BETALSÄTT",
        totaltAmount: "TOTALKOSTNAD",
        startedAt: "Påbörjades:",
        regnoLabel: "Reg.nr:",
        damageInformationLabel: "Skadeinformation",
        damageReasonHeader: "Skadeorsak:",
        locationsForDamage: "PLATSER:",
        costs: "KOSTNAD",
        damageScene: "Skadeplats",
        descriptiveDamageReason: "Skadebeskrivning:",
        towingLabel: "Bärgning",
        towingTo: "Bärgad till",
        dateLabel: "Datum",
        towedFromLabel: "Bärgad från:",
        towedToLabel: "Bärgad till:",
        chargedDistanceLabel: "Extra debiterad sträcka:",
        costsLabel: "Kostnader",
        costTypeLabel: "Kostnadstyp",
        costLabel: "Kostnader",
        paymentMethodLabel: "Betalningssätt",
        totalLabel: "Totalt",
        assistansbolagetCompanyName: "Rescue Europe AB",
        assistansbolagetAddressStreet: "Redaregatan 50",
        assistansbolagetAddressZip: "252 36 Helsingborg",
        receiptHeader: "KVITTO",
        assistanstbolaget: "Assistansbolaget",
        emailLabel: "Epost",
        email: "kontakt@assistansbolaget.nu",
        orgNrLabel: "Org. nr",
        orgNr: "559248-8265",
        phoneNrLabel: "Telefon",
        phoneNr: "042 324 324",
        vatLabel: "Varav moms",
        missionId: "Uppdragsnummer",
        recieptGenerated: "Kvitto genererat"
      },
      opinion: {
        title: "HUR VAR DIN UPPLEVELSE?",
        text1: "Tack för att du valde Assistansbolaget!",
        text2:
          "Vi skulle uppskatta om du lämnade ett omdöme om din upplevelse så att vi kan bli ännu bättre.",
        placeholder: "Beskriv din upplevelse...",
        send: "SKICKA OMDÖME"
      },
      summarySuccessModal: {
        title: "TACK FÖR DIN RECENSION",
        text: "Din åsikt är viktig för oss!",
        primaryButton: "STÄNG"
      },
      summaryFailureModal: {
        title: "NÅGONTING GICK FEL",
        text: "Vänligen försök igen.",
        primaryButton: "STÄNG"
      },
      receiptFailureModal: {
        title: "NÅGONTING GICK FEL",
        text:
          "Vi kunde tyvärr inte skicka uppdragsbeskrivningen till dig. Vänligen försök igen eller kontakta oss på Tel: +46 42 324 324.",
        primaryButton: "STÄNG"
      }
    },
    carPositionPicker: {
      placeholder: "Vart står fordonet?",
      loading: "Laddar...",
      saveButton: "OK",
      searchError: "Du måste välja ett av sökresultaten"
    },
    estimatePriceView: {
      searchLocationPlaceholder: "Vart vill du bärgas?"
    },
    dispatchPosition: {
      locationBlockedModal: {
        title: "ÅTKOMST BLOCKERAD",
        text:
          "Kontrollera webbläsarens inställningar över tillåtna platser för att ge åtkomst till platsinformation."
      },
      unexpectedErrorModal: {
        title: "NÅGONTING GICK FEL",
        text:
          "Någonting gick fel, vi kunde inte positionera ditt fordon. Vänligen försök igen."
      },
      successModal: {
        title: "Tack!",
        text: "Ditt fordons position har registrerats."
      },
      okButtonText: "OK"
    },
    loginTriggerComponent: {
      text: "Vänligen vänta, uppgifter hämtas."
    },
    damageDetails: {
      frontLeft: "Vänster fram",
      frontRight: "Höger fram",
      rearLeft: "Vänster bak",
      rearRight: "Höger bak",
      missing: "Saknas",
      defect: "Defekt",
      lockedInsideVehicle: "Inlåst",
      petrol: "Bensin",
      diesel: "Diesel",
      ethanol: "Etanol",
      electric: "El",
      red: "Röd",
      yellow: "Gul",
      errorMessage: "Felmeddelande",
      camDrive: "Kamdrivning/drivsystem",
      turbo: "Turbo/kompressor",
      oilLeakage: "Oljeläckage",
      fanBelt: "Fläktrem",
      coolingSystem: "Kylsystem",
      waterPump: "Vattenpump",
      powerTransmission: "Kraftöverföring",
      clutch: "Koppling",
      flywheel: "Svänghjul",
      distributionBox: "Fördelningslåda",
      generator: "Generator",
      controlDevice: "Styrdon/styrenhet",
      ignitionLock: "Tändlås",
      batteryBank: "Batteribank(elbil)",
      fuelInjector: "Spridare",
      fuelPump: "Bränslepump",
      gasRegulator: "Gasregulator",
      steeringGear: "Styrväxel",
      servopump: "Servopump",
      steeringWheelLock: "Rattlås",
      abs: "ABS/ESP",
      parkingBrake: "Parkeringsbroms",
      brakeServo: "Bromsservo",
      brakePads: "Bromsok/skivor/klossar",
      rearCarriage: "Bakvagn",
      frontCarriage: "Framvagn",
      exhaustSystem: "Avgassystem",
      hydraulics: "Hydraulik",
      glassDamage: "Glasskada",
      fire: "Brand",
      fuelError: "Feltankning"
    },
    damageReasons: {
      flatTire: "Punktering",
      startHelp: "Strömlöst batteri",
      keyIssues: "Nyckelproblem",
      propulsion: "Drivmedelsbrist",
      warningLight: "Varningslampa lyser",
      engineProblem: "Motor",
      gearBox: "Växellåda & drivning",
      electronics: "Elektronik",
      fuelSystem: "Bränslesystem",
      steering: "Styrning",
      brakingSystem: "Bromssystem",
      otherDamage: "Övrig skada",
      collision: "Kollision",
      ditch: "Dikeskörning",
      transport: "Transport",
      drivingBan: "Körförbud"
    },
    accidentOptionFunction: {
      chooseWarningColor: "Välj färg på varningslampan",
      chooseReason: "Välj orsak",
      chooseTire: "Välj däck",
      fuelType: "Ange typ av drivmedel",
      damageDetail: "Skadeorsak detalj",
      chooseEngineProblem: "Välj motorproblem",
      chooseGearBox: "Välj växellådeproblem",
      chooseElectronics: "Välj elfel",
      chooseFuelSystem: "Välj bränslesystemsfel",
      chooseSteering: "Välj styrningsfel",
      chooseBrakingSystem: "Välj bromsfel",
      chooseOtherDamage: "Välj övrig skada"
    }
  },
  transport: {
    topBar: {
      phoneAlt: "Klicka för att ringa oss på telefonnummer +4642324324"
    },
    orientation: {
      title: "VÄGHJÄLP DIREKT STÖDJER ENDAST PORTRÄTTLÄGE",
      message: "Var god rotera tillbaka telefonen till porträttläge"
    },
    toggleInsured: {
      yes: "Ja",
      no: "Nej"
    },
    logoAlt: "Assistansbolaget",
    currency: "kr",
    minutes: "min",
    errors: {
      vehicleNotFound: "Ogiltigt reg.nr (ABC123, ABC12A)",
      fetchVehicleFailure: "Ett fel inträffade ring oss på: +4642324324",
      fetchUserFailure: "Ett fel inträffade ring oss på: +4642324324",
      personNotFound: "Ogiltigt personnummer (ÅÅMMDDXXXX)",
      vehicleRequired: "Du måste ange ett registreringsnummer",
      socialNumberRequired: "Ogiltigt personnummer (ÅÅMMDDXXXX)",
      required: "Obligatoriskt fält",
      minimumLength: "Ogiltigt reg.nr (ABC123, ABC12A)",
      maximumLength: "Ogiltigt reg.nr (ABC123, ABC12A)",
      invalidPhone: "Ogiltigt telefonnummer (0701234567)",
      invalidEmail: "Ogiltig epostadress (namn@namn.com)",
      terms: "Du måste godkänna användarvillkoren.",
      messageMaximumLength: "Max 4000 tecken",
      minimumLengthSocialNumber: "Ogiltigt personnummer (ÅÅMMDDXXXX)",
      maximumLengthSocialNumber: "Ogiltigt personnummer (ÅÅMMDDXXXX)"
    },
    phoneNumber: "+4620400500",
    background: {
      alt: "Bakgrunds bild",
      gotQuestions: "Har du frågor?",
      contactUs: "Kontakta oss på"
    },
    createOrder: {
      preliminary: "Preliminärt",
      toggleText1: "Har du en gällande vägassistans-",
      toggleText2: "försäkring hos Assistansbolaget?",
      time: "kl",
      readMore: "Läs mer",
      tmaCar: "Inkluderar ej eventuell kostnad för TMA-bil",
      engineInsuranceType: "Ange bilförsäkringstyp",
      fullOrHalfInsurance: "Hel/Halv",
      trafficInsurance: "Trafik",
      insuranceMissing: "Vet ej",
      noConfirmationModal: {
        title: "BOKNING AVBRÖTS",
        text:
          "Tiden för att bekräfta bokningen har gått ut. Du blir automatiskt omdirigerad till föregående steg för att genomföra bokningen på nytt.",
        button: "OK"
      },
      dispatchWillCallModal: {
        text:
          "Tyvärr finns det inte någon ledig bärgningsbil i ditt område just nu.",
        text2:
          "Oroa dig inte, vi kommer att ringa upp dig för vidare hjälp inom några minuter.",
        button: "OK"
      },
      alreadyStartedErrorModal: {
        title: "DU HAR REDAN ETT PÅGÅENDE ÄRENDE",
        text:
          "Det finns redan ett pågående uppdrag med mobilnumret du angav. Klicka på OK för att följa ditt uppdrag.",
        button: "OK"
      },
      cancelledByDispatchErrorModal: {
        title: "BOKNINGEN AVBRÖTS",
        text: "Räddningscentralen avbröt bokningen.",
        button: "OK"
      },
      unexpectedErrorModal: {
        title: "NÅGONTING GICK FEL",
        text:
          "Någonting gick fel och vi kan inte presentera information för dig. Vänligen kontakta oss på Tel: +46 42 324 324 så hjälper vi dig vidare.",
        button: "OK"
      },
      welcomeScreen: {
        title: "BEGÄR VÄGHJÄLP",
        subtitle: "I SVERIGE",
        roundTheClock: "Dygnet runt i hela Sverige",
        quickResponse: "Snabbt på plats",
        payWithKlarna: "Delbetala med Klarna",
        happyCustomers: "4,6/5 hos Reco",
        call: "RING 020-400 500",
        book: "BOKA BÄRGNINGSBIL",
        description:
          "Välkommen till en av Sveriges största vägassistansoperatörer. Här begär du tryggt och enkelt väghjälp direkt med våra professionella och erfarna bärgare.",
        errorTitle: "INGEN BÄRGARE LEDIG",
        errorDescription:
          "Tyvärr finns det inte någon ledig bärgningsbil i ditt område just nu. Var god försök igen senare.",
        noLocationServiceModal: {
          title: "POSITION NEKAD",
          text1:
            "Eftersom att du nekade oss åtkomst till din position kan vi tyvärr inte erbjuda dig assistans.",
          text2:
            "Vänligen klicka på knappen ring räddningscentralen så hjälper vi dig.",
          button: "OK"
        },
        metaData: {
          buttonText: "visa mer information",
          seoText1:
            "Assistansbolaget är snabba på att hjälpa dig med bärgning av bil, lätt lastbil, släpvagn, husbil eller MC i Stockholm. Vid ankomst försöker vi reparera ditt fordon på plats, om inte det är möjligt bärgas fordonet till närmaste verkstad.",
          seoText2:
            "Vår bärgning är trygg och prisvärd. Vi är det marknadsledande alternativet med 350 000 nöjda kunder i Norden."
        }
      },
      estimatePrice: {
        title: "PRELIMINÄRT PRIS",
        titleChange: "ÄNDRA DESTINATION",
        text1: "Om vi kan reparera ditt fordon på plats betalar du endast",
        text2: "Om ditt fordon behöver bärgas betalar du totalt",
        textEngineInsuranceCoverage:
          "Eftersom du har valt att du har hel- eller halvförsäkring så kommer vi endast att debitera dig din självrisk, överskjutande del debiteras ditt försäkringsbolag.",
        suggestedAssistancePrice: "REPARATION PÅ PLATS:",
        suggestedShopPrice: "BÄRGNING TILL:",
        suggestedPrice: "BERÄKNAT PRIS:",
        suggestedArrival: "BÄRGARENS ANKOMSTTID:",
        destinationButtonOpen: "ÄNDRA DESTINATION",
        destinationButtonSave: "SPARA & BERÄKNA NYTT PRIS",
        destinationButtonUndo: "ÅNGRA ÄNDRING",
        time: "kl"
      },
      customerDetails: {
        title: "MINA UPPGIFTER",
        text:
          "Vänligen fyll i dina uppgifter för att beräkna priset för din väghjälp.",
        carNumber: "Registreringsnummer",
        mobileNumber: "Telefonnummer",
        carPosition: "Fordonets position",
        socialNumber: "ÅÅÅÅMMDDXXXX",
        pnrInfo: "Som försäkringstagare behöver du ange ditt personnummer.",
        engineInsuranceTypeSelect: "Ange bilförsäkringstyp"
      },
      damageReason: {
        title: "ANGE VAD SOM ÄR FEL",
        selectDamage: "Ange vad som är fel",
        garage: "Är fordonet i ett P-hus eller annat trångt utrymme?",
        tierPlacement: "Välj däck",
        chooseFuelType: "Ange drivmedelstyp",
        warningLamp: "Välj färg på varningslampan",
        red: "Röd",
        yellow: "Gul",
        chooseReason: "Välj orsak",
        missing: "Saknas",
        defect: "Defekt",
        lockedInside: "Inlåst",
        petrol: "Bensin",
        diesel: "Diesel",
        etanol: "Etanol",
        electric: "El",
        frontTierLeft: "Vänster fram",
        frontTierRight: "Höger fram",
        rearTierLeft: "Vänster bak",
        rearTierRight: "Höger bak",
        spareTier: "Har du reservdäck?",
        acceptText: "Genom att boka bärgning godkänner du",
        terms: "användarvillkoren"
      },
      userTerms: {
        title: "ANVÄNDARVILLKOR",
        text: TERMS
      },
      tmaCarTerms: {
        title: "TMA-VILLKOR",
        text: TERMS
      },
      seekingResponder: {
        towtruck: {
          title: "VI SÖKER EFTER NÄRMSTA BÄRGNINGSBIL",
          text:
            "Vänligen vänta, stäng inte ner denna sida under tiden som vi söker efter en bärgningsbil åt dig."
        },
        dispatch: {
          title: "VI KOMMER ATT HJÄLPA DIG!",
          text1: "Som försäkringstagare hos Assistansbolaget får du",
          text2: "prioriterad",
          text3:
            "väghjälp. Ditt ärende har nu mottagits av vår räddningscentral.",
          text4:
            "Vänligen vänta, vi ringer upp dig inom några minuter på det telefonnummer du uppgett."
        }
      },
      confirmOrder: {
        title: "PRIS",
        text1: "Om vi kan reparera ditt fordon på plats betalar du endast",
        text2: "Om ditt fordon behöver bärgas betalar du totalt",
        estimateArrival: "BÄRGARENS ANKOMSTTID:",
        assistanceTitle: "REPARATION PÅ PLATS:",
        towingTitle: "BÄRGNING TILL:",
        confirm: "BEKRÄFTA",
        time: "kl"
      },
      abort: "AVBRYT",
      previousStep: "TILLBAKA",
      nextStep: "VIDARE",
      close: "STÄNG",
      yes: "Ja",
      no: "Nej",
      home: "TILL STARTSIDAN"
    },
    activeOrder: {
      unexpectedErrorModal: {
        title: "NÅGONTING GICK FEL",
        text:
          "Någonting gick fel och vi kan inte presentera information för dig. Vänligen kontakta oss på Tel: +46 42 324 324 så hjälper vi dig vidare.",
        button: "OK"
      },
      button: "OK",
      orderStartedTitle: "TACK, VI ÄR NU PÅ VÄG",
      orderStartedDescription:
        "Här kan du följa ditt ärende i realtid. En länk har även skickats till dig per SMS där också kan följa ditt ärende.",
      cancelOrder: "AVBRYT",
      callSuport: "RING RÄDDNINGSCENTRALEN",
      onWay: {
        title: "VI ÄR PÅ VÄG",
        text1: "Föraren",
        text2: "från",
        text3:
          "är på väg för att hjälpa dig. Räddningsfordonets registreringsnummer är:",
        time: "ANKOMSTTID:",
        etaOutdated:
          "Ankomsttiden har inte kunnat uppdateras på över fem minuter och kan därför vara missvisande."
      },
      arrived: {
        title: "VI ÄR PÅ PLATS",
        text1: "Föraren",
        text2: "från",
        text3: "är på plats. Räddningsfordonets registreringsnummer är:"
      },
      pickedup: {
        title: "DITT FORDON BÄRGAS",
        text1: "Föraren",
        text2: "från",
        text3: "bärgar ditt fordon till",
        text4: "Bärgningsbilens registreringsnummer är:"
      },
      dropped: {
        title: "DITT FORDON HAR BÄRGATS",
        text: "",
        text1: "Föraren",
        text2: "från",
        text3: "har nu bärgat ditt fordon till"
      },
      canceled: {
        title: "DIN VÄGHJÄLP ÄR AVBRUTEN",
        text1:
          "Du har valt att avbryta din väghjälp och kommer att debiteras för framkörningsavgiften om",
        arrivalFee: "FRAMKÖRNINGSAVGIFT:",
        towTruckTitle: "UPPDRAGET ÄR AVBRUTET",
        ok: "OK",
        towTruckText:
          "Bärgaren har avbrutit uppdraget. Vi kommer att kontakta dig inom kort."
      },
      complete: {
        title: "UPPDRAGET ÄR SLUTFÖRT",
        text1:
          "Tack för att du valde Assistansbolaget. Vi hoppas att du är nöjd med vår hjälp.",
        text2:
          "Vid frågor är du alltid varmt välkommen att kontakta vår räddningscentral."
      },
      cancelOrderModal: {
        abort: "NEJ, AVBRYT INTE",
        cancel: "JA, AVBRYT",
        title: "AVBRYT VÄGHJÄLP",
        text1:
          "Vi är redan på väg till dig. Om du väljer att avbryta din väghjälp nu kommer du att debiteras för framkörningsavgiften om",
        text2: "Är du säker på att du vill avbryta?"
      }
    },
    summary: {
      receipt: {
        header: "TACK FÖR ATT DU VALDE ASSISTANSBOLAGET.",
        title: "SAMMANFATTNING AV DIN BOKNING",
        text1: "Vi hoppas att du är nöjd med vår hjälp.",
        text2: "Betygsätt oss gärna - ditt omdöme är viktigt för oss!",
        text3: "Maila mig kvitto & uppdragsbeskrivning:",
        assistance: "ASSISTANS:",
        towing: "BÄRGNING:",
        shopChange: "BYTE AV VERKSTAD:",
        gradeExperience: "Betygsätt din upplevelse",
        summary: "SKRIV OMDÖME",
        placeholder: "Ange din epostadress",
        sendEmailButton: "SKICKA",
        emailSent: "SKICKAD",
        repairAtSpotLabel: "Reparerad på plats",
        downloadReceipt: "HÄMTA FULLSTÄNDIGT KVITTO",
        customerLabel: "Kund",
        nameLabel: "Namn",
        addressLabel: "Adress",
        vehicleLabel: "Fordon",
        brandLabel: "Bilmärke",
        yearLabel: "År",
        regnoLabel: "Registreringsnummer",
        damageInformationLabel: "Skadeinformation",
        damageReasonLabel: "Skadeorsak",
        dateLabel: "Datum",
        towedFromLabel: "Bärgad från",
        towedToLabel: "Bärgad till",
        chargedDistanceLabel: "Debiterad sträcka",
        costsLabel: "Kostnader",
        costTypeLabel: "Kostnadstyp",
        costLabel: "Kostnader",
        paymentMethodLabel: "Betalningssätt",
        totalLabel: "Totalt",
        assistansbolagetAddress1: "Rescue Europe AB",
        assistansbolagetAddress2: "Redaregatan 50",
        assistansbolagetAddress3: "252 36 Helsingborg",
        receiptHeader: "KVITTO FÖR VÄGASSISTANSUPPDRAG",
        assistanstbolaget: "Assistansbolaget",
        emailLabel: "Epost",
        email: "kontakt@assistansbolaget.nu",
        orgNrLabel: "Org. nr",
        orgNr: "559248-8265",
        phoneNrLabel: "Telefon",
        phoneNr: "042324 324",
        vatLabel: "Varav moms",
        missionId: "Uppdragsnummer"
      },
      opinion: {
        title: "HUR VAR DIN UPPLEVELSE?",
        text1: "Tack för att du valde Assistansbolaget!",
        text2:
          "Vi skulle uppskatta om du lämnade ett omdöme om din upplevelse så att vi kan bli ännu bättre.",
        placeholder: "Beskriv din upplevelse...",
        send: "SKICKA OMDÖME"
      },
      summarySuccessModal: {
        title: "TACK FÖR DIN RECENSION",
        text: "Din åsikt är viktig för oss!",
        primaryButton: "STÄNG"
      },
      summaryFailureModal: {
        title: "NÅGONTING GICK FEL",
        text: "Vänligen försök igen.",
        primaryButton: "STÄNG"
      },
      receiptFailureModal: {
        title: "NÅGONTING GICK FEL",
        text:
          "Vi kunde tyvärr inte skicka uppdragsbeskrivningen till dig. Vänligen försök igen eller kontakta oss på Tel: +46 42 324 324.",
        primaryButton: "STÄNG"
      }
    },
    carPositionPicker: {
      placeholder: "Vart står fordonet?",
      loading: "Laddar...",
      saveButton: "OK",
      searchError: "Du måste välja ett av sökresultaten"
    },
    estimatePriceView: {
      searchLocationPlaceholder: "Vart vill du bärgas?"
    },
    dispatchPosition: {
      locationBlockedModal: {
        title: "ÅTKOMST BLOCKERAD",
        text:
          "Kontrollera webbläsarens inställningar över tillåtna platser för att ge åtkomst till platsinformation."
      },
      unexpectedErrorModal: {
        title: "NÅGONTING GICK FEL",
        text:
          "Någonting gick fel, vi kunde inte positionera ditt fordon. Vänligen försök igen."
      },
      successModal: {
        title: "Tack!",
        text: "Ditt fordons position har registrerats."
      },
      okButtonText: "OK"
    },
    loginTriggerComponent: {
      text: "Vänligen vänta, uppgifter hämtas."
    },
    damageDetails: {
      frontLeft: "Vänster fram",
      frontRight: "Höger fram",
      rearLeft: "Vänster bak",
      rearRight: "Höger bak",
      missing: "Saknas",
      defect: "Defekt",
      lockedInsideVehicle: "Inlåst",
      petrol: "Bensin",
      diesel: "Diesel",
      ethanol: "Etanol",
      electric: "El",
      red: "Röd",
      yellow: "Gul",
      errorMessage: "Felmeddelande",
      camDrive: "Kamdrivning/drivsystem",
      turbo: "Turbo/kompressor",
      oilLeakage: "Oljeläckage",
      fanBelt: "Fläktrem",
      coolingSystem: "Kylsystem",
      waterPump: "Vattenpump",
      powerTransmission: "Kraftöverföring",
      clutch: "Koppling",
      flywheel: "Svänghjul",
      distributionBox: "Fördelningslåda",
      generator: "Generator",
      controlDevice: "Styrdon/styrenhet",
      ignitionLock: "Tändlås",
      batteryBank: "Batteribank(elbil)",
      fuelInjector: "Spridare",
      fuelPump: "Bränslepump",
      gasRegulator: "Gasregulator",
      steeringGear: "Styrväxel",
      servopump: "Servopump",
      steeringWheelLock: "Rattlås",
      abs: "ABS/ESP",
      parkingBrake: "Parkeringsbroms",
      brakeServo: "Bromsservo",
      brakePads: "Bromsok/skivor/klossar",
      rearCarriage: "Bakvagn",
      frontCarriage: "Framvagn",
      exhaustSystem: "Avgassystem",
      hydraulics: "Hydraulik",
      glassDamage: "Glasskada",
      fire: "Brand",
      fuelError: "Feltankning"
    },
    damageReasons: {
      flatTire: "Punktering",
      startHelp: "Strömlöst batteri",
      keyIssues: "Nyckelproblem",
      propulsion: "Drivmedelsbrist",
      warningLight: "Varningslampa lyser",
      engineProblem: "Motor",
      gearBox: "Växellåda & drivning",
      electronics: "Elektronik",
      fuelSystem: "Bränslesystem",
      steering: "Styrning",
      brakingSystem: "Bromssystem",
      otherDamage: "Övrig skada",
      collision: "Kollision",
      ditch: "Dikeskörning",
      transport: "Transport",
      drivingBan: "Körförbud"
    },
    accidentOptionFunction: {
      chooseWarningColor: "Välj färg på varningslampan",
      chooseReason: "Välj orsak",
      chooseTire: "Välj däck",
      fuelType: "Ange typ av drivmedel",
      damageDetail: "Skadeorsak detalj",
      chooseEngineProblem: "Välj motorproblem",
      chooseGearBox: "Välj växellådeproblem",
      chooseElectronics: "Välj elfel",
      chooseFuelSystem: "Välj bränslesystemsfel",
      chooseSteering: "Välj styrningsfel",
      chooseBrakingSystem: "Välj bromsfel",
      chooseOtherDamage: "Välj övrig skada"
    }
  }
};
