import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import classNames from "classnames";
import PropTypes from "prop-types";
import actions from "./actions";
import selectors from "./selectors";
import Modal from "../../components/Modal/Modal";

import DispatchCarPositionPicker from "./dispatchCarPositionPicker";

import "./style.css";
import { SESSION_KEYS, setSession } from "../../helpers/sessionStore";

const { toggleCarLocalizationErrorModal, getPosition } = actions;

const {
  getShowDispatchMap,
  getCarLocalizationErrorModalState,
  getCarLocalizationSuccessModalState,
  getLocationBlockedModalState
} = selectors;

class DispatchPosition extends Component {
  componentDidMount() {
    const { requestPosition } = this.props;
    setSession(SESSION_KEYS.appId, process.env.REACT_APP_APPLICATION_ID);
    if (window.location.search.includes("token")) {
      requestPosition();
    }
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("locale")) {
      setSession(SESSION_KEYS.locale, urlParams.get("locale"));
    }
  }

  render() {
    const {
      locationBlockedModalState,
      dispatchPositionErrorModalState,
      dispatchPositionSuccessModalState,
      showDispatchMap,
      requestPosition,
      intl
    } = this.props;

    const windowReloadAction = () => {
      requestPosition();
    };

    return (
      <div
        className={classNames(
          "createOrder flex-column align-center justify-center"
        )}
      >
        {locationBlockedModalState && (
          <Modal
            title={intl.formatMessage({
              id: "DispatchPosition.AccessDeniedModal.Title",
              defaultMessage: "Access denied",
              description: "dispatchPosition: access denied modal title"
            })}
            bodyText={intl.formatMessage({
              id: "DispatchPosition.AccessDeniedModal.Text",
              defaultMessage:
                "Check the browser settings for allowed locations to grant access to location information.",
              description: "dispatchPosition: access denied modal text"
            })}
            windowReload={intl.formatMessage({
              id: "DispatchPosition.AccessDeniedModal.ButtonText",
              defaultMessage: "Reload the page",
              description: "dispatchPosition: access denied modal button text"
            })}
            windowReloadAction={windowReloadAction}
          />
        )}
        {dispatchPositionErrorModalState && (
          <Modal
            title={intl.formatMessage({
              id: "DispatchPosition.UnexpectedError.Title",
              defaultMessage: "Something went wrong",
              description: "dispatchPosition: unexpected error modal title"
            })}
            bodyText={intl.formatMessage({
              id: "DispatchPosition.UnexpectedError.Text",
              defaultMessage:
                "Something went wrong, we were unable to locate your vehicle. Please try again.",
              description: "dispatchPosition: unexpected error modal text"
            })}
          />
        )}
        {dispatchPositionSuccessModalState && (
          <Modal
            title={intl.formatMessage({
              id: "DispatchPosition.SuccessModal.Title",
              defaultMessage: "Thank you",
              description: "successModal: success modal title"
            })}
            bodyText={intl.formatMessage({
              id: "DispatchPosition.SuccessModal.Text",
              defaultMessage: "Your vehicle location has been updated",
              description: "successModal: success modal text"
            })}
          />
        )}
        <div className="dispatchPosition flex-column align-center justify-center">
          {showDispatchMap && <DispatchCarPositionPicker />}
        </div>
      </div>
    );
  }
}

DispatchPosition.propTypes = {
  requestPosition: PropTypes.func.isRequired,
  dispatchPositionErrorModalState: PropTypes.bool.isRequired,
  dispatchPositionSuccessModalState: PropTypes.bool.isRequired,
  showDispatchMap: PropTypes.bool.isRequired,
  locationBlockedModalState: PropTypes.bool.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired
};

const mapStateToProps = state => ({
  locationBlockedModalState: getLocationBlockedModalState(state),
  dispatchPositionErrorModalState: getCarLocalizationErrorModalState(state),
  dispatchPositionSuccessModalState: getCarLocalizationSuccessModalState(state),
  showDispatchMap: getShowDispatchMap(state)
});

const mapDispatchToProps = dispatch => ({
  closeErrorModal: () => {
    dispatch(toggleCarLocalizationErrorModal(false));
  },
  requestPosition: () => dispatch(getPosition())
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(DispatchPosition)
);
