import React, { useEffect, useState } from "react";
import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import { useSessionStorage } from "usehooks-ts";
import styles from "./pdf.style";
import { SESSION_KEYS } from "../../../../helpers/sessionStore";

const PDF = ({
  summary,
  damageDate,
  fixedOnPlace,
  markers,
  sourceAddress,
  destinationAddress,
  intl
}) => {
  const { brand, year, registrationNumber } = summary?.customerVehicle || {};
  const { firstName, lastName, city, zipCode, address } =
    summary?.customer || {};
  const [companyAttributes] = useSessionStorage(
    SESSION_KEYS.companyAttributes,
    undefined
  );
  const [organizationHeaderName] = useSessionStorage(
    SESSION_KEYS.organizationHeaderName,
    undefined
  );
  const companyName = organizationHeaderName;
  const companyAddress = companyAttributes?.CompanyAddress?.value;
  const companyZipCode = companyAttributes?.CompanyZipCode?.value;
  const companyCity = companyAttributes?.CompanyCity?.value;
  const companyPhone = companyAttributes?.CompanyPhone?.value;
  const companyLogo = companyAttributes?.CompanyLogo?.value;
  const companyEmail = companyAttributes?.CompanyEmail?.value;
  const organisationNumber = companyAttributes?.OrganisationNumber?.value;

  const todaysDate = new Date().toLocaleDateString("SV");

  const baseUrl = "https://maps.googleapis.com/maps/api/staticmap";
  const apiKey = "AIzaSyCM3Er5wltGE45H78BW1z__41onnf_xl-0";
  const markerParameters = () => {
    if (markers.length === 1) {
      return markers
        .map(m => `markers=icon:${m.icon}%7C${m.lat},${m.long}&zoom=13`)
        .join("&");
    }
    return markers
      .map(m => `markers=icon:${m.icon}%7C${m.lat},${m.long}`)
      .join("&");
  };

  const mapUrl = `${baseUrl}?${markerParameters()}&size=800x300&key=${apiKey}&format=png&maptype=roadmap&style=element:geometry%7Ccolor:0x40464f&style=element:labels.text.fill%7Ccolor:0x746855&style=element:labels.text.stroke%7Ccolor:0x242f3e&style=feature:administrative.locality%7Celement:labels.text.fill%7Ccolor:0xd59563&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0xd59563&style=feature:poi.park%7Celement:geometry%7Ccolor:0x344a48&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x6b9a76&style=feature:road%7Celement:geometry%7Ccolor:0x38414e&style=feature:road%7Celement:geometry.stroke%7Ccolor:0x212a37&style=feature:road%7Celement:labels.text.fill%7Ccolor:0x9ca5b3&style=feature:road.arterial%7Ccolor:0x808793&style=feature:road.highway%7Celement:geometry.fill%7Ccolor:0x707683&style=feature:road.highway%7Celement:geometry.stroke%7Ccolor:0x707783&style=feature:road.highway%7Celement:labels.text.fill%7Ccolor:0xf3d19c&style=feature:road.local%7Ccolor:0x808793&style=feature:transit%7Celement:geometry%7Ccolor:0x2f3948&style=feature:transit.station%7Celement:labels.text.fill%7Ccolor:0xd59563&style=feature:water%7Celement:geometry%7Ccolor:0x272d34&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x515c6d&style=feature:water%7Celement:labels.text.stroke%7Ccolor:0x17263c`;

  const finishedOrders = summary?.orders?.filter(
    item => item?.status === "Finished"
  );

  const totalCost = finishedOrders?.reduce((accumulator, orderLine) => {
    return (
      accumulator +
      orderLine?.orderLines?.reduce((subtotal, orderLineItem) => {
        return subtotal + orderLineItem?.total + orderLineItem?.calculatedVAT;
      }, 0)
    );
  }, 0);

  const currency = summary?.currency;

  const vat = finishedOrders?.reduce((accumulator, orderLine) => {
    return (
      accumulator +
      orderLine?.orderLines?.reduce((subtotal, orderLineItem) => {
        return subtotal + orderLineItem?.calculatedVAT;
      }, 0)
    );
  }, 0);

  const [dataPicture, setDataPicture] = useState(null);

  useEffect(() => {
    const img = document.createElement("img");
    img.crossOrigin = "anonymous";
    img.src = companyLogo;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = img?.width;
      canvas.height = img?.height;
      ctx.filter = "invert(100%)";
      ctx.drawImage(img, 0, 0);
      const dataURI = canvas.toDataURL("image/png");
      setDataPicture(dataURI);
    };
  }, [companyLogo]);

  return (
    <Document>
      <Page size="A4" style={styles.body} wrap>
        <View fixed>
          <View style={styles.recieptTextContainer}>
            <Text style={styles.recieptText}>
              {intl.formatMessage({
                id: "Summary.PDF.ReceiptGenerated",
                defaultMessage: "Receipt generated:",
                description: "PDF: Receipt generated"
              })}{" "}
              {todaysDate}
            </Text>
            <Text>
              <Text style={styles.recieptHeader}>
                {intl
                  .formatMessage({
                    id: "Summary.PDF.Receipt",
                    defaultMessage: "Receipt",
                    description: "PDF: Receipt"
                  })
                  .toUpperCase()}
              </Text>
            </Text>
          </View>
        </View>
        <View style={styles.iconNav} fixed>
          <View style={styles.iconSize}>
            <View style={styles.recieptAdressContainer}>
              <Text style={styles.address}>{companyName}</Text>
              <View>
                <Text style={styles.recieptAdress}>{companyAddress}</Text>
                <Text style={styles.recieptAdress}>
                  {`${companyZipCode} ${companyCity}`}
                </Text>
              </View>
            </View>
            <View style={styles.iconContainer}>
              <Image
                src={{
                  uri: dataPicture,
                  method: "GET",
                  headers: { "Cache-Control": "no-cache" },
                  body: ""
                }}
                alt="Company logo"
                style={styles.logoImage}
              />
            </View>
          </View>
        </View>

        <View style={styles.testEtt}>
          <View style={styles.heroContainer}>
            <View style={styles.borderLine}>
              <View style={styles.heroOne}>
                <View style={styles.oneInfo}>
                  <Text style={styles.header}>
                    {intl.formatMessage({
                      id: "Summary.PDF.CustomerOrderLabel",
                      defaultMessage: "Client",
                      description: "PDF: Client label"
                    })}
                  </Text>
                  <Text style={styles.smallText}>
                    {firstName} {lastName}
                  </Text>
                  <Text style={styles.smallText}>{address}</Text>
                  <Text style={styles.smallText}>
                    {zipCode} {city}
                  </Text>
                </View>

                <View style={styles.oneInfo}>
                  <Text style={styles.header}>
                    {intl.formatMessage({
                      id: "Summary.PDF.VehicleLabel",
                      defaultMessage: "Vehicle",
                      description: "PDF: Vehicle label"
                    })}
                  </Text>
                  <Text style={styles.smallText}>
                    {intl.formatMessage({
                      id: "Summary.PDF.RegistrationNumberLabel",
                      defaultMessage: "Registration number",
                      description: "PDF: Registration number label"
                    })}
                    : {registrationNumber}
                  </Text>
                  <Text style={styles.smallText}>
                    {intl.formatMessage({
                      id: "Summary.PDF.VehicleBrandLabel",
                      defaultMessage: "Vehicle brand",
                      description: "PDF: Vehicle brand label"
                    })}
                    : {brand}
                  </Text>
                  <Text style={styles.smallText}>
                    {intl.formatMessage({
                      id: "Summary.PDF.VehicleYearLabel",
                      defaultMessage: "Year model",
                      description: "PDF: Year model label"
                    })}
                    : {year}
                  </Text>
                </View>
              </View>
            </View>

            <View style={styles.borderLine}>
              <View style={styles.heroOne}>
                <View style={styles.oneInfo}>
                  <Text style={styles.header}>
                    {intl.formatMessage({
                      id: "Summary.PDF.MissionLabel",
                      defaultMessage: "Mission",
                      description: "PDF: mission label label"
                    })}
                  </Text>
                  <Text style={styles.smallText}>
                    {intl.formatMessage({
                      id: "Summary.PDF.MissionIdLabel",
                      defaultMessage: "Mission ID",
                      description: "PDF: mission id label"
                    })}
                    : {summary?.shortId}
                  </Text>
                  <Text style={styles.smallText}>
                    {intl.formatMessage({
                      id: "Summary.PDF.MissionTypeLabel",
                      defaultMessage: "Mission type",
                      description: "PDF: mission type label"
                    })}
                    :{" "}
                    <Text style={styles.smallText}>
                      {summary?.missionTypeName}
                    </Text>
                  </Text>

                  <Text style={styles.smallText}>
                    {intl.formatMessage({
                      id: "Summary.PDF.DamageReasonLabel",
                      defaultMessage: "Damage reason",
                      description: "PDF: damage reason label"
                    })}
                    :{" "}
                    <Text style={styles.smallText}>
                      {summary?.missionReasonName}
                    </Text>
                  </Text>

                  <Text style={styles.smallText}>
                    {intl.formatMessage({
                      id: "Summary.PDF.MissionStartedLabel",
                      defaultMessage: "Mission started",
                      description: "PDF: mission started label"
                    })}
                    : {damageDate}
                  </Text>
                </View>

                <View style={styles.oneInfo}>
                  <Text style={styles.header}>
                    {intl.formatMessage({
                      id: "Summary.PDF.MissionLocationsLabel",
                      defaultMessage: "Locations",
                      description: "PDF: mission locations label"
                    })}
                  </Text>
                  {fixedOnPlace ? (
                    <>
                      <Text style={styles.mediumText}>
                        {intl.formatMessage({
                          id: "Summary.PDF.TowedFromLabel",
                          defaultMessage: "Towed from",
                          description: "PDF: towed from label"
                        })}
                        : <Text style={styles.smallText}>{sourceAddress}</Text>
                      </Text>
                    </>
                  ) : (
                    <>
                      <Text style={styles.mediumText}>
                        {intl.formatMessage({
                          id: "Summary.PDF.DamageScene",
                          defaultMessage: "Damage scene",
                          description: "PDF: damage scene label"
                        })}
                        : <Text style={styles.smallText}>{sourceAddress}</Text>
                      </Text>
                    </>
                  )}
                  {destinationAddress && fixedOnPlace && (
                    <>
                      <Text style={styles.mediumText}>
                        {intl.formatMessage({
                          id: "Summary.PDF.TowedToLabel",
                          defaultMessage: "Towed to",
                          description: "PDF: towed to label"
                        })}
                        :{" "}
                        <Text style={styles.smallText}>
                          {destinationAddress}
                        </Text>
                      </Text>
                    </>
                  )}
                </View>
              </View>
            </View>

            <View style={styles.priceInfo}>
              <View style={styles.oneInfo}>
                <Text style={styles.header}>
                  {intl.formatMessage({
                    id: "Summary.PDF.SpecificationLabel",
                    defaultMessage: "Specification",
                    description: "PDF: specification label"
                  })}
                </Text>
                {finishedOrders?.map(order => {
                  return order.orderLines
                    ? order.orderLines.map(line => (
                        <Text style={styles.smallText}>{line.articleName}</Text>
                      ))
                    : null;
                })}
              </View>

              <View style={styles.onePricesInfo}>
                <Text style={styles.header}>
                  {intl.formatMessage({
                    id: "Summary.PDF.PriceLabel",
                    defaultMessage: "Costs",
                    description: "PDF: costs label"
                  })}
                </Text>
                {finishedOrders?.map(order => {
                  return order.orderLines
                    ? order.orderLines.map(line => (
                        <Text style={styles.smallText}>
                          {line.total + line.calculatedVAT} {currency}
                        </Text>
                      ))
                    : null;
                })}
              </View>
            </View>

            <View style={styles.priceInfo}>
              <View style={styles.oneInfo}>
                <Text style={styles.header}>
                  {intl.formatMessage({
                    id: "Summary.PDF.TotalAmountLabel",
                    defaultMessage: "Total amount",
                    description: "PDF: total amount label"
                  })}
                </Text>
                <Text style={styles.smallText}>
                  {intl.formatMessage({
                    id: "Summary.PDF.VATLabel",
                    defaultMessage: "VAT",
                    description: "PDF: VAT label"
                  })}
                </Text>
              </View>

              <View style={styles.onePricesInfo}>
                <Text style={styles.header}>
                  {totalCost} {currency}
                </Text>
                <Text style={styles.smallText}>
                  {vat} {currency}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.imageContainer}>
            <Image style={styles.image} src={mapUrl} />
          </View>
        </View>

        <View style={styles.footerContainer} fixed>
          <View style={styles.divider} />
          <View style={styles.footer}>
            <View>
              <Text style={styles.smallTextBold}>{companyName}</Text>
            </View>
            <View>
              <Text style={styles.smallTextBold}>{companyAddress}</Text>
              <Text style={styles.smallTextThin}>
                {`${companyZipCode} ${companyCity}`}
              </Text>
            </View>

            <View>
              <Text style={styles.smallTextBold}>
                {intl.formatMessage({
                  id: "Summary.PDF.FooterOrgNumberLabel",
                  defaultMessage: "Org. number",
                  description: "PDF: org. number label"
                })}
              </Text>
              <Text style={styles.smallTextThin}>{organisationNumber}</Text>
            </View>

            <View>
              <Text style={styles.smallTextBold}>
                {intl.formatMessage({
                  id: "Summary.PDF.FooterPhoneLabel",
                  defaultMessage: "Phone",
                  description: "PDF: phone label"
                })}
              </Text>
              <Text style={styles.smallTextThin}>{companyPhone}</Text>
            </View>

            <View>
              <Text style={styles.smallTextBold}>
                {intl.formatMessage({
                  id: "Summary.PDF.FooterEmailLabel",
                  defaultMessage: "E-mail",
                  description: "PDF: email label"
                })}
              </Text>
              <Text style={styles.smallTextThin}>{companyEmail}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

PDF.propTypes = {
  summary: PropTypes.instanceOf(Object).isRequired,
  damageDate: PropTypes.string.isRequired,
  fixedOnPlace: PropTypes.bool.isRequired,
  markers: PropTypes.instanceOf(Array).isRequired,
  sourceAddress: PropTypes.string.isRequired,
  destinationAddress: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired
};

export default PDF;
