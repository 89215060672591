/* global google */
import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import { SWEDEN_LAT_LNG, DENMARK_LAT_LNG } from "../../helpers/locations";
import { directionsMapOptions } from "./defaultMapOptions";
import { GOOGLE_MAP_URL } from "../../constants/apiConstants";
import { selectors as carPositionSelectors } from "../carPositionPicker";
import { getSession, SESSION_KEYS } from "../../helpers/sessionStore";

const { getCenterCoords } = carPositionSelectors;

const CreateOrderMap = compose(
  withProps({
    googleMapURL: GOOGLE_MAP_URL,
    loadingElement: <div className="full-height-width" />,
    containerElement: <div className="map-container-element" />,
    mapElement: <div className="full-height-width" />
  }),
  withScriptjs,
  withGoogleMap
)(props => {
  const { carPositionCoordinates } = props;
  const [zoomLevel, setZoomLevel] = useState(4);
  const appId = getSession(SESSION_KEYS.appId);

  return (
    <>
      <GoogleMap
        ref={map => {
          if (map && carPositionCoordinates && carPositionCoordinates.lat) {
            const carLatLng = new google.maps.LatLng({
              lat: carPositionCoordinates.lat,
              lng: carPositionCoordinates.lng
            });
            const headerHeight = 35;
            const contentHeight = 400;
            const visibleHeight =
              window.innerHeight - headerHeight - contentHeight;

            setTimeout(() => {
              setZoomLevel(16);
              map.panTo(carLatLng);
              map.panBy(
                0,
                visibleHeight / 2 +
                  (contentHeight - (window.innerHeight - headerHeight) / 2) -
                  headerHeight
              );
            }, 100);
          }
        }}
        zoom={zoomLevel}
        defaultCenter={
          appId === "gat-rescue-frontend" ? SWEDEN_LAT_LNG : DENMARK_LAT_LNG
        }
        defaultOptions={directionsMapOptions}
        className="google-map-container"
      >
        {carPositionCoordinates && (
          <Marker
            zIndex={100}
            name="carPosition"
            position={{
              lat: carPositionCoordinates.lat,
              lng: carPositionCoordinates.lng
            }}
            icon={{
              path: google.maps.SymbolPath.CIRCLE,
              scale: 7,
              fillOpacity: 1,
              fillColor: "#FFD4A3",
              strokeWeight: 2,
              strokeColor: "#232323",
              strokeOpacity: 1
            }}
            animation={google.maps.Animation.DROP}
          />
        )}

        {carPositionCoordinates && (
          <Marker
            zIndex={1}
            name="circle"
            position={{
              lat: carPositionCoordinates.lat,
              lng: carPositionCoordinates.lng
            }}
            icon={{
              path: google.maps.SymbolPath.CIRCLE,
              scale: 16,
              fillOpacity: 0.2,
              fillColor: "#FFD4A3",
              strokeWeight: 0.2,
              strokeColor: "#232323",
              strokeOpacity: 0.2
            }}
            animation={google.maps.Animation.DROP}
          />
        )}
      </GoogleMap>
    </>
  );
});

CreateOrderMap.propTypes = {
  carPositionCoordinates: PropTypes.shape({
    accuracy: PropTypes.number,
    lat: PropTypes.number,
    lng: PropTypes.number
  })
};

const mapStateToProps = state => ({
  carPositionCoordinates: getCenterCoords(state)
});

export default connect(mapStateToProps, null)(CreateOrderMap);
