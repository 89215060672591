/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
// import check from "../../assets/images/check-solid-blue.svg";

import "./style.css";
import { useIntl } from "react-intl";
import { CustomerDetailsInput } from "../../features/createOrder/customerDetails.style";

const Input = ({ input, meta, ...props }) => {
  const { placeholder } = props;
  const { active, error, touched } = meta;
  const shouldShowError = touched && !active && error;

  const intl = useIntl();

  // TODO - Errorhandling for input
  return (
    <div className="input-container">
      <CustomerDetailsInput
        type="text"
        label={placeholder}
        size="large"
        error={
          shouldShowError
            ? intl.formatMessage({
                id: "CreateOrder.RegNumber.ErrorMessage",
                defaultMessage: "Wrong format",
                description: "Startpage: registration number error message"
              })
            : undefined
        }
        {...input}
      />
    </div>
  );
};

Input.defaultProps = {
  image: null,
  loading: false,
  customFormerror: false,
  customErrorStyle: false
};

Input.propTypes = {
  input: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  image: PropTypes.string,
  loading: PropTypes.bool,
  customFormerror: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  customErrorStyle: PropTypes.bool,
  placeholder: PropTypes.string.isRequired
};

export default Input;
