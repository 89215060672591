/* global google */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { compose, withProps, withState } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import { DRIVER_DRIVING_TO_DESTINATION } from "../../constants/orderStatusConstants";
import { SWEDEN_LAT_LNG, DENMARK_LAT_LNG } from "../../helpers/locations";

import { directionsMapOptions } from "./defaultMapOptions";
import { GOOGLE_MAP_URL } from "../../constants/apiConstants";
import vehiclePosition from "../../assets/images/car-position-icon.svg";
import shopPosition from "../../assets/images/ico_repair_destination.svg";
import truckIcon from "./towIcon";
import { getSession, SESSION_KEYS } from "../../helpers/sessionStore";

const DELTA_POSITION_THRESHOLD = 0.00001;

const DirectionsMap = compose(
  withProps({
    googleMapURL: GOOGLE_MAP_URL,
    loadingElement: <div className="full-height-width" />,
    containerElement: <div className="map-container-element" />,
    mapElement: <div className="full-height-width" />
  }),
  withState("fitBounds", "setFitBounds", true),
  withState("heading", "setHeading", 0),
  withScriptjs,
  withGoogleMap
)(props => {
  const {
    vehicleCoordinates,
    towtruckCoordinates,
    previousTowtruckCoordinates,
    orderStatus,
    shopCoordinates
  } = props;
  const [markers, setMarkers] = useState();
  const appId = getSession(SESSION_KEYS.appId);

  useEffect(() => {
    if (orderStatus === DRIVER_DRIVING_TO_DESTINATION) {
      setMarkers([
        {
          name: "shop",
          lat: shopCoordinates.latitude,
          lng: shopCoordinates.longitude,
          icon: shopPosition,
          id: 1
        },
        {
          name: "towtruck",
          lat: towtruckCoordinates.latitude,
          lng: towtruckCoordinates.longitude,
          icons: truckIcon,
          id: 2
        }
      ]);
    } else {
      setMarkers([
        {
          name: "vehicle",
          lat: vehicleCoordinates.lat,
          lng: vehicleCoordinates.lng,
          icon: vehiclePosition,
          id: 1
        },
        {
          name: "towtruck",
          lat: towtruckCoordinates.latitude,
          lng: towtruckCoordinates.longitude,
          icons: truckIcon,
          id: 2
        }
      ]);
    }
  }, [shopCoordinates, towtruckCoordinates, vehicleCoordinates, orderStatus]);

  useEffect(() => {
    if (
      google &&
      google.maps &&
      towtruckCoordinates &&
      towtruckCoordinates.latitude
    ) {
      const point1 = new google.maps.LatLng({
        lat: previousTowtruckCoordinates.latitude,
        lng: previousTowtruckCoordinates.longitude
      });
      const point2 = new google.maps.LatLng({
        lat: towtruckCoordinates.latitude,
        lng: towtruckCoordinates.longitude
      });

      if (
        Math.abs(
          previousTowtruckCoordinates.latitude - towtruckCoordinates.latitude
        ) > DELTA_POSITION_THRESHOLD &&
        Math.abs(
          previousTowtruckCoordinates.longitude - towtruckCoordinates.longitude
        ) > DELTA_POSITION_THRESHOLD
      ) {
        const heading = previousTowtruckCoordinates.latitude
          ? google.maps.geometry.spherical.computeHeading(point1, point2) + 0
          : 0;
        props.setHeading(heading);
      }
    }
  }, [google.maps, towtruckCoordinates, previousTowtruckCoordinates]);

  const deltaLat = Math.abs(
    vehicleCoordinates.lat - towtruckCoordinates.latitude
  );
  const bounds = new window.google.maps.LatLngBounds();
  const headerHeight = 35;
  const contentHeight = 274;
  const contentWidth = 310;
  const towTruckMarkerHeight = 75;
  const vehicleMarkerHeight = 32;
  const visibleHeight = window.innerHeight - headerHeight - contentHeight;
  const paddingLeftRight = (window.innerWidth - contentWidth) / 2;
  const bottomPadding =
    window.innerHeight - visibleHeight - headerHeight + towTruckMarkerHeight;
  const topPadding =
    window.innerHeight - visibleHeight - contentHeight + vehicleMarkerHeight;

  return (
    <>
      <div
        className="fitbounds-button-container"
        style={props.fitBounds ? { opacity: 0, pointerEvents: "none" } : null}
      >
        <button
          type="button"
          className="fitBounds-button flex-right"
          onClick={() => {
            props.setFitBounds(true);
          }}
        />
      </div>

      {markers && (
        <GoogleMap
          ref={map => {
            if (map) {
              markers.map(marker => {
                if (marker.lat && marker.lng) {
                  bounds.extend({ lat: marker.lat, lng: marker.lng });
                }
                return marker.id;
              });
              if (props.fitBounds) {
                map.fitBounds(bounds, {
                  top: topPadding,
                  bottom: bottomPadding,
                  left: paddingLeftRight,
                  right: paddingLeftRight
                });
                if (deltaLat < 0.000233) {
                  map.panBy(
                    0,
                    visibleHeight / 2 +
                      (contentHeight -
                        (window.innerHeight - headerHeight) / 2) -
                      headerHeight
                  );
                }
              }
            }
          }}
          defaultCenter={
            appId === "gat-rescue-frontend" ? SWEDEN_LAT_LNG : DENMARK_LAT_LNG
          }
          defaultOptions={directionsMapOptions}
          onClick={() => props.setFitBounds(false)}
          onDragStart={() => props.setFitBounds(false)}
        >
          {markers.length > 0 &&
            markers.map(marker => {
              let icon;
              let anchor;
              let size;
              if (marker.icons) {
                const type = "towtruck";
                /* -- TODO - Ändra färg på bärgningsbil */
                const iconString = marker.icons("#FFD4A3", props.heading, type);
                const base64IconString = Buffer.from(
                  iconString,
                  "utf8"
                ).toString("base64");
                icon = `data:image/svg+xml;base64,${base64IconString}`;
                const ICON_WIDTH = 55;
                const ICON_HEIGHT = 55;
                size = new window.google.maps.Size(ICON_WIDTH, ICON_HEIGHT);
                anchor = new window.google.maps.Point(
                  ICON_WIDTH / 2,
                  ICON_HEIGHT / 2
                );
              } else if (marker.name === "vehicle") {
                // eslint-disable-next-line
                icon = marker.icon;
                anchor = new google.maps.Point(15, 15);
              } else {
                // eslint-disable-next-line
                icon = marker.icon;
              }
              if (!marker.lat || !marker.lng) return null;

              return (
                <Marker
                  key={marker.name}
                  name={marker.name}
                  position={{
                    lat: marker.lat,
                    lng: marker.lng
                  }}
                  icon={{
                    url: icon,
                    anchor,
                    size
                  }}
                />
              );
            })}
        </GoogleMap>
      )}
    </>
  );
});

DirectionsMap.propTypes = {
  vehicleCoordinates: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }).isRequired,
  towtruckCoordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }).isRequired
};

export default DirectionsMap;
