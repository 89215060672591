import languageHelper from "../../helpers/languageHelper";

const language = languageHelper();

const validateMessage = value => {
  if (value && value.length >= 4000) {
    return language.errors.messageMaximumLength;
  }
  return null;
};

const validateEmail = email => {
  // eslint-disable-next-line
  const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (email && !email.match(emailPattern)) {
    return language.errors.invalidEmail;
  }
  if ((email && email.trim() === "") || !email) {
    return language.errors.required;
  }
  return null;
};
export default {
  validateMessage,
  validateEmail
};
