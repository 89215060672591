import { fromJS } from "immutable";
import * as constants from "./constants";

const initialState = fromJS({
  showDispatchMap: false,
  map: null,
  centerCoords: null,
  carLocalizationErrorModalState: false,
  carLocalizationSuccessModalState: false,
  locationBlockedModalState: false,
});

const reducer = (state = initialState, action) => {
  let newState = state;
  switch (action.type) {
    case constants.SHOW_DISPATCH_MAP:
      newState = newState.set("showDispatchMap", action.payload);
      return newState;
    case constants.SAVE_DISPATCH_MAP_TO_STATE:
      newState = newState.set("map", action.payload);
      return newState;
    case constants.SAVE_DISPATCH_CENTER_COORDS:
      newState = newState.set("centerCoords", action.payload);
      return newState;
    case constants.TOGGLE_DISPATCH_POSITION_ERROR_MODAL:
      newState = newState.set("carLocalizationErrorModalState", action.payload);
      return newState;
    case constants.TOGGLE_DISPATCH_POSITION_SUCCESS_MODAL:
      newState = newState.set(
        "carLocalizationSuccessModalState",
        action.payload
      );
      return newState;
    case constants.SET_SEARCH_PLACES_RESULT:
      newState = newState.set("searchPlacesResult", action.payload);
      return newState;
    case constants.TOGGLE_LOCATION_BLOCKED_MODAL:
      newState = newState.set("locationBlockedModalState", action.payload);
      return newState;
    default:
      return newState;
  }
};

export default { reducer };
