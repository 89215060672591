import moment from "moment/moment";

export const formatYYYYMMDD = dateString => {
  return moment.utc(dateString).toDate().toLocaleString("sv-SE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric"
  });
};

export const formatHoursAndMinutes = dateString => {
  return moment.utc(dateString).toDate().toLocaleString("sv-SE", {
    hour: "2-digit",
    minute: "2-digit"
  });
};
