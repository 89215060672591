import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import languageHelper from "../../helpers/languageHelper";
import towTruck from "../../assets/images/towtruck_welcome.svg";
import thumbsUp from "../../assets/images/thumbs_up.svg";
import klarna from "../../assets/images/klarna_logo.svg";
import clockPrice from "../../assets/images/clock_price.svg";
import { CREATE_ORDER_PATH } from "../../constants/routeConstants";
import history from "../../history";
import actions from "./actions";
import { actions as createOrderActions } from "../createOrder";
import { CUSTOMER_DETAILS_VIEW } from "../createOrder/viewConstants";
import activeOrderSelectors from "../activeOrder/selectors";
import "./style.css";
import arrowButton from "../../assets/images/arrow-down.svg";
import CallButton from "./welcomeScreen.style";

const language = languageHelper();

const { setCurrentView, toggleUnexpectedErrorModal } = createOrderActions;
const { resumeOrder } = actions;
const { getPreventResumeOrderState } = activeOrderSelectors;
class WelcomeScreen extends React.Component {
  state = {
    toggleDescription: false
  };

  componentDidMount() {
    const {
      currentView,
      resetUnexpectedErrorModal,
      checkAndResumeActiveOrder,
      preventResumeOrder
    } = this.props;
    currentView();
    resetUnexpectedErrorModal();
    if (!preventResumeOrder) {
      checkAndResumeActiveOrder();
    }
  }

  toggleDescriptionHandler = () => {
    const { toggleDescription } = this.state;
    if (!toggleDescription) {
      this.scrollToBottom();
    } else {
      this.scrollToTop();
    }
    this.setState(prevState => ({
      toggleDescription: !prevState.toggleDescription
    }));
  };

  scrollToBottom = () => {
    this.regionTextRef.scrollIntoView({ behavior: "smooth" });
  };

  scrollToTop = () => {
    this.DOMRef.scrollIntoView({ behavior: "smooth" });
  };

  render() {
    const { toggleDescription } = this.state;
    const allowMetadata = true;

    const metaData = allowMetadata ? (
      <>
        <div className="metadata-collapsible">
          <button
            type="button"
            className="arrow-down-button"
            onClick={this.toggleDescriptionHandler}
          >
            <img
              src={arrowButton}
              alt="arrow"
              className={`arrow-button-image ${
                toggleDescription ? "rotated" : ""
              }`}
            />
            <span className="welcome-screen-toggle-text">
              {language.createOrder.welcomeScreen.metaData.buttonText}
            </span>
          </button>
        </div>
        <div className="welcome-screen-additional-description">
          <div
            className="welcome-screen-region-text"
            ref={el => {
              this.regionTextRef = el;
            }}
          >
            <p>{language.createOrder.welcomeScreen.metaData.seoText1}</p>
            <p>{language.createOrder.welcomeScreen.metaData.seoText2}</p>
          </div>
        </div>
      </>
    ) : null;

    const content = (
      <>
        <div className="welcome-screen flex-column tinted-background justify-between">
          <div className="flex-column flex-alignItemsCenter">
            <div className="title-div">
              <h2>{language.createOrder.welcomeScreen.title}</h2>
              {allowMetadata && (
                <h3>{language.createOrder.welcomeScreen.subtitle}</h3>
              )}
            </div>
            <div className="welcome-screen-wrapper-div flex-center-content">
              <span className="icon-span">
                <img
                  src={clockPrice}
                  alt="phone_price"
                  className="welcome-image"
                />
                <p className="icon-p">
                  {language.createOrder.welcomeScreen.roundTheClock}
                </p>
              </span>
              <span className="icon-span">
                <img
                  src={towTruck}
                  alt="phone_accept"
                  className="welcome-image"
                />
                <p className="icon-p">
                  {language.createOrder.welcomeScreen.quickResponse}
                </p>
              </span>
              <span className="icon-span">
                <img src={klarna} alt="klarna_logo" className="welcome-image" />
                <p className="icon-p">
                  {language.createOrder.welcomeScreen.payWithKlarna}
                </p>
              </span>
              <span className="icon-span">
                <img src={thumbsUp} alt="thumbs_up" className="welcome-image" />
                <p className="icon-p">
                  {language.createOrder.welcomeScreen.happyCustomers}
                </p>
              </span>
            </div>
          </div>
          <div className="welcome-screen-button-wrapper">
            <a
              href={`tel: ${language.phoneNumber}`}
              className="welcome-screen-telephone button button-primary"
            >
              {language.createOrder.welcomeScreen.call}
            </a>
            <CallButton
              type="Solid"
              onClick={() => history.push(CREATE_ORDER_PATH)}
            >
              {language.createOrder.welcomeScreen.book}
            </CallButton>
          </div>
        </div>
      </>
    );

    return (
      <>
        <div
          className="welcome-screen-container z-index1"
          ref={el => {
            this.DOMRef = el;
          }}
        >
          {content}
          {metaData}
        </div>
      </>
    );
  }
}

WelcomeScreen.propTypes = {
  currentView: PropTypes.func.isRequired,
  resetUnexpectedErrorModal: PropTypes.func.isRequired,
  checkAndResumeActiveOrder: PropTypes.func.isRequired,
  preventResumeOrder: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  preventResumeOrder: getPreventResumeOrderState(state)
});

const mapDispatchToProps = dispatch => ({
  currentView: () => dispatch(setCurrentView(CUSTOMER_DETAILS_VIEW)),
  resetUnexpectedErrorModal: () => dispatch(toggleUnexpectedErrorModal(false)),
  checkAndResumeActiveOrder: () => dispatch(resumeOrder())
});

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeScreen);
