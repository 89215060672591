import React from "react";
import PropTypes from "prop-types";

import "./style.css";
import { Button } from "@assistansbolaget/gat-components";

function NextStepButton({ nextStep, nextStepText, disabled, id }) {
  return (
    <Button
      fullWidth
      kind="Solid"
      type="submit"
      onClick={nextStep}
      disabled={disabled}
      id={id}
    >
      {nextStepText}
    </Button>
  );
}

NextStepButton.defaultProps = {
  disabled: false,
  id: ""
};

NextStepButton.propTypes = {
  nextStep: PropTypes.func.isRequired,
  nextStepText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string
};

export default NextStepButton;
