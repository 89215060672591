import { fromJS } from "immutable";
import moment from "moment";
import * as constants from "./constants";
import { TOGGLE_UNEXPECTED_ERROR_MODAL } from "../createOrder/constants";

const initialState = fromJS({
  order: {},
  cancelModalOpen: false,
  startedOrderModal: false,
  towCancelModalOpen: false,
  estimatedArrivalTimeInSeconds: null,
  estimatedArrivalTime: {},
  unexpectedErrorModalState: false,
  preventResumeOrder: false
});

const reducer = (state = initialState, action) => {
  let newState = state;
  switch (action.type) {
    case constants.CANCEL_ORDER_SUCCESS:
      newState = newState.set("cancelModalOpen", false);
      return newState;
    case constants.OPEN_CANCEL_ORDER_MODAL:
      newState = newState.set("cancelModalOpen", true);
      return newState;
    case constants.CLOSE_CANCEL_ORDER_MODAL:
      newState = newState.set("cancelModalOpen", false);
      newState = newState.set("towCancelModalOpen", false);
      return newState;
    case constants.SET_ORDER_STATUS:
      newState = newState.set("order", action.payload);
      return newState;
    case constants.TOGGLE_STARTED_ORDER_MODAL:
      newState = newState.set("startedOrderModal", action.payload);
      return newState;
    case constants.TOW_TRUCK_CANCELLED:
      newState = newState.set("towCancelModalOpen", true);
      return newState;
    case constants.GET_ESTIMATED_ARRIVAL_TIME_SUCCESS: {
      const eta = action.payload;
      const now = moment();
      const etaLocal = moment.utc(eta).local();
      const etaHours = etaLocal.diff(now, "hours");
      const etaMinutes = etaLocal.diff(now, "minutes");
      let timeText = "";

      if (etaMinutes > 0) {
        timeText =
          etaHours > 0
            ? `${etaHours} h ${etaMinutes % 60} min`
            : `${etaMinutes} min`;
      } else {
        timeText = "0 min";
      }

      const updatedAtLocal = moment.utc(eta).local();
      const updatedAtDiff = now.diff(updatedAtLocal, "minutes");
      const outdatedEta = updatedAtDiff >= 5;

      newState = newState.set("estimatedArrivalTime", {
        timeSeconds: etaMinutes * 60,
        timeText,
        outdatedEta
      });
      return newState;
    }
    case TOGGLE_UNEXPECTED_ERROR_MODAL:
      if (action.payload === true) {
        newState = newState.set("preventResumeOrder", true);
      }
      newState = newState.set("unexpectedErrorModalState", action.payload);
      return newState;
    case constants.GET_MISSION_BY_TOKEN_SUCCESS:
      newState = newState.set("order", action.mission);
      return newState;
    case constants.GET_MISSION_BY_TOKEN_FAILURE:
      newState = newState.set("unexpectedErrorModalState", true);
      return newState;
    default:
      return newState;
  }
};

export default { reducer };
