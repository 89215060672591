import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import languageHelper from "../../helpers/languageHelper";
import actions from "./actions";

const language = languageHelper();

const { modalGoBack } = actions;

const TmaCarTerms = ({ closeTerms }) => (
  <>
    <div className="overlay-opacity" />
    <div className="card-box terms-background">
      <h3>{language.createOrder.tmaCarTerms.title}</h3>
      <div className="terms-text flex-grow overflowy-scroll">
        <p style={{ textAlign: "left", whiteSpace: "pre-line" }}>
          {language.createOrder.tmaCarTerms.text}
        </p>
      </div>
    </div>
    <button
      type="submit"
      onClick={closeTerms}
      className="button terms-button button-primary"
    >
      {language.createOrder.close}
    </button>
  </>
);

TmaCarTerms.propTypes = {
  closeTerms: PropTypes.func.isRequired,
};


const mapDispatchToProps = (dispatch) => ({
  closeTerms: () => dispatch(modalGoBack()),
});

export default connect(null, mapDispatchToProps)(TmaCarTerms);
