export const POST_RATING_SUCCESS = "POST_RATING_SUCCESS";
export const POST_RECEIPT_SUCCESS = "POST_RECEIPT_SUCCESS";
export const POST_RECEIPT = "POST_RECEIPT";
export const RECEIPT_SUBMITTED = "RECEIPT_SUBMITTED";
export const SET_DISPLAY_VALUE = "SET_DISPLAY_VALUE";
export const SET_RATING = "SET_RATING";
export const SUMMARY_SUBMITTED = "SUMMARY_SUBMITTED";
export const LEGACY_SUMMARY_SUBMITTED = "LEGACY_SUMMARY_SUBMITTED";
export const TOGGLE_RECEIPT_FAILURE_MODAL = "TOGGLE_RECEIPT_FAILURE_MODAL";
export const TOGGLE_SUMMARY_FAILURE_MODAL = "TOGGLE_SUMMARY_FAILURE_MODAL";
export const TOGGLE_SUMMARY_SUCCESS_MODAL = "TOGGLE_SUMMARY_SUCCESS_MODAL";
