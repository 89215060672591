import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import languageHelper from "../../helpers/languageHelper";
import { CONFIRM_ORDER_VIEW } from "./viewConstants";
import actions from "./actions";

const language = languageHelper();

const { setCurrentView } = actions;

const UserTerms = ({ closeTerms }) => (
  <>
    <div className="overlay-opacity" />
    <div className="card-box terms-background">
      <h3>{language.createOrder.userTerms.title}</h3>
      <div className="terms-text flex-grow overflowy-scroll">
        <p style={{ textAlign: "left", whiteSpace: "pre-line" }}>
          {language.createOrder.userTerms.text}
        </p>
      </div>
    </div>
    <button
      type="submit"
      onClick={closeTerms}
      className="button terms-button button-primary"
    >
      {language.createOrder.close}
    </button>
  </>
);

UserTerms.propTypes = {
  closeTerms: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  closeTerms: () => dispatch(setCurrentView(CONFIRM_ORDER_VIEW))
});

export default connect(null, mapDispatchToProps)(UserTerms);
