const getCurrentView = ({ createOrder }) =>
  createOrder.getIn(["view", "current"]);

const getNextView = ({ createOrder }) => createOrder.getIn(["view", "next"]);

const getPreviousView = ({ createOrder }) =>
  createOrder.getIn(["view", "previous"]);

const getModalBackView = ({ createOrder }) =>
  createOrder.getIn(["view", "current"]);

const getEstimateAssistancePrice = ({ createOrder }) =>
  createOrder.get("estimateAssistancePrice");

const getEstimateTowingPrice = ({ createOrder }) =>
  createOrder.get("estimateTowingPrice");

const getEstimateCalculatedAssistancePrice = ({ createOrder }) =>
  createOrder.get("estimateCalculatedAssistancePrice");

const getEstimateCalculatedTowingPrice = ({ createOrder }) =>
  createOrder.get("estimateCalculatedTowingPrice");

const getEstimateTime = ({ createOrder }) =>
  createOrder.getIn(["estimateTime", "seconds"]);

const getCustomerType = ({ createOrder }) =>
  createOrder.getIn(["customerType"]);

const getFinalPriceLoading = ({ createOrder }) =>
  createOrder.get("fetchingFinalPrice");

const getFinalPrice = ({ createOrder }) => createOrder.get("finalPrice");

const getLoading = ({ createOrder }) => createOrder.get("loading");

const getNoConfirmationModalState = ({ createOrder }) =>
  createOrder.get("noConfirmationModalState");

const getUnexpectedErrorModalState = ({ createOrder }) =>
  createOrder.get("unexpectedErrorModalState");

const getAlreadyStartedErrorModalState = ({ createOrder }) =>
  createOrder.get("alreadyStartedErrorModalState");

const getToggleFormValue = ({ createOrder }) => createOrder.get("toggleForm");

const getSocialNumberFieldError = ({ createOrder }) =>
  createOrder.get("socialNumberFieldError");

const getRegnoFieldError = ({ createOrder }) =>
  createOrder.get("regnoFieldError");

const getShowTowDestinationPicker = ({ createOrder }) =>
  createOrder.getIn(["towDestination", "showTowDestination"]);

const getShowPreviousEstimate = ({ createOrder }) =>
  createOrder.getIn(["previousEstimate", "show"]);

const getPreviousEstimatePrice = ({ createOrder }) =>
  createOrder.getIn(["previousEstimate", "price"]);

const getSearchPlacesResult = ({ createOrder }) =>
  createOrder.get("searchPlacesResult");

const getIsCustomer = ({ createOrder }) => createOrder.get("isCustomer");

const getIsFetchingVehicle = ({ createOrder }) =>
  createOrder.get("isFetchingVehicle");

const getIsFetchingPerson = ({ createOrder }) =>
  createOrder.get("isFetchingPerson");

const getCancelledByDispatchModalState = ({ createOrder }) =>
  createOrder.get("cancelledByDispatchModalState");

const getDispatchWillCallModalState = ({ createOrder }) =>
  createOrder.get("dispatchWillCallModalState");

const getHasPreviousVehiclePositionChanged = ({ createOrder }) =>
  createOrder.get("hasPreviousVehiclePositionChanged");

const getVehicleCoords = ({ createOrder }) =>
  createOrder.get("vehiclePosition");

const getNoEstimateReason = ({ createOrder }) =>
  createOrder.get("noEstimateReason");

const getEstimatePriceCollectedCoords = ({ createOrder }) =>
  createOrder.get("estimatePriceCollectedCoords");

const getDamageReasons = ({ createOrder }) => createOrder.get("damageReasons");

const selectConfinedSpaces = ({ createOrder }) =>
  createOrder.get("confinedSpaces");

const selectVehicleInsuranceLevels = ({ createOrder }) =>
  createOrder.get("insuranceLevels");

const isCookiesAccepted = ({ createOrder }) => createOrder.get("isAccepted");

export default {
  getDamageReasons,
  getCurrentView,
  getNextView,
  getPreviousView,
  getModalBackView,
  getEstimateAssistancePrice,
  getEstimateTowingPrice,
  getEstimateCalculatedAssistancePrice,
  getEstimateCalculatedTowingPrice,
  getEstimateTime,
  getCustomerType,
  getFinalPriceLoading,
  getFinalPrice,
  getLoading,
  getNoConfirmationModalState,
  getUnexpectedErrorModalState,
  getAlreadyStartedErrorModalState,
  getToggleFormValue,
  getSocialNumberFieldError,
  getRegnoFieldError,
  getShowTowDestinationPicker,
  getShowPreviousEstimate,
  getPreviousEstimatePrice,
  getSearchPlacesResult,
  getIsCustomer,
  getIsFetchingVehicle,
  getIsFetchingPerson,
  getCancelledByDispatchModalState,
  getDispatchWillCallModalState,
  getHasPreviousVehiclePositionChanged,
  getVehicleCoords,
  getNoEstimateReason,
  getEstimatePriceCollectedCoords,
  selectConfinedSpaces,
  selectVehicleInsuranceLevels,
  isCookiesAccepted
};
