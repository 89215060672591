import React from "react";
import PropTypes from "prop-types";
import styles from "./staticMap.module.scss";

const StaticMap = ({ markers }) => {
  const baseUrl = "https://maps.googleapis.com/maps/api/staticmap";
  const apiKey = "AIzaSyCM3Er5wltGE45H78BW1z__41onnf_xl-0";
  const markerParameters = () => {
    if (markers.length === 1) {
      return markers
        .map(m => `markers=icon:${m.icon}%7C${m.lat},${m.long}&zoom=13`)
        .join("&");
    }
    return markers
      .map(m => `markers=icon:${m.icon}%7C${m.lat},${m.long}`)
      .join("&");
  };

  const mapUrl = `${baseUrl}?${markerParameters()}&size=800x300&key=${apiKey}&format=png&maptype=roadmap&style=element:geometry%7Ccolor:0x40464f&style=element:labels.text.fill%7Ccolor:0x746855&style=element:labels.text.stroke%7Ccolor:0x242f3e&style=feature:administrative.locality%7Celement:labels.text.fill%7Ccolor:0xd59563&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0xd59563&style=feature:poi.park%7Celement:geometry%7Ccolor:0x344a48&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x6b9a76&style=feature:road%7Celement:geometry%7Ccolor:0x38414e&style=feature:road%7Celement:geometry.stroke%7Ccolor:0x212a37&style=feature:road%7Celement:labels.text.fill%7Ccolor:0x9ca5b3&style=feature:road.arterial%7Ccolor:0x808793&style=feature:road.highway%7Celement:geometry.fill%7Ccolor:0x707683&style=feature:road.highway%7Celement:geometry.stroke%7Ccolor:0x707783&style=feature:road.highway%7Celement:labels.text.fill%7Ccolor:0xf3d19c&style=feature:road.local%7Ccolor:0x808793&style=feature:transit%7Celement:geometry%7Ccolor:0x2f3948&style=feature:transit.station%7Celement:labels.text.fill%7Ccolor:0xd59563&style=feature:water%7Celement:geometry%7Ccolor:0x272d34&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x515c6d&style=feature:water%7Celement:labels.text.stroke%7Ccolor:0x17263c`;

  return (
    <div className={styles.container}>
      <img src={mapUrl} alt="static-map" className={styles.map} />
    </div>
  );
};

StaticMap.propTypes = {
  markers: PropTypes.instanceOf(Array).isRequired
};

export default StaticMap;
