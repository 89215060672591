export default function addressComponentsHelper(results) {
  let street;
  let streetNumber;
  let route;
  let postalTown;
  let postalCode;
  let country;
  let streetAddress;
  let area;
  let formattedAddress;

  if (results) {
    results[0].address_components.forEach(adressComponent => {
      if (adressComponent.types.includes("street")) {
        street = adressComponent.long_name;
      } else if (adressComponent.types.includes("street_number")) {
        streetNumber = adressComponent.long_name;
      } else if (adressComponent.types.includes("route")) {
        route = adressComponent.long_name;
      } else if (adressComponent.types.includes("postal_town")) {
        postalTown = adressComponent.long_name;
      } else if (adressComponent.types.includes("postal_code")) {
        postalCode = adressComponent.long_name;
      } else if (adressComponent.types.includes("country")) {
        country = adressComponent.long_name;
      } else if (adressComponent.types.includes("locality")) {
        postalTown = adressComponent.long_name;
      } else if (adressComponent.types.includes("city")) {
        postalTown = adressComponent.long_name;
      }
    });

    if (street) {
      streetAddress = streetNumber ? `${street} ${streetNumber}` : street;
    } else if (route) {
      streetAddress = streetNumber ? `${route} ${streetNumber}` : route;
    } else {
      streetAddress = "";
    }
    if (postalTown) {
      area = postalCode ? `${postalCode} ${postalTown}` : postalTown;
    } else if (postalCode) {
      area = postalCode;
    } else {
      area = "";
    }

    if (streetAddress) {
      formattedAddress = area ? `${streetAddress}, ${area}` : streetAddress;
    } else if (area) {
      formattedAddress = streetAddress ? `${streetAddress}, ${area}` : area;
    }
    if (country) {
      if (area) {
        area += `, ${country}`;
      }
      if (formattedAddress) {
        formattedAddress += `, ${country}`;
      }
    }

    return {
      street: street || "",
      streetNumber: streetNumber || "",
      route: route || "",
      postalTown: postalTown || "",
      postalCode: postalCode || "",
      country: country || "",
      streetAddress,
      area,
      formattedAddress: formattedAddress || ""
    };
  }
  return null;
}
