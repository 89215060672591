export default function updateServePositionAddressHelper(components) {
  let street;
  let streetNumber;
  let route;
  let postalTown;
  let postalCode;
  let country;
  let streetAddress;
  let area;
  let formattedAddress;

  if (components) {
    const { addressComponents } = components;
    street = addressComponents.street || "";
    streetNumber = addressComponents.streetNumber || "";
    route = addressComponents.route || "";
    postalTown = addressComponents.postalTown || "";
    postalCode = addressComponents.postalCode || "";
    country = addressComponents.country || "";
    streetAddress = addressComponents.streetAddress || "";
    area = addressComponents.area || "";
    formattedAddress = addressComponents.formattedAddress || "";

    if (street) {
      streetAddress = streetNumber ? `${street} ${streetNumber}` : street;
    } else if (route) {
      streetAddress = streetNumber ? `${route} ${streetNumber}` : route;
    } else {
      streetAddress = "";
    }
    if (postalTown) {
      area = postalCode ? `${postalCode} ${postalTown}` : postalTown;
    } else if (postalCode) {
      area = postalCode;
    } else {
      area = "";
    }

    if (streetAddress) {
      formattedAddress = area ? `${streetAddress}, ${area}` : streetAddress;
    } else if (area) {
      formattedAddress = streetAddress ? `${streetAddress}, ${area}` : area;
    }
    if (country) {
      if (area) {
        area += `, ${country}`;
      }
      if (formattedAddress) {
        formattedAddress += `, ${country}`;
      }
    }

    return {
      street: street || "",
      streetNumber: streetNumber || "",
      route: route || "",
      postalTown: postalTown || "",
      postalCode: postalCode || "",
      country: country || "",
      streetAddress,
      area,
      formattedAddress: formattedAddress || ""
    };
  }
  return null;
}
