import { isValidPhoneNumber } from "react-phone-number-input";
import languageHelper from "../../helpers/languageHelper";

const language = languageHelper();

const validateRegno = (regno) => {
  if ((regno && regno.trim() === "") || !regno) {
    return language.errors.vehicleRequired;
  }
  if (regno && regno.length < 2) {
    return language.errors.minimumLength;
  }
  if (regno && regno.length > 7) {
    return language.errors.maximumLength;
  }
  return null;
};

function validateMobile(mobile) {
  if (!mobile) {
    return language.errors.required;
  }
  if (!isValidPhoneNumber(mobile)) {
    return language.errors.invalidPhone;
  }
  return null;
}

function validateRadio(value) {
  if (value === undefined) {
    return language.errors.required;
  }
  return null;
}

const validateSocialNumber = (socialNumber) => {
  const onlyNumberPattern = /^\d+$/;
  if (socialNumber && !socialNumber.match(onlyNumberPattern)) {
    return language.errors.onlyNumbers;
  }
  if ((socialNumber && socialNumber.trim() === "") || !socialNumber) {
    return language.errors.socialNumberRequired;
  }
  if (socialNumber.length < 12) {
    return language.errors.minimumLengthSocialNumber;
  }
  if (socialNumber.length > 12) {
    return language.errors.maximumLengthSocialNumber;
  }
  return null;
};

const validatePosition = (position) => {
  if ((position && position.trim() === "") || !position) {
    return language.errors.required;
  }
  return null;
};

export default {
  validateRegno,
  validateMobile,
  validateRadio,
  validateSocialNumber,
  validatePosition,
};
