export const GET_POSITION_DISPATCH = "GET_POSITION_DISPATCH";
export const GET_POSITION_FAILURE = "GET_POSITION_FAILURE";
export const SAVE_CENTER_COORDS = "SAVE_CENTER_COORDS";
export const TOGGLE_DISPATCH_POSITION_ERROR_MODAL =
  "TOGGLE_DISPATCH_POSITION_ERROR_MODAL";
export const SHOW_DISPATCH_MAP = "SHOW_DISPATCH_MAP";
export const SAVE_DISPATCH_MAP_TO_STATE = "SAVE_DISPATCH_MAP_TO_STATE";
export const SAVE_DISPATCH_CENTER_COORDS = "SAVE_DISPATCH_CENTER_COORDS";
export const UPDATE_ORDER_POSITION = "UPDATE_ORDER_POSITION";
export const TOGGLE_DISPATCH_POSITION_SUCCESS_MODAL =
  "TOGGLE_DISPATCH_POSITION_SUCCESS_MODAL";
export const SET_SEARCH_PLACES_RESULT = "SET_SEARCH_PLACES_RESULT";
export const TOGGLE_LOCATION_BLOCKED_MODAL = "TOGGLE_LOCATION_BLOCKED_MODAL";

export const SAVE_DESTINATION_CHANGER = "SAVE_DESTINATION_CHANGER";
