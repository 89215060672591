import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { ThemeProvider, defaultTheme } from "@assistansbolaget/gat-components";
import { RawIntlProvider } from "react-intl";
import App from "./App";
import { store } from "./store";
import history from "./history";
import "./assets/fonts/DINPro/style.css";
import "./assets/fonts/OverpassLight/style.css";
import "./App.css";
import { useTranslations } from "./language/useTranslations";

/* eslint-disable */
if (!global._babelPolyfill) {
  require("babel-polyfill");
}

function AppWrapper() {

  const currentMessagesForRescue = useTranslations();

  return (
    <RawIntlProvider value={currentMessagesForRescue.messages}>
      <ThemeProvider theme={defaultTheme}>
        <Provider store={store}>
          <Router history={history}>
            <App />
          </Router>
        </Provider>
      </ThemeProvider>
    </RawIntlProvider>
  );
}

ReactDOM.render(<AppWrapper />, document.getElementById("root"));
