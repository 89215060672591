import {
  GET_POSITION_DISPATCH,
  SAVE_DISPATCH_MAP_TO_STATE,
  SAVE_DISPATCH_CENTER_COORDS,
  UPDATE_ORDER_POSITION,
  TOGGLE_DISPATCH_POSITION_ERROR_MODAL,
  TOGGLE_DISPATCH_POSITION_SUCCESS_MODAL,
  SHOW_DISPATCH_MAP,
  TOGGLE_LOCATION_BLOCKED_MODAL,
  SAVE_DESTINATION_CHANGER
} from "./constants";

const getPosition = () => ({
  type: GET_POSITION_DISPATCH
});

const toggleCarLocalizationErrorModal = bool => ({
  type: TOGGLE_DISPATCH_POSITION_ERROR_MODAL,
  payload: bool
});

const saveMapToState = map => ({
  type: SAVE_DISPATCH_MAP_TO_STATE,
  payload: map
});

const saveCenterCoords = centerCoords => ({
  type: SAVE_DISPATCH_CENTER_COORDS,
  payload: centerCoords
});

const saveCenterDestinationCoords = centerCoords => ({
  type: SAVE_DESTINATION_CHANGER,
  payload: centerCoords
});

const updateOrderPosition = () => ({
  type: UPDATE_ORDER_POSITION
});

const toggleDispatchPositionSuccessModal = boolean => ({
  type: TOGGLE_DISPATCH_POSITION_SUCCESS_MODAL,
  payload: boolean
});

const showDispatchMap = bool => ({
  type: SHOW_DISPATCH_MAP,
  payload: bool
});

const toggleLocationBlockedModal = bool => ({
  type: TOGGLE_LOCATION_BLOCKED_MODAL,
  payload: bool
});

export default {
  getPosition,
  toggleCarLocalizationErrorModal,
  saveMapToState,
  saveCenterCoords,
  updateOrderPosition,
  toggleDispatchPositionSuccessModal,
  showDispatchMap,
  toggleLocationBlockedModal,
  saveCenterDestinationCoords
};
