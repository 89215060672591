import { createIntl, createIntlCache } from "react-intl";
import React, { useEffect, useState } from "react";
import { useSessionStorage } from "usehooks-ts";
import { BaseUrl } from "../constants/apiConstants";
import api from "../helpers/apiUtils";
import { getSession, SESSION_KEYS } from "../helpers/sessionStore";
import * as defaultMessages from "./strings/translation-base.json";

const initialLocale = "en-US";

export const cache = createIntlCache();

const mappedDefaultMessages = Object.entries(defaultMessages).reduce(
  (acc, [key, value]) => {
    acc[key] = value.defaultMessage;
    return acc;
  },
  {}
);

// eslint-disable-next-line no-unused-vars
let intl = createIntl(
  {
    locale: getSession(SESSION_KEYS.locale) || initialLocale,
    messages: mappedDefaultMessages
  },
  cache
);

export const useTranslations = () => {
  const [messages, setMessages] = useState(intl);
  const [companyAttributes] = useSessionStorage(
    SESSION_KEYS.companyAttributes,
    undefined
  );
  const [translations, setTranslations] = React.useState([]);
  const [locale, setLocale] = useSessionStorage(SESSION_KEYS.locale, undefined);
  const appId = getSession(SESSION_KEYS.appId);

  React.useEffect(() => {
    const url = new URL(
      `${api.getBaseUrl(BaseUrl.GatUrl)}v1/translations,export-application`
    );
    url.searchParams.append("client", `Application-gat-rescue-frontend`);
    url.searchParams.append("locale", locale);
    fetch(url, {
      method: "GET",
      headers: {
        "gat-organization-unit": getSession(SESSION_KEYS.gatOrganizationUnit),
        "gat-locale": locale,
        "Content-Type": "application/json",
        "gat-application-id": appId
      }
    })
      .then(response => response.json())
      .then(data => {
        setTranslations(data);
      })
      .catch(error => {
        console.log("Error when fetching translations", error);
      });
  }, [locale]);

  useEffect(() => {
    if (!locale && companyAttributes) {
      setLocale(companyAttributes?.CompanyLocale?.value);
    }
  }, [companyAttributes]);

  useEffect(() => {
    intl = createIntl(
      {
        locale,
        messages: translations
      },
      cache
    );
    setMessages(intl);
  }, [translations]);

  return { locale, messages };
};
