import styled from "styled-components";
import { Card } from "@assistansbolaget/gat-components";

const CardBoxContainerTest = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 310px;
  color: #ffffff;
  padding: 24px;
  &.card-box {
    &.card-box-dark {
      padding: 10px 8px;
      height: 0px;
      width: 310px;
      z-index: 10;
      box-sizing: border-box;
      pointer-events: all;
      box-shadow: 2px 3px 8px 0 rgba(0, 0, 0, 0.5);
    }
    &.card-box-height {
      height: unset;
    }
  }
`;

export default CardBoxContainerTest;
