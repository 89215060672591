import React from "react";
import PropTypes from "prop-types";

import "./style.css";
import { Button } from "@assistansbolaget/gat-components";
import { LocationsHandlerButton } from "../../features/createOrder/customerDetails.style";

function TwoButtonContainer({
  nextStep,
  previousStep,
  nextStepText,
  previousStepText,
  id,
  disabled
}) {
  return (
    <div className="button-container" id={id}>
      <Button
        fullWidth
        kind="Solid"
        type="submit"
        onClick={nextStep}
        disabled={disabled}
      >
        {nextStepText}
      </Button>
      <LocationsHandlerButton
        kind="Primary"
        fullWidth
        type="submit"
        onClick={previousStep}
      >
        {previousStepText}
      </LocationsHandlerButton>
    </div>
  );
}

TwoButtonContainer.defaultProps = {
  disabled: false,
  id: ""
};

TwoButtonContainer.propTypes = {
  nextStep: PropTypes.func.isRequired,
  previousStep: PropTypes.func.isRequired,
  nextStepText: PropTypes.string.isRequired,
  previousStepText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string
};

export default TwoButtonContainer;
