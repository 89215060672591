/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Select } from "@assistansbolaget/gat-components";

import "./style.css";
import { useIntl } from "react-intl";

const SelectField = ({
  meta: { touched, error },
  input: { onChange, value },
  options,
  ...rest
}) => {
  const optionsList = options?.map(option => {
    return { label: option.name, value: option.value || option.id };
  });

  const intl = useIntl();

  const errors =
    touched && error
      ? intl.formatMessage({
          id: "DamageOrder.DamageReason.ErrorMessage",
          defaultMessage: "Required",
          description: "damagereasons: errormessage"
        })
      : null;

  const { placeholder } = rest;

  return (
    <Select
      className="components-select-field"
      options={optionsList}
      error={errors}
      zIndex={12}
      value={
        value ? { label: value.label, value: value.value || value.id } : null
      }
      size="large"
      placeholder={placeholder}
      onChange={onChange}
      {...rest}
    />
  );
};

SelectField.propTypes = {
  input: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired
};

export default SelectField;
