import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/pro-light-svg-icons";
import { useIntl } from "react-intl";
import CardBoxContainer from "../../components/CardBoxContainer/CardBoxContainer";

const OrderArrived = ({ company, driver, towTruckRegNo }) => {
  const intl = useIntl();
  return (
    <>
      <CardBoxContainer disableToggle minimumHeight="88px" marginBottom="94px">
        <div className="driver-onRoute">
          <div className="driver-onRoute-title">
            <FontAwesomeIcon
              icon={faLocationDot}
              color="#FFD4A3"
              style={{ width: "2rem", height: "2rem" }}
            />
            <h3>
              {intl.formatMessage({
                id: "ActiveOrder.OrderArrived.Title",
                defaultMessage: "We have arrived",
                description: "ActiveOrder: order arrived Title"
              })}
            </h3>
          </div>
          <div className="driver-onRoute-firstInfoText">
            <p>
              {intl.formatMessage({
                id: "ActiveOrder.OrderArrived.InfoTextDriver",
                defaultMessage: "Driver",
                description: "ActiveOrder: order arrived InfoTextDriver"
              })}
              <strong>{` ${driver} `}</strong>
              {intl.formatMessage({
                id: "ActiveOrder.OrderArrived.InfoTextFrom",
                defaultMessage: "from",
                description: "ActiveOrder: order arrived InfoTextFrom"
              })}
              <strong>{` ${company} `}</strong>
              {intl.formatMessage({
                id: "ActiveOrder.OrderArrived.InfoBreadText",
                defaultMessage: "is on sight to help you",
                description: "ActiveOrder: order arrived InfoBreadText"
              })}
            </p>
          </div>
          <div className="driver-onRoute-secondInfoText">
            <p>
              {intl.formatMessage({
                id: "ActiveOrder.OrderArrived.InfoTextRegistrationNumber",
                defaultMessage: "The registration number of the tow truck is",
                description:
                  "ActiveOrder: order arrived InfoTextRegistrationNumber"
              })}
              <strong>{` ${towTruckRegNo}`}</strong>
            </p>
          </div>
        </div>
      </CardBoxContainer>
    </>
  );
};

OrderArrived.propTypes = {
  company: PropTypes.string.isRequired,
  driver: PropTypes.string.isRequired,
  towTruckRegNo: PropTypes.string.isRequired
};

export default OrderArrived;
