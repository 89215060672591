import { deleteSession, getSession, SESSION_KEYS } from "./sessionStore";

const logout = () => {
  const gatAccesToken = getSession(SESSION_KEYS.accessToken);
  const missionId = getSession(SESSION_KEYS.missionId);
  const token = getSession(SESSION_KEYS.token);

  if (gatAccesToken) {
    deleteSession(SESSION_KEYS.accessToken);
  }
  if (missionId) {
    deleteSession(SESSION_KEYS.missionId);
  }
  if (token) {
    deleteSession(SESSION_KEYS.token);
  }
};

export default logout;
