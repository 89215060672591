import React from "react";
import { Helmet } from "react-helmet";

const HeaderTag = () => {
  const appId = process.env.REACT_APP_APPLICATION_ID;

  const config = {
    "gat-rescue-frontend": {
      title: "Rescue Assistansbolaget",
      description:
        "Get a price estimate, estimated time of arrival, and GPS location with GAT.",
      image: "https://rescue.assistansbolaget.com/images/rescue.png",
      url: "https://rescue.assistansbolaget.nu",
      type: "website",
      canonical: "https://rescue.assistansbolaget.nu",
      itemPropDescription: "Get towing assistance | GAT",
      itemPropDescriptionTwo:
        "Get a price estimate, estimated time of arrival, and GPS location with GAT."
    },
    "gat-rescue-dk": {
      title: "Rescue Assist24",
      description:
        "Get a price estimate, estimated time of arrival, and GPS location with GAT.",
      canonical: "https://rescue.assist24.dk",
      type: "website"
    }
  };

  const currentConfig = config[appId] || {};

  return (
    <>
      <Helmet encodeSpecialCharacters={false}>
        <title>{currentConfig.title}</title>
        <meta name="description" content={currentConfig.description} />
        <meta itemProp="name" content={currentConfig.itemPropDescription} />
        <meta
          itemProp="description"
          content={currentConfig.itemPropDescriptionTwo}
        />
        <meta property="og:url" content={currentConfig.url} />
        <meta property="og:type" content={currentConfig.type} />
        <meta property="og:title" content={currentConfig.title} />
        <meta property="og:description" content={currentConfig.description} />
        <link rel="canonical" href={currentConfig.canonical} />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />

        {appId === "gat-rescue-frontend" ? (
          <script>
            {`window.dataLayer = window.dataLayer || [])(
                function gtag() {
                  dataLayer.push(arguments);
                },
                gtag("js", new Date()),
                gtag("config", "G-S0WRRK1N2J")
              `}
          </script>
        ) : null}

{appId === "gat-rescue-dk" ? (
          <script>
            {`window.dataLayer = window.dataLayer || [])(
                function gtag() {
                  dataLayer.push(arguments);
                },
                gtag("js", new Date()),
                gtag("config", "G-HJNNMCEJX8")
              `}
          </script>
        ) : null}

        {appId === "gat-rescue-frontend" ? (
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-S0WRRK1N2J"
          ></script>
        ) : null}



        {appId === "gat-rescue-dk" ? (
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-HJNNMCEJX8"
          ></script>
        ) : null}


        
        
        {appId === "gat-rescue-frontend" ? (
          <script
            id="Cookiebot"
            src="https://consent.cookiebot.com/uc.js"
            data-cbid="509aec90-b95a-402b-9103-3686a23b84e1"
            data-blockingmode="auto"
            type="text/javascript"
          />
        ) : null}

        {appId === "gat-rescue-frontend" ? (
          <script
            id="CookieDeclaration"
            src="https://consent.cookiebot.com/509aec90-b95a-402b-9103-3686a23b84e1/cd.js"
            type="text/javascript"
            async
          />
        ) : null}

        {appId === "gat-rescue-dk" ? (
          <script
            id="Cookiebot"
            src="https://consent.cookiebot.com/uc.js"
            data-cbid="9f17f02a-750d-4570-9f06-c8f3943f072b"
            data-blockingmode="auto"
            type="text/javascript"
          />
        ) : null}

        {appId === "gat-rescue-frontend" ? (
          <script>{`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
             })(window,document,'script','dataLayer','GTM-KC2CN99');
           `}</script>
        ) : null}

        {appId === "gat-rescue-dk" ? (
          <script>{`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
         })(window,document,'script','dataLayer','GTM-T2LKTQW4');
        `}</script>
        ) : null}


      </Helmet>
    </>
  );
};

export default HeaderTag;
