import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Divider } from "@assistansbolaget/gat-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBadgeDollar } from "@fortawesome/pro-light-svg-icons";
import { injectIntl } from "react-intl";
import { useSessionStorage } from "usehooks-ts";
import actions from "./actions";
import activeOrderSelectors from "../activeOrder/selectors";
import CardBoxContainer from "../../components/CardBoxContainer/CardBoxContainer";
import loadingSelector from "../loading/selectors";
import {
  CONFIRM_ORDER_VIEW,
  DAMAGE_REASON_VIEW,
  SEEKING_RESPONDER_VIEW,
  USER_TERMS_VIEW
} from "./viewConstants";
import "./confirmOrder.css";
import {
  BaseUrl,
  CLEAR_DESTINATION_CHANGE,
  CUSTOMER_ACCEPT_MISSION,
  EDIT_MISSION_REQUEST,
  FIND_DRIVER,
  GET_MISSIONS_BY_ID
} from "../../constants/apiConstants";
import { getSession, SESSION_KEYS } from "../../helpers/sessionStore";
import api from "../../helpers/apiUtils";
import {
  CANCELLED,
  MISSION_NOT_STARTED,
  WAITING_FOR_CUSTOMER_ACCEPT,
  WAITING_FOR_DRIVER_ACCEPT
} from "../../constants/orderStatusConstants";
import CustomerAcceptCountDown from "./customerAcceptCountDown";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import history from "../../history";
import { ACTIVE_ORDER_PATH } from "../../constants/routeConstants";
import createOrderSelectors from "./selectors";

const { getOrder, getEstimatedArrivalTimeSeconds } = activeOrderSelectors;
const { getPreviousView } = createOrderSelectors;
const {
  createOrder,
  deny,
  toggleNoConfirmationModal,
  setCurrentView,
  getPosition,
  setPreviousView
} = actions;

const { getLoading } = loadingSelector;

const ConfirmOrder = ({
  requestPosition,
  previousView,
  redirectToSeekingResponder,
  handlePreviousButton,
  loading,
  intl
}) => {
  const [gatLocale] = useSessionStorage(SESSION_KEYS.locale, undefined);
  const [gatOrgUnit] = useSessionStorage(
    SESSION_KEYS.gatOrganizationUnit,
    undefined
  );
  const accessToken = getSession(SESSION_KEYS.accessToken);
  const missionId = getSession(SESSION_KEYS.missionId);
  const appId = getSession(SESSION_KEYS.appId);
  const [currentOrder, setCurrentOrder] = useState({});
  const [smallLoaderState, setSmallLoaderState] = useState(false);
  const [findDriverLoadingState, setFindDriverLoadingState] = useState(false);

  const currentOrderVehiclePosition = currentOrder.locations;

  const destinationObject = currentOrderVehiclePosition?.find(
    item => item?.type === "Destination"
  );

  const sourceObject = currentOrderVehiclePosition?.find(
    item => item?.type === "Source"
  );

  const calculatedDestinationAddress = currentOrderVehiclePosition?.find(
    item => item?.type === "CalculatedDestination"
  );

  const renderDestinationAddress = () => {
    if (!destinationObject && !calculatedDestinationAddress) {
      return null;
    }

    if (destinationObject) {
      const { address, zipCode, city } = destinationObject;
      const addressParts = [];

      if (address) {
        addressParts.push(address);
      }

      if (zipCode) {
        addressParts.push(zipCode);
      }

      if (city) {
        addressParts.push(city);
      }

      if (addressParts.length > 0) {
        return addressParts.join(", ");
      }
    }

    if (calculatedDestinationAddress) {
      const {
        address,
        zipCode,
        city,
        coordinate
      } = calculatedDestinationAddress;
      const addressParts = [];

      if (coordinate.name) {
        addressParts.push(coordinate.name);
      }

      if (address) {
        addressParts.push(address);
      }

      if (zipCode) {
        addressParts.push(zipCode);
      }

      if (city) {
        addressParts.push(city);
      }

      if (addressParts.length > 0) {
        return addressParts.join(", ");
      }
    }

    return null;
  };

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const interval = setInterval(() => {
      if (!missionId) {
        return;
      }
      try {
        api
          .get(`${GET_MISSIONS_BY_ID}${missionId}`, BaseUrl.GatUrl, {
            gatOrgUnit,
            accessToken,
            gatLocale,
            appId
          })
          .then(response => {
            if (
              response.data.state === CANCELLED ||
              response.statusCode > 400 ||
              response.statusCode === undefined
            ) {
              redirectToSeekingResponder();
              return () => clearInterval(interval);
            }
            return setCurrentOrder(response.data);
          });
      } catch (error) {
        clearInterval(interval);
        redirectToSeekingResponder();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [missionId]);

  const locationObject = currentOrder?.locations?.find(
    item => item.type === "Destination"
  );

  const destinationButtonMessage = locationObject
    ? intl.formatMessage({
        id: "ConfirmOrder.UndoChangeDestinationButton",
        defaultMessage: "Undo change destination",
        description: "Change destination button text"
      })
    : intl.formatMessage({
        id: "ConfirmOrder.ChangeDestinationButton",
        defaultMessage: "Change destination",
        description: "Change destination button text"
      });

  // eslint-disable-next-line consistent-return
  const onClick = () => {
    if (locationObject) {
      setSmallLoaderState(true);
      return api
        .post(
          `${CLEAR_DESTINATION_CHANGE}`,
          BaseUrl.GatUrl,
          { missionId },
          {
            gatOrgUnit,
            accessToken,
            gatLocale,
            appId
          }
        )
        .then(() => {
          setSmallLoaderState(false);
        });
    }

    requestPosition();
    previousView();
  };

  const towingPrice = currentOrder?.orders?.find(
    item => item?.missionType?.externalReference === "Towing"
  );
  const assistancePrice = currentOrder?.orders?.find(
    item => item?.missionType?.externalReference === "Assistance"
  );
  const servicePrice = currentOrder?.orders?.find(
    item => item?.category === "Service"
  );

  const formatPrice = price => {
    if (price == null) return "";
    const roundedPrice = Math.round(price * 100) / 100;
    return roundedPrice % 1 === 0
      ? roundedPrice.toString()
      : roundedPrice.toFixed(2);
  };

  const totalTowingPrice = formatPrice(
    towingPrice?.total + towingPrice?.calculatedVAT
  );
  const totalAssistancePrice = formatPrice(
    assistancePrice?.total + assistancePrice?.calculatedVAT
  );
  const totalServicePrice = formatPrice(
    servicePrice?.total + servicePrice?.calculatedVAT
  );

  const showAssistanceOrService = assistancePrice || servicePrice;
  const showTowingOrService = towingPrice || servicePrice;

  const timeToResolve = 180;

  const findDriver = () => {
    try {
      setFindDriverLoadingState(true);
      api
        .post(
          `${FIND_DRIVER}`,
          BaseUrl.GatUrl,
          { missionId },
          {
            gatOrgUnit,
            accessToken,
            gatLocale,
            appId
          }
        )
        .then(res => {
          setFindDriverLoadingState(false);
          if (res.statusCode > 399 || res.data.currentDriver === null) {
            redirectToSeekingResponder();
          }
        });
    } catch (e) {
      setFindDriverLoadingState(false);
      redirectToSeekingResponder();
    }
  };

  const customerAcceptMission = () => {
    try {
      api
        .post(
          `${CUSTOMER_ACCEPT_MISSION}`,
          BaseUrl.GatUrl,
          { missionId },
          {
            gatOrgUnit,
            accessToken,
            gatLocale,
            appId
          }
        )
        .then(() => {
          history.push(`${ACTIVE_ORDER_PATH}`);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const loadingStates =
    findDriverLoadingState ||
    currentOrder?.state === WAITING_FOR_DRIVER_ACCEPT ||
    !currentOrder.id ||
    loading;

  const providerArrivalAt = currentOrder?.segments?.find(
    item => item?.endType === "Source"
  );

  function formatArrivalTime(arrivalTime) {
    if (arrivalTime) {
      const dateObj = new Date(arrivalTime);
      const hours = dateObj.getHours();
      const minutes = dateObj.getMinutes();
      const formattedTime = `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
      return formattedTime;
    }
    return "";
  }

  const handleBackButton = () => {
    try {
      api
        .post(
          `${EDIT_MISSION_REQUEST}`,
          BaseUrl.GatUrl,
          { missionId },
          {
            gatOrgUnit,
            accessToken,
            gatLocale,
            appId
          }
        )
        .then(() => {
          handlePreviousButton();
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {loadingStates ? (
        <LoadingSpinner
          loading={loadingStates}
          infoText={
            currentOrder?.state === WAITING_FOR_DRIVER_ACCEPT
              ? intl.formatMessage({
                  id: "SeekingResponder.LoadingPage",
                  defaultMessage: "We are looking for drivers in your area.",
                  description: "Searching driver"
                })
              : null
          }
        />
      ) : (
        <CardBoxContainer>
          <div className="confirmOrder-header-container">
            <FontAwesomeIcon
              icon={faBadgeDollar}
              color="#ffd4a3"
              style={{ width: "2rem", height: "2rem" }}
            />
            {currentOrder.state === WAITING_FOR_CUSTOMER_ACCEPT ? (
              <div className="confirmOrder-header">
                <p>
                  {intl.formatMessage({
                    id: "general.confirm",
                    defaultMessage: "Confirm",
                    description: "Confirm"
                  })}
                </p>
              </div>
            ) : (
              <div className="confirmOrder-header">
                <p>
                  {intl.formatMessage({
                    id: "ConfirmOrder.Title",
                    defaultMessage: "Estimated price",
                    description: "Title for first price modal"
                  })}
                </p>
              </div>
            )}
          </div>
          <div className="confirmOrder-infotext">
            <p>
              {intl.formatMessage({
                id: "ConfirmOrder.BreadTextBeforeAssistancePrice",
                defaultMessage:
                  "If we can can repair the vehicle on the spot, you only pay ",
                description: "Text before the first price in estimated price"
              })}{" "}
              <strong>
                {showAssistanceOrService &&
                  `${totalAssistancePrice || totalServicePrice} ${
                    currentOrder?.currency
                  }`}
              </strong>{" "}
              <br></br>{" "}
              {intl.formatMessage({
                id: "ConfirmOrder.BreadTextAfterAssistancePrice",
                defaultMessage:
                  "If your vehicle is in need of towing, you will have to pay ",
                description: "Text after the first price in estimated price"
              })}{" "}
              <strong>
                {showTowingOrService &&
                  `${totalTowingPrice || totalServicePrice} ${
                    currentOrder?.currency
                  }`}
              </strong>
            </p>
          </div>
          {currentOrder.state === WAITING_FOR_CUSTOMER_ACCEPT && (
            <div className="confirmOrder-infoBox">
              <span className="infoBox-header">
                <p>
                  {intl.formatMessage({
                    id: "ConfirmOrder.ProviderEta",
                    defaultMessage: "Driver ETA",
                    description: "Drivers ETA"
                  })}
                </p>
                <p>{`${formatArrivalTime(
                  providerArrivalAt?.estimatedArrivalAt
                )}`}</p>
              </span>
              <Divider variant="line" width="100%" />{" "}
            </div>
          )}
          <div className="confirmOrder-infoBox">
            <span className="infoBox-header">
              <p>
                {intl.formatMessage({
                  id: "ConfirmOrder.AssistancePriceSubTitle",
                  defaultMessage: "On the spot",
                  description: "Sub title for assistance"
                })}
              </p>
              <p>
                {showAssistanceOrService &&
                  `${totalAssistancePrice || totalServicePrice} ${
                    currentOrder?.currency
                  }`}
              </p>
            </span>
            <span className="infoBox-text">
              <p>{sourceObject?.address} </p>
            </span>
            <Divider variant="line" width="100%" />{" "}
          </div>
          <div className="confirmOrder-infoBox">
            <span className="infoBox-header">
              <p>
                {intl.formatMessage({
                  id: "ConfirmOrder.TowingPriceSubTitle",
                  defaultMessage: "Towing",
                  description: "Sub title for towing"
                })}
              </p>
              <p>
                {showTowingOrService &&
                  `${totalTowingPrice || totalServicePrice} ${
                    currentOrder?.currency
                  }`}
              </p>
            </span>
            <span className="infoBox-text">
              <p>{renderDestinationAddress()}</p>
            </span>
          </div>
          {currentOrder.state === MISSION_NOT_STARTED && (
            <div className="change-destinationButton">
              <Button
                title={destinationButtonMessage.toUpperCase()}
                kind="Outline"
                fullWidth
                disabled={smallLoaderState}
                onClick={onClick}
              />
              <Divider variant="line" width="100%" />
            </div>
          )}
          {currentOrder.state === WAITING_FOR_CUSTOMER_ACCEPT && (
            <>
              <CustomerAcceptCountDown countdownTime={timeToResolve} />
            </>
          )}
          <div className="confirmOrder-buttonContainer">
            {currentOrder.state === WAITING_FOR_CUSTOMER_ACCEPT ? (
              <Button
                id="acceptPrice4"
                fullWidth
                title={intl
                  .formatMessage({
                    id: "general.confirm",
                    defaultMessage: "Confirm",
                    description: "Confirm"
                  })
                  .toUpperCase()}
                onClick={customerAcceptMission}
              />
            ) : (
              <Button
                id="acceptMission4"
                fullWidth
                disabled={smallLoaderState}
                title={intl
                  .formatMessage({
                    id: "general.accept",
                    defaultMessage: "Accept",
                    description: "Accept for buttons"
                  })
                  .toUpperCase()}
                onClick={findDriver}
              />
            )}

            {currentOrder.state !== WAITING_FOR_CUSTOMER_ACCEPT && (
              <Button
                fullWidth
                title={intl
                  .formatMessage({
                    id: "general.goBack",
                    defaultMessage: "Go back",
                    description: "Go back"
                  })
                  .toUpperCase()}
                kind="Ghost"
                onClick={handleBackButton}
              />
            )}
          </div>
        </CardBoxContainer>
      )}
    </>
  );
};

ConfirmOrder.propTypes = {
  order: PropTypes.shape({
    s_address: PropTypes.string,
    updated_at: PropTypes.string,
    assistance_price: PropTypes.number,
    d_address: PropTypes.string,
    workshop_city: PropTypes.string,
    workshop_name: PropTypes.string,
    workshop_address: PropTypes.string,
    towing_price: PropTypes.number
  }).isRequired,
  requestPosition: PropTypes.func.isRequired,
  previousView: PropTypes.func.isRequired,
  redirectToSeekingResponder: PropTypes.func.isRequired,
  handlePreviousButton: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired
};

const mapStateToProps = state => ({
  order: getOrder(state),
  timeSeconds: getEstimatedArrivalTimeSeconds(state),
  previous: getPreviousView(state),
  loading: getLoading(state)
});

const mapDispatchToProps = dispatch => ({
  previousView: () => dispatch(setPreviousView(CONFIRM_ORDER_VIEW)),
  confirmOrder: () => {
    dispatch(createOrder());
  },
  requestPosition: () => dispatch(getPosition()),
  openTerms: () => dispatch(setCurrentView(USER_TERMS_VIEW)),
  abortOrder: () => dispatch(deny()),
  showNoConfirmationModal: () => dispatch(toggleNoConfirmationModal(true)),
  redirectToSeekingResponder: () =>
    dispatch(setCurrentView(SEEKING_RESPONDER_VIEW)),
  handlePreviousButton: () => dispatch(setCurrentView(DAMAGE_REASON_VIEW))
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(ConfirmOrder)
);
