import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import history from "../../history";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import {
  selectors as createOrderSelectors,
  actions as createOrderActions
} from "../createOrder";
import { CREATE_ORDER_PATH } from "../../constants/routeConstants";
import Modal from "../../components/Modal/Modal";
import languageHelper from "../../helpers/languageHelper";
import "./style.css";

const language = languageHelper();

const { login, toggleUnexpectedErrorModal } = createOrderActions;
const { getUnexpectedErrorModalState } = createOrderSelectors;

class LoginTriggerComponent extends Component {
  componentDidMount() {
    const { loginWithUrlParameters } = this.props;
    if (
      window.location.search.includes("id") &&
      window.location.search.includes("phone") &&
      (window.location.search.includes("regno") ||
        window.location.search.includes("transport"))
    ) {
      loginWithUrlParameters();
    }
  }

  render() {
    const {
      unexpectedErrorModalState,
      closeUnexpectedErrorModal,
      intl
    } = this.props;
    return (
      <>
        {unexpectedErrorModalState && (
          <Modal
            title={intl.formatMessage({
              id: "ErrorModal.Title",
              defaultMessage: "Something went wrong",
              description: "error modal title"
            })}
            bodyText={intl.formatMessage({
              id: "ErrorModal.BreadText",
              defaultMessage:
                "Something went wrong. We will contact you as soon as possible",
              description: "error modal title"
            })}
            primaryModalButtonAction={closeUnexpectedErrorModal}
            primaryModalButtonText={
              language.createOrder.unexpectedErrorModal.button
            }
          />
        )}
        {!unexpectedErrorModalState && (
          <>
            <LoadingSpinner loading />
            <div className="flex justify-center align-center">
              <div className="login-title">
                {language.loginTriggerComponent.text}
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

LoginTriggerComponent.propTypes = {
  unexpectedErrorModalState: PropTypes.bool.isRequired,
  closeUnexpectedErrorModal: PropTypes.func.isRequired,
  loginWithUrlParameters: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired
};

const mapStateToProps = state => ({
  unexpectedErrorModalState: getUnexpectedErrorModalState(state)
});

const mapDispatchToProps = dispatch => ({
  loginWithUrlParameters: () => dispatch(login()),
  closeUnexpectedErrorModal: () => {
    dispatch(toggleUnexpectedErrorModal(false));
    history.push(CREATE_ORDER_PATH);
  }
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(LoginTriggerComponent)
);
