import { call, put, select, takeLatest } from "redux-saga/effects";
import { getFormValues } from "redux-form";
import {
  SUMMARY_SUBMITTED,
  RECEIPT_SUBMITTED,
  POST_RECEIPT_SUCCESS,
  TOGGLE_RECEIPT_FAILURE_MODAL,
  POST_RECEIPT
} from "./constants";
import selectors from "./selectors";
import service from "./service";
import actions from "./actions";
import activeOrderSelectors from "../activeOrder/selectors";
import validators from "./validators";
import toggleLoading from "../loading/actions";
import logErrorHelper from "../../helpers/logErrorHelper";

const { toggleSummarySuccessModal } = actions;
const { getRating } = selectors;
const { getOrder } = activeOrderSelectors;
const { postReview, postReceipt } = service;
const { validateEmail } = validators;

function* submitSummaryWorker() {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  try {
    const order = yield select(getOrder);
    const opinionFormValue = yield select(getFormValues("receiptForm"));
    const rating = yield select(getRating);
    let summaryBody;
    if (opinionFormValue) {
      summaryBody = {
        missionId: order.id,
        rating,
        review: opinionFormValue.message,
        token
      };
    } else {
      summaryBody = {
        request_id: order.id,
        rating,
        token
      };
    }
    yield put(toggleLoading(true));
    yield call(postReview, summaryBody);
    yield put(toggleLoading(false));
    yield put(toggleSummarySuccessModal(true));
  } catch (error) {
    yield put(toggleLoading(false));
    logErrorHelper(error);
  }
}

function* registerEmailWorker() {
  try {
    const order = yield select(getOrder);
    const formValue = yield select(getFormValues("receiptForm"));
    if (
      formValue &&
      !validateEmail(formValue.email) &&
      order &&
      order.damageOrderNumber
    ) {
      const receiptBody = {
        orderNumber: order.damageOrderNumber,
        email: formValue.email
      };
      yield put({ type: POST_RECEIPT });
      const result = yield call(postReceipt, receiptBody);
      if (result.ok) {
        yield put({ type: POST_RECEIPT_SUCCESS, payload: true });
      } else {
        yield put({ type: TOGGLE_RECEIPT_FAILURE_MODAL, payload: true });
      }
    }
  } catch (err) {
    yield put({ type: TOGGLE_RECEIPT_FAILURE_MODAL, payload: true });
  }
}

function* summarySagaWatcher() {
  yield takeLatest(SUMMARY_SUBMITTED, submitSummaryWorker);
}

function* registerEmailSagaWatcher() {
  yield takeLatest(RECEIPT_SUBMITTED, registerEmailWorker);
}

export default { summarySagaWatcher, registerEmailSagaWatcher };
