import styled from "styled-components";
import {Button, Input, InputStyles} from "@assistansbolaget/gat-components";
import PhoneInput from "react-phone-number-input";

export const LocationsHandlerButton = styled(Button)`
  margin: 0.5rem 0;
  font-size: 10px;
  background-color: rgba(255, 212, 163, 0.15);
  color: #ffd4a3;
`;

export const CustomerDetailsInput = styled(Input)`
  width: 100%;
  margin-bottom: 25px;
`;

export const PhoneNumberContainer = styled(InputStyles.Container)`
  margin-bottom: 25px;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition: color 600000s ease-out, background-color 600000s ease-out; // HACK to remove the background color of the autofill
    -webkit-transition-delay: 600000s;
  }
`;


export const PhoneNumber = styled(PhoneInput)`
  --PhoneInput-color--focus: ${(p) => p.theme.palette.primary.main};

  .PhoneInputCountry {
    background-color: transparent;
    align-self: center;
    border-radius: 0;
  } 
  
  .PhoneInputCountrySelect {
    align-self: center;
  }

  .PhoneInputInput {
    background-color: transparent;
    color: ${(p) => p.theme.palette.layout.depth6};
    font-size: ${(p) => p.theme.fontSize.sm};
  }
`;

