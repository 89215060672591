import styled from "styled-components";
import { Card } from "@assistansbolaget/gat-components";

const StyledInfoCard = styled(Card)`
  width: 370px;
  height: fit-content;
  padding: 2rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
`;

export default StyledInfoCard;
