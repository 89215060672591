import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DirectionsMap from "./DirectionsMap";
import welcomeScreenSelectors from "../welcomeScreen/selectors";
import OrderCompleteMap from "./OrderCompleteMap";
import { CANCELLED, FINISHED } from "../../constants/orderStatusConstants";
import activeOrderSelectors from "../activeOrder/selectors";

const {
  getVehiclePosition,
  getTowtruckCoordinates,
  getPreviousTowtruckCoordinates,
  getShopCoordinates
} = welcomeScreenSelectors;

const { getOrder } = activeOrderSelectors;

const ActiveOrderMapHandler = ({
  vehicleCoordinates,
  towtruckCoordinates,
  previousTowtruckCoordinates,
  shopCoordinates,
  order
}) => {
  const [animateTowtruckCoordinates, setAnimateTowtruckCoordinates] = useState(
    null
  );

  const coordinatesHasChanged = (newLat, newLng, oldLat, oldLng) =>
    Math.abs(oldLat - newLat) > 0.00001 && Math.abs(oldLng - newLng) > 0.00001;

  useEffect(() => {
    if (
      coordinatesHasChanged(
        towtruckCoordinates.latitude,
        towtruckCoordinates.longitude,
        previousTowtruckCoordinates.latitude,
        previousTowtruckCoordinates.longitude
      )
    ) {
      const DELTA_SIZE = 110;
      const deltaLng =
        (towtruckCoordinates.longitude -
          previousTowtruckCoordinates.longitude) /
        DELTA_SIZE;
      const deltaLat =
        (towtruckCoordinates.latitude - previousTowtruckCoordinates.latitude) /
        DELTA_SIZE;
      for (let i = 0; i < DELTA_SIZE; i++) {
        setTimeout(
          () =>
            setAnimateTowtruckCoordinates({
              longitude: previousTowtruckCoordinates.longitude + i * deltaLng,
              latitude: previousTowtruckCoordinates.latitude + i * deltaLat
            }),
          i * 20
        );
      }
    }
  }, [towtruckCoordinates, previousTowtruckCoordinates]);

  return (
    <>
      {order.state === FINISHED || order.state === CANCELLED ? (
        <OrderCompleteMap vehicleCoordinates={shopCoordinates} />
      ) : (
        <DirectionsMap
          orderStatus={order.state}
          towtruckCoordinates={
            animateTowtruckCoordinates || towtruckCoordinates
          }
          shopCoordinates={shopCoordinates}
          previousTowtruckCoordinates={previousTowtruckCoordinates}
          vehicleCoordinates={vehicleCoordinates}
        />
      )}
    </>
  );
};

ActiveOrderMapHandler.propTypes = {
  order: PropTypes.shape({
    state: PropTypes.string
  }).isRequired,
  vehicleCoordinates: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }).isRequired,
  towtruckCoordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }).isRequired,
  shopCoordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }).isRequired,
  previousTowtruckCoordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }).isRequired
};

const mapStateToProps = state => ({
  vehicleCoordinates: getVehiclePosition(state),
  shopCoordinates: getShopCoordinates(state),
  towtruckCoordinates: getTowtruckCoordinates(state),
  previousTowtruckCoordinates: getPreviousTowtruckCoordinates(state),
  order: getOrder(state)
});

export default connect(mapStateToProps, null)(ActiveOrderMapHandler);
