export const CANCEL_ESTIMATED_ARRIVAL_TIME_POLLER =
  "CANCEL_ESTIMATED_ARRIVAL_TIME_POLLER";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
export const CLOSE_CANCEL_ORDER_MODAL = "CLOSE_CANCEL_ORDER_MODAL";
export const FETCH_TOWTRUCK_POSITION = "FETCH_TOWTRUCK_POSITION";
export const GET_ESTIMATED_ARRIVAL_TIME_FAILURE =
  "GET_ESTIMATED_ARRIVAL_TIME_FAILURE";
export const GET_ESTIMATED_ARRIVAL_TIME_SUCCESS =
  "GET_ESTIMATED_ARRIVAL_TIME_SUCCESS";
export const GET_ESTIMATED_ARRIVAL_TIME = "GET_ESTIMATED_ARRIVAL_TIME";
export const OPEN_CANCEL_ORDER_MODAL = "OPEN_CANCEL_ORDER_MODAL";
export const SET_ORDER_STATUS = "SET_ORDER_STATUS";
export const SET_TOWTRUCK_LOCATION = "SET_TOWTRUCK_LOCATION";
export const FIND_CURRENT_VIEW = "FIND_CURRENT_VIEW";
export const START_CANCEL_ORDER = "START_CANCEL_ORDER";
export const TOGGLE_STARTED_ORDER_MODAL = "TOGGLE_STARTED_ORDER_MODAL";
export const TOW_TRUCK_CANCELLED = "TOW_TRUCK_CANCELLED";
export const CANCEL_FETCH_TOWTRUCK_POSITION = "CANCEL_FETCH_TOWTRUCK_POSITION";

export const GET_MISSION_BY_TOKEN = "GET_MISSION_BY_TOKEN";
export const GET_MISSION_BY_TOKEN_SUCCESS = "GET_MISSION_BY_TOKEN_SUCCESS";
export const GET_MISSION_BY_TOKEN_FAILURE = "GET_MISSION_BY_TOKEN_FAILURE";
