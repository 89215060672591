import { fromJS } from "immutable";
import * as constants from "./constants";

const initialState = fromJS({
  map: null,
  centerCoords: null,
});

const reducer = (state = initialState, action) => {
  let newState = state;
  switch (action.type) {
    case constants.SAVE_MAP_TO_STATE:
      newState = newState.set("map", action.payload);
      return newState;
    case constants.SAVE_CENTER_COORDS:
      newState = newState.set("centerCoords", action.payload);
      return newState;
    default:
      return newState;
  }
};

export default { reducer };
