/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
// import check from "../../assets/images/check-solid-blue.svg";

import "./style.css";
import { LocationsHandlerButton } from "../../features/createOrder/customerDetails.style";

const PositionInput = ({ input, customFormerror, ...rest }) => {
  return (
    <div className="input-container">
      <LocationsHandlerButton kind="Secondary" fullWidth onClick={rest.onClick}>
        {rest.label}
      </LocationsHandlerButton>
    </div>
  );
};

PositionInput.defaultProps = {
  image: null,
  loading: false,
  customFormerror: false,
  customErrorStyle: false
};

PositionInput.propTypes = {
  input: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  image: PropTypes.string,
  loading: PropTypes.bool,
  customFormerror: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  customErrorStyle: PropTypes.bool
};

export default PositionInput;
