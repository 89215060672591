/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable react/no-array-index-key */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import actions from "./actions";
import starSolid from "../../assets/images/star-solid.svg";
import star from "../../assets/images/star.svg";

const { setRating } = actions;

const Star = ({ selected, onClick }) => (
  <img
    src={selected ? starSolid : star}
    alt="rating"
    onClick={onClick}
    role="button"
    onKeyDown={onClick}
    tabIndex={0}
  />
);

Star.propTypes = {
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

Star.defaultProps = {
  selected: false
};

class Rate extends Component {
  constructor(props) {
    super(props);
    this.state = { starsSelected: 0 };
  }

  change = starsSelected => {
    const { storeRating } = this.props;
    this.setState({ starsSelected });
    storeRating(starsSelected);
  };

  render() {
    const { totalStars } = this.props;
    const { starsSelected } = this.state;

    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        {[...Array(totalStars)].map((n, i) => (
          <Star
            key={i}
            selected={i < starsSelected}
            onClick={() => this.change(i + 1)}
          />
        ))}
      </div>
    );
  }
}

Rate.propTypes = {
  totalStars: PropTypes.number,
  storeRating: PropTypes.func.isRequired
};

Rate.defaultProps = {
  totalStars: 5
};

const mapDispatchToProps = dispatch => ({
  storeRating: starsSelected => dispatch(setRating(starsSelected))
});

export default connect(null, mapDispatchToProps)(Rate);
