import { fromJS } from "immutable";
import languageHelper from "../../helpers/languageHelper";
import * as constants from "./constants";
import { CUSTOMER_DETAILS_VIEW } from "./viewConstants";

const language = languageHelper();

const initialState = fromJS({
  isFetchingVehicle: false,
  isFetchingPerson: false,
  view: { current: CUSTOMER_DETAILS_VIEW, next: "", previous: "" },
  toggleForm: false,
  noConfirmationModalState: false,
  unexpectedErrorModalState: false,
  alreadyStartedErrorModalState: false,
  estimatePriceCollectedCoords: null,
  estimateAssistancePrice: 0,
  estimateTowingPrice: 0,
  estimateCalculatedAssistancePrice: 0,
  estimateCalculatedTowingPrice: 0,
  estimateTime: {
    string: "",
    seconds: 0
  },
  socialNumberFieldError: { show: false, message: "", type: "" },
  isCustomer: false,
  customerType: undefined,
  regnoFieldError: { show: false, message: "", type: "" },
  fetchingFinalPrice: false,
  finalPrice: {
    assistance_price: null,
    calculated_assistance_price: null,
    distance: null,
    driver_latitude: null,
    driver_longitude: null,
    time: null,
    towing_price: null,
    calculated_towing_price: null,
    unit: null,
    workshop_latitude: null,
    workshop_longitude: null
  },
  towDestination: { showTowDestination: false },
  previousEstimate: { show: false, price: 0 },
  searchPlacesResult: {},
  cancelledByDispatchModalState: false,
  dispatchWillCallModalState: false,
  hasPreviousVehiclePositionChanged: false,
  damageReasons: [],
  confinedSpaces: "",
  selfServeMission: {},
  isAccepted: false
});

const reducer = (state = initialState, action) => {
  let newState = state;
  switch (action.type) {
    case constants.FETCH_ESTIMATE_SUCCESS:
      newState = newState.set(
        "estimateAssistancePrice",
        action.estimate.assistance_price
      );
      newState = newState.set(
        "estimateTowingPrice",
        action.estimate.towing_price
      );
      newState = newState.set(
        "estimateCalculatedAssistancePrice",
        action.estimate.calculated_assistance_price
      );
      newState = newState.set(
        "estimateCalculatedTowingPrice",
        action.estimate.calculated_towing_price
      );
      newState = newState.setIn(
        ["estimateTime", "string"],
        action.estimate.time
      );
      newState = newState.setIn(
        ["estimateTime", "seconds"],
        action.estimate.time_seconds
      );
      newState = newState.setIn(["customerType"], action.estimate.customerType);
      return newState;
    case constants.FETCH_ESTIMATE_SUCCESS_COORDS:
      newState = newState.set("estimatePriceCollectedCoords", action.payload);
      return newState;
    case constants.GO_FORWARD: {
      const next = newState.getIn(["view", "next"]);
      const current = newState.getIn(["view", "current"]);
      newState = newState.setIn(["view", "previous"], current);
      newState = newState.setIn(["view", "current"], next);
      newState = newState.setIn(["view", "next"], "");
      return newState;
    }
    case constants.GO_BACK: {
      const previous = newState.getIn(["view", "previous"]);
      const current = newState.getIn(["view", "current"]);
      newState = newState.setIn(["view", "previous"], "");
      newState = newState.setIn(["view", "next"], current);
      newState = newState.setIn(["view", "current"], previous);
      return newState;
    }
    case constants.SET_NEXT_VIEW:
      newState = newState.setIn(["view", "next"], action.payload);
      return newState;
    case constants.SET_CURRENT_VIEW:
      newState = newState.setIn(["view", "current"], action.payload);
      return newState;
    case constants.SET_MODAL_VIEW: {
      const current = newState.getIn(["view", "current"]);
      newState = newState.setIn(["view", "modalBack"], current);
      newState = newState.setIn(["view", "current"], action.payload);
      return newState;
    }
    case constants.MODAL_GO_BACK: {
      const current = newState.getIn(["view", "modalBack"]);
      newState = newState.setIn(["view", "modalBack"], "");
      newState = newState.setIn(["view", "current"], current);
      return newState;
    }
    case constants.SET_PREVIOUS_VIEW:
      newState = newState.setIn(["view", "previous"], action.payload);
      return newState;
    case constants.FETCHING_VEHICLE:
      newState = newState.set("isFetchingVehicle", true);
      newState = newState.set("regnoFieldError", {
        show: false,
        message: "",
        type: ""
      });
      return newState;
    case constants.GET_VEHICLE_DETAILS_SUCCESS:
      newState = newState.set("isFetchingVehicle", false);
      newState = newState.set("regnoFieldError", {
        show: false,
        message: "",
        type: ""
      });
      return newState;
    case constants.ERROR_VEHICLE_NOT_FOUND:
      newState = newState.set("isFetchingVehicle", false);
      newState = newState.set("regnoFieldError", {
        show: true,
        message: language.errors.vehicleNotFound,
        type: "regno"
      });
      return newState;
    case constants.GET_VEHICLE_DETAILS_FAILURE:
      newState = newState.set("isFetchingVehicle", false);
      newState = newState.set("regnoFieldError", {
        show: true,
        message: language.errors.fetchVehicleFailure,
        type: "regno"
      });
      return newState;
    case constants.FETCH_DAMAGE_REASONS_SUCCESS: {
      newState = newState.set("damageReasons", action.payload);
      return newState;
    }
    case constants.FETCH_CONFINED_SPACE_ATTRIBUTES_SUCCESS: {
      const { content } = action.payload;
      newState = newState.set("confinedSpaces", content[0].id);
      return newState;
    }
    case constants.FETCH_VEHICLE_INSURANCE_LEVELS_SUCCESS: {
      newState = newState.set("insuranceLevels", action.payload);
      return newState;
    }
    case constants.START_FETCH_FINAL_PRICE:
      newState = newState.set("fetchingFinalPrice", true);
      return newState;
    case constants.FETCH_FINAL_PRICE_SUCCESS:
      newState = newState.set("fetchingFinalPrice", false);
      newState = newState.set("finalPrice", action.finalPrice);
      return newState;
    case constants.FETCH_FINAL_PRICE_FAILURE:
      newState = newState.set("fetchingFinalPrice", false);
      return newState;
    case constants.TOGGLE_NO_CONFIRMATION_MODAL:
      newState = newState.set("noConfirmationModalState", action.payload);
      return newState;
    case constants.TOGGLE_ALREADY_STARTED_ERROR_MODAL:
      newState = newState.set("alreadyStartedErrorModalState", action.payload);
      return newState;
    case constants.TOGGLE_UNEXPECTED_ERROR_MODAL:
      newState = newState.set("unexpectedErrorModalState", action.payload);
      return newState;
    case constants.FETCHING_PERSON:
      newState = newState.set("isFetchingPerson", true);
      newState = newState.set("socialNumberFieldError", {
        show: false,
        message: "",
        type: ""
      });
      return newState;
    case constants.FETCHING_PERSON_SUCCESS:
      newState = newState.set("isFetchingPerson", false);
      newState = newState.set("isCustomer", true);
      newState = newState.set("socialNumberFieldError", {
        show: false,
        message: "",
        type: ""
      });
      return newState;
    case constants.GET_PERSON_DETAILS_FAILURE:
      newState = newState.set("isCustomer", false);
      newState = newState.set("isFetchingPerson", false);
      newState = newState.set("socialNumberFieldError", {
        show: true,
        message: language.errors.fetchPersonFailure,
        type: "socialNumber"
      });
      return newState;
    case constants.PERSON_NOT_FOUND:
      newState = newState.set("isFetchingPerson", false);
      newState = newState.set("isCustomer", false);
      newState = newState.set("socialNumberFieldError", {
        show: true,
        message: language.errors.personNotFound,
        type: "socialNumber"
      });
      return newState;
    case constants.TOGGLE_ESTIMATE_DESTINATION: {
      const towDestination = newState.getIn([
        "towDestination",
        "showTowDestination"
      ]);
      newState = newState.setIn(
        ["towDestination", "showTowDestination"],
        !towDestination
      );
      return newState;
    }
    case constants.SET_TOGGLE_ESTIMATE_DESTINATION_VALUE: {
      newState = newState.setIn(
        ["towDestination", "showTowDestination"],
        action.payload
      );
      return newState;
    }
    case constants.SET_PREVIOUS_PRICE:
      newState = newState.setIn(["previousEstimate", "price"], action.payload);
      return newState;
    case constants.SET_SHOW_PREVIOUS_PRICE:
      newState = newState.setIn(["previousEstimate", "show"], action.show);
      return newState;
    case constants.SET_SEARCH_PLACES_RESULT:
      newState = newState.set("searchPlacesResult", action.payload);
      return newState;
    case constants.TOGGLE_ORDER_CANCELLED_BY_DISPATCH_MODAL:
      newState = newState.set("cancelledByDispatchModalState", action.payload);
      return newState;
    case constants.TOGGLE_DISPATCH_WILL_CALL_MODAL:
      newState = newState.set("dispatchWillCallModalState", action.payload);
      return newState;
    case constants.SET_NO_ESTIMATE_REASON:
      newState = newState.set("noEstimateReason", action.payload);
      return newState;
    case constants.SET_HAS_PREVIOUS_VEHICLE_POSITION_CHANGED:
      newState = newState.set(
        "hasPreviousVehiclePositionChanged",
        action.payload
      );
      return newState;
    case constants.SAVE_SELF_SERVE_MISSION_SUCCESS:
      newState = newState.set("selfServeMission", action.payload);
      return newState;

    case constants.ACCEPT_COOKIE_VALUE:
      newState = newState.set("isAccepted", true);
      return newState;

    default:
      return newState;
  }
};

export default { reducer };
