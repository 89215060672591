import {
  GET_LOCATION,
  RESET_POSITION,
  TOGGLE_NO_TOW_TRUCKS_MODAL,
  TOGGLE_NO_LOCATION_SERVICE_MODAL,
  RESUME_ORDER,
  SET_UNSAVED_SHOP_LOCATION,
  SET_VEHICLE_LOCATION,
  FETCHING_REVERSE_GEOCODE,
  CLEAR_VEHICLE_POSITION,
  SAVE_DESTINATION_PLACE,
  SAVE_DESTINATION_CHANGER_WITH_ALL_INFO,
  SET_POSITION_TO_SERVE
} from "./constants";

const getLocation = () => ({
  type: GET_LOCATION
});

const resumeOrder = () => ({
  type: RESUME_ORDER
});

const resetPosition = () => ({
  type: RESET_POSITION
});
const toggleNoTowTrucksModal = bool => ({
  type: TOGGLE_NO_TOW_TRUCKS_MODAL,
  payload: bool
});

const toggleNoLocationServiceModal = bool => ({
  type: TOGGLE_NO_LOCATION_SERVICE_MODAL,
  payload: bool
});

const handleUnsavedShopPosition = position => ({
  type: SET_UNSAVED_SHOP_LOCATION,
  payload: position
});

const setVehicleLocation = vehiclePosition => ({
  type: SET_VEHICLE_LOCATION,
  payload: vehiclePosition
});

const setDestinationChangePlace = vehiclePosition => ({
  type: SAVE_DESTINATION_PLACE,
  payload: vehiclePosition
});

const fetchingReverseGeocode = boolean => ({
  type: FETCHING_REVERSE_GEOCODE,
  payload: boolean
});

const clearVehiclePosition = () => ({
  type: CLEAR_VEHICLE_POSITION
});

const setDestinationToState = destination => ({
  type: SAVE_DESTINATION_CHANGER_WITH_ALL_INFO,
  payload: destination
});

const setPositionToServe = position => ({
  type: SET_POSITION_TO_SERVE,
  payload: position
});

export default {
  getLocation,
  resetPosition,
  toggleNoTowTrucksModal,
  toggleNoLocationServiceModal,
  resumeOrder,
  handleUnsavedShopPosition,
  setVehicleLocation,
  fetchingReverseGeocode,
  clearVehiclePosition,
  setDestinationChangePlace,
  setDestinationToState,
  setPositionToServe
};
