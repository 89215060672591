export const FETCHING_REVERSE_GEOCODE = "FETCHING_REVERSE_GEOCODE";
export const GET_LOCATION_SUCCESS = "GET_LOCATION_SUCCESS";
export const GET_LOCATION = "GET_LOCATION";
export const RESET_POSITION = "RESET_POSITION";
export const RESUME_ORDER = "RESUME_ORDER";
export const SET_VEHICLE_LOCATION = "SET_VEHICLE_LOCATION";
export const TOGGLE_NO_LOCATION_SERVICE_MODAL =
  "TOGGLE_NO_LOCATION_SERVICE_MODAL";
export const TOGGLE_NO_TOW_TRUCKS_MODAL = "TOGGLE_NO_TOW_TRUCKS_MODAL";
export const SET_UNSAVED_SHOP_LOCATION = "SET_UNSAVED_SHOP_LOCATION";
export const CLEAR_VEHICLE_POSITION = "CLEAR_VEHICLE_POSITION";

export const SAVE_DESTINATION_PLACE = "SAVE_DESTINATION_PLACE";
export const SAVE_DESTINATION_CHANGER_WITH_ALL_INFO =
  "SAVE_DESTINATION_CHANGER_WITH_ALL_INFO";
export const SET_POSITION_TO_SERVE = "SET_POSITION_TO_SERVE";
